import React, { useEffect, useState } from 'react'
import { getDomains, getVolum } from '../../../../services/api/request';
import { Server } from '../../../../types';
import OneLine from '../../../../components/shared/object/oneLine';
import WithDivider from '../../../../components/shared/WithDivider';
import ReactSvg from '../../../../components/shared/ReactSvg';
import ObjectMode from '../../../../components/shared/details/modes/Object';
import { storageUrl } from '../../../../services/api/constants';
import Icon from '../../../../components/icons/Icon';
import { Spin } from 'antd';
import { ACTIVATED_DOMAINS } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { fetchInt } from '../../../../utils/api/fetchInt';

// const qorddu = {
//     id:'qorddu',
//     title:'Qorddu',
//     children:[
//         {
//             id:'qorddu-info',
//             title: 'info',
//             iconUrl:'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/Logo%20qorddu.svg?alt=media&token=234812e0-d737-49e3-a55d-8bcda820ac3f',
//             iconType:'image',
//             description: ''
//         },
//         {
//             id:'qorddu-pro',
//             title: 'pro',
//             iconUrl:'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/Logo%20qorddu.svg?alt=media&token=234812e0-d737-49e3-a55d-8bcda820ac3f',
//             iconType:'image',
//             description: ''
//         },
//         {
//             id:'qorddu-business',
//             title: 'business',
//             iconUrl:'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/Logo%20qorddu.svg?alt=media&token=234812e0-d737-49e3-a55d-8bcda820ac3f',
//             iconType:'image',
//             description: ''
//         },
//         {
//             id:'qorddu-network',
//             title: 'network',
//             iconUrl:'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/Logo%20qorddu.svg?alt=media&token=234812e0-d737-49e3-a55d-8bcda820ac3f',
//             iconType:'image',
//             description: ''
//         },
//         {
//             id:'qorddu-publisher',
//             title: 'publisher',
//             iconUrl:'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/Logo%20qorddu.svg?alt=media&token=234812e0-d737-49e3-a55d-8bcda820ac3f',
//             iconType:'image',
//             description: ''
//         },
//         {
//             id:'qorddu-academic',
//             title: 'academic',
//             iconUrl:'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/Logo%20qorddu.svg?alt=media&token=234812e0-d737-49e3-a55d-8bcda820ac3f',
//             iconType:'image',
//             description: ''
//         },
//         {
//             id:'qorddu-brand',
//             title: 'brand',
//             iconUrl:'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/Logo%20qorddu.svg?alt=media&token=234812e0-d737-49e3-a55d-8bcda820ac3f',
//             iconType:'image',
//             description: ''
//         }
//     ]
// }
export default function DomainNavigation({onClose}:{onClose?:()=>void}) {
    const [data, setdata] = useState<Server[]>([]);
    const [selectedUniver, setselectedUniver] = useState<Server | null|{id:string, title:string, children:{id:string, title:string, iconUrl:string, description:string, iconType?:string}[]}>(null);
    const [selectedDomain, setselectedDomain] = useState<string>('');
    const [description, setdescription] = useState({name:'', descrition:'', image:'', icon:'', iconType:''});
    const [loading, setloading] = useState(false);
    const {t} = useTranslation()
    useEffect(() => {
        
       if(selectedDomain && selectedDomain!=='qorddu'){
        fetchInt(getVolum({volumId:selectedDomain, volumColl:'Domains' })).then(res => res.json()).then(data => {
            if(data.data){
                setdescription({
                    name: `${data.data.full_title?.en?.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`,
                    descrition: data.data.description ? data.data.description : t('no-desciption-yet'),
                    image: data.data.image,
                    icon: `${storageUrl}${data.data.icon?.src}`,
                    iconType: ''
                })
            }
        })
       }else{
        setdescription({name:'', descrition:'', image:'', icon:'', iconType:''})
       }
    }, [selectedDomain]);
    useEffect(() => {
        setloading(true)
        fetch(getDomains).then(res => res.json()).then(data => setdata(data.data)).catch(e=>setloading(false)).finally(()=>setloading(false))
    }, [])
    useEffect(() => {
        
        setselectedDomain('')
        setdescription({name:'', descrition:'', image:'', icon:'',iconType:''})
    }, [selectedUniver?.id]);
  return (
    <div className='' style={{
        // backdropFilter: 'blur(5px)',
        // background: 'rgba(255,255,255,0.5)'
    }}>
        
        
        <div className='w-[820px] relative drop-shadow-lg bg-white teddra-rounded  teddra-shadow  flex min-h-[400px] h-[53vh]'>
            {onClose && (
                <div className="absolute top-0 right-0 px-3 bar-h flex justify-end items-center" onClick={onClose}>
                    <button className='p-1 rounded hover:text-white hover:bg-red-600'>
                        <Icon name='Close' className='icon-mini' />
                    </button>
                </div>

            )}
            <div className="w-[180px] h-full">
                <div className="bar-h pl-4 border-b border-main">
                    <WithDivider><p>{t('univers')}</p></WithDivider>
                </div>
                <div className='pl-4 pt-1'>
                    {/* <OneLine active={selectedUniver?.id === 'qorddu'} dropdownProps={null} onClick={() => setselectedUniver(qorddu)}>

                        <button className='flex items-center space-x-2 pl-[5px]'>
                            <img src="https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logoq_black.png?alt=media&token=c5969d32-3d07-44f3-8117-1c5fdb7473ec" className='h-[20px] w-auto' alt="" />
                            <p>Qorddu</p>
                        </button>
                    </OneLine> */}
                    {data.map(univer => (
                        <OneLine key={univer.id} active={selectedUniver?.id === univer.id} dropdownProps={null} onClick={() => setselectedUniver(univer)}>
                            <button className='flex items-center space-x-2 pl-[5px]'>
                                <ReactSvg src={univer.iconUrl} className='icon-sm'/>
                                <p>{univer.title}</p>
                            </button>
                        </OneLine>
                    ))}

                </div>
            </div>
            <div className='flex-1 '>
                <div className="bar-h pl-2 border-b border-main">
                    <WithDivider><p>{t('domains')}</p></WithDivider>
                </div>
                <div className="min-h-[400px] h-[calc(53vh-53px)] overflow-y-auto pl-1 pt-1">
                    <Spin spinning={loading}>
                        {selectedUniver && selectedUniver.children && selectedUniver.children.map(domain => (
                            <OneLine key={domain.id} dropdownProps={null} className='w-full' active={domain.id === selectedDomain}
                            disabled={!ACTIVATED_DOMAINS.includes(domain.id)}> 
                                <button className='flex space-x-2 items-center pl-[10px]' disabled={!ACTIVATED_DOMAINS.includes(domain.id)}>
                                    {domain.iconType === 'image' ? <img src={domain.iconUrl} className='h-[20px] w-auto' alt="" /> : <ReactSvg src={domain.iconUrl} className='icon-sm'/>}
                               

                                    {/* <a href={`https://${domain.title.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' className='flex items-center space-x-1.5 text-center  !text-inherit'> */}
                                    <div className='flex items-center space-x-1.5 text-center  !text-inherit' onClick={() => ACTIVATED_DOMAINS.includes(domain.id)? setselectedDomain(domain.id):{}}>
                                        <p className='truncate flex-1 min-w-0'>
                                            <span className='font-bold'>{domain.title.split(' ').join('-').toLocaleLowerCase()}</span>
                                            <span className='font-light'>.qorddu.com</span>
                                        </p>
                                    </div>
                                </button>
                            </OneLine>
                        ))}

                    </Spin>

                </div>
            </div>
            <div className='w-[320px] '>
                <div className="bar-h pl-2 border-b border-main">
                    <WithDivider><p>{t('description')}</p></WithDivider>
                </div>
                {description.name && (
                    <div className='h-[calc(53vh-43px)] overflow-y-auto'>

                        <ObjectMode
                            thumb={description.image ? {type:'slide', images:[{src: description.image, type:'image'}]} : {type:'icon', icon:{src: description.icon, type:'image'}}}
                            fields={[
                                {
                                    name: t('Name'),
                                    value:description.name
                                },
                                {
                                    name: t('description'),
                                    value:<p className='ellipsis-5'>{description.descrition}</p>
                                },
                                {
                                    name: '',
                                    value: <button className={`p-1 rounded bg-hover-transparent !text-inherit h-max  -ml-1 w-max flex items-center space-x-2  `}  onClick={() => window.open(`https://${description.name}`)} >
                                        <Icon name='OpenWindow' className='icon-mini'/>
                                        <p>{t('open')}</p>
                                    </button>
                                }
                                
                            ]}
                        
                        />
                    </div>

                )}
            </div>
        </div>

    </div>
  )
}
