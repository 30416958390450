import React, { useEffect, useState } from 'react'
import { VolumeSearch } from '../../../../../types'
import Results from './results'
import useSearchLocations from '../../../hooks/useSearchLocations'
import { useTranslation } from 'react-i18next'

type Props = {
    selectedKey : string,
    domainId:string,
    setResultsLength:any,
    handleSearchSelection : (doc:VolumeSearch) => void,
    searchValue:string,
    domainType: 'thematic'|'local'|'regional'
}
export default function Search({domainId, domainType, selectedKey, setResultsLength,handleSearchSelection, searchValue}: Props) {
    const [query, setquery] = useState('')
    const [selectedParent, setselectedParent] = useState<{value:undefined |string, id:string}>({value:undefined, id:''});
    const {searchResults, resultsLength} = useSearchLocations({
        query, 
        sort: 'depth:asc,isActive:desc',
        filter: `&& domainsIds:=${domainId} ${domainType === 'thematic' ? '&& cfs_type:LocalClusters' : ''} ${domainType === 'local' ? '&& cfs_type:DepartmentDrive' : ''} ${domainType === 'regional' ? '&& cfs_type:RegionalServers' : ''}`,
        status:'[enabled, coming_soon]'

    })
    const {t} = useTranslation()

    useEffect(() => {
        
        setResultsLength(resultsLength)
    }, [resultsLength]);
        


      useEffect(() => {
        
        if(searchValue.length > 2){
            setquery(searchValue)
        }else{
            setquery('')
        }
      }, [searchValue]);
  return (
    <div className='pt-2 space-y-3'>
       {/* <div className="flex space-x-2 px-5">
            <GeoTree
                selectedVolume={selectedParent}
                setselectedVolume={(id, value) => setselectedParent({id, value})}
                domainId={domainId}
            />
            <Input
                className='w-full border border-main input-h rounded'
                value={searchValue}
                onChange={e => setsearchValue(e.target.value)}
                placeholder='Search a drive, diretcory,...'
            />

        </div> */}
        <div className="space-y-2">
            <p className='text-group px-5'>{query.length <3 ? '' : t('your-search-for') + ' ' + query + ' :'}</p>
            <div className='h-[calc(100vh-217px)] overflow-auto px-5 '>
                {query.length > 2 && (
                    <Results
                        selectedKey={selectedKey}
                        handleServerSelection={handleSearchSelection}
                        results={searchResults}
                    
                    /> 

                )}

            </div>
        </div>
    </div>
  )
}
