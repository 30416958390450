import { PlayIcon } from '@heroicons/react/solid'
import { Tooltip } from 'antd'
import DetailsPageLayout from '../../components/DetailsPageLayout'
import { useTranslation } from 'react-i18next'

export default function Video() {
  const {t} = useTranslation(['common', 'home'])
  return (
    <DetailsPageLayout>
      <div className='h-[calc(100vh-180px)] overflow-y-auto'>
          <div className='w-full h-[400px] overflow-hidden border-b border-main relative'>
              <img src="/cyber-security.png" className='w-full object-cover' alt="" style={{transform:'translate(304px,-44px) scale(1.5) rotate(-14deg)'}}/>
              <div className='absolute top-0 left-0 w-full h-full  z-10 flex items-center justify-center'>
                  <Tooltip title={t('home:video.block.p1')}>
                      <PlayIcon className='w-[70px] h-[70px] opacity-70 cursor-not-allowed'/>

                  </Tooltip>
              </div>


          </div>
          <p className='text-muted italic text-center !text-[18px] mt-3'>{t('home:video.block.p1')}</p> 
      </div>

    </DetailsPageLayout>
  )
}
