import React, { useEffect, useState } from 'react'
import { Outlet, OutletProps } from 'react-router-dom'
import { useAuthContext } from '../context/AuthContext'
import { checkSession } from '../services/api/request'


export default function CheckAuthRoute(props:OutletProps) {
  const [isLoaded, setisLoaded] = useState(false)
  const { setUser } = useAuthContext()
  
  useEffect(() => {
    (async () => {
      try{
        const response = await fetch(checkSession, {
          method: "GET",
          credentials: "include",
          headers:{
            "Access-Control-Allow-Credentials": "true",
            // "Access-Control-Allow-Origin": "http://localhost:3001",
          }
          
      })
  
      if (response.ok) {
          const data = await response.json();
          if(data.role === 'super_admin' || data.role === 'admin' || data.role === 'guest' || data.role === 'CLIENT'){
            setUser({...data, name: data.name!, id: data.uid, picture: data.picture ? data.picture : undefined, email: data.email!,emailVerified: data.emailVerified})
          }else{
            setUser(null)
          }
        
          console.log("User logged in on Main Domain & Subdomain1");
        }
        setisLoaded(true)
        
      }catch(e){
        console.log(e)
        setisLoaded(true)
      }

    })()
  }, [])

  
  
  

  if(!isLoaded){
    return <p>...loading.</p>
  }
  
  return <Outlet {...props}/>
 
}
// export default function CheckAuthRoute(props:OutletProps) {
//     const [isLoaded, setisLoaded] = useState(false)
//     const { setUser } = useAuthContext()
//     const navigate = useNavigate()
//     useEffect(() => {
//       console.log('check auth')
//       const listen = onAuthStateChanged(auth, async user => {
//         if(user){
          
//             fetchInt(getAuthUser).then(res => res.json()).then(data => {
//               setUser({...data.user,id:user.uid, name: user.displayName!, email:user.email!, picture: user.photoURL!, emailVerified: user.emailVerified  })
//               console.log(data.user,'user')
            
//             }).catch(err => console.log(err)).finally(() => setisLoaded(true))

          
         
//         }else{
//           setUser(null)
//           setisLoaded(true)
//         }
      
//         console.log(user) 
//       })
//       return listen
//     }, [])

    
    
    
  
//     if(!isLoaded){
//       return <p>...loading.</p>
//     }
    
//     return <Outlet {...props}/>
   
// }
