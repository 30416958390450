import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={23} height={23} stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99666 3.99667H14.0008C14.5533 3.99667 15.0012 4.44457 15.0012 4.99708V20.0033H3.99666C3.44415 20.0033 2.99625 19.5554 2.99625 19.0029V4.99708C2.99625 4.44457 3.44415 3.99667 3.99666 3.99667Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 7.87328C6.92886 7.87328 6.87287 7.92927 6.87287 7.99833C6.87287 8.0674 6.92886 8.12339 6.99792 8.12339C7.06698 8.12339 7.12297 8.0674 7.12297 7.99833C7.12297 7.92927 7.06698 7.87328 6.99792 7.87328"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9996 7.87328C10.9305 7.87328 10.8745 7.92927 10.8745 7.99833C10.8745 8.0674 10.9305 8.12339 10.9996 8.12339C11.0686 8.12339 11.1246 8.0674 11.1246 7.99833C11.1246 7.92927 11.0686 7.87328 10.9996 7.87328"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 11.8749C6.92886 11.8749 6.87287 11.9309 6.87287 12C6.87287 12.0691 6.92886 12.1251 6.99792 12.1251C7.06698 12.1251 7.12297 12.0691 7.12297 12C7.12297 11.9309 7.06698 11.8749 6.99792 11.8749"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9996 11.8749C10.9305 11.8749 10.8745 11.9309 10.8745 12C10.8745 12.0691 10.9305 12.1251 10.9996 12.1251C11.0686 12.1251 11.1246 12.0691 11.1246 12C11.1246 11.9309 11.0686 11.8749 10.9996 11.8749"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6667 12.8889V12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6667 18.2222V19.1111"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.221 17.5873C17.4345 17.9789 17.8258 18.2214 18.2505 18.2253H19.1639C19.7546 18.2248 20.2524 17.7547 20.3246 17.1293C20.3968 16.5039 20.0207 15.9191 19.4477 15.7658L17.8858 15.3473C17.3128 15.194 16.9367 14.6092 17.0089 13.9838C17.081 13.3584 17.5788 12.8884 18.1696 12.8879H19.0829C19.5068 12.891 19.8977 13.1323 20.1115 13.5228"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 20V15.4167C7 15.1865 7.25584 15 7.57143 15H10.4286C10.7442 15 11 15.1865 11 15.4167V20"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
