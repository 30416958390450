import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Spin, Tooltip, message, notification } from 'antd'
import { DetailsActions } from '../../../types'
import { ButtonSm } from '../../../../../Buttons/Small'
import Divider from '../../../../../Divider'
import { retrieveDocumentsById } from '../../../../../../../services/api/request/search'
import { apiEndPoint } from '../../../../../../../services/api/constants'


type Props = DetailsActions
export default function DetailsActionsComp(props : any) {
    const params = useParams()
    const location = useLocation()
    const [url, seturl] = useState('');
    const [loading, setloading] = useState(false);
    const [urlFetched, seturlFetched] = useState(false);

 
    useEffect(() => {
      if(location.state){
        seturl(location.state.url)
        
      }else{
        let id 
        let collection
        if(params.publicationId || params.websitePublicationId){
          id = params.publicationId??params.websitePublicationId!
          collection = 'publications'
        }else if(params.webpageId){
          id = params.webpageId
          collection = 'webpages'
        }
        
        if(id && collection){
          setloading(true)
          fetch(retrieveDocumentsById(collection, id)).then((res:any) => {
            res.json().then((data:any) => {
              if(data.data){
                seturl(data.data.url)

              }
              setloading(false)
            })
          })
        }else{
          seturl('')
        }
      }
    },[location.state, params.publicationId, params.webpageId, params.websitePublicationId])

    useEffect(() => {
      if(url){
        seturlFetched(false)
        props.setObjectDisplayMode('object')
        fetch(`${apiEndPoint}/check-frame?url=${url}`).then(res => res.json()).then(res => {
          seturlFetched(res.embeddable)
        }).catch(err => {
          seturlFetched(false)
          console.log(err)
        })

      }
    }, [url]);
   
  return (
   <div className='flex items-start pr-7'>

         {(params.publicationId || params.webpageId || params.websitePublicationId || params.ownerId || params.partnerId || params.sponsorId) && (
                <>
                  <Tooltip title='Object'>
                    <ButtonSm icon='Document' active={props?.mode === 'object'} onClick={() => props?.setObjectDisplayMode('object')} iconSize='mini'/>
                  </Tooltip>
                  <Tooltip title='Preview'>
                    <ButtonSm icon='ModeUrl' active={props?.mode === 'url'} onClick={() => props?.setObjectDisplayMode('url')} disabled={!urlFetched} iconSize='mini'/>
                  </Tooltip>
                
                </>
            )}
            <Spin spinning={loading}>
              <Tooltip title='Open'>
                <a href={url} className='!text-inherit' target="_blank" rel="noopener noreferrer" aria-disabled={loading || !url}>
                  <ButtonSm icon='OpenWindow' disabled={((!url) || loading)} iconSize='mini' className={'disabled:fill-skin-muted bg-hover-transparent p-1 rounded disabled:opacity-50'}/>

                </a>
              </Tooltip>

            </Spin>
            <Tooltip title='Location'>
                <button className='!text-inherit' >
                  <ButtonSm icon='Map'  iconSize='mini' className={'disabled:fill-skin-muted bg-hover-transparent p-1 rounded disabled:opacity-50'} disabled={!(params.publicationId || params.webpageId || params.websitePublicationId || params.ownerId || params.partnerId || params.sponsorId)}/>

                </button>
              </Tooltip>
              
            <div className='h-full flex items-center mx-2'>
              <Divider />

            </div>
        <Tooltip title='Share'>
          <ButtonSm icon='Share' iconSize='mini' disabled/>
        </Tooltip>

        <Spin spinning={false}>
          <Tooltip title={'Pin'}>
            <ButtonSm icon={'Pin'} iconSize='mini' disabled />
          </Tooltip>
        </Spin>

          <Spin spinning={false}>
            <Tooltip title={'Shortcut'}>
              <ButtonSm icon={'Shortcut'}  iconSize='mini' disabled/>
            </Tooltip>
          </Spin>

            <div className='h-full flex items-center mx-2'>
              <Divider />

            </div>
            <ButtonSm icon='Chat'  iconSize='mini' disabled/>
            <ButtonSm icon='Card'  iconSize='mini' disabled/>
            <ButtonSm icon='Phone'  iconSize='mini' disabled/>
            <div className='h-full flex items-center mx-2'>
              <Divider />

            </div>
            <ButtonSm icon='DandB' disabled iconSize='mini' />


          
   </div>
  )
}
 