import React, { useEffect, useState } from 'react'
import { useQfsContext } from '../../../../context/qfsContext'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import SearchBar from '../../../../GlobalLayout/SearchBar'
import QfsActionsBar from '../../../../GlobalLayout/QfsActionsBar'
import Footer from '../../../../components/Footer'
import LocationgUiLayout from '../../../../components/locationLayout/Uilayout'
import { CameraIcon, ChevronLeftIcon } from '@heroicons/react/solid'
import Icon from '../../../../../../components/icons/Icon'
import { Drawer, Modal, Steps } from 'antd'
import { useAppContext } from '../../../../../../context/AppContext'
import { Close } from '../../../../../../components/icons'
import Demo from '../../../../../home/pages/Demo'
import Divider from '../../../../../../components/shared/Divider'
import HomeLayout from '../Layout'
import { Trans, useTranslation } from 'react-i18next'
import { CardComponent } from '../../../../../home/Layout'
import i18n from '../../../../../../i18n'
import useGetPartners from '../../../../hooks/useGetPartners'
import { PartnerType } from '../../../../../../types'
import useNavigateTo from '../../../../hooks/useNavigateTo'
import Reserve from './offer/reserve/Reserve'
import Collapse, { Panel } from '../../../../../../components/shared/Collapse'
import SponsorWithMinuature from '../../../../../../components/shared/cards/sponsor/SponsorWithMinuature'
import CardActions from '../../../../../../components/shared/cards/WithWebsite/CardActions'
import IconAction from '../../../../../../components/shared/cards/WithWebsite/IconAction'
import Article from '../../../../../../components/shared/cards/articles/Article'
import Description from '../../../../../../components/shared/cards/WithWebsite/Description'
import useGeGuestPartner from '../../../../hooks/useGetGuestPartner'

const { Step } = Steps;

export default function ServerACtivation() {
  const params = useParams()
  const {t} = useTranslation(['common', 'home', 'server'])
  const [loading, setloading] = useState(false);
  const {goTo} = useNavigateTo()
  const { partner, isOwner, isPreReserved } = useGeGuestPartner({setloading, id: params.id!})
  const {currentLocation} = useQfsContext()
  const [openModal, setopenModal] = useState(false);
 


      // useEffect(() => {
      //   if(isOwner){
      //     goTo(`/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}/activation/pages`, {})

      //   }
      // }, [isOwner]);

    useEffect(() => {
      
      if(currentLocation?.status === 'reserved' && !partner?.validated){
        setopenModal(true)
      }
    }, [currentLocation?.status, partner?.validated]);
  return (
    <>
    

      <HomeLayout
        stats={{
          organizations:0,
          publications:0,
          people:0,
          pins:0
        }
        }
        partner={partner}
        isPreReserved={isPreReserved}
        isOwner={isOwner}
      >
        <>
        <Collapse defaultActiveKey={['articles', 'info','sponsors']}>
      
          <>
          <Panel 
              header={<p className='text-groupe'>Sponsors</p>}
              key={'sponsors'}
            >
              <div className='ml-[22px] mb-[40px] flex space-x-4 blur-content'>
          
                <SponsorWithMinuature
                  placeholder
                  sponsor={{
                    name: 'Lorem ipsum',
                    description: '  Lorem ipsum dolor sit amet consectetur adipisicing elit. ',
                  }}
                />
                <SponsorWithMinuature
                  placeholder
                  sponsor={{
                    name: 'Lorem ipsum',
                    description: '  Lorem ipsum dolor sit amet consectetur adipisicing elit. ',
                  }}
                />
              
              </div>
            </Panel> 
            <Panel
              header={<p className='text-groupe'>Dernières actualités du {isOwner ? partner?.name : 'partenaire'}</p>}
              key={'articles'}
            >
                <div className='ml-[22px] mb-[40px] mt-3 space-y-8'>
                    <div className='flex space-x-4 max-w-[620px] max-[530px]:block max-[530px]:space-x-0 max-[530px]:space-y-1  blur-content'>
                      <div className="w-[300px] max-[450px]:w-full h-[150px] border border-main rounded flex justify-center items-center ">
                        <CameraIcon className='w-[50px] h-[50px] opacity-10'/>
                      </div>
                      <div className='flex-1 flex flex-col'>
                        <div className='flex-1'>
                          <p className="">A la Une
                          </p>
                          <p className="font-bold">
                            Lorem, ipsum dolor sit 
                          </p>
                          <p className='ellipsis-3'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, totam. Ipsam veritatis ratione consequuntur repellat, dignissimos quasi amet, quidem maxime possimus, quod officia exercitationem saepe culpa delectus ex ducimus optio!
                          </p>

                        </div>
                        <CardActions>
                          <IconAction icon='OpenWindow'/>
                        </CardActions>
                      </div>
                    </div>
                    <div className='max-w-[620px] grid grid-cols-3 max-[1200px]:grid-cols-2 max-[450px]:grid-cols-1 gap-7 blur-content'>

                      <button className='flex space-x-3 text-start'>
                        <CameraIcon className='w-[30px] h-[30px] opacity-10'/>
                        <Description
                            title={'Lorem ipsum'}
                            description={' Lorem ipsum dolor sit amet consectetur adipisicing elit.'}
                            website={''}
                            type=''
                            active={false}
                            actions={{website:true, chat:false, card:false}}
                            width='flex-1'
                          />
                      </button>
                      <button className='flex space-x-3 text-start'>
                        <CameraIcon className='w-[30px] h-[30px] opacity-10'/>
                        <Description
                            title={'Lorem ipsum'}
                            description={' Lorem ipsum dolor sit amet consectetur adipisicing elit.'}
                            website={''}
                            type=''
                            active={false}
                            actions={{website:true, chat:false, card:false}}
                            width='flex-1'
                          />
                      </button>
                      <button className='flex space-x-3 text-start'>
                        <CameraIcon className='w-[30px] h-[30px] opacity-10'/>
                        <Description
                            title={'Lorem ipsum'}
                            description={' Lorem ipsum dolor sit amet consectetur adipisicing elit.'}
                            website={''}
                            type=''
                            active={false}
                            actions={{website:true, chat:false, card:false}}
                            width='flex-1'
                          />
                      </button>
                    </div>
                </div>
            </Panel>
            {/* <Panel 
              header={<p className='text-groupe'>{t('server:panels.pre-reserve')}</p>}
              key={'pre-reservation'}
            >
              <div className='ml-[22px] mb-[40px]'>
                <div className="max-w-[450px] space-y-5 max-[650px]:w-full">
                  <p className='text-[18px] font-light'>
                    <Trans i18nKey="server:partner-text" components={{ br: <br />, span: <span className="font-bold" /> }} values={{ name: partner?.name }} />

                  </p>
                  <div>
                    <button onClick={() => setopen('reserve')} className='bg-skin-fill-inverted text-skin-inverted px-4 py-2 rounded block'>{t('server:reserve')}</button>
                    <a href='' className='italic text-gray-600 underline text-[11px]'>{t('server:cards.cancel.p1')}</a>

                  </div>
                </div>

              
              </div>
            </Panel>

            <Panel 
              header={<p className='text-groupe'>{t('server:panels.for-more')}</p>}
              key={'info'}
            >
              <div className="ml-[11px]">
                <div className=" text-[18px] flex-1 max-[650px]:-ml-2 ">
                  <button onClick={() => setopen('demo')} className="flex space-x-2 items-center bg-hover-transparent px-3 py-2 rounded">
                    <CameraIcon className='icon-sm opacity-10'/>
                    <p className='flex-1 truncate min-w-0'>{t('server:cards.demo.title')}</p>
                  </button>
                  <button onClick={() => naviageteTo('pages/newspapers/benefits')} className="flex space-x-2 items-center bg-hover-transparent px-3 py-2 rounded">
                    <CameraIcon className='icon-sm opacity-10'/>
                    <p className='flex-1 truncate min-w-0'>{t('server:cards.why.title')}</p>
                  </button>
                  <button onClick={() => naviageteTo('pages/qorddu/vision')} className="flex space-x-2 items-center bg-hover-transparent px-3 py-2 rounded">
                    <CameraIcon className='icon-sm opacity-10'/>
                    <p className='flex-1 truncate min-w-0'>{t('server:cards.qorddu.title')}</p>
                  </button>
              </div>
              </div>
            </Panel> */}

            <Panel
                    header={<p className='text-groupe'>Contributions d'entrepriseset d'experts</p>}
                    key={'articles-companies'}
                  >
                    <div className='mb-[40px] ml-[22px]  '>
                        <div className=' '>
                            <p className='italic'>Aucune contribution en cours</p>
                        </div>

                    </div>
                  </Panel>
          </>
        
        </Collapse>
      
        <Outlet/>
     
 
              <Modal
                visible={openModal}
                onCancel={() => setopenModal(false)}
                footer={null}

              >
                <div className='p-4'>
                  <p>Nous somme desole de vous informez que ce serveur a ete deja reserver par un autre partenaire</p>
                </div>
              </Modal>
    

        </>
      </HomeLayout>


   
        
    
    </>
  )
}
