import { createContext, useContext, useMemo, useState } from "react";
import { Server } from "../types";


type States = {
    domain: Server | null | undefined,
    server: Server | null,
    winSize:{w:number, h:number}


}
type Functions = {
    setDomain:(data:Server | null| undefined) => void,
    setServer:(data:Server | null) => void,
    setWinSize:(data:{w:number, h:number}) => void


}
type Props = States & Functions
const AppContext = createContext<Props>({} as Props);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    domain:null,
    server:null,
   winSize:{w:0, h:0}
  })
  

  const value = useMemo(() => {
    
    const setDomain =(data:Server | null| undefined) => {
        setState((prev) => ({
          ...prev,
          domain:data
        }))
      }

      const setServer =(data:Server | null) => {
        setState((prev) => ({
          ...prev,
          server:data
        }))
      }
   
      const setWinSize =(data:{w:number, h:number}) => {
        setState((prev) => ({
          ...prev,
          winSize:data
        }))
      }
    return {
      ...state,
      setDomain,
      setServer,
      setWinSize

     
    }
  }, [state])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};