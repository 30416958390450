import { useEffect } from "react"
import { useSearchContext } from "../context/searchContext"

type Props = {
    displayMode:any,
    disabled:boolean,
    setPaginate: (data:{page?:number, limit?:number, offset?:number}) => void
}
export const useHandleLimits= ({displayMode, disabled, setPaginate }:Props) => {
    useEffect(() => {
        function handleResize() {
            if(!disabled){
                const limit = Math.round((window.innerHeight-220)/(displayMode === 'OneLine' ? 30 :75/2)) - 2
       
                if(limit > 0){
                  setPaginate({limit})
                }else{
                  setPaginate({limit:0})
                }
                

            }

        
    }

         handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, [displayMode, disabled])


    return{

    }
}