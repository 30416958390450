import React, { useEffect, useState } from 'react'
import Icon from '../../../../components/icons/Icon'
import { PublicationTypes } from '../../../../types';
import ReactSvg from '../../../../components/shared/ReactSvg';
import { storageUrl } from '../../../../services/api/constants';
import { useMydeskContext } from '../../context/MydeskContext';
import { volumeCodes } from '../../constants';
import { Menu } from 'antd';
import { mapTreemenu } from '../../../../components/shared/bars/TopBar/actionsBar/actions/resources/TypesDropdown';
import { useNavigate, useParams } from 'react-router-dom';


type Props = {
    publicationType:any,
    allowPublications?:boolean
}
export default function NewDropdown({ publicationType, allowPublications }: Props) {
    const [typesList, settypesList] = useState<any>([]);
    const { userCurrentSubscription } = useMydeskContext()
    const [volumes, setvolumes] = useState<{name:string, icon:string, disabled?:boolean, code:string}[]>([])
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
            if(userCurrentSubscription){
                const features = userCurrentSubscription.tier.features
                const data:{name:string, icon:string, disabled?:boolean, code:string}[] = []
            
                volumeCodes.map(code => {
                    if(features.some(element => element.code === code.code)){
                        data.push({
                            name:code.name,
                            icon:`${storageUrl}${code.icon.src}`,
                            code:code.code
                        })
                    }else{
                        data.push({
                            name:code.name,
                            icon:`${storageUrl}${code.icon.src}`,
                            disabled:true,
                            code:code.code
                        })
                    }
                })
                setvolumes(data)
                
            }
        }, [userCurrentSubscription?.id])

    useEffect(() => {
        if(publicationType){

            settypesList(
    
                publicationType.filter((type:any) => type.children?.length>0)
            )
        }
    }, [publicationType]);
    const handleSelectVolume = (code:string)=> {
         navigate(`/mydesk/volumes/create/${code}/edit`)
    }
    const handleSelectPublication = () => {
        if(params.id && params.coll){
            navigate(`/mydesk/${params.coll}/${params.id}/publications/create/edit`)
        }else{
            navigate('/mydesk/publications')
        }
    }
  return (
    <div className="relative min-w-[270px]  teddra-shadow bg-menu teddra-rounded p-2 menu-filter" >
         {volumes.map(volume => (
                <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={volume.disabled} onClick={() => handleSelectVolume(volume.code)}>
                    <div className='flex items-center'>
                        <ReactSvg src={volume.icon} className='icon-sm ant-dropdown-menu-item-icon'/>
                        <p className='ant-dropdown-menu-title-content'>{volume.name}</p>
                    </div>
                </button>
            ))}
  
       
        <div className='w-full bg-black opacity-20 h-[1px] my-1'></div>
        {/* <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} onClick={handleSelectPublication}>
            <div className='flex items-center'>
                <Icon name={'AddDocument'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>Publication</p>
            </div>

        </button> */}
        {/* {typesList && typesList.map((type:PublicationTypes) => (
            <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={false} onClick={() => {}}>
            <div className='flex items-center'>
                <ReactSvg src={`${storageUrl}${type.icon.src}`} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>{type.name_singular.en}</p>
            </div>
        </button>
        ))} */}
        
        <Menu items={[...mapTreemenu(JSON.parse(JSON.stringify(typesList ? typesList:[])),handleSelectPublication)]} disabled={!allowPublications} className='!shadow-none'/>
        

           
 
    
  

</div>
  )
}
