import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { useAppContext } from '../../../../context/AppContext'
import { UI_WIDTH_PUBLICATIONS_SM } from '../../../../constants'

type Props = {
    loading:any 
    children:JSX.Element,
}
export default function LocationgUiLayout(props : Props) {
 
    const {winSize} = useAppContext()
    
    

   
  return (
    <div className='flex  '>
        <div className='flex-1 flex flex-col'>
        <Spin spinning={props.loading}>
          <div className="flex-1" >
            {props.children}
          

          </div>
        </Spin>
        </div>
        {winSize.w > UI_WIDTH_PUBLICATIONS_SM ? (
          <div className='w-[33%] flex flex-col'>
            <div className='flex-1'>
              <Outlet/>

            </div>
          </div>

        ) : <Outlet/>}
      </div>
    
  )
}
