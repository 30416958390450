import React, { useEffect, useState } from 'react'

import { PartnerType } from '../../../types'
import { getGuestPartner, getVolumCollaborators } from '../../../services/api/request'
import { fetchInt } from '../../../utils/api/fetchInt'

export default function useGeGuestPartner({setloading, id} : {setloading:any, id:string}) {
    const [partner, setpartner] = useState<null | PartnerType>(null)
    const [isPreReserved, setisPreReserved] = useState(false)
    const [isOwner, setisOwner] = useState(false)


    useEffect(() => {
        if( id ){
            setloading(true)
            fetchInt(getGuestPartner({volumId: id})).then((res) => res.json()).then(data => {
                setpartner(data.data?.partner)
                setisOwner(data.data?.isOwner)
                setisPreReserved(data.data?.isReserverd	)
               
                
            }).catch(err => console.log(err)).finally(() => setloading(false))
  
        }
    }, [id])
  return {
    partner,
    isPreReserved,
    isOwner
  }
}
