import React, { useEffect, useState } from 'react'
import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom'


import useGetOwner from '../../../../../hooks/useGetOwner'
import { Spin, Tooltip } from 'antd'
import Icon from '../../../../../../../components/icons/Icon'
import { CameraIcon } from 'lucide-react'
import QfsActionsBar from '../../../../../GlobalLayout/QfsActionsBar'
import useGetPublications from '../../../../../hooks/useGetPublications'
import Collapse, { Panel } from '../../../../../../../components/shared/Collapse'
import PublicationsLink from '../../../../../components/resourcesLayout/publications/PublicationsLink'
import Footer from '../../../../../components/Footer'
import { useQfsContext } from '../../../../../context/qfsContext'


export default function CommunityActive() {
    const [loading, setloading] = useState(false)
    const params = useParams()
  
    const { owner } = useGetOwner({coll: params.coll!, id:params.id!, setloading})
    
const { currentLocation } = useQfsContext()
    
    
   


  
  
    
  return (
  
    <>
      
            <div className='overflow-auto height-community space-y-[60px]'>
                {
                    owner && (
                        <div>
                           {

                             <div className="w-full h-[500px] bg-skin-fill-muted flex items-center justify-center">
                                {owner.logo ? <img src={owner.logo} className='w-[400px] h-[400px] opacity-20' alt="" style={{filter: 'grayscale(100%)'}}/> :<CameraIcon className='w-[120px] h-[120px] opacity-10 fill-transparent'/>}
                             
                            </div>
                           }
                            {/* <div className="w-full h-[500px] bg-skin-fill-muted flex items-center justify-center">
                                <CameraIcon className='w-[120px] h-[120px] opacity-10 fill-transparent'/>
                            </div> */}

                            
                            
                            <div className="pl-[50px]">
                                <div className="flex space-x-16">
                                    <div className='w-[200px]'> 
                                        <div className='-mt-[150px]'>
                                            <div className="flex space-x-10 ">
                                                <div className='w-full h-[200px] border border-main rounded overflow-hidden' style={{filter:'drop-shadow(1px 2px 6px var(--shadow-win-color))'}}>
                                                    {owner.logo ? (
                                                        <img src={owner.logo} className='w-full h-full' alt="" />
                                                    ) : (
                                                        <div className="w-full h-full flex items-center justify-center" style={{background:'rgb(242, 242, 242)'}}>
                                                            <CameraIcon className='w-[50px] h-[50px] fill-transparent text-white'/>
                                                        </div>
                                                    )}
                                                    {/* <img src={owner.logo} className='w-full h-full' alt="" /> */}
                                                </div>
                                            

                                            </div>

                                        </div>
                                        <div className="mt-[30px]  w-full">
                                            <p className="font-bold text-[20px] ellipsis-3">{owner.name}</p>
                                            <p>{owner.adresse?.line1}</p>
                                            <p className='mt-0.5'>{owner.adresse?.zipcode} {owner.adresse?.city}</p>
                                            <div className='flex space-x-1 -ml-1 mt-2'>
                                                <Tooltip title={`Visit ${owner.name}'s website`}>
                                                    <Link to={owner.webResources?.website!} target='_blank' rel="noopener noreferrer" className='p-1 bg-hover-transparent rounded !text-inherit'>
                                                        <Icon className='icon-mini' name='OpenWindow'/>
                                                    </Link>

                                                </Tooltip>
                                                <Tooltip title={`Visit ${owner.name}'s linkedin profile`}>
                                                    <Link to={owner.webResources?.linkedin!} target='_blank' className='p-1 bg-hover-transparent rounded !text-inherit' rel="noopener noreferrer">
                                                        <Icon className='icon-mini' name='Linkedin'/>
                                                    </Link>

                                                </Tooltip>
                                                <Tooltip title={`Location of ${owner.name}`}>
                                                    <Link to={'/'} target='_blank' rel="noopener noreferrer" className='p-1 bg-hover-transparent rounded !text-inherit'>
                                                        <Icon className='icon-mini' name='Map'/>
                                                    </Link>

                                                </Tooltip>
                                                
                                                <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' >
                                                    <Icon className='icon-mini' name='Chat'/>
                                                </button>
                                                <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                                    <Icon className='icon-mini' name='Call'/>
                                                </button>
                                                <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                                    <Icon className='icon-mini' name='Pin'/>
                                                </button>
                                                <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                                    <Icon className='icon-mini' name='Share'/>
                                                </button>
                                            
                                            </div>
                                        </div>
                                        <div className='mt-3 text-small text-muted'>
                                            <p>This server has not been activated yet.</p>
                                        </div>

                                    </div>
                                    <div className="flex-1 mt-[20px]">
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }
            </div>
   
          <Footer
                totalHits={{webresources: 0 , pubs: 0, location:0}}
                pagination={{limit:7, offset:0, page:1}}
                setPaginate={(data) => {}}
                onlyWebpages={false}
                currentLocation={currentLocation}
                allowPaginate={
                  {
                  webresources:false,
                  pubs: false
                  }
                }
                windows={{
                    active: '',
                    suffix:`${params.serverId}/${params.coll}/${params.id}/community`,
                    disabled:true
                }}
            />
        <Outlet/>
    
    </>

    
      

    
  )
}
