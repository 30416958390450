import React, { useEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import i18n from '../../../../i18n';
import DetailsLayout from '../../components/DetailsLayout';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../../context/AppContext';
import { UI_MD } from '../../../../constants';
import { Dropdown } from 'antd';
import { ChevronDownIcon } from '@heroicons/react/solid';

const paths = [
  {
    key: '/newspapers/benefits',
    title: 'benefits:menu.benefits'
  }, 
  {
    key: '/newspapers/how-it-works',
    title: 'benefits:menu.how-it-works'
  },
  {
    key: '/newspapers/revenu',
    title:'benefits:menu.revenu'
  },
  {
    key: '/newspapers/pricing',
    title:'benefits:menu.pricing'
  },
  {
    key:'/newspapers/demo',
    title:'benefits:menu.demo'
  }
]
function Links({server, backLink}:{server?:boolean, backLink?:string}) {
  const {t} = useTranslation(['common', 'home', 'benefits', 'server'])
  const location = useLocation()
  return (
    <>
      <Link to={server ? `${backLink}/pages/newspapers/benefits` : `/home/${i18n.language}/pages/newspapers/benefits`}  className='text-inherit block  '>
          <button className={location.pathname.includes('benefits')? 'active max-[990px]:after:hidden max-[990px]:bg-skin-fill-inverted max-[990px]:px-2 max-[990px]:rounded max-[990px]:text-skin-inverted max-[990px]:text-start max-[990px]:w-full' : 'max-[990px]:px-2'}>
              <p className='text-level2'>{t('benefits:menu.benefits')}</p>
              
          </button>
      
      </Link>


      <Link to={server ? `${backLink}/pages/newspapers/how-it-works` : `/home/${i18n.language}/pages/newspapers/how-it-works`}  className='text-inherit block  '>
          <button className={location.pathname.includes('/how-it-works') ? 'active max-[990px]:after:hidden max-[990px]:bg-skin-fill-inverted max-[990px]:px-2 max-[990px]:rounded max-[990px]:text-skin-inverted max-[990px]:text-start max-[990px]:w-full' : 'max-[990px]:px-2'}>
              <p className='text-level2'>{t('benefits:menu.how-it-works')}</p>

          </button>
      
      </Link>

      

      <Link to={server ? `${backLink}/pages/newspapers/revenu` : `/home/${i18n.language}/pages/newspapers/revenu`}  className='text-inherit block  '>
          <button className={location.pathname.includes('/revenu') ? 'active max-[990px]:after:hidden max-[990px]:bg-skin-fill-inverted max-[990px]:px-2 max-[990px]:rounded max-[990px]:text-skin-inverted max-[990px]:text-start max-[990px]:w-full' : 'max-[990px]:px-2'}>
              <p className='text-level2'>{t('benefits:menu.revenu')}</p>

          </button>
      
      </Link>
      <Link to={server ? `${backLink}/pages/newspapers/pricing` : `/home/${i18n.language}/pages/newspapers/pricing`}  className='text-inherit block  '>
          <button className={location.pathname.includes('/pricing') ? 'active max-[990px]:after:hidden max-[990px]:bg-skin-fill-inverted max-[990px]:px-2 max-[990px]:rounded max-[990px]:text-skin-inverted max-[990px]:text-start max-[990px]:w-full' : 'max-[990px]:px-2'}>
              <p className='text-level2'>{t('benefits:menu.pricing')}</p>

          </button>
      
      </Link>
      {!server && (
        <Link to={server ? `${backLink}/pages/newspapers/demo` : `/home/${i18n.language}/pages/newspapers/demo`}  className='text-inherit block  '>
            <button className={location.pathname.includes('/demo') ? 'active max-[990px]:after:hidden max-[990px]:bg-skin-fill-inverted max-[990px]:px-2 max-[990px]:rounded max-[990px]:text-skin-inverted max-[990px]:text-start max-[990px]:w-full' : 'max-[990px]:px-2'}>
                <p className='text-level2'>{t('benefits:menu.demo')}</p>

            </button>
        
        </Link>

      )}
    </>
  )
}
function NewspapersLayout({server}:{server?:boolean}) {
    const location = useLocation()
    const scrollRef = useRef<any>(null);
  const [scrollY, setScrollY] = useState(0);
  const {t} = useTranslation(['common', 'home', 'benefits', 'server'])
  const params = useParams()
  const {winSize} = useAppContext()

  const backLink = server ? `/${i18n.language}/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}/activation` : undefined
  
  useEffect(() => {
    
     
    scrollRef.current?.scrollTo({ top: 0, behavior: "instant" });
    
  }, [location.pathname, scrollRef.current]);
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        setScrollY(scrollRef.current.scrollTop);
      }
    };

    const divElement = scrollRef.current;
    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  

  return (

        <div className=''>
        
            <div>

                <div className={` h-[60px] sticky top-0 border-b border-main bg-white z-[10000] `}>
                    <div className='flex items-center  px-page   relative max-[1500px]:!px-2 max-[750px]:!px-2 h-full'>
                        {winSize.w > UI_MD ? (
                        <div className="max-w-page max-[1400px]:!max-w-[95%] w-full mx-auto justify-between flex  gap-4">
                            
                          <Links backLink={backLink} server={server}/>
                          

                        </div>

                        ): (
                          <div className='px-4'>
                            <Dropdown
                              trigger={['click','hover']}
                              overlay={
                                <div className='bg-white rounded teddra-shadow p-2'>
                                  <Links backLink={backLink} server={server}/>

                                </div>
                              }
                            >
                              <button className='flex space-x-3 items-center'>
                                <p>{t(paths.filter(k => location.pathname.includes(k.key))?.[0]?.title)}</p>
                                <ChevronDownIcon className='icon-sm'/>
                              </button>
                            </Dropdown>

                          </div>
                        )}

                    </div>
                </div>
                <div className={`overflow-y-auto ${server ? 'h-[calc(100vh-89px)]' : 'h-[calc(100vh-135px)]'}  overflow-x-hidden   `} ref={scrollRef}      style={{ scrollBehavior: "smooth" }} // Ensures smooth scrolling
                >
                        <Outlet/>
                </div>
            </div>
        </div>

  )
}
export default function NewspapersLayoutContainer({server}:{server?:boolean}) {
  const {t} = useTranslation()

  return (
    server ? (
      <NewspapersLayout server/>
    ):(
      <DetailsLayout title={t('home:fornewspapers.title')} backLink={`/home/${i18n.language}`}>
        <NewspapersLayout/>
      </DetailsLayout>
    )
  )
}