import React, { useEffect, useState } from 'react';
import { Highlight, HighlightQuery, Query, VolumeSearch } from '../../../types';
import { searchDomains, searchQueries } from '../../../services/search/search';
import { searchDocuments } from '../../../services/api/request/search';
import { useDebounce } from '../../../hooks/useDebounce';
import { QUERY_DEBOUNCE_DELAY } from '../../../constants';



export default function useSearchDomains({ filterBy, query }: { filterBy: string; query: string }) {
  const [domains, setdomains] = useState<undefined | { document: VolumeSearch; highlight: Highlight }[]>(undefined);
  const [isLoaded, setIsLoaded] = useState(true);

  // Debounced Query
  const debouncedQuery = useDebounce(query, QUERY_DEBOUNCE_DELAY);

  useEffect(() => {
    (async () => {
      if (debouncedQuery) {
        setIsLoaded(false); // Indicate loading state
        const req = await fetch(searchDocuments('domains'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: searchDomains({ q: debouncedQuery, filter_by: filterBy}),
          }),
        });

        const res: any = await req.json();
        setdomains(res.data?.hits);
        setIsLoaded(true); // Loading complete
      } else {
        setdomains([]);
      }
    })();
  }, [debouncedQuery, filterBy]);

  return {
    domains,
    isLoaded,
  };
}
