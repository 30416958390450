import React, { useEffect, useState } from 'react'
import { BreadcrumbComp } from '../../../components/shared/breadcrumb'
import { useAppContext } from '../../../context/AppContext'
import { UI_WIDTH_HIDE_SOME_ACTIONS } from '../../../constants'
import UserActionsButton from '../../../components/shared/bars/TopBar/userActionsButton'
import { CameraIcon } from '@heroicons/react/solid'
import BlockwithImgLayout from '../components/block/withImgLayout'
import TextBlock2 from '../components/block/TextBlock2'
import Blocks from '../components/block/Blocks'
import CentredBlock from '../components/block/CentredBlock'
import { useTranslation } from 'react-i18next'

export default function QordduPage() {
    const { t } = useTranslation(['common', 'qorddu', 'home'])
   
   return (
            <div> 
                
                <div className="w-full h-full overflow-auto relative ">
            
                    <div className="space-between-main-blocks3 pb-end-page">
                    
                        
                        
                        <div className="mt-[180px] space-y-[220px]">
                                <div className='w-full h-[300px]  flex justify-center items-center'>
                                    <CameraIcon className='w-[150px] h-[150px] opacity-10 ' />
                                </div>
                            <div className="relative flex flex-col justify-center  max-w-page mx-auto">
                                    <div className=" text-center space-y-10">
                                    <div>
                                        <p className="title-main">
                                        {t('qorddu:title')}
                                        </p>
                                        <p className="text-main">
                                        {t('qorddu:description')}
                                        </p>
                                    </div>
                                    
                                        

                                    </div>

                            </div>
                            <BlockwithImgLayout orientation='right'>
                                <>
                                <TextBlock2
                                    label=""
                                    title={t('qorddu:section1.title')}
                                    text={t('qorddu:section1.text')}
                                />
                                
                              


                            
                                  
                                </>

                            </BlockwithImgLayout>
                            
                            <BlockwithImgLayout orientation='left'>
                                <>
                                <TextBlock2
                                    label=""
                                    title={t('qorddu:section2.title')}
                                    text={t('qorddu:section2.text')}
                                />
                                <Blocks
                                    columns={'grid-cols-2  max-[450px]:grid-cols-1'}
                                    content={[
                                    {
                                        text: 
                                        <span dangerouslySetInnerHTML={{ __html: t('qorddu:section2.block1.text') }} />
                                    },
                                    {
                                        text: <span dangerouslySetInnerHTML={{ __html: t('qorddu:section2.block2.text') }} />
                                    },
                                    ]}
                                />
                                </>
                            </BlockwithImgLayout>
                            

                        

                            <div>
                                
                            <CentredBlock
                                topElement={
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                                    <path fill="var(--bg-inverted)" fillOpacity="1" d="M0,192L1440,96L1440,320L0,320Z"></path>
                                </svg>
                                }
                                text={
                                <TextBlock2
                                    label=""
                                    title={t('qorddu:section3.title')}
                                    text={<p className="title-level2 !font-light">{t('qorddu:section3.text')}</p>}
                                />
                                }
                                blocks={{
                                columns: 'grid-cols-3 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1',
                                content: [
                                    {
                                    text: <span dangerouslySetInnerHTML={{ __html: t('qorddu:section3.block1.text') }} />
                                    },
                                    {
                                    text: <span dangerouslySetInnerHTML={{ __html: t('qorddu:section3.block2.text') }} />
                                    },
                                    {
                                    text: <span dangerouslySetInnerHTML={{ __html: t('qorddu:section3.block3.text') }} />
                                    }
                                ]
                                }}
                            />
                                

                            </div>

                            <BlockwithImgLayout orientation='left'>
                                <>
                                <div>
                                    <TextBlock2
                                    label=""
                                    title={t('qorddu:section4.title')}
                                    text={t('qorddu:section4.text')}
                                    />
                                </div>
                                <Blocks
                                    columns={'grid-cols-2 max-[450px]:grid-cols-1'}
                                    content={[
                                    {
                                        text: <span dangerouslySetInnerHTML={{ __html: t('qorddu:section3.block1.text') }} />
                                    },
                                    {
                                        text: <span dangerouslySetInnerHTML={{ __html: t('qorddu:section3.block2.text') }} />
                                    },
                                    ]}
                                />
                                </>
                            </BlockwithImgLayout>
                    

                                <div className='max-w-page mx-auto space-y-16'>
                                    <div className='w-[50%] max-[950px]:w-[80%] max-[450px]:w-full'>
                                    <TextBlock2
                                        label=""
                                        title={t('qorddu:section5.title')}
                                        text={t('qorddu:section5.text')}
                                    />
                            

                                    </div>
                                    <div className="space-y-8">
                                        <p className="text-level2 !font-bold">{t('qorddu:section5.subheading')}</p>
                                        <div className="grid grid-cols-4 max-[950px]:grid-cols-3 max-[450px]:grid-cols-2 gap-3 gap-y-7">
                                            {(t('qorddu:section5.services', { returnObjects: true }) as any)?.map((service:any, index:any) => (
                                            <div key={index} className="flex space-x-3">
                                                <CameraIcon className="w-[40px] h-[40px] opacity-10" />
                                                <div className="flex-1">
                                                <p className="text-level2">{service.title}</p>
                                                <p className="text-level3 !-mt-1 text-gray-400">
                                                    {service.text}
                                                </p>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                        </div>
                            

                                </div>

                               



                                <BlockwithImgLayout orientation="left">
                                    <>
                                    <div>
                                        <TextBlock2
                                        label=""
                                        title={t('qorddu:section6.title')}
                                        text={t('qorddu:section6.text')}
                                        />
                                    </div>
                                    <Blocks
                                        columns={'grid-cols-2 max-[450px]:grid-cols-1'}
                                        content={[
                                        {
                                            text: <span dangerouslySetInnerHTML={{ __html: t('qorddu:section6.block1.text') }} />
                                        },
                                        {
                                            text:<span dangerouslySetInnerHTML={{ __html: t('qorddu:section6.block2.text') }} />
                                        },
                                        ]}
                                    />
                                    </>
                                </BlockwithImgLayout>




                        

                            

                            
                            
                            
                            
                        </div>
                    </div>

                </div>
            </div>

            
      
           

  )
}
