import React, { useEffect, useState } from 'react'
import OrgForm from '../../login/components/form/OrgForm'
import { Form, notification, Spin } from 'antd'
import { fetchInt } from '../../../utils/api/fetchInt';
import { getOrganization, updateOrganization } from '../../../services/api/request/user';

export default function Organization() {
    const [form] = Form.useForm()
    const [revalidate, setrevalidate] = useState(0);
    const [loading, setloading] = useState(false);
    useEffect(() => {
        setloading(true)
        fetchInt(getOrganization).then(res => res.json()).then(data => {
            const organizationData = data.data
            form.setFieldsValue({
                organizationName: organizationData.name,
                organizationRaisonSocial: organizationData.raisonSocial,
                organizationActivity: organizationData.activity,
                organizationActivityCode: organizationData.activityCode,
                organizationDuns: organizationData.duns,
                organizationPhone: organizationData.phone,
                organizationEmail: organizationData.email,
                organizationLogo: organizationData.logo,
                organizationAddressLine1: organizationData.address?.line1,
                organizationAddressLine2: organizationData.address?.line2?? '',
                organizationCountry: organizationData.address?.country,
                organizationCity: organizationData.address?.city,
                organizationZipCode: organizationData.address?.zipCode,
                organizationWebsite: organizationData.webResources?.website,
                organizationLinkedin: organizationData.webResources?.linkedin,
                organizationDescription: organizationData.description?.en,
                id:data.data.id
            })
        }).catch(err => console.log(err)).finally(() => setloading(false))
    }, []);

    const handleEdit = async ()=> {
        const { organizationDuns, organizationName, organizationActivity, organizationActivityCode, organizationAddressLine1, organizationAddressLine2, organizationCity, organizationCountry, organizationEmail, organizationLinkedin, organizationPhone, organizationRaisonSocial,organizationLogo, organizationZipCode, organizationWebsite, organizationDescription, id} = form.getFieldsValue()
        const submittedData = {
            logo:organizationLogo,
            name:organizationName,
            raisonSocial:organizationRaisonSocial,
            activity:organizationActivity,
            activityCode:organizationActivityCode,
            duns:organizationDuns,
            phone:organizationPhone,
            email:organizationEmail,
            address:{
                line1:organizationAddressLine1,
                line2:organizationAddressLine2,
                country:organizationCountry,
                city:organizationCity,
                zipCode:organizationZipCode
            },
            description:{
                en:organizationDescription
            },
            webResources:{
                website:organizationWebsite,
                linkedin:organizationLinkedin
            },
        }
        setloading(true)
        const req = await fetchInt(updateOrganization(id), {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(submittedData)
        })
        const res = await req.json()
        if(res.success){
            notification.success({message: 'Organization updated successfully'})
            setrevalidate(prev => prev+1)
        }
        setloading(false)
    }
   
  return (
    <div>
        <div className="max-w-[500px] px-8 pt-10">
            <Spin spinning={loading}>
                <Form form={form} onSubmitCapture={handleEdit} className='space-y-8'>
                    <Form.Item
                        name={'id'}
                        rules={[{required:true}]}
                        hidden
                        ></Form.Item>
                    <OrgForm/>
                    <button className='w-[100px] justify-center input-h flex items-center rounded bg-sub-windows disabled:opacity-40 border border-black' type={'submit'} disabled={loading}>Submit</button>
                </Form>

            </Spin>

        </div>
    </div>
  )
}
