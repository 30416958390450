import { Server, VolumeSearch } from "../types";



export function getPosition(title:any, search:string) {
    const indexes:any = [...title.matchAll(new RegExp(search, "gi"))].map(
      (a) => a.index
    );
    const result:any = [];
    indexes.forEach((i:number) => {
      search.split("").forEach((l, j:number) => {
        result.push(i + j);
      });
    });
    return result;
  }




export const generateSearchVolumKey = (server:VolumeSearch) => {
  if(server.cfs_type === 'LocalClusters' || server.cfs_type==='Datacenters'){
      return `/${server.cfs_type}/${server.id}`
  }else{
    return `/${server.clustersIds[0]}/${server.cfs_type}/${server.id}`
  }

}

export function isObject(value: unknown): value is object {
  return value !== null && typeof value === 'object';
}

export function getExtraPath(coll:string){
  if(coll.startsWith('Dedicated')){
    if(coll === 'DedicatedDrives'){
      return '/community'
    }
    else {
      return '/organization'
    }
  }
  return ''
}