import { CameraIcon } from '@heroicons/react/solid'
import React from 'react'


type Props = {
    children: JSX.Element,
    img?: {
        type: 'image' | 'icon',
        src:string,
        dimension?:string
    } | JSX.Element,
    orientation: 'left' | 'right'
}
export default function BlockwithoutImgLayout({children, img, orientation}:Props) {
  return (
    <div className="relative flex flex-col justify-center max-w-page mx-auto" >
        <div className={`flex space-x-[55px] justify-between items-center  ${orientation === 'left' ? 'flex-row-reverse' : ''}`}>
            <div className=" w-[50%] space-y-10 max-[750px]:w-[80%] max-[450px]:w-full">
               {children}
                

            </div>
           
            {/* <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px] ' style={{background: ' -439px -1522px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div> */}
        </div>

    </div>
  )
}
