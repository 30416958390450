import Blocks from './Blocks'


type Props = {
    text: JSX.Element,
    blocks:{
        columns:string,
        content: {
            img?: {type:'icon' | 'image', src:string},
            text:string |JSX.Element,
            title?:string
        }[]
    },
    topElement?:JSX.Element,
    bottomElement?:JSX.Element,
    transparent?:boolean
}
export default function CentredBlock({text, blocks, topElement, bottomElement, transparent}:Props) {
  return (
    <div>
                    
        {topElement}
        <div className={`relative  flex flex-col justify-center ${!transparent && 'bg-skin-fill-inverted text-skin-inverted'} ${!topElement ? 'pt-block' : '-mt-[1px]'} ${!bottomElement && 'pb-block'}`}>
            <div className='space-y-4   max-w-page mx-auto'>
                <div className='flex justify-center items-center'>
                    <div className="w-full space-y-16">
                        <div className={`text-center  ${!transparent && 'text-white'}`}>
                           {text}
                        </div>
                        <Blocks columns={blocks.columns} content={blocks.content.map(b => ({text: b.text, title: b.title, center:true, img: b.img ? {...b.img, dimensions:'w-[130px] h-[130px]'} : undefined}))} />
               
                        

                    </div>
                    {/* <div className='w-full flex-1 h-[500px]  flex justify-center items-center'>
                    <CameraIcon className='w-[150px] h-[150px] opacity-10 ' />
                </div> */}
                    {/* <div className='w-[900px] h-[900px] rounded-full border absolute right-[-449px]' style={{background: ' -3151px -227px / 505% no-repeat', boxShadow: '0 5px 31px rgb(0,0,0,0.1)'}}></div> */}
                </div>
            
            </div>
        
        </div>
        {bottomElement}

    </div>
  )
}
