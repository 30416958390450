import { Dropdown } from 'antd';
import React from 'react'
import UserActionsButton from '../../../components/shared/bars/TopBar/userActionsButton';

import { useParams, useSearchParams } from 'react-router-dom';
import Icon from '../../../components/icons/Icon';


export default function TopBarContainer() {
     const params = useParams()
      const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div>
        <div className='flex justify-between items-center w-full pr-7 border-b border-main'>
        

                
                    
            <div className="flex space-x-3 itesm-center flex-1">
            
                        <Icon name='Settings' className='icon-sm'/>
                
                
            <p className='w-[120px] truncate'>Settings</p>
            </div>
                

                  
                            
        


            <UserActionsButton/>
        
        </div> 

       
    </div>
  )
}
