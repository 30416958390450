import { Outlet, useParams } from "react-router-dom";
import { useAppContext } from "../../../../../../context/AppContext";
import { useQfsContext } from "../../../../context/qfsContext";
import { useEffect, useState } from "react";
import Icon from "../../../../../../components/icons/Icon";
import Divider from "../../../../../../components/shared/Divider";
import Footer from "../../../../components/Footer";
import { CameraIcon } from "@heroicons/react/solid";
import useGetPartners from "../../../../hooks/useGetPartners";
import { PartnerType } from "../../../../../../types";
import useGetPublications from "../../../../hooks/useGetPublications";
import Collapse, { Panel } from "../../../../../../components/shared/Collapse";
import PublicationsLink from "../../../../components/resourcesLayout/publications/PublicationsLink";
import PartnersAndSponsors from "../../../../components/resourcesLayout/Partners";
import { useUIContext } from "../../../../context/UIContext";
import useGetWebResources from "../../../../hooks/useGetWebResources";
import useFetchServerStatistics from "../../../../hooks/useFetchServerStats";
import HomeLayout from "../Layout";


export default function PublicVolume() {
  const params = useParams()
  const [loading, setloading] = useState(false);
  const {partners, sponsors, isPreReserved} = useGetPartners({setloading, coll: params.coll!, id: params.id!})
  const [partner, setpartner] = useState<null | PartnerType>(null);
 const { publications, isLoaded, totalHits: totalHitsPubs } = useGetPublications({
        id:params.id!,
        limit:7, 
        mode:'roots', 
        groupBy:false, 
        extraFilter:'&& publicationType.id :! company_profile',
        query:'*',
        pagination:{page:1, offset:0, limit:7},
        setGroupBy:() => {},
        selectedPubType: {value:'', icon:'', type:'type'},
        setPaginate:() => {},
        setselectedPubType: () => {}
    })
    const { organizations, total_publications} = useFetchServerStatistics(params.id)
  useEffect(() => {
    
    if(partners && partners.length > 0) {
      setpartner(partners[0])
    }else{
      setpartner(null)
    }
  }, [partners]);
   
    

      

  return (
    <>
    

            <HomeLayout
              stats={{
                organizations,
                publications:total_publications,
                people:0,
                pins:0
              }
              }
              partner={partner}
              isPreReserved={isPreReserved}
              isOwner={false}
            >

              <Collapse
                  defaultActiveKey={['publications','sponsors']}

              >
                <>
                {sponsors && (
                  <Panel
                          header={<p className='text-groupe'>Sponsors </p>}
                          key={'sponsors'}
                  >
                    <div className='mb-[40px] max-[580px]:mb-[20px] ml-[20px] mt-[10px]'>
                      <PartnersAndSponsors
                        partners={null}
                        sponsors={sponsors}
                        suffix={''}
                      />

                    </div>
                  </Panel>

                )}
                {publications && (

                      <Panel
                      header={<p className='text-groupe'>Recent publications </p>}
                      key={'publications'}
                  >
                      <div className="mb-[40px] max-[580px]:mb-[20px] ml-[18px] mt-[2px]">
                      <PublicationsLink
                          publications={publications}
                          webResources={undefined}
                          suffix={`${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}/`}
                          groupBy={{webresources:false, paidPubs:false}}
                          totalHits={{pubs:0, webresources:0}}
                          searchon={false}
                          selectedPubType={{value:'', icon:'', type:'type'}}
                          barActivatedFields={{author:true, date:true}}
                          setRecheckPin={() => {}}
                          displayMode='OneLine'
                      />
                      </div>
                  </Panel>
                )}
                
                </>
              
              </Collapse>
            </HomeLayout>
      


   
        
    
    </>
  )
}
