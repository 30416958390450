import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import SettingsLayout from './Layout'
import Organization from './pages/organization'
import Profile from './pages/Profile'
import Subscriptions from './pages/Subscriptions'
import Billing from './pages/Billing'
import Account from './pages/Account'



export default function SettingsNavigation() {
 
  return (
      <Routes>
          <Route element={<SettingsLayout/>}>
            
            

            <Route path='/organization' element={<Organization/>}/>
            <Route path='/profile' element={<Profile/>}/>
            <Route path='/subscription' element={<Subscriptions/>}/>
            <Route path='/billing' element={<Billing/>}/>
            <Route path='/account' element={<Account/>}/>





              <Route path='/*' element={<></>}></Route>

          </Route>
    

      </Routes>

  )
}
