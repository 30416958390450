import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.049 15H17.558C18.419 15 19.183 15.551 19.455 16.368L20.122 18.368C20.554 19.663 19.59 21 18.225 21H5.775C4.41 21 3.446 19.663 3.878 18.368L4.545 16.368C4.816 15.551 5.581 15 6.442 15H8.952"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 18C12 18 6 13.208 6 8.833C6 5.612 8.686 3 12 3C15.314 3 18 5.612 18 8.833C18 13.208 12 18 12 18Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4142 7.58579C14.1953 8.36684 14.1953 9.63317 13.4142 10.4142C12.6332 11.1953 11.3668 11.1953 10.5858 10.4142C9.80472 9.63317 9.80472 8.36684 10.5858 7.58579C11.3668 6.80474 12.6332 6.80474 13.4142 7.58579"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
