import React, { useState } from 'react'
import { useUIContext } from '../context/UIContext';
import { useParams } from 'react-router-dom';
import ResourcesTopBar from '../../../components/shared/bars/ResourcesBar/ResourcesPanel';
import DetailsTopBar from '../../../components/shared/bars/ResourcesBar/DetailsPanel';
import DetailsActionsComp from '../../../components/shared/bars/TopBar/actionsBar/actions/details';
import { UI_WIDTH_HIDE_SOME_ACTIONS, UI_WIDTH_IS_MOBILE, UI_WIDTH_PUBLICATIONS_SM } from '../../../constants';
import { useAppContext } from '../../../context/AppContext';

type Props = {
  details:boolean, 
  extra?:any
}
export default function QfsActionsBar({details, extra}:Props) {
    const { disabled, barActivatedFields, mode, setObjectDisplayMode,} = useUIContext()
    const {winSize}= useAppContext()
  return (
    <>
       

        <div className="">
        
          {winSize.w > UI_WIDTH_IS_MOBILE && (
            <div className='flex  items-center bg-bars bar-h border-b border-main pl-[11px]'>
              {extra}
                <div className='flex-1 flex flex-col'>
                    <ResourcesTopBar isPublication={!disabled.publicationBar} author={winSize.w < UI_WIDTH_HIDE_SOME_ACTIONS ? false : !!barActivatedFields.author} date={winSize.w < UI_WIDTH_HIDE_SOME_ACTIONS ? false : !!barActivatedFields.date}/>
                
                </div>
                {details && (
                  winSize.w > UI_WIDTH_PUBLICATIONS_SM && (
                    <div className='w-[33%] items-center flex justify-between'>
                        <DetailsTopBar/>
                          <DetailsActionsComp 
                            setObjectDisplayMode={setObjectDisplayMode}
                            mode={mode}
                            recheckPin={0}
      
                          
                          />
  
                        
                    
                    </div>
  
                  )

                )}
              </div>

          )}

        </div>
    </>
  )
}
