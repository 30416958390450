import { CameraIcon } from '@heroicons/react/solid'
import React from 'react'
import Blocks from '../../../../../../../home/components/block/Blocks'

export default function Preparation({prevStep, nextStep}:{prevStep:()=>void, nextStep:()=>void}) {
  return (
    <div className='space-y-14 max-w-[1100px]'>
    <p className="title-level2">Installation    </p>
    <div className="w-full h-[400px]  flex justify-center items-center rounded">
      <CameraIcon className='w-[100px] h-[100px] opacity-10'/>
    </div>
   
    <div className='space-y-7'>
      <div className=' flex justify-center w-full'>
        <div className="">
          <Blocks
            columns='grid-cols-4'
            content={[
              {
                title:'Démarrez fort ! ',
                text:'Votre serveur est amorcé en contenu pour attirer les utilisateurs et les entreprises dès son lancement (été 2025). ',
                img:{src:'',type:'icon',hidden:true}
              },
              {
                title:'Ensemble. Plus forts. ',
                text:'d’un réseau dans lequel chaque serveur est un point d’accès vers tous les autres serveurs. ',
                img:{src:'',type:'icon',hidden:true}
              },
              {
                title:'Promotion  ',
                text:'Votre serveur Qorddu et votre marque sont visibles dans LinkedIN et sur les principaux moteurs de recherche.   ',
                img:{src:'',type:'icon',hidden:true}
              },
            ]}
          />

        </div>

      </div>
    </div>
    <div className="flex w-full justify-end space-x-3">
      <button onClick={prevStep} className='px-4 py-2 rounded bg-gray-300 text-level2 '>Revenir a l'étape précédente </button>

      <button onClick={nextStep} className='px-4 py-2 rounded bg-gray-300 text-level2 '>L'étape suivante </button>
    </div>
  </div>
  )
}
