import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 20.0033H4.99708C3.89162 20.0033 2.99625 19.108 2.99625 18.0025V7.49812C2.99625 6.66978 3.66853 5.9975 4.49688 5.9975H17.5023C18.3306 5.9975 19.0029 6.66978 19.0029 7.49812V10.9996"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99792 5.9975V5.74739V5.74739C6.99792 3.67553 8.67762 1.99583 10.7495 1.99583H11.2497C13.3216 1.99583 15.0013 3.67553 15.0013 5.74739V5.74739V5.9975"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5611 14.51H21.0037L20.2995 18.0335H15.2664L14.2589 13.0004H13.0004"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8313 20.4165C19.8513 20.4365 19.8513 20.4675 19.8313 20.4875C19.8113 20.5075 19.7802 20.5075 19.7602 20.4875C19.7402 20.4675 19.7402 20.4365 19.7602 20.4165C19.7792 20.3965 19.8123 20.3965 19.8313 20.4165"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8046 20.4165C15.8246 20.4365 15.8246 20.4675 15.8046 20.4875C15.7846 20.5075 15.7536 20.5075 15.7336 20.4875C15.7135 20.4675 15.7135 20.4365 15.7336 20.4165C15.7526 20.3965 15.7846 20.3965 15.8046 20.4165"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
