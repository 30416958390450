import React, { useRef } from 'react'

import CardWithWebsiteLayout from '../WithWebsite/Layout'
import Description from '../WithWebsite/Description'
import { useAppContext } from '../../../../context/AppContext'
import Sm from '../WithWebsite/Sm'
import { UI_WIDTH_IS_MOBILE } from '../../../../constants'

type PartnerType = {
  partner: any,
  location?:any,
  imgHeight?:string,
  active?:boolean,
  small?:boolean, 
  country?:string
}
export default function PartnerWithWebsite({ partner, imgHeight,active, small, country } : PartnerType) {

 const {winSize} = useAppContext()
  return (
    <>
      {(winSize.w > UI_WIDTH_IS_MOBILE) ? (
      <div className={` ${active && 'bg-skin-fill-inverted text-skin-inverted'} rounded flex space-x-3`}>
        <CardWithWebsiteLayout
          item={{
            title: partner.name,
            // website:  <Website url={partner.website}/>
            image: partner.logo,
            cover: partner.coverUrl
          }}
          small={small}
          imgClassName={`h-[190px] w-full ${imgHeight && imgHeight}`}
          border
          active={active}
          country={country}
        />
        {
          !small && (

          <Description
            title={partner.name}
            description={partner.description}
            website={partner.webResources.website}
            type=''
            country={country}
            active={!!active}
            actions={{website:true, chat:true, card:true}}
          />
          )
        }
      
      </div>

      ): (
        <Sm title={partner.name} type={''} image={partner.logo} active={active}/>
      )}
    </>
  )
}
