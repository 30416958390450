import { Spin } from 'antd'
import React, { useState } from 'react'
import { Highlight, OwnerType, PartnerType, Publication, VolumeSearch } from '../../../../types/index'
import ResourcesLayout from '../resourcesLayout'
import LocationgUiLayout from './Uilayout'
import LocationgGlobalLayout from './globalLayout'
import { displayModes } from '../../context/UIContext'

type Props = {
  data: {
    availableLocations: {document :VolumeSearch}[] | null,
    publications: undefined | {document:Publication, highlight:Highlight}[],
    webResources: undefined | {document:Publication, highlight:Highlight}[],
    sponsors: PartnerType[] | null
    partners : PartnerType[] | null,
    owner?:OwnerType | undefined,
  }
  suffix: string,
  isLoaded:boolean,
  all?:string,
  searchStates : {
      query: string,
      selectedPubType: {value:string, icon:string, type:'groupe' | 'type'},
      allowSearch: boolean,
      onlyWebpages: boolean,
      totalHits: { pubs: number | null, webresources:number | null, locations:number } ,
      isSearchActive: boolean
      
  },
  title?:{
    webresources?:string,
    pubs?:string
  }
  ui:{
    displayMode: displayModes,
    barActivatedFields: { author:boolean, date: boolean},
    setAll :(value:string) => void,
    groupBy?:{
      webresources:boolean,
      paidPubs: boolean
    },
  },
  layoutProps:{
      loading:boolean 
      selectedKey:string,
      disabled:{filter:boolean, display:boolean, new:boolean,query:boolean},
      publicationBar:boolean,
      id:string,
      collection:string
    },

    header?:JSX.Element,
   
}
export default function LocationLayout({ layoutProps, suffix,all , isLoaded, header,searchStates, title, data, ui} : Props) {
    
  return (
  
      <LocationgUiLayout {...layoutProps}>
        <>
          {header}
          <ResourcesLayout
            data={data}
            suffix={suffix}
            ui={ui}
            all={all}
       
            isLoaded={isLoaded}
            searchStates={searchStates}
            title={title}
          />
        
        </>

      </LocationgUiLayout>

          

       
    
  )
}
