import React, { useEffect, useState } from 'react'
import ModalLayout from '../../../components/publishLayout/ModalLayout'
import Header from '../../../components/publishLayout/Header'
import { Outlet, useParams } from 'react-router-dom'
import { useVolumeCreationContext } from '../../../context/volumeCreationContext'
import { volumeCodes } from '../../../constants'
import { storageUrl } from '../../../../../services/api/constants'

export default function CreateVolumeLayout() {
    const { active, setVolumeType, volumeType } = useVolumeCreationContext()
    const params = useParams()
    useEffect(() => {
    
    const volume =volumeCodes.filter(code => code.code === params.type)[0]
    if(volume){
        setVolumeType(volume)
    }
    }, [params.type]);
  return (
    <div>
        <ModalLayout backLink='/mydesk'>
            <>
                <Header
                    tabs={[
                        {
                            title: 'Edit',
                            path: 'edit',
                            handleNavigation: () => {},
                            disabled: false
                        },
                        {
                            title: 'Publish',
                            path: 'publish',
                            handleNavigation: () => {},
                            disabled: !active.publish
                        }
                    ]}
                    title={`Create a ${volumeType.name}`}
                    icon={{src:`${storageUrl}${volumeType.icon.src}`, type:'image'}}
                
                />
                <Outlet/>
            </>
        </ModalLayout>
    </div>
  )
}
