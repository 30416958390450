import { Form, message, notification, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useQfsContext } from '../../../../../../context/qfsContext';
import useGetPartners from '../../../../../../hooks/useGetPartners';
import { useParams } from 'react-router-dom';
import { PartnerType } from '../../../../../../../../types';
import { CameraIcon, PencilAltIcon } from '@heroicons/react/solid';
import { fetchInt } from '../../../../../../../../utils/api/fetchInt';
import { validateReservation } from '../../../../../../../../services/api/request/partners';
import Blocks from '../../../../../../../home/components/block/Blocks';
import useGeGuestPartner from '../../../../../../hooks/useGetGuestPartner';
import { use } from 'i18next';
import i18n from '../../../../../../../../i18n';

export default function Reservation({nextStep, prevStep, partner}:{nextStep:()=>void, prevStep:()=>void, partner:PartnerType | null}) {
    const { currentLocation } = useQfsContext();
    const params = useParams();
    const [loading, setloading] = useState(false);
    const [image, setimage] = useState('');
    const fileRef = useRef<any>()
    const [form] = Form.useForm()
    const [preview, setPreview] = useState('');

   
  const handleImageChange = (e:any) => {
    const file = e.target.files[0];
    if (file) {
        setimage(file);
      setPreview(URL.createObjectURL(file)); // Show new preview
    }
  };
    useEffect(() => {
        
       if(partner){
        setPreview(partner.logo??"")
       }else{
        setPreview('')
       }
    }, [partner]);

    const confirmReservation = async ()=>{
        if(!partner) return
        await form.validateFields()
        const { name, description, url } = form.getFieldsValue()
        const formData = new FormData()
        formData.append('name', name)
        formData.append('description', description)
        formData.append('lang', i18n.language)

        formData.append('url', url)
        formData.append('validated', "true")
        if(image){
            formData.append('logoFile', image)
        }
        setloading(true)
        const req = await fetchInt(validateReservation({id: partner?.id, serverId:params.id!}), {
            method: 'PATCH',
                
            body:  formData,

        })
        const res = await req.json()
        setloading(false)
        if(res.success){
            notification.success({
                message: "Le serveur a été validé avec succès", 
                description: 'vous allez recevoir un email de confirmation',

              });
              nextStep()

        }else{
            message.error(res.message)
        }
    }
  return (
    <div className='space-y-14 max-w-[1100px]'>
            <p className="title-level2">Réservation  </p>
            <div className="h-[400px] flex items-center">
                {partner && (
                    <Form
                    initialValues={{server: currentLocation?.title, name: partner.name, description:partner.description, website:partner.webResources?.website, linkedin:partner?.webResources?.linkedin, logo: partner.logo, url:partner.webResources?.website}}
                    className='space-y-5 flex flex-1'
                    form={form}
                    >

                        
                        <div className="space-x-[30px] flex w-full max-w-[800px]">
                            <div className='space-y-2 flex-1 max-[450px]:space-y-5'>
                                <div className="flex space-x-6 items-center max-[450px]:block max-[450px]:space-x-0 max-[450px]:space-y-1">
                                    <p className="!font-semibold w-[110px]  text-level2 !mt-0 max-[450px]:w-full max-[450px]:text-start">Nom</p>
                                    <Form.Item
                                        name="name"
                                        style={{ marginBottom: 0 }}
                                        className='flex-1'
                                        rules={[{ required: true, message: 'Veuillez saisir un nom' }]}
                                    >
                                        <input type="text" className='rounded w-full text-level2' placeholder='Nom'  />
                                    </Form.Item>
                                </div>
                                <div className="flex space-x-6 items-center max-[450px]:block max-[450px]:space-x-0 max-[450px]:space-y-1">
                                    <p className="!font-semibold w-[110px]  text-level2 !mt-0 max-[450px]:w-full max-[450px]:text-start">Url</p>
                                    <Form.Item
                                        name="url"
                                        style={{ marginBottom: 0 }}
                                        className='flex-1'
                                        rules={[{ required: true, message: 'Veuillez saisir un lien' }]}

                                    >
                                        <input type="text" className='rounded w-full text-level2' placeholder='Url'  />
                                    </Form.Item>
                                </div>
                                <div className="flex space-x-6 max-[450px]:block max-[450px]:space-x-0 max-[450px]:space-y-1 ">
                                    <p className="!font-semibold w-[110px]  text-level2 !mt-0 max-[450px]:w-full max-[450px]:text-start">Description</p>
                                    <Form.Item
                                        name="description"
                                        style={{ marginBottom: 0 }}
                                        className='flex-1'
                                        rules={[{ required: true, message: 'Veuillez saisir une description' }]}

                                    >
                                        <textarea rows={4} className='rounded w-full text-level2' placeholder='Description'  />
                                    </Form.Item>

                                </div>

                            </div>
                            <div className="flex space-x-6 max-[450px]:block max-[450px]:space-x-0 max-[450px]:space-y-1">
                                <Form.Item
                                    name="logo"
                                    style={{ marginBottom: 0 }}
                                    className='flex-1'
                                >
                                    <div className="flex space-x-2 items-start">
                                        {preview && <img src={preview} alt="Preview" className='w-[150px] h-[150px] rounded' />}
                                        <input ref={fileRef} type="file" onChange={handleImageChange} className='!hidden' />
                                        <button onClick={() => fileRef?.current?.click()} className='' >
                                            <PencilAltIcon className='icon-sm'/>
                                        </button>
                                    </div>
                                </Form.Item>
                            </div>
                            
                        </div>
                    </Form>

                )}

            </div>

    
        <div className=' space-y-[70px]'>
            <div className="flex justify-center">
                <div className="">
                    <Blocks
                        columns='grid-cols-4'
                        content={[
                        {
                            title:'Offrez une expérience web unique ',
                            text:'Proposez des services et des contenus innovants et uniques sur votre marché (moteur de recherche, système d’annuaire/réseau…)..   ',
                            img:{src:'',type:'icon',hidden:true},
                        },
                        {
                            title:"Augmentez votre visibilité ",
                            text:"Devenez membre de la grille Qorddu et bénéficiez de puissants effets réseau pour augmenter votre trafic et votre visibilité.   ",
                            img:{src:'',type:'icon',hidden:true},

                        },
                        {
                            title:'Développez vos revenus ',
                            text:"Proposez aux entreprises des services Premium et augmentez vos revenus web. Sans risque et sans peine. ",
                            img:{src:'',type:'icon',hidden:true},

                        }
                        ]}
                    />

                </div>

            </div>
            </div>
            <div className="flex w-full justify-end space-x-3">
            <button className='px-4 py-2 rounded bg-gray-300 text-level2 disabled:opacity-30' disabled={loading} onClick={prevStep}>Revenir à l’étape précédente  </button>
            {partner?.validated ? (
                <button className='px-4 py-2 rounded bg-gray-300 text-level2 disabled:opacity-30 ' disabled={loading || currentLocation?.status === 'reserved' || partner?.validated} onClick={nextStep}>L'étape suivante</button>
            ) : (
                <button className='px-4 py-2 rounded bg-gray-300 text-level2 disabled:opacity-30 ' disabled={loading || currentLocation?.status === 'reserved'} onClick={confirmReservation}>Je valide la réservation  </button>

            )}
            </div>
        </div>
  )
}
