import { ReactSVG } from "react-svg";
import { storageUrl } from "../../../../../../../services/api/constants";
import Icon from "../../../../../../icons/Icon";
import DropDownMenu from "../../../../../menu";
import ReactSvg from "../../../../../ReactSvg";


type Props = {
    selectAction : (node:any) => void;
    selectedType:{value:string},
    typesList: any,
    extraTypes?:any
}

export function mapTreemenu(tree:any, onselect:any) {
  return tree.map((node:any) => {
    
    if (node.children && node.children.length > 0) mapTreemenu(node.children,onselect)
    if(node.icon.src){
      node.iconUrl = `${storageUrl}${node.icon.src}`
      node.icon = 
        <div className={`relative icon-sm`}>
            <ReactSvg src={`${storageUrl}${node.icon.src}`} className='w-full h-full' />
        </div>
        
    }
  

    node.key = node.id
    node.value = node.id
      if(node.name_singular){
          node.label = node.name_singular.en
      }else{
          node.label = node.title.en
      }
  if(node.selectable !== false){
      node.onClick = () => onselect(node)

  }
   
  
    return node
  })
}
export default function TypesDropdown({ selectedType, selectAction, typesList, extraTypes}:Props) {


  return (

        <DropDownMenu
          selectedKeys={[selectedType.value]}
          items={typesList ?[...mapTreemenu(JSON.parse(JSON.stringify(typesList)),selectAction),
            ...extraTypes ? [extraTypes] : []
          //   {
          //     label: 'Web resources',
          //     icon: <ReactSvg src={`${storageUrl}/o/icons%2Fpublications%2FwebResource.svg?alt=media&token=c03ba597-ec13-458b-b05b-5e3f8128cc79`} className="icon-sm"/>,
          //     key:'webresources',
          //     id:'webresources',
          //     onClick: () => selectAction({id:'webresources'})
          
          //   },
          //   {
            
          //     label:'Others',
          //     icon: <Icon name='FilterIcon' className="icon-sm"/>,
          //     selectable:false,
          //     key: 'others',
          //     children: mapTreemenu(JSON.parse(JSON.stringify(typesList.filter((type:any) => type.id !== 'popular' && type.id !=='web_resources' && type.children?.length>0))), selectAction)
          // }
        
        
        ] : []}
        
        />
  )
}

