import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../../context/AppContext'
import { Drawer } from 'antd'
import PublicationDetails from './index'
import { useNavigate } from 'react-router-dom'
import Icon from '../../../icons/Icon'
import { UI_WIDTH_PUBLICATIONS_SM } from '../../../../constants'
import DrawerSmLayout from '../../Drawers/drawerSmLayout'
import CollaboratorDetails from './index'
type Props = {
    collaboratorLayout:{
        mode : 'url' | 'object',
        extra?:{
          name:string | JSX.Element,
          value:JSX.Element | string
        }[],
        height?:string

    },
    header: JSX.Element
  }
export default function CollaboratorDeviceLayout(props:Props) {
    const { winSize } = useAppContext()
    const navigate = useNavigate()
    const [visible, setvisible] = useState(false);
    useEffect(() => {
        
        setvisible(true)
    }, []);
  return (
    <>
        {winSize.w >  UI_WIDTH_PUBLICATIONS_SM ? (
        
            <CollaboratorDetails {...props.collaboratorLayout}/>
        ) : (
                <DrawerSmLayout visible={visible} onClose={() => navigate(-1)} placement='right' title='Details'>

                        <>
                        {props.header}
                        <CollaboratorDetails {...props.collaboratorLayout}/>
                        
                        </>
                </DrawerSmLayout>
        )}
    </>
  )
}
