import { CheckIcon } from '@heroicons/react/solid'
import Icon from '../../../../../../icons/Icon'
import { useTranslation } from 'react-i18next'

type Props = {
    setGroupBy: (value:boolean) => void,
    groupBy:{paidPubs:boolean, webresources:boolean},
    disabled?:boolean,
    displayMode:"OneLine" | "TwoLines" | "Thumb" | "Blocks",
    setDisplayMode: (data:"OneLine" | "TwoLines" | "Thumb" | "Blocks") => void,
    barActivatedFields?: { author:boolean, date: boolean}
    setBarActivatedFields?: (data:{ author?:boolean, date?: boolean}) => void
}

export default function DisplayDropdown({setGroupBy, groupBy,disabled, displayMode, setDisplayMode, barActivatedFields, setBarActivatedFields}: Props) {
  const {t} = useTranslation()
  return (
    <>
  
    <div className="relative min-w-[270px]  teddra-shadow bg-menu teddra-rounded p-2 menu-filter" >
        {/* <Menu selectedKeys={props.selectedKeys} triggerSubMenuAction='click' mode="vertical" items={props.items} rootClassName="p-2 rounded menu-filter"   /> */}
      <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={disabled} onClick={() => setDisplayMode('OneLine')}>
          <div className='flex items-center'>
            <Icon name={'OneLine'} className='icon-sm ant-dropdown-menu-item-icon'/>
            <p className='ant-dropdown-menu-title-content'>{t('actions.display.one-line')}</p>
          </div>
          {displayMode === 'OneLine' && <CheckIcon className='icon-mini'/>}
      </button>

      <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={disabled} onClick={() => setDisplayMode('TwoLines')}>
          <div className='flex items-center'>
            <Icon name={'TwoLines'} className='icon-sm ant-dropdown-menu-item-icon'/>
            <p className='ant-dropdown-menu-title-content'>{t('actions.display.two-lines')}</p>
          </div>
          {displayMode === 'TwoLines' && <CheckIcon className='icon-mini'/>}
      </button>

      <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={true} onClick={() => setDisplayMode('Thumb')}>
          <div className='flex items-center'>
            <Icon name={'Thumbnails'} className='icon-sm ant-dropdown-menu-item-icon'/>
            <p className='ant-dropdown-menu-title-content'>{t('actions.display.thumbnail')}</p>
          </div>
          {displayMode === 'Thumb' && <CheckIcon className='icon-mini'/>}
      </button>

      <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={disabled} onClick={() => setDisplayMode('Blocks')}>
          <div className='flex items-center'>
            <Icon name={'Blocks'} className='icon-sm ant-dropdown-menu-item-icon'/>
            <p className='ant-dropdown-menu-title-content'>{t('actions.display.blocks')}</p>
          </div>
          {displayMode === 'Blocks' && <CheckIcon className='icon-mini'/>}
      </button>

      <div className='w-full bg-skin-fill-muted h-[1px] my-1'></div>

      <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={disabled} onClick={() => setGroupBy(false)}>
          <div className='flex items-center'>
            <Icon name={'Degroup'} className='icon-sm ant-dropdown-menu-item-icon'/>
            <p className='ant-dropdown-menu-title-content'>{t('actions.display.list')}</p>
          </div>
          {!groupBy.paidPubs && <CheckIcon className='icon-mini'/>}
      </button>
      <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={disabled} onClick={() => setGroupBy(true)}>
          <div className='flex items-center'>
            <Icon name={'GroupBy'} className='icon-sm ant-dropdown-menu-item-icon'/>
            <p className='ant-dropdown-menu-title-content'>{t('actions.display.groupe')}</p>
          </div>
          {groupBy.paidPubs && <CheckIcon className='icon-mini'/>}
      </button>
      {barActivatedFields && setBarActivatedFields && (
        <>
          <div className='w-full bg-skin-fill-muted h-[1px] my-1'></div>
          <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={disabled} onClick={() => setBarActivatedFields({author: true})}>
              <div className='flex items-center'>
                <Icon name={'Author'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>{t('actions.display.display-author')}</p>
              </div>
              {barActivatedFields.author && <CheckIcon className='icon-mini'/>}
          </button>
          <button className={`ant-dropdown-menu-item flex justify-between w-full disabled:opacity-20`} disabled={disabled} onClick={() => setBarActivatedFields({date: true})}>
              <div className='flex items-center'>
                <Icon name={'Date'} className='icon-sm ant-dropdown-menu-item-icon'/>
                <p className='ant-dropdown-menu-title-content'>{t('actions.display.display-date')}</p>
              </div>
              {barActivatedFields.date && <CheckIcon className='icon-mini'/>}
          </button>
        
        </>
      )}
    </div>
    {/* <DropDownMenu
      selectedKeys={[displayMode, groupBy ? 'GroupedBy' : 'List']}
      items={[
        {
          label: 'One line',
          key: 'OneLine',
          icon:<Icon name='OneLine' className='icon-sm'/>,
          disabled:disabled,
          onClick:() => setDisplayMode('OneLine')
        },
        {
          label: 'Two lines',
          key: 'TwoLines',
          icon:<Icon name='TwoLines' className='icon-sm'/>,
          disabled:disabled,
          onClick:() => setDisplayMode('TwoLines')
        },
        {
          label: 'Thumbnail',
          key: 'Thumbnail',
          icon:<Icon name='Thumbnails' className='icon-sm'/>,
          disabled:true,
          onClick:() => setDisplayMode('Thumb')
        },
        {
          label: 'Blocks',
          key: 'Block',
          icon:<Icon name='Blocks' className='icon-sm'/>,
          disabled:disabled,
          onClick:() => setDisplayMode('Blocks')
        },
        {
          type:'divider'
        },
        {
          label: 'List',
          key: 'List',
          icon:<Icon name='Degroup' className='icon-sm'/>,
          disabled:disabled,
          onClick:() => setGroupBy('')
        },
        {
          label: 'Groupe',
          key: 'GroupedBy',
          icon:<Icon name='GroupBy' className='icon-sm'/>,
          disabled:disabled,
          onClick:() => setGroupBy('publicationGroupe.id, publicationGroupe.title.en')
        },
      ]}
    /> */}
       {/* {mods.map(mod => (
          <button disabled={(mod.disabled || disabled)} className='px-3 py-2 bg-hover-transparent flex items-center justify-between w-full disabled:text-skin-muted' key={mod.id} onClick={() => setDisplayMode(mod.id)}>
                  <div className={`flex items-center space-x-2`}>
                      <Icon name={mod.icon}className='icon-sm'/>
                      <p>{mod.name}</p>
                  </div>
                  {displayMode == mod.id  && <CheckIcon className='icon-sm' />}
          
          </button>
          

      ))} */}
                          
      {/* <div className="w-full h-[1px] bg-skin-fill-muted"></div>
      <button className='px-3 py-2 bg-hover-transparent flex items-center justify-between w-full'  onClick={() => setGroupBy('')}>
          <div className='flex items-center space-x-2'>
              <Icon name='Degroup' className='icon-sm'/>
              <p>List</p>
          </div>
          {!groupBy && <CheckIcon className='icon-sm' />}
          
      </button>
      <button className='px-3 py-2 bg-hover-transparent flex items-center justify-between w-full disabled:text-skin-muted disabled:fill-skin-muted'  onClick={() => setGroupBy('publicationGroupe.id, publicationGroupe.title.en, publicationGroupe.iconUrl')}>
          <div className='flex items-center space-x-2'>
              <Icon name='GroupBy' className='icon-sm'/>
              <p>Groupe</p>
          </div>
          {groupBy && <CheckIcon className='icon-sm' />}
      </button> */}
    
    </>
     
  )
}
