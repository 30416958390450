import { Outlet, useParams } from "react-router-dom";
import { useQfsContext } from "../../../../context/qfsContext";
import Footer from "../../../../components/Footer";
import { CameraIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Icon from "../../../../../../components/icons/Icon";
import { useUIContext } from "../../../../context/UIContext";

export default function Module() {
    const {currentLocation} = useQfsContext()
    const params = useParams()
    const {t} = useTranslation(['modules'])
    const [module, setmodule] = useState<null | any>(null);
    const {hidePanel, setHidePanel} = useUIContext()
    const modules = [
        {
            ... t('modules:journal', {returnObjects: true}),
            key:'journal',
            icon: 'Newspapers',
          
        },
        {
            ... t('modules:websearch', {returnObjects: true}),
            key:'websearch',
            icon:'Explorer',
           
        },
        {
            ... t('modules:directory', {returnObjects: true}),
            key:'directory',
            icon:'Contacts',
          
        },
        {
            ... t('modules:marketplace', {returnObjects: true}),
            key:'marketplace',
            icon:'Marketplace',
       
        },
        {
            ... t('modules:opportunities', {returnObjects: true}),
            key:'opportunities',
            icon:'Opportunities'
          
        },
        {
            ... t('modules:communities', {returnObjects: true}),
            key:'communities',
           
        },
        {
            ... t('modules:jobs', {returnObjects: true}),
            key:'jobs',
            icon:'Jobs',
          
        },
        {
            ... t('modules:education', {returnObjects: true}),
            key:'education',
            icon:'Education',
         
        },
        {
            ... t('modules:knowledge', {returnObjects: true}),
            key:'knowledge',
            icon:'Knowledge',
          
        },
        {
            ... t('modules:agenda', {returnObjects: true}),
            key:'agenda',
            icon:'Calandars',
           
        },
        {
            ... t('modules:immovablesen', {returnObjects: true}),
            key:'immovables-en',
            icon:'ImmoEn',
         
        },
        {
            ... t('modules:immovablesre', {returnObjects: true}),
            key:'immovables-re',
            icon:'ImmoRe',
           
        },
        {
            ... t('modules:cityguides', {returnObjects: true}),
            key:'cityguides',
            icon:'CityGuide',
          
        },
        {
            ... t('modules:yellowpages', {returnObjects: true}),
            key:'yellowpages',
            icon:'YellowPages',
            
        },
        {
            ... t('modules:innovation', {returnObjects: true}),
            icon:'Innovation',
            key:'innovation',
           
        }
    ]
    useEffect(() => {
        
        setmodule(modules.filter(m => m.key === params.module) ? modules.filter(m => m.key ===params.module)[0] : null)
    }, [params.module]);
    

  return (
    <>
    

            <div className="height-community overflow-auto flex justify-center items-center relative">
                <div className="bar-h flex items-center px-5 absolute top-0 left-0 z-30">
                    <button className={`p-1.5 bg-hover-transparent rounded no-dragable disabled:opacity-20 transition-all ${hidePanel ? ' rotate-180' :''}`} onClick={() => setHidePanel(!hidePanel)} disabled={false}>
                        <Icon name='PanelOpen' className='icon-sm'/>
                    </button>

                </div>
                {module && (
                    <div className={`flex space-x-8 items-center ${module.p?.length > 4 ? 'max-w-[800px] w-full' : 'max-w-[600px]'}`}>
                        {module.icon ? (
                            <Icon name={module.icon} className="w-[250px] h-[250px] opacity-10"/>

                        ) : (
                            <CameraIcon className="w-[250px] h-[250px] opacity-10"/>

                        )}
                        <div className="flex-1">
                            <p className="font-bold text-[18px]">{module.title}</p>
                            <p className="">{t('modules:text')}</p>
                            <div className={`mt-3  ${module.p?.length > 4 ? 'grid grid-cols-2 justify-between gap-y-2' :'space-y-2'}`}>
                               { module.p?.map((p:string,i:number) => (
                                <div className={`flex space-x-3 items-center `} key={i}>
                                    <CameraIcon className="w-[40px] h-[40px] opacity-10" />
                                    <p className="flex-1">{p}</p>
                                </div>

                               ))}
                             
                               
                            </div>

                        </div>
                    </div>

                )}

            </div>
            <Footer
                totalHits={{webresources:0, pubs:0, location:0}}
                pagination={{limit:7, offset:0, page:1}}
                setPaginate={(data) => {}}
                onlyWebpages={false}
                currentLocation={currentLocation}
                allowPaginate={
                    {
                    webresources: false,
                    pubs: false
                    }
                }
                windows={{
                    active: params.module!,
                    suffix:`/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}`,
                    disabled:!currentLocation?.isActive
                }}
       />

        <Outlet/>

   
        
    
    </>
  )
}
