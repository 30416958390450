import { useNavigate } from "react-router-dom"
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid"
import { ButtonLg } from "../Buttons/ButtonLg"
import { Breadcrumb, routeType } from "../bars/TopBar/types"
import { useState } from "react"
import { Dropdown } from "antd"
import VolumesOverlay from "./overlays/volumes"
import DomainNavigation from "../../../modules/Network/components/domainNavigation"
import { getExtraPath } from "../../../utils/utils"



export const BreadcrumbComp = (props:Breadcrumb) => {
    const navigate = useNavigate()
    const [open, setopen] = useState(false);
    const [openedRoute, setopenedRoute] = useState<null | number>(null);
    const handleClick = (route:routeType) => {
        if((route.path && !route.disabled)){
            if(route.coll){
                navigate(route.path + getExtraPath(route.coll)) 
                return
            }
            navigate(route.path) 
        }
            
        }
    return (
        <div className={`w-full rounded flex justify-between bg-sub-windows `}>
            <div className="flex space-x-0.5 items-center flex-1  rounded  py-0.5">
                {props.routes.map((route,i) => (
                    <>
                    {!route.action ? (
                        <>
                            <button className={`${openedRoute === i ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'} flex space-x-0.5 items-center rounded `} onClick={() => setopen(true)} disabled={(route.disabled || props.disabled)}>
                                

                                <ButtonLg
                                    key={i}
                                    buttonProps={{
                                        onClick:() => handleClick(route),
                                        disabled: props.disabled,
                                        className:'rounded-0 !bg-transparent input-h px-2'
                                    
                                    }}
                                    prefix={route.icon && {
                                        icon: route.icon,
                                        size:'sm'
                                    }}
                                    
                                    
                                    
                                >
                                    <p className={`${(i===props.routes.length -1) && 'font-bold'} ellipsis-1`}>{route.name}</p>
                                </ButtonLg>
                                {route.id ? (
                                        <Dropdown
                                            trigger={['click']}
                                            visible={openedRoute === i}
                                            destroyPopupOnHide
                                            
                                            overlay={
                                                route.id === 'qorddu' ? <DomainNavigation onClose={() => setopenedRoute(null)}/> :
                                            <VolumesOverlay route={route} clusterId={props.routes.filter((r:any) => r.depth===2)[0]?.id} onClose={() => setopenedRoute(null)}/>
                                        }
                                        >
                                            <button className={`p-1 input-h w-[30px] flex justify-center items-center ${openedRoute === i ? '' : 'bg-hover-transparent'} `} 
                                            onClick={() => setopenedRoute(i)}
                                            onBlur={(e) => {
                                                const relatedTarget = e.relatedTarget;
                                             
                                                if (!relatedTarget || !relatedTarget.closest('.ant-dropdown')) {
                                                    setopenedRoute(null);
                                                }
                                            }}
                                            >
                                                {openedRoute === i ? (
                                                    <ChevronDownIcon className="w-3.5 h-3.5"/>

                                                ) : (
                                                    <ChevronRightIcon className="w-3.5 h-3.5"/>
                                                )}

                                            </button>

                                        </Dropdown>

                                ) : (
                                        <button className={`p-1 rounded ${openedRoute === i ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'} `} 
                                            
                                        >
                                            
                                                <ChevronRightIcon className="w-3.5 h-3.5"/>
                                        

                                        </button>
                                )}
                            </button>

                                
                        
                        </>
                    ) : (
                        <>
                            <button className="flex space-x-0.5 items-center disabled:opacity-20" onClick={() => setopen(true)} disabled={(route.disabled || props.disabled)}>

                                <ButtonLg
                                    key={i}
                                    buttonProps={{
                                        onClick:() => handleClick(route),
                                    }}
                                    prefix={route.icon && {
                                        icon: route.icon,
                                        size:'sm'
                                    }}
                                    
                                >
                                    <p className={`${(i===props.routes.length -1) && 'font-bold'} ellipsis-1`}>{route.name}</p>
                                </ButtonLg>
                                <button className="p-1 rounded bg-hover-transparent">
                                    <ChevronRightIcon className="w-3.5 h-3.5"/>

                                </button>
                            </button>
                            {route.action({open, setopen})}
                        </>

                    )}
                    
                    </>

                ))}
                
            </div>
        </div>
    )
}