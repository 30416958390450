import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 17.0796V21"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 21V19.5285C3 18.0985 4.00938 16.8672 5.41165 16.5868L8.25765 16.0176C8.49288 15.9705 8.73717 16.0188 8.93677 16.1519V16.1519C10.7917 17.3885 13.2083 17.3885 15.0633 16.1519V16.1519C15.2629 16.0188 15.5072 15.9705 15.7424 16.0176L18.5884 16.5868C19.9907 16.8672 21 18.0985 21 19.5285V21"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 7.39834V10C16 12.2091 14.2091 14 12 14V14C9.79086 14 8 12.2091 8 10V7.39834"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1012 13.4054L14.8253 16.3019"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.90124 13.4054L9.17719 16.3018"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09686 6.45618L10.5514 8.66141C11.4644 9.11286 12.5356 9.11286 13.4486 8.66141L17.9031 6.45618C18.2684 6.27761 18.5 5.90656 18.5 5.5C18.5 5.09344 18.2684 4.72239 17.9031 4.54382L13.4486 2.33859C12.5356 1.88714 11.4644 1.88714 10.5514 2.33859L6.09686 4.54382C5.73161 4.72239 5.5 5.09344 5.5 5.5C5.5 5.90656 5.73161 6.27761 6.09686 6.45618Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5 5.53453V8.13453"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
