



export function searchLocations({q, filter, limit, sort, status}: {q:string, filter?:string, limit?:number, sort?:string, status?:string}){
  return {
    'q': q,
    'query_by'  : 'title.en,title.fr, description.en, description.fr',
    'sort_by':sort ? `${sort}` : "isActive:desc,title.en:asc",
    'filter_by': status ? `status:=${status} ${filter ?? ''}` : `status:'[enabled, coming_soon]' ${filter ?? ''}`,
    'text_match_type': 'max_weight',
    'prioritize_token_position':true,
    'exclude_fields':'pinned_sources',
    'limit':limit ? limit : 100,
    query_by_weights: '50,50,20,20',
    "prioritize_num_matching_fields":false,
    "include_fields": "$organizations(*)"
    
 
  }
}


export function searchPublications({q, volumeId,mode='roots', page, offset, type, groupBy, limit, sponsored, extraFilter, status}: {q:string, volumeId:string, page?: number, offset?:number, type?:{type: 'groupe' | 'type', value:string}, mode?:'roots' | 'volume' , groupBy?:string, limit?:number, sponsored?:boolean, extraFilter?:string, status?:string}){
  

 
  
   return { 
    'q': q,
    'query_by'  : 'url,title.en, title.fr,description.en, description.fr, keywords.en, keywords.fr',

    'filter_by':`$publications_locations($locations(${mode === 'volume' ? `(id:${volumeId})` : `roots:=${volumeId}`} && status:enabled)) && ${status ? `status:${status}` :'status:published'} ${(type?.value && type.type === 'type') ? `&& publicationType.id:${type.value}` : ''} ${(type?.value && type.type === 'groupe') ? `&& publicationGroupe.id:${type.value}` : ''} ${sponsored ? '&& sponsored:true' : ''} ${extraFilter ?? ''}` ,

    'group_by' : groupBy?groupBy:'',
    limit: (limit && limit > 0) ? limit :15,
    page : page? page:1,
    offset: offset?offset:0,

    'group_limit': 7,
    'prioritize_token_position':true,
    // "sort_by": groupBy ? 'created_at:desc' : 'created_at:desc',
    "sort_by": q ? '_text_match:desc':'created_at:desc',
    query_by_weights: '10,7,7,2,2,3,3',
    "prioritize_num_matching_fields":false,
    'prioritize_exact_match':false,
    'text_match_type': 'max_weight',
    num_typos: 0,
    'exclude_fields':'pinned_sources',
    snippet_threshold	: 500

  }

}
export function searchWebResources({q, volumeId, groupBy, groupLimit, websiteId, limit, offset, page, mode='roots'}: {q:string, volumeId:string, groupBy?:string, groupLimit?:number, websiteId?:string, limit?:number, page?:number, offset?:number,mode?:'roots' | 'volume'  }){
 

  return {
    'q': q,
    'query_by'  : 'url,title.en, title.fr,description.en, description.fr, keywords.en, keywords.fr',
    'filter_by':`$web_resources_locations($locations(${mode === 'volume' ? `(id:${volumeId})` : `roots:=${volumeId}`} && status:enabled)) && status:published ${websiteId ? `&& publication_id:${websiteId}` : ''}` ,
  
    'group_by' : groupBy ? groupBy: '',
    limit: (limit && limit > 0) ? limit :15,
    page : page? page:1,
    offset: offset?offset:0,
    'group_limit': groupLimit ? groupLimit : 10,
    // 'text_match_type': 'max_score',
    'prioritize_token_position':true,
    "sort_by": q ? '_text_match:desc':'created_at:desc',
    'exclude_fields':'pinned_sources',
    query_by_weights: '10,7,7,2,2,3,3',
    "prioritize_num_matching_fields":false,
    'text_match_type': 'max_weight',
    num_typos: 0,
    "include_fields": "$publications(*)",
    snippet_threshold	: 500,
  
  }

}

export function searchQueries({q, domainId}: {q:string, domainId:string}){
 

  return {
    'q': q,
    'query_by'  : 'query',
    'filter_by':`${domainId ? `domainId:=${domainId}` : ''}`,
    limit: 5,
    num_typos: 0,
  
  }

}

export function searchDomains({q, limit, offset, page, filter_by, sort_by}: {q:string, limit?:number, offset?:number, page?:number, filter_by?:string, sort_by?:string}){
  

  return {
    'q': q,
    limit: limit ? limit : 10,
    page : page? page:1,
    offset: offset?offset:0,
    num_typos: 0,
    filter_by: filter_by ? filter_by : '',
    sort_by: sort_by ? sort_by : '',
  }
}

 



