import { useLayoutEffect, useState } from "react";
import { useAppContext } from "../context/AppContext";

export default function useWindowSize(){
    const {setWinSize} = useAppContext()
    useLayoutEffect(() => {
      const updateSize = () => {
        setWinSize({w: window.innerWidth, h: window.innerHeight});
      };
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return {};
  };