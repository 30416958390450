import React from 'react'
import VolumeDetails from '../../../../components/shared/details/volume'
import VolumeDeviceLayout from '../../../../components/shared/details/volume/deviceLayout'
import DetailsActionsComp from '../../../../components/shared/bars/TopBar/actionsBar/actions/details'

export default function VolumeDetailsPage() {
  return (
    <VolumeDeviceLayout
      header={
          <div className='flex justify-end'>
            <DetailsActionsComp
              setObjectDisplayMode={() =>{}}
              mode={'object'}
              recheckPin={0}
  
            
            />

          </div>
        }
    
    />

  )
}
