import React, { useEffect } from 'react'
import { useQfsContext } from '../../../../../context/qfsContext'
import CommunityActive from './CommunityActive'
import CommunityInactive from './CommunityInactive'
import { useParams } from 'react-router-dom'
import LocationgGlobalLayout from '../../../../../components/locationLayout/globalLayout'
import QfsActionsBar from '../../../../../GlobalLayout/QfsActionsBar'
import Icon from '../../../../../../../components/icons/Icon'
import { useUIContext } from '../../../../../context/UIContext'

export default function CommunityLayout() {
    const {currentLocation} = useQfsContext()
    const { hidePanel, setHidePanel } = useUIContext()
    const params = useParams()
    const { setAll } = useUIContext()

    useEffect(() => {
        setAll('')
        
    }, []);
  return (
    <div>
         <LocationgGlobalLayout
            collection={params.coll!}
            id={params.id!}
            selectedKey={params.id!}
            disabled={{filter:false, new:false, display:false,query:false}}
            publicationBar={false}
        >
            <>
                {currentLocation && (
                    <>
                    <QfsActionsBar
                        details={false}
                        extra={
                            <button className={`p-1.5 bg-hover-transparent rounded no-dragable disabled:opacity-20 h-max transition-all ${hidePanel ? ' rotate-180' :''}`} onClick={() => setHidePanel(!hidePanel)} disabled={false}>
                                <Icon name='PanelOpen' className='icon-sm'/>
                            </button>
                        }
                        />
                    {currentLocation.isActive ? (
                        <CommunityActive/>
                    ) : (
                        <CommunityInactive/>
                    )}
                    
                    </>
                )}
            
            </>

        </LocationgGlobalLayout>
    </div>
  )
}
