import React from 'react'
import BlockwithImgLayout from '../../../../../../../home/components/block/withImgLayout'
import TextBlock2 from '../../../../../../../home/components/block/TextBlock2'
import { CameraIcon } from '@heroicons/react/solid'
import Blocks from '../../../../../../../home/components/block/Blocks'

export default function Subscription({prevStep, nextStep}:{prevStep:()=>void, nextStep:()=>void}) {
  return (
    <div className='space-y-14 max-w-[1100px]'>
    <p className="title-level2">Souscription</p>
    <div className="w-full h-[400px]  flex justify-center items-center rounded">
      <CameraIcon className='w-[100px] h-[100px] opacity-10'/>
    </div>
   
    <div className='space-y-7'>
      <div className=' flex justify-center w-full'>
        <div className="">
          <Blocks
            columns='grid-cols-4'
            content={[
              {
                title:'Des frais d’installation minimes ',
                text:'Les frais d’exclusivité et d’’installation de votre serveur vont de 500 à 2.500€ seulement selon le marché ciblé. ',
                img:{src:'',type:'icon',hidden:true}
              },
              {
                title:'Un abonnement accessible ',
                text:'Un abonnement à la plateforme à partir de 190€ par mois seulement pour les serveurs locaux (départements français, comtés américains…).    ',
                img:{src:'',type:'icon',hidden:true}
              },
              {
                title:'Un simulateur de revenus ',
                text:'Vous pouvez simuler vos revenus avec le simulateur proposé dans la page « Pourquoi réserver votre serveur ? »  ',
                img:{src:'',type:'icon',hidden:true}
              },
            ]}
          />

        </div>

      </div>
    </div>
    <div className="flex w-full justify-end space-x-3">
      <button onClick={prevStep} className='px-4 py-2 rounded bg-gray-300 text-level2 '>Revenir a l'étape précédente </button>

      <button onClick={nextStep} className='px-4 py-2 rounded bg-gray-300 text-level2 '>L'étape suivante </button>
    </div>
  </div>
  )
}
