import { on } from "events";
import PaginationComponent from "../../../components/shared/pagination";
import { useSearchContext } from "../context/searchContext";
import { useUIContext } from "../context/UIContext";
import Help from "./Help";
import useNavigateTo from "../hooks/useNavigateTo";
import { Server } from "../../../types";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { UI_WIDTH_HIDE_SOME_ACTIONS } from "../../../constants";
import { Drawer } from "antd";

type Props = {
  
  totalHits:{webresources:number | null, pubs:number | null, location:number | null},
  pagination:{page:number, offset:number, limit:number},
  setPaginate: (data:{page?:number, limit?:number, offset?:number}) => void,

  onlyWebpages:boolean,
  currentLocation:Server | null,
  allowPaginate:{
    webresources:boolean,
    pubs:boolean
  }
  windows: {
    active: string,
    
    disabled:boolean
    suffix: string,
    hidden?:boolean
    
  }
}
export default function Footer({ totalHits, pagination, setPaginate, onlyWebpages, currentLocation, allowPaginate, windows }: Props) {
  
  const { disabled, hidePanel } = useUIContext()
  const { goTo } = useNavigateTo()
  const { setAll } = useUIContext()
  const {domain, winSize} = useAppContext()
  const [windowsList, setwindowsList] = useState<{text:string, icon:string, key:string}[] | undefined>(undefined);
  const {t} = useTranslation(['modules'])
  const [openWindows, setopenWindows] = useState(false);

  const RegionalWindows =  [
    
 
    {
      text: t('modules:websearch.title'),
      icon: '',
      key: 'websearch',
    },
    {
      text: t('modules:directory.title'),
      icon: '',
      key: 'directory',
    },
    {
      text: t('modules:marketplace.title'),
      icon: '',
      key: 'marketplace',
    },
    {
      text: t('modules:jobs.title'),
      icon: '',
      key: 'jobs',
    },
    {
      text: t('modules:immovablesen.title'),
      icon: '',
      key: 'immovables-en',
    },
    {
      text: t('modules:education.title'),
      icon: '',
      key: 'education',
    },
    
  ]
  const LocalWindows =  [
    

    {
      text: t('modules:yellowpages.title'),
      icon: '',
      key: 'yellowpages',
    },
    {
      text: t('modules:cityguides.title'),
      icon: '',
      key: 'cityguides',
    },
    {
      text: t('modules:agenda.title'),
      icon: '',
        key: 'agenda',
    },
    {
      text: t('modules:jobs.title'),
      icon: '',
      key: 'jobs',
    },
    {
      text: t('modules:immovablesre.title'),
      icon: '',
      key: 'immovables-re',
    },
    
  ]
  const ThematicWindows =  [
   
   
    {
      text: t('modules:websearch.title'),
      icon: '',
      key: 'websearch',
    },
    {
      text: t('modules:directory.title'),
      icon: '',
      key: 'directory',
    },
    {
      text: t('modules:marketplace.title'),
      icon: '',
      key: 'marketplace',
    },
    {
      text: t('modules:jobs.title'),
      icon: '',
      key: 'jobs',
    },
    {
      text: t('modules:education.title'),
      icon: '',
      key: 'education',
    },
    {
      text: t('modules:innovation.title'),
      icon: '',
      key: 'innovation',
    },
    {
      text: t('modules:knowledge.title'),
      icon: '',
        key: 'knowledge',
    },
    {
      text: t('modules:opportunities.title'),
      icon: '',
      key: 'opportunities',
    },
    
    
  ]
  const handleSelect = (window:string) => {
    // if(window === '') {
    //   // setGroupBy({webresources:true})
    //   setOnlyWebpages(false)
    // }
    if(!windows.hidden){
      console.log(windows.suffix )
      setAll('')
      goTo(`${windows.suffix ? windows.suffix : ''}${window}`,{})

    }
  }
 
  useEffect(() => {
    
    if(domain){
      if(domain.local){
        setwindowsList(LocalWindows)
      }else if(domain.regional){
        setwindowsList(RegionalWindows)
      }else{
        setwindowsList(ThematicWindows)
      }
    }
  }, [domain]);
 

  return (
    <div>
        <div className='border-t border-main flex items-center bg-topbar max-w-screen w-full flex-1'>
        
            <div className=" flex space-x-0.5 px-5 bar-h whitespace-nowrap overflow-x-auto overflow-y-hidden">
                {winSize.w > UI_WIDTH_HIDE_SOME_ACTIONS ? 
                <>
                  <button className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === '' ? 'active-window ' : ''}`} onClick={() => handleSelect('')}>
                    <p className="truncate">
                      {t('modules:home.title')}

                    </p>
                  </button>
                  {!windows.hidden && windowsList?.map(window => (
                    <button key={window.key}  className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === window.key ? 'active-window ' : ''} truncate`} onClick={() => handleSelect(`${windows.disabled ? '/activation' : ''}/window/${window.key}`)}>
                      <p className="truncate">
                        {window.text}
                      </p>
                  </button>
                  ))}
                </>
                :(
                  <div className="flex">
                    {(windowsList && windows.active) ? windowsList?.filter(w => w.key === windows.active).map(window => (
                        <button key={window.key}  className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === window.key ? 'active-window ' : ''}`} onClick={() => handleSelect(`${windows.disabled ? '/activation' : ''}/window/${window.key}`)}>
                          <p>
                            {window.text}

                          </p>
                      </button>
                    )): (
                      <div className="flex">
                        <button className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === '' ? 'active-window ' : ''}`} onClick={() => handleSelect('')}>
                          <p>
                            {t('modules:home.title')}

                          </p>
                      </button>

                      </div>
                    )}
                    {!windows.hidden &&  (
                      <>
                        <button className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b`} onClick={() => setopenWindows(true)}>
                          {t('modules:all-windows')}
                        </button>
                        <Drawer
                          visible={openWindows}
                          onClose={() => setopenWindows(false)}
                          placement="right"
                          title={t('modules:windows')}
                          width={winSize.w > 420 ? 400 : '100%'}
                        >
                          <div className="px-2">
                          <button className={`w-full block disabled:opacity-30  px-4 h-max pb-2 pt-1 rounded ${windows.active === '' ? '  bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'}`} onClick={() => handleSelect('')}>
                            {t('modules:home.title')}
                          </button>
                          {windowsList?.map(window => (
                            <button key={window.key}  className={`w-full block disabled:opacity-30  px-4 h-max pb-2 pt-1 rounded ${windows.active === window.key ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'}`} onClick={() => handleSelect(`${windows.disabled ? '/activation' : ''}/window/${window.key}`)}>
                            {window.text}
                          </button>
                          ))}
                          </div>
                        </Drawer>
                      
                      </>
                    )}
                    
                  </div>
                )}
                {/* <button disabled={windows.disabled.websearch}  className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === 'websearch' ? 'active-window ' : ''}`} onClick={() => handleSelect('/window/websearch')}>
                  Websearch
                </button>
                <button disabled={windows.disabled.directory}  className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === 'directory' ? 'active-window ' : ''}`} onClick={() => handleSelect('/window/directory')}>
                  Directory
                </button>
                <button disabled className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === 'news' ? 'active-window ' : ''}`} onClick={() => handleSelect('window/news')}>
                  News
                </button>
                <button disabled className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === 'events' ? 'active-window ' : ''}`}>
                  Events
                </button>
                <button disabled className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === 'jobs' ? 'active-window ' : ''}`}>
                  Jobs
                </button>
                <button disabled className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === 'marketplace' ? 'active-window ' : ''}`}>
                  Marketplace
                </button>
                <button disabled className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === 'knowledge' ? 'active-window ' : ''}`}>
                  Knowledge 
                </button>
                <button disabled className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === 'forum' ? 'active-window ' : ''}`} onClick={() => handleSelect('/window/forum')}>
                  Forum
                </button>
                <button  disabled className={`disabled:opacity-30 bg-sub-windows border border-t-0 px-4 h-max pb-2 pt-1 rounded-b ${windows.active === 'knowledge' ? 'active-window ' : ''}`}>
                  Research engine 
                </button> */}
          
              
            </div>
        </div>

      <div className="border-t border-main">
      <div className="bg-black  text-white">
        <div className="bar-h-mini flex justify-between items-center ">
          <PaginationComponent
              disabled={disabled.query}
              pagination={pagination}
              totalHits={{webresources: totalHits.webresources , pubs:totalHits.pubs}}    
              hidePanel={hidePanel}
              setPaginate={(data) => setPaginate(data)}
              onlyWebpages={onlyWebpages}
              allowPaginate={allowPaginate}
          />
          {/* <Help/> */}

        </div>

      </div>

      </div>
    </div>
    
  
  )
}
