import React, { useEffect, useRef, useState } from 'react'
import { useAppContext } from '../../../context/AppContext';
import { UI_WIDTH_SIDEBAR_SM } from '../../../constants';
import { Drawer } from 'antd';
import DrawerSmLayout from '../Drawers/drawerSmLayout';

type Props = {
    sidebar:JSX.Element | null,
    children:JSX.Element,
    hideSidebar:boolean,
    setWidth: (value: number) => void,
    footer?:JSX.Element,
    hidePanel:boolean,
    setHidePanel:any
}
function Component({sidebar,sidebarRef,sidebarWidth, footer, resizable, setSidebarWidth, setWidth} : any) {
  const rsMouseDownHandler = (e:any) => {
    if(e){
      const x = e.clientX;
      const sbWidth = window.getComputedStyle(sidebarRef.current).width;
      const initialWidth = parseInt(sbWidth, 10);
      const mouseMoveHandler = (e:any) => {
        // const dx = x - e.clientX; 
        const dx = e.clientX - x; 
        const newWidth = initialWidth + dx;
  
        if (newWidth >= 200 && newWidth<=450) {
          setSidebarWidth(newWidth);
          setWidth(newWidth)
        }
      };
  
      const mouseUpHandler = () => {
        document.removeEventListener('mouseup', mouseUpHandler);
        document.removeEventListener('mousemove', mouseMoveHandler);
      };
  
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);

    }
  };
  return (
    <div>
          
      <div onMouseDown={(e) => resizable ? rsMouseDownHandler(e) : {}}
      style={{ cursor: resizable ? 'ew-resize' : '' }} className=''>

        <div className="cursor-default" ref={sidebarRef} style={{ width: resizable ? `${sidebarWidth}px` : '100%' }}>
            {sidebar}
        </div>
      </div>

      {footer && footer}
    </div>
  )
}
export default function WithSidebar({sidebar, children,hideSidebar, setWidth, footer, hidePanel, setHidePanel} : Props) {
  const [sidebarWidth, setSidebarWidth] = useState(300);
  const sidebarRef = useRef<any>(null);
  const { winSize } = useAppContext()

  useEffect(() => {
        
        if(winSize.w <1100 && winSize.w>0){
          setSidebarWidth(250)
          setWidth(250)
        }
    }, [winSize.w]);
 
  return (
    <div className='flex h-full overflow-hidden'>
      {sidebar && (
        winSize.w > UI_WIDTH_SIDEBAR_SM ? (
          <Component sidebar={sidebar} footer={footer} sidebarWidth={sidebarWidth} sidebarRef={sidebarRef} setSidebarWidth={setSidebarWidth} setWidth={setWidth} resizable={true} />
        ): (
          <DrawerSmLayout noHeader visible={hidePanel} onClose={() => setHidePanel(false)} placement='left' title=''>
            <Component sidebar={sidebar} footer={footer} sidebarWidth={sidebarWidth} sidebarRef={sidebarRef} setSidebarWidth={setSidebarWidth} setWidth={setWidth} resizable={false} />

          </DrawerSmLayout>
        )

      )}
        <div className={`flex-1 flex flex-col relative ${hideSidebar && 'bg-sub-windows relative -ml-[260px] shadow-panel'}`}>
            {children}
        </div>
    </div>
  )
}
