import React from 'react'

export default function MenuLayout({children, width}:{children:JSX.Element, width?:string}) {
  return (
    <div className={`relative ${width ? width : 'min-w-[270px]'}  teddra-shadow bg-menu teddra-rounded p-2 menu-filter`}>
        {
            children
        }
    </div>
  )
}
