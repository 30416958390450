import React, { useEffect, useState } from 'react'
import { routeType } from '../../bars/TopBar/types'
import { getVolumChilds } from '../../../../services/api/request';
import OneLine from '../../object/oneLine';
import ReactSvg from '../../ReactSvg';
import { useNavigate } from 'react-router-dom';
import { getExtraPath } from '../../../../utils/utils';
import { Spin } from 'antd';
import i18n from '../../../../i18n';
import { fetchInt } from '../../../../utils/api/fetchInt';

type Props = {
    route:routeType,
    clusterId?:string,
    onClose:() => void
}
export default function VolumesOverlay({route, clusterId, onClose}:Props) {
    const [volumes, setvolumes] = useState<any>([]);
    const navigate = useNavigate()
    const [loading, setloading] = useState(false);
    useEffect(() => {
        setloading(true)
        fetchInt(getVolumChilds({volumId:route.id!, volumColl:route.coll!, clusterId})).then(res => res.json()).then(data => {
            setvolumes(data?.data)
        }).finally(() => setloading(false))
    }, []);
  return (
    <div className=' bg-white teddra-rounded  teddra-shadow min-h-[100px] max-h-[300px] overflow-auto  w-[220px] rounded p-2'>
        <Spin spinning={loading}>
        {volumes && volumes.length> 0 ? volumes.map((volum:any) => (
            <OneLine
                key={volum.id}
                dropdownProps={null}
                onClick={() => {
                    const extraPath = getExtraPath(volum.coll)
                    navigate('/' +i18n.language + volum.path+extraPath)
                    onClose()
                }}
            >
                <button className='flex space-x-2 min-w-0'>
                    <ReactSvg src={`${volum.iconUrl}`} className='icon-sm'/>
                    <p className='truncate flex-1 min-w-0'>{volum.title}</p>

                </button>
            </OneLine>
        )) : (
            <p className='px-4 py-2 text-skin-muted'>No volume found</p>
        )}

        </Spin>
    </div>
  )
}
