import { CameraIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Blocks from '../../components/block/Blocks'
import BlockwithoutImgLayout from '../../components/block/withoutImg'
import TextBlock2 from '../../components/block/TextBlock2'
import Block from '../../components/block/Block'
import useSearchDomains from '../../hooks/useSearchDomains'
import { ACTIVATED_DOMAINS, LOCAL_ECONOMY, MAIN_DOMAINS, REGIONAL_ECONOMY } from '../../../../constants'
import { btnClassName, CardComponent } from '../../Layout'
import i18n from '../../../../i18n'
import Icon from '../../../../components/icons/Icon'
import { useParams } from 'react-router-dom'
import { VolumeSearch } from '../../../../types'

export default function Solution() {
    const {t} = useTranslation(['common', 'solution'])
    const { domains } = useSearchDomains({filterBy: `id:=[${ACTIVATED_DOMAINS}]`, query: '*'})
    const [local, setlocal] = useState<null | {document:VolumeSearch}>(null);
    const [regional, setregional] = useState<null | {document:VolumeSearch}>(null);

    const params = useParams()

    useEffect(() => {
      
      if(domains){
        setlocal(domains.filter(d => d.document.id === LOCAL_ECONOMY)?.[0])
        setregional(domains.filter(d => d.document.id === REGIONAL_ECONOMY)?.[0])
      }
    }, [domains]);
  return (
    <div className="w-full h-full relative ">
       
    <div className="space-between-main-blocks3 pb-end-page">
    
        
        
        <div className="space-y-[270px]">
            <div className="relative flex flex-col justify-center  max-w-page mx-auto min-h-[calc(100vh-176px)]">
                <div className='flex space-x-[55px] justify-centyer items-center  '>
                <div className=" w-full text-center space-y-10">
                        <div className="h-[400px] flex justify-center items-center w-full">
                            <CameraIcon className='w-[100px] h-[100px] opacity-10 ' />
                        </div>
                        <div>
                            <p className='title-main w-full'>
                                {t('solution:title')}
                            </p>
                            <p className="text-main max-w-[950px] mx-auto">
                            {t('solution:description.p1')} 


                            </p>

                        </div>
                   
                        

                    </div>
                 
                 

                </div>
            </div>
           

            
          
          

            <div className='space-y-10'>

                <BlockwithoutImgLayout orientation='right'>
                    <>
                    <TextBlock2
                        label=''
                        title={t('solution:section1.title')}
                        text={t('solution:section1.text')}
                   
                    />
                    </>


                </BlockwithoutImgLayout>
                <div className="max-w-page mx-auto">
                    <Blocks
                        columns={'grid-cols-4 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1'}
                        
                        content={[
                            {
                                title: t('solution:section1.block1.title'),
                                text: t('solution:section1.block1.text'),    
                            },
                            {
                                title: t('solution:section1.block2.title'),
                                text: t('solution:section1.block2.text'),    
                            },
                            {
                                title: t('solution:section1.block3.title'),
                                text: t('solution:section1.block3.text'),    
                            },
                            
                        ]}
                    />
                     {local && (
                    <div className="-ml-4 mt-7">

                        <CardComponent
                            name={(local.document.title as any)[i18n.language] ?? local.document.title.en}
                            description={local.document.description ?(local.document.description as any)[i18n.language] ?? local.document.description.en : ''}
                            icon={{type:'image', src:`${local.document.image}`}}
                            active={params.domainId === local.document.id}
                            onClick={() => window.open(`https://${local.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`, '_blank')}
                            key={local.document.id}
                            link={
                            // <a className={btnClassName(params.domainId === local.document.id)} href={`https://${local.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">{t('open')}</a>
                            <a className={btnClassName(params.domainId === local.document.id)} href={`https://${local.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">
                            <Icon name='OpenWindow' className='icon-mini'/>
                            <p>{t('open')}</p>
                        </a>
                        }
                        />

                    </div>

                  )}

                </div>
            </div>

            <div className='space-y-10'>

                <div className="space-y-16">
                    <div className="max-w-page mx-auto">
                        <div className=" gap-x-[70px] gap-y-[100px] grid grid-cols-4 max-[750px]:grid-cols-2 max-[450px]:grid-cols-1">
                            <div className='max-[950px]:hidden'></div>
                            <div className="col-span-2">
                                <TextBlock2
                                    label=''
                                    title={t('solution:section2.title')}
                                    text={t('solution:section2.text')}
                                />
    
                            </div>
                        </div>
    
                    </div>
                        
                    <div className="max-w-page mx-auto">
                        <div className="gap-x-[70px] gap-y-[100px] grid grid-cols-4 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1">
                            <div className='max-[950px]:hidden'></div>
                            <Block
                                block={{
                                    title: t('solution:section2.block1.title'),
                                    text: t('solution:section2.block1.text')
                                }}
                            />
                            <Block
                                block={{
                                    title: t('solution:section2.block2.title'),
                                    text: t('solution:section2.block2.text')
                                }}
                            />
                            <Block
                                block={{
                                    title: t('solution:section2.block3.title'),
                                    text: t('solution:section2.block3.text')
                                }}
                            />
                           
                            
                            
                        </div>
    
                    </div>
    
                  </div>
                  {regional && (
                    <div className="max-w-page mx-auto mt-7">
                      <div className="gap-x-[70px] gap-y-[100px] grid grid-cols-4 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1 -ml-4">
                        <div className='max-[950px]:hidden'></div>

                        <CardComponent
                            name={(regional.document.title as any)[i18n.language] ?? regional.document.title.en}
                            description={regional.document.description ?(regional.document.description as any)[i18n.language] ?? regional.document.description.en : ''}
                            icon={{type:'image', src:`${regional.document.image}`}}
                            active={params.domainId === regional.document.id}
                            onClick={() => window.open(`https://${regional.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`, '_blank')}
                            key={regional.document.id}
                            link={
                            // <a className={btnClassName(params.domainId === regional.document.id)} href={`https://${regional.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">{t('open')}</a>
                            <a className={btnClassName(params.domainId === regional.document.id)} href={`https://${regional.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">
                            <Icon name='OpenWindow' className='icon-mini'/>
                            <p>{t('open')}</p>
                        </a>
                        }
                        />

                      </div>
                    </div>

                  )}
            </div>

            <div className='space-y-10'>

                <BlockwithoutImgLayout orientation='right'>
                    <>
                    <TextBlock2
                        label=''
                        title={t('solution:section3.title')}
                        text={t('solution:section3.text')}
                   
                    />
                    </>


                </BlockwithoutImgLayout>
                <div className="max-w-page mx-auto">
                    <Blocks
                        columns={'grid-cols-4 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1'}
                        
                        content={[
                            {
                                title: t('solution:section3.block1.title'),
                                text: t('solution:section3.block1.text'),    
                            },
                            {
                                title: t('solution:section3.block2.title'),
                                text: t('solution:section3.block2.text'),    
                            },
                            {
                                title: t('solution:section3.block3.title'),
                                text: t('solution:section3.block3.text'),    
                            },
                            
                        ]}
                    />
                    <div className="flex flex-wrap gap-x-10 -ml-3 mt-7">
                     
                    {domains && domains.filter(d => !MAIN_DOMAINS.includes(d.document.id)).map(d => (
                          <CardComponent
                              name={(d.document.title as any)[i18n.language] ?? d.document.title.en}
                              description={d.document.description ?(d.document.description as any)[i18n.language] ?? d.document.description.en : ''}
                              icon={{type:'image', src:`${d.document.image}`}}
                              active={params.domainId === d.document.id}
                              onClick={() => window.open(`https://${d.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`, '_blank')}
                              key={d.document.id}
                              link={
                              // <a className={btnClassName(params.domainId === d.document.id)} href={`https://${d.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">{t('open')}</a>
                              <a className={btnClassName(params.domainId === d.document.id)} href={`https://${d.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">
                              <Icon name='OpenWindow' className='icon-mini'/>
                              <p>{t('open')}</p>
                          </a>
                          }
                          />

                      ))}
                    </div>

                </div>
            </div>

            
            
    
        </div>
    </div>
        

         



</div>
  )
}
