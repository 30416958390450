import React, { useEffect, useState } from 'react';
import { searchWebResources } from '../../../services/search/search';
import { Highlight, Publication } from '../../../types';
import { searchDocuments } from '../../../services/api/request/search';
import { useDebounce } from '../../../hooks/useDebounce';
import { QUERY_DEBOUNCE_DELAY } from '../../../constants';



export default function useGetWebPages({
  id,
  websiteId,
  groupBy,
  mode,
  query,
  pagination,
  setGroupBy,
  selectedPubType,
  setPaginate,
  onlyWebpages,
  limit,
  

}: {
  id: string;
  websiteId?: string;
  groupBy: boolean;
  mode?: 'roots' | 'volume';
  query:string;
  pagination:{page:number, offset:number, limit:number},
  setGroupBy: (data:{webresources:boolean}) => void,
  selectedPubType:{value:string, icon:string, type:'groupe' | 'type'},
  setPaginate: (data:{page:number, offset:number}) => void,
  onlyWebpages:boolean,
  limit?:number
}) {
  const [webresources, setWebresources] = useState<
    undefined | { document: Publication; highlight: Highlight }[]
  >(undefined);
  const [newRequest, setNewRequest] = useState(0);
  const [isLoaded, setIsLoaded] = useState(true);
    const [totalHits, settotalHits] = useState(0);
  // Debounced Query
  const debouncedQuery = useDebounce(query, QUERY_DEBOUNCE_DELAY);
  useEffect(() => {
    (async () => {
      if (id && !selectedPubType.value) {
        // let limit = 7;
        // if (groupBy && !debouncedQuery) {
        //   limit = 2;
        // }
        // if (!groupBy) {
        //   limit = pagination.limit;
        // }
        // if(onlyWebpages){
        //   limit = pagination.limit>10 ? pagination.limit - 5 : pagination.limit
        // }
   

 
        
          const req = await fetch(searchDocuments('webpages'), {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              data: searchWebResources({
                q: debouncedQuery,
                volumeId: id,
                groupBy: groupBy ? 'publication_id' : undefined,
                websiteId,
                groupLimit: 5,
                limit: limit ? limit : pagination.limit,
                offset: pagination.offset,
                page: pagination.page,
                mode,
              }),
            }),
          });

          const res: any = await req.json();

          if (res.data.grouped_hits) {
            setGroupBy({ webresources: true });
            setWebresources(res?.data?.grouped_hits);
            // setTotalHits({ webresources: onlyWebpages ? res.data.found :res.data.found_docs });
          } else {
            setGroupBy({ webresources: false });
            setWebresources(res?.data?.hits);
            // setTotalHits({ webresources: res.data.found });
          }
          settotalHits(res.data.found_docs ?? res.data.found)
      }
    })();
  }, [
    debouncedQuery,
    id,
    pagination.offset,
    pagination.page,
    newRequest,
    selectedPubType.value,
    mode,
  ]);

  useEffect(() => {
    setIsLoaded(false);
    setTimeout(() => {
      setNewRequest((prev) => prev + 1);
      setIsLoaded(true);
      console.log('is loaded')
    }, 100);
  }, [pagination.limit, groupBy, websiteId,onlyWebpages]);

  useEffect(() => {
    setPaginate({ page: 1, offset: 0 });
  }, [groupBy]);

  return {
    webresources,
    isLoaded,
    totalHits
  };
}
