import { createContext, useContext, useEffect, useMemo, useState } from "react";


type States = {
  
  query: string,
  totalHits: {webresources:number | null, pubs:number | null, partnerPubs?:number | null, locations?:number },
  selectedPubType : {value: string, icon:string, type:'type' | 'groupe', title:string },
  groupBy: {
    paidPubs: boolean,
    webresources:boolean
  },
  pagination: {page: number, limit:number, offset:number},
  onlyWebpages: boolean,
 
}
type Functions = {
  
  setQuery: (query:string) => void,
  setSelectedPubType: (data:{ value: string, icon: string, type:'type' | 'groupe', title:string }) => void,
  setTotalHits: (value:{webresources?:number | null, pubs?:number | null, partnerPubs?:number | null, locations?:number}) => void,
  setGroupBy: (data: {webresources?: boolean, paidPubs?:boolean})=> void,
  setPaginate: (data:{page?: number, limit?:number, offset?:number}) => void,
  setOnlyWebpages: (value:boolean) => void,
  clearSearch: () => void
}
type Props = States & Functions
const SearchContext = createContext<Props>({} as Props);

export const useSearchContext = () => useContext(SearchContext);

export const SearchContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
    query: '',
    totalHits: {webresources:null, pubs:null, partnerPubs:0, locations:0},
   
    selectedPubType : {value: '', icon: '', type:'type', title:''},
    groupBy:{
      webresources: true, 
      paidPubs: false
    },
   
    pagination: {page:1, offset:0, limit:15},
    
    onlyWebpages: false
  })
  

  const value = useMemo(() => {
    

    const setQuery = (query:string) =>{
      setState((prev) => ({
        ...prev,
        query,
        pagination: {page:1, offset:0, limit:prev.pagination.limit}
      }))
    }


    const setPaginate = (data:{page?: number, limit?:number, offset?:number}) =>{
      setState((prev) => ({
        ...prev,
        pagination: {...prev.pagination,...data},
      }))
    }

  
    const clearSearch = () => {
      setState((prev) => ({
        ...prev,
        query: '',
        // expandedPanel:['partners', 'publications', 'childrens', 'owner','sites'],
        selectedPubType: {value:'', icon:'', type: 'type', title:''},
        pagination: {page:1, offset:0, limit:prev.pagination.limit},
        onlyWebpages: false,
      }))
    }

    const setSelectedPubType = (data:{ value: string, icon: string  , type:'type' | 'groupe', title: string}) => {
      setState((prev) => ({
        ...prev,
       selectedPubType: data,
      //  groupBy:{
      //   webresources: prev.groupBy.webresources,
      //   paidPubs:false
      //  },
       pagination: {page:1, offset:0, limit:prev.pagination.limit}
      }))
    }

    const setTotalHits = (value:{webresources?:number | null, pubs?:number | null, partnerPubs?:number | null, locations?:number}) => {
      setState((prev) => ({
        ...prev,
       totalHits: {...prev.totalHits,...value}
      }))
    }

    


    const setGroupBy = (data: {webresources?: boolean, paidPubs?:boolean})=> {
      setState((prev) => ({
        ...prev,
        groupBy: {...prev.groupBy ,...data},
        // selectedPubType:{value: '', icon: '', type:'type'},
        // pagination: {page:1, offset:0, limit:prev.pagination.limit}
      }))
    }

    const setOnlyWebpages = (value:boolean) => {
      setState((prev) => ({
        ...prev,
        onlyWebpages: value,
        selectedPubType: !value ? prev.selectedPubType : {value:'', icon:'', type: 'type', title:''},
      }))
    }

  

    


    return {
      ...state,
      setQuery,
    
      clearSearch,
      setSelectedPubType,
      setTotalHits,
      setGroupBy,
     
      setOnlyWebpages,
      setPaginate,
     
    }
  }, [state])

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};