import React, { useEffect, useState } from 'react'
import { useSearchContext } from '../context/searchContext';
import { displayModes, useUIContext } from '../context/UIContext';
import { useQfsContext } from '../context/qfsContext';

import { Dropdown } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';

import { storageUrl } from '../../../services/api/constants';
import { ButtonLg } from '../../../components/shared/Buttons/ButtonLg';
import TypesDropdown from '../../../components/shared/bars/TopBar/actionsBar/actions/resources/TypesDropdown';
import useGetPubsTypes from '../hooks/useGetPubsTypes';
import {  SearchIcon } from '@heroicons/react/solid';
import DisplayDropdown from '../../../components/shared/bars/TopBar/actionsBar/actions/resources/DisplayDropdown';
import SearchDropdown from '../components/searchDropdown';
import useNavigateTo from '../hooks/useNavigateTo';
import { useAppContext } from '../../../context/AppContext';
import { UI_WIDTH_HIDE_SOME_ACTIONS, UI_WIDTH_IS_MOBILE } from '../../../constants';
import Icon from '../../../components/icons/Icon';
import { PublicationGroup, Server } from '../../../types';
import { useTranslation } from 'react-i18next';

type Props = {
    selectedPubType : {value:string, icon:string, type:'groupe' | 'type'},
    setSelectedPubType: (data:{value:string, icon:string, type: 'type' | 'groupe', title:string}) => void,
    groupBy: {paidPubs: boolean, webresources:boolean},
    query: string,
    setQuery: (data:string) => void,
    totalHits: { webresources: number | null, pubs: number | null },
    setOnlyWebpages: (data:boolean) => void,
    onlyWebpages: boolean,
    setDisplayMode: (data: displayModes) => void,
    disabled:{filter:boolean, display:boolean, new:boolean,query:boolean},
    displayMode: "OneLine" | "TwoLines" | "Thumb" | "Blocks",
    barActivatedFields: { author:boolean, date: boolean},
    setBarActivatedFields: (data:{ author?:boolean, date?: boolean}) => void,
    currentLocation?: Server | null,
    publicationsTypes: {
        types:PublicationGroup[],
        loading:boolean
    },
    onClear : () => void,
    
}

export default function SearchBar({ selectedPubType, setSelectedPubType, groupBy, query, setQuery, totalHits, setOnlyWebpages, setDisplayMode, disabled, displayMode, barActivatedFields, setBarActivatedFields, currentLocation, publicationsTypes, onClear }: Props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams()
    const { winSize } = useAppContext()
    const resourcesLength = (totalHits.pubs??0) + (totalHits.webresources??0)
    const [validQuery, setvalidQuery] = useState('');
    const [openSearchDropdown, setopenSearchDropdown] = useState(false);
    const {goTo} = useNavigateTo()
    const { hidePanel, setHidePanel} = useUIContext()
    const {t} = useTranslation()
    
    useEffect(() => {
    
        if(validQuery.length > 2){
          setQuery(validQuery)
        }else{
          setQuery('')
        }
      }, [validQuery]);
      useEffect(() => {
        
        setvalidQuery('')
      }, []);

    const onSelectQuery = (query:string) => {
      setQuery(query)
      setvalidQuery(query)
      setopenSearchDropdown(false)
    }
    const handleSelection = (node:any) => {
        const path = currentLocation?.routes?.[currentLocation.routes.length-1]?.path
        if(path){
            goTo(path,{})
        }
    }

    const handleSelectTypes = (node:any) => {
        console.log(node)
        if(node.id === 'webresources'){
            console.log('jjjj')
            setOnlyWebpages(true)
        }else{
            setSelectedPubType({ value: node.id, icon: node.iconUrl, type:'type' , title:node.name_plural.en})

        }
}
    return (
    <div className='flex justify-between items-center w-full px-5 bar-h border-b border-main border-r'>
        <div className={` flex-1 items-center flex space-x-3`}>
            
            <button className={`p-1.5 bg-hover-transparent rounded no-dragable disabled:opacity-20 transition-all ${hidePanel ? ' rotate-180' :''}`} onClick={() => setHidePanel(!hidePanel)} disabled={false}>
                <Icon name='PanelOpen' className='icon-sm'/>
            </button>
            <div className="flex items-center rounded space-x-2">
                <button className='p-1 rounded bg-hover-transparent' onClick={() => {setvalidQuery('');onClear()}}>
                    <Icon className='icon-sm' name='HomeIcon' />
                </button>
                {winSize.w > UI_WIDTH_IS_MOBILE && (
                    <div className='h-[15px] w-[1px] bg-gray-300'></div>

                )}
                <div className=' px-1 flex items-center ' id='tutorial-search'>
                    <SearchIcon className='icon-mini'/>
                    <Dropdown
                        overlay={<SearchDropdown onSelectQuery={onSelectQuery} query={query}/>} trigger={['click']}
                        visible={openSearchDropdown}
                    >
                        <input 
                        type="text" 
                        value={validQuery} 
                        onChange={e => setvalidQuery(e.target.value)} 
                        className='bg-input border-none roundedw-full placeholder:font-bold w-[220px] max-[1150px]:w-[170px]' 
                        placeholder={resourcesLength === 0 ? t('search') : t("search-among", { count: resourcesLength })} 
                        onFocus={() => setopenSearchDropdown(true)} 
                        onBlur={(e) => {
                            const relatedTarget = e.relatedTarget;
                            if (!relatedTarget || !relatedTarget.closest('.ant-dropdown')) {
                                setopenSearchDropdown(false);
                            }
                        }}
                        />

                    </Dropdown>
                </div>
                <div className='h-[15px] w-[1px] bg-gray-300'></div>
                <Dropdown
                    // overlay={(disabled.filter) ? <></> : <TypesDropdown selectedType={selectedPubType} selectAction={handleSelectTypes} typesList={publicationsTypes.types ?? []}/>} trigger={['click']} disabled={!!(publicationsTypes.loading || params.group)} 
                    overlay={<></>}
                    >
                        {/* <Spin spinning={loading}> */}
                        <button>
                            <ButtonLg
                            
                                suffix={{
                                    icon:{type:'icon', src: (selectedPubType?.value) ? 'XCircleIcon' : 'ChevronDownIcon'},
                                    size: 'mini',
                                    onClick:() => {
                                        selectedPubType.value ? setSelectedPubType({value:'', icon: '', type:'type', title: ''}) : setOnlyWebpages(false)
                                    }
                                }}
                                prefix={{
                                    icon:selectedPubType?.value ? {type:'image', src: selectedPubType?.icon} : {type:'icon', src: 'FilterIcon'},
                                    size: 'sm'
                                }}
                                buttonProps={{
                                    // disabled:!!(publicationsTypes.loading || disabled.filter || params.group)
                                    disabled:true
                                }}
                                // width='w-[150px] max-[1250px]:w-[105px]'
                                 width='w-[80px]'
                            >
                                <>
                            {/* {selectedPubType?.value? selectedPubType.title : onlyWebpages ? 'Web resources' : 'Filter'} */}
                            </>
                            </ButtonLg>

                        </button>
                    

                    </Dropdown>
                    {winSize.w > UI_WIDTH_HIDE_SOME_ACTIONS && (
                        <>
                            <div className='h-[15px] w-[1px] bg-gray-300 '></div>
                            <Dropdown
                                overlay={disabled.display ? <></> : <DisplayDropdown setGroupBy={
                                    (value:boolean) => {
                                        searchParams.set('grouped', value ? 'true' : '');  
                                        setSearchParams(searchParams)
                                    }
                                } groupBy={groupBy} displayMode={displayMode} setDisplayMode={setDisplayMode} barActivatedFields={barActivatedFields} setBarActivatedFields={setBarActivatedFields} /> }
                                trigger={['click']}
                                disabled={(disabled.display)}
                                >
                                        <button>
                                            <ButtonLg
                                                suffix={{
                                                    icon:{type:'icon', src: 'ChevronDownIcon' },
                                                    size: 'mini'
                                                }}
                                                buttonProps={{
                                                    disabled: (disabled.display)
                                                }}
                                                prefix={{
                                                    icon:{type:'icon', src: groupBy ? 'GroupBy' : 'Degroup' },
                                                    size: 'sm'
                                                }}
                                                // width='w-[150px] max-[1250px]:w-[105px]'
                                                width='w-[80px]'
                                            >
                                                <>
                                                {/* Diplay */}
                                                </>
                                                </ButtonLg>
            
                                        </button>
                                                                                    
                                    
                                </Dropdown>
                        
                        </>
                    )}

            </div>
                
                        
    
        </div>
     
    </div>       

   
  )
}
