import { CameraIcon } from '@heroicons/react/solid'
import React from 'react'
import Icon from '../../../../components/icons/Icon'

type Props = {
    block:{
        title?:string,
        text:string | JSX.Element, 
        center?:boolean,
        img?: {type:'icon' | 'image', src:string, dimensions?:string, center?:boolean, hidden?:boolean},
        size?:string,
    }
}
export default function Block({block}:Props) {
  return (
    <div>
        <div className="space-y-7">
            {!block.img?.hidden && (
            <div className={` flex items-center ${block.center ? 'justify-center mx-auto' : ''}`}>
                
                {block.img?.type ? (
                    block.img.type === 'icon' ? (
                        <Icon name={block.img.src} className={`  ${block.img?.dimensions ? block.img.dimensions : 'w-[100px] h-[100px] '}`} />
                    ):(
                        <img src={block.img.src} className={`  ${block.img?.dimensions ? block.img.dimensions : 'w-[100px] h-[100px] '}`} />
                    )
                ):(
                    <CameraIcon className={` opacity-10  ${block.img?.dimensions ? block.img.dimensions : 'w-[100px] h-[100px] '}`} />
                )}
            </div>

            )}
            <div>
                {block.title && (
                    <p className={`text-level2 !font-bold ${block.center ? 'text-center' : ''}`}>
                        {block.title}
                    </p>
                )}
                <p className={`${block.size ?? 'text-level2'} ${block.center ? 'text-center' : ''}`}>
                    {block.text}

                </p>

            </div>

        </div>
    
    </div>
  )
}
