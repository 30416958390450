export const PUBLICATIONS_FEATURE_CODE = '001'
export const DRIVES_FEATURE_CODE = '004'
export const DIRECTORIES_FEATURE_CODE = '003'
export const FOLDERS_FEATURE_CODE = '002'
export const FILES_FEATURE_CODE = '005'
export const QUERY_DEBOUNCE_DELAY = 700

export const UI_WIDTH_PUBLICATIONS_SM = 1250
export const UI_WIDTH_SIDEBAR_SM = 1000
export const UI_WIDTH_HIDE_SOME_ACTIONS = 750
export const UI_WIDTH_IS_MOBILE = 520
export const UI_WIDTH_HID_MAP = 950
export const UI_MD = 990
export const LOCAL_ECONOMY = 'c0aa840b-e1cf-45da-b980-2272ef6be830'
export const REGIONAL_ECONOMY = '6fa486a9-1c3f-4e17-b81d-5bb09a41dab1'
export const MAIN_DOMAINS = [LOCAL_ECONOMY, REGIONAL_ECONOMY]
export const ACTIVATED_DOMAINS = [
    ...MAIN_DOMAINS,
    'fb17c269-1ed1-4cb0-92e6-04d9b712560b', //ict
    'dab35d2a-2b46-4446-b4e0-71a8f3c5ce70', //industry
    '9aa5380d-4ba8-4022-8752-d11317768d8a',//logistics
    'e28d9368-1c6e-443f-9dc0-4bc99191e328', //universities
    '888debcf-672b-4f40-bd59-22d2f28cbbed', //food
    '90121130-a854-4040-a5b5-c6878a97ec58', // entrepreneurship
    'd0dd0899-3f21-4071-b0f0-7e1399c0134e' //energy

]
