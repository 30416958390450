import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { storageUrl } from '../../../../services/api/constants';
import { getVolum } from '../../../../services/api/request';
import ObjectMode from '../../../../components/shared/details/modes/Object';
import DetailsPageLayout from '../../components/DetailsPageLayout';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/icons/Icon';
import { fetchInt } from '../../../../utils/api/fetchInt';

export default function DomainDetails() {
    const [description, setdescription] = useState({name:'', descrition:'', image:'', icon:'', iconType:''});
    const [loading, setloading] = useState(false);
    const params = useParams()

    const {t} = useTranslation()
    useEffect(() => {
        
        if(params.domainId){
        fetchInt(getVolum({volumId:params.domainId, volumColl:'Domains' })).then(res => res.json()).then(data => {
            if(data.data){
                setdescription({
                    name: `${data.data.full_title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`,
                    descrition: data.data.description ? data.data.description : 'No description yet',
                    image: data.data.image,
                    icon: `${storageUrl}${data.data.icon?.src}`,
                    iconType: ''
                })
            }
        })
        }else{
        setdescription({name:'', descrition:'', image:'', icon:'', iconType:''})
        }
    }, [params.domainId]);
  return (
    <DetailsPageLayout>
    <div className='h-[calc(100vh-180px)] overflow-y-auto'>
            <div className='p-3 rounded border border-main m-2'>
                <img src={description.image} className='w-full h-[300px] object-cover rounded' alt="" />
            </div>
            <ObjectMode
                // thumb={description.image ? {type:'slide', images:[{src: description.image, type:'image'}]} : {type:'icon', icon:{src: description.icon, type:'image'}}}
                fields={[
                    {
                        name: 'Name',
                        value:description.name, center:true
                    },
                    {
                        name: 'Description',
                        value:<p className='ellipsis-5'>{description.descrition}</p>
                    },
                    {
                        name: '',
                        value: <button className={`p-1 rounded bg-hover-transparent !text-inherit h-max  -ml-1 w-max flex items-center space-x-2  `}  onClick={() => window.open(`https://${description.name}`)} >
                        <Icon name='OpenWindow' className='icon-mini'/>
                        <p>{t('open')}</p>
                    </button>
                    }
                    
                ]}
            
            />
        </div>

    </DetailsPageLayout>
  )
}
