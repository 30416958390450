import { useCallback } from 'react';
import { PartnerType, OwnerType, VolumeSearch, Publication, iconType, Highlight } from '../../../types';
import useNavigateTo from './useNavigateTo';

export const useSelectionHandlers = (suffix: string) => {
    const { goTo } = useNavigateTo();

    const handleSelectPublication = useCallback(
        (item: {document:Publication, highlight?:Highlight}, type?: 'webresource' | 'website') => {
            if (type === 'webresource') {
                goTo(`/${suffix}webpage/${item.document.id}`, { state: { url: item.document.url, item:item.document, highlight: item.highlight } });
            } else if(type === 'website') {
                goTo(`/${suffix}website/${item.document.id}`, { state: { url: item.document.url, item:item.document, highlight: item.highlight } });
            }else{
                goTo(`/${suffix}publications/${item.document.id}`, { state: { url: item.document.url, item:item.document, highlight: item.highlight } });
            }
        },
        [suffix]
    );

    const handleSelectGroupedWebresources = useCallback(
        (id: string) => {
            goTo(`/${suffix}webresources/${id}`, {});
        },
        [suffix]
    );

    const handleSelectGroupedPublication = useCallback(
        (id: string) => {
            goTo(`/${suffix}group/${id}`, {}, true);
        },
        [suffix]
    );

    const handleSelectLocation = useCallback(
        (volume: VolumeSearch) => {
            goTo(`/${suffix}volumes/${volume.id}`, { state: volume });
        },
        [suffix]
    );

    const handleSelectPartner = useCallback(
        (item: PartnerType, type:'sponsor'|'partner') => {
            goTo(`/${suffix}${type === 'partner' ? 'partners' : 'sponsors'}/${item.id}`, { state: { url: item.webResources.website, item } });
        },
        [suffix]
    );
    const handleSelectOwner = useCallback(
        (item: OwnerType) => {
            goTo(`/${suffix}owner/${item.id}`, { state: { url: item.website, item } });
        },
        [suffix]
    );

  

    const handleOpenCollection = useCallback(
        ({fct, title, icon, domainId, suffix , moduleBreadcrumb}: {fct:any, title:string, icon:iconType, domainId:string, suffix:string, moduleBreadcrumb:any}) => {
            
            fct({title, icon, key: `/${domainId}/qord/${suffix}`, moduleBreadcrumb})

                
   

        },
        [suffix]
    
    )
    const handleSelectSeeAllPartnerPublications = useCallback(
        (partnerId: string) => {
            goTo(`/${suffix}partner/${partnerId}`, {});
        },
        [suffix]
    );
    return {
        handleSelectPublication,
        handleSelectGroupedWebresources,
        handleSelectGroupedPublication,
        handleSelectLocation,
        handleSelectPartner,
        handleSelectOwner,
        handleOpenCollection,
        handleSelectSeeAllPartnerPublications
    };
};
