import React from 'react'
import Icon from '../../../icons/Icon'

type Props = {
    link?:string,
    icon:string
}
export default function IconAction({link, icon}:Props) {
  return (
    <>
        {link ? (
            <a className='p-1 rounded bg-hover-transparent !text-inherit' href={link} target='_blank' rel="noopener noreferrer">
                <Icon name={icon} className='icon-mini'/>
            </a>

        ) : (
            <button className='p-1 rounded bg-hover-transparent !text-inherit disabled:opacity-30' disabled>
            <Icon name={icon} className='icon-mini'/>
            </button>
        )}
    </>
  )
}
