import React, { useCallback, useEffect, useState } from 'react'
import WithSidebar from '../../../components/shared/Layouts/WithSidebar'
import { useUIContext } from '../context/UIContext'
import Sidebar from '../components/sidebar'
import { Server, VolumeSearch } from '../../../types'
import useNavigateTo from '../hooks/useNavigateTo'
import { useQfsContext } from '../context/qfsContext'
import { useAppContext } from '../../../context/AppContext'
import StatsBar from '../components/sidebar/Stats'
import { generateSearchVolumKey, getExtraPath } from '../../../utils/utils'
import Icon from '../../../components/icons/Icon'
import { UI_WIDTH_SIDEBAR_SM } from '../../../constants'

type Props = {
    domain: Server|null,
    serverId: string | undefined,
    handleLocationSelection: (node:any)=> void,
    handleSearchSelection:(doc:VolumeSearch) => void,
    selectedKey: string

}

export default function SidebarContainer({children}:{children:JSX.Element}) {
    const { hidePanel, setHidePanel } = useUIContext()
    const { currentLocation } = useQfsContext()
    const { domain, server, winSize } = useAppContext()
    const [sideBarWidth, setsideBarWidth] = useState(300);
    const [resultsLength, setresultsLength] = useState(0);
    const {goTo} = useNavigateTo()
  

    const handleLocationSelection = useCallback(
        (node: Server) => {
            let extraPath = getExtraPath(node.coll??'')
            goTo(node.path+extraPath, {});
            if(winSize.w < UI_WIDTH_SIDEBAR_SM){
                setHidePanel(false)
            }
        },
        [goTo]
    );

    const handleSearchSelection = useCallback(
        (doc:VolumeSearch) => {
            let extraPath = getExtraPath(doc.cfs_type??'')
            goTo(generateSearchVolumKey(doc)+extraPath, {});
            if(winSize.w < UI_WIDTH_SIDEBAR_SM){
                setHidePanel(false)
            }
        },
        [goTo]
    );
  return (
    <>
    { (domain) && (
            <WithSidebar
                footer={<StatsBar volumes={resultsLength} />}
                setWidth={setsideBarWidth}
                sidebar={
                    <div className="relative " >
                        
                        <Sidebar
                        server={server}
                        selectedKey={currentLocation ? (currentLocation.isDomain ? '/' : currentLocation.id) : '/'}
                        handleLocationSelection={handleLocationSelection}
                        handleSearchSelection={handleSearchSelection}
                        openAction={({ key, title, icon }) => {
                    
                        }}
                        resultsLength={resultsLength}
                        setresultsLength={setresultsLength}
                        datacenter={domain}
                        serverId={currentLocation?.clusterId} 
                        isSm={winSize.w < UI_WIDTH_SIDEBAR_SM}
                        setHidePanel={setHidePanel}
                    />
                    
                        
                    </div>

                }
                hideSidebar={false}
                hidePanel={hidePanel}
                setHidePanel={setHidePanel}
            >
                <div className='flex h-full'>
                    {/* <div className='flex flex-col'>
                        <div className="-ml-[20px] flex items-center flex-1 relativd z-[1000]">
                            <ModulesBar/>
                        </div>
                        <div className="bg-black bar-h-mini"></div>
                    </div> */}

                <div className={`flex h-full flex-1 flex-col transition-all bg-sub-windows z-100  ${hidePanel && `shadow-sidebar-opened  `}`} style={{marginLeft: (hidePanel && winSize.w > UI_WIDTH_SIDEBAR_SM) ? -sideBarWidth+40 : 0}}>
                        {children}

                </div>
                </div>
            </WithSidebar>


    )}
    
    </>
  )
}
