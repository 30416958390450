import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 7H7"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 18.6894V5.5C3 4.11929 4.11929 3 5.5 3H6C6.55228 3 7 3.44772 7 4V15C7 15.5523 6.55228 16 6 16H5.5C4.11929 16 3 17.1193 3 18.5C3 19.8807 4.11929 21 5.5 21H18C19.1046 21 20 20.1046 20 19V14"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.499 6.25001C17.3611 6.25056 17.2496 6.36273 17.25 6.50067C17.2504 6.63861 17.3624 6.75019 17.5003 6.75C17.6383 6.74982 17.75 6.63795 17.75 6.50001C17.7504 6.43342 17.724 6.36947 17.6768 6.32248C17.6296 6.27549 17.5656 6.24939 17.499 6.25001"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 6.5V6.5C14 4.567 15.567 3 17.5 3V3C19.433 3 21 4.567 21 6.5V6.5C21 8.13851 19.212 9.88881 18.192 10.7544C17.7892 11.0819 17.2118 11.0819 16.809 10.7544C15.788 9.88881 14 8.13851 14 6.5Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
