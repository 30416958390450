import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import i18n from '../../../i18n'


export default function useNavigateTo() {
    const navigate  = useNavigate()
    const [searchParams] = useSearchParams()

    const goTo = (to:string,props:any, noQuery?:boolean) =>{
      const query = searchParams.toString();
   
     
          navigate(`/${i18n.language}${to}${!noQuery ? `?${query}` : ''}`, props )

        
      
      }
  return {
    goTo
  }
}
