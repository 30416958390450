import { Spin, Tree } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { iconType, Server } from '../../../../../types'

import TreeLayout from './Layout'
import useSearchLocations from '../../../hooks/useSearchLocations'



type Props = {
    handleLocationSelection: (node:Server) => void, 
    selectedKey:string,
    openAction : ({title,id, icon,key}:{title:string, icon:iconType,id: string, key:string}) => void,
    datacenter: Server | null, 
    tree: any,
    setResultsLength:any,
    updateTree:any,
    defaultExpandedKeys?:any[],
    loadingAllServerTree?:string|null
}
 


export default function TreeComponent({handleLocationSelection, loadingAllServerTree, selectedKey, openAction, datacenter, tree, setResultsLength, updateTree,defaultExpandedKeys}: Props) {

    
    const [loading, setloading] = useState(false)
    const {resultsLength} = useSearchLocations({
      query: '*', 
      filter: `&& roots:=${datacenter?.id}`,
      limit:1,
      status:'[enabled, coming_soon]'

    })

    useEffect(() => {
      
      setResultsLength(resultsLength)
    }, [resultsLength]);


    

  
   
    

 
    

  return (
         
          <Spin spinning={loading}>
            {datacenter && (
                <TreeLayout
                defaultExpandedKeys={defaultExpandedKeys}
                  tree={tree}
                  openAction={openAction}
                  handleLocationSelection={handleLocationSelection}
                  selectedKey={selectedKey}
                  updateTree={updateTree}
                  loadingAllServerTree={loadingAllServerTree}
                />

            )}
          </Spin>
        

      
  )
}
