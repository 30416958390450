import { Drawer } from 'antd'
import React from 'react'
import { useAppContext } from '../../../context/AppContext'
import Icon from '../../icons/Icon'
import { Close } from '../../icons'

type Props = {
    visible:boolean,
    onClose:() => void,
    children:JSX.Element,
    placement:'left'|'right',
    title:string,
    noHeader?:boolean
}
export default function DrawerSmLayout({visible, onClose, children, placement, title, noHeader}:Props) {
    const {winSize} = useAppContext()
  return (
    <Drawer
        visible={visible}
        placement={placement}
        onClose={onClose}
        width={winSize.w > 400 ? 400 :'100%'}
        rootClassName='bg-sub-windows'
        headerStyle={{display:'none'}}
        >
        <div className='bg-sub-windows h-full'>
          {!noHeader && (
            <div className="bar-h border-b border-main px-5 flex justify-between items-center">
                <div className="flex-1 flex items-center space-x-2">
                    <p className="">{title}</p>
                </div>
                <button className='p-1 rounded hover:bg-red-600 hover:text-white' onClick={onClose}>
                    <Close className='icon-mini'/>
                </button>
            </div>

          )}
            {children}
        </div>
    
    </Drawer>
  )
}
