import { Route, Routes } from 'react-router-dom'

import FoldersLayout from './Layout'
import PublicationDetailsPage from './pages/publications/details'
import PartnerDetailsUrl from './pages/partnerDetails'
import VolumeDetailsPage from './pages/volumeDetails'



import DatacenterLocationLayout from './pages/1Tabs/1Home/datacenter'
import Website from './pages/website'
import { QfsContextProvider } from './context/qfsContext'
import { SearchContextProvider } from './context/searchContext'
import { UIContextProvider } from './context/UIContext'
import Websearch from './pages/windows/websearch'
import PublicVolumeLayout from './pages/1Tabs/1Home/public/PublicVolume'
import CommunityLayout from './pages/1Tabs/1Home/private/community/layout'
import CompanyLayout from './pages/1Tabs/1Home/private/CompanyLayout'
import Directory from './pages/windows/directory'
import OwnerDetailsUrl from './pages/ownerDetails'
import ServerLayout from './pages/1Tabs/ServerLayout'
import ServerACtivation from './pages/1Tabs/1Home/deactivated'
import Module from './pages/1Tabs/1Home/deactivated/Module'
import Qorddu from '../home/pages/details/Qorrdu'
import About from '../home/pages/About'
import Newspapers from '../home/pages/details/Newspapers'
import NewspapersLayout from '../home/pages/newspapers/Layout'
import HowItWorks from '../home/pages/newspapers/HowItWorks'
import Solution from '../home/pages/newspapers/Solution'
import Revenu from '../home/pages/newspapers/Revenu'
import Pricing from '../home/pages/newspapers/Pricing'
import Demo from '../home/pages/Demo'
import Home from '../home/pages/newspapers/Benefits'
import QordduPage from '../home/pages/QordduPage'
import Domain from './pages/domain'
import OfferLayout from './pages/1Tabs/1Home/deactivated/offer/Layout'
import Offer from './pages/1Tabs/1Home/deactivated/offer'
import Reserve from './pages/1Tabs/1Home/deactivated/offer/reserve/Reserve'



export default function FilesNavigation() {

  
  return (
    <QfsContextProvider>
      <SearchContextProvider>
        <UIContextProvider>

          <Routes>
          
              {/** FOLDERS **/}
              <Route element={
                
                  <FoldersLayout/>
              
              }>
            
              
             
                  <Route element={<DatacenterLocationLayout/>}>
                    <Route path='/Datacenters/:id' element={<></>}/>
                    <Route path='/Datacenters/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                    <Route path='/Datacenters/:id/sponsors/:sponsorId' element={<PartnerDetailsUrl/>}/>

                    <Route path='/Datacenters/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                  </Route>

               
                <Route element={<ServerLayout/>}>
                  <Route element={<ServerACtivation/>}>
                    <Route path='/:coll/:id/activation' element={<></>}/>

                    <Route element={<OfferLayout/>}>
                      {/* Qorddu Routes */}
                        <Route element={<Offer/>} path='/:coll/:id/activation/pages'/>
                        <Route element={<About server/>} >
                          <Route path="/:coll/:id/activation/pages/qorddu/vision" element={<QordduPage/>}/>
                        </Route>
                        <Route path="/:coll/:id/activation/pages/demo" element={<Demo/>}/>
                        <Route path="/:coll/:id/activation/pages/reserve" element={<Reserve/>}/>


              
                      {/* Newspapers Routes */}
                        <Route element={<NewspapersLayout server/>}>
                          {/* <Route path="/:coll/:id/activation/pages/newspapers" element={<NewspapersPage />} /> */}
                          <Route path="/:coll/:id/activation/pages/newspapers/how-it-works" element={<HowItWorks />} />
                          <Route path="/:coll/:id/activation/pages/newspapers/benefits" element={<Home />} />
                          <Route path="/:coll/:id/activation/pages/newspapers/solution" element={<Solution />} />
              
                          <Route path="/:coll/:id/activation/pages/newspapers/revenu" element={<Revenu />} />
                          <Route path="/:coll/:id/activation/pages/newspapers/pricing" element={<Pricing />} />
                          <Route path="/:coll/:id/activation/pages/newspapers/demo" element={<Demo />} />
              
                      </Route>
                      
                    </Route>
                  
                  </Route>
                  <Route path='/:coll/:id/activation/window/:module' element={<Module/>}/>
                  <Route element={<PublicVolumeLayout/>}>
                    <Route path='/:coll/:id' element={<></>}/>
                    <Route path='/:coll/:id/publications/:publicationId' element={<PublicationDetailsPage isDrawer/>}/>
                    <Route path='/:coll/:id/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:coll/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:coll/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                    <Route path='/:coll/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                    <Route path='/:coll/:id/sponsors/:sponsorId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website type='' selectedWindow=''/>}>
                    <Route path='/:coll/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/:coll/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Websearch type=''/>}>
                    <Route path='/:coll/:id/window/websearch' element={<></>}/>
                    <Route path='/:coll/:id/window/websearch/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:coll/:id/window/websearch/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Website type='' selectedWindow='websearch'/>}>
                    <Route path='/:coll/:id/window/websearch/webresources/:websiteId' element={<></>}/>
                    <Route path='/:coll/:id/window/websearch/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Directory type=''/>}>
                    <Route path='/:coll/:id/window/directory' element={<></>}/>
                    <Route path='/:coll/:id/window/directory/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                
                </Route>
                 
                  {/* <Route element={<Group suffix=':coll'/>}>
                    <Route path='/:coll/:id/group/:group' element={<></>}/>
                    <Route path='/:coll/:id/group/:group/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route> */}
                 <Route element={<ServerLayout/>}>
                 <Route path='/' element={<Domain/>}/>
                  <Route element={<ServerACtivation/>}>
                      <Route path='/:serverId/:coll/:id/activation' element={<></>}/>

                      <Route element={<OfferLayout/>}>
                      {/* Qorddu Routes */}
                        <Route element={<Offer/>} path='/:serverId/:coll/:id/activation/pages'/>
                        <Route element={<About server/>} >
                          <Route path="/:serverId/:coll/:id/activation/pages/qorddu/vision" element={<QordduPage/>}/>
                        </Route>
                        <Route path="/:serverId/:coll/:id/activation/pages/demo" element={<Demo/>}/>
                        <Route path="/:serverId/:coll/:id/activation/pages/reserve" element={<Reserve/>}/>


              
                      {/* Newspapers Routes */}
                        <Route element={<NewspapersLayout server/>}>
                          {/* <Route path="/:serverId/:coll/:id/activation/pages/newspapers" element={<NewspapersPage />} /> */}
                          <Route path="/:serverId/:coll/:id/activation/pages/newspapers/how-it-works" element={<HowItWorks />} />
                          <Route path="/:serverId/:coll/:id/activation/pages/newspapers/benefits" element={<Home />} />
                          <Route path="/:serverId/:coll/:id/activation/pages/newspapers/solution" element={<Solution />} />
              
                          <Route path="/:serverId/:coll/:id/activation/pages/newspapers/revenu" element={<Revenu />} />
                          <Route path="/:serverId/:coll/:id/activation/pages/newspapers/pricing" element={<Pricing />} />
                          <Route path="/:serverId/:coll/:id/activation/pages/newspapers/demo" element={<Demo />} />
              
                      </Route>
                      
                    </Route>
                     
              
                     
                    
                    </Route>
                  <Route path='/:serverId/:coll/:id/activation/window/:module' element={<Module/>}/>
                  <Route element={<PublicVolumeLayout/>}>
                    <Route path='/:serverId/:coll/:id' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/webpage/:webpageId' element={<PublicationDetailsPage isDrawer/>}/>
                    <Route path='/:serverId/:coll/:id/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/partners/:partnerId' element={<PartnerDetailsUrl/>}/>
                    <Route path='/:serverId/:coll/:id/sponsors/:sponsorId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website type='' selectedWindow=''/>}>
                    <Route path='/:serverId/:coll/:id/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Websearch type=''/>}>
                    <Route path='/:serverId/:coll/:id/window/websearch' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/window/websearch/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/window/websearch/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Website type='' selectedWindow='websearch'/>}>
                    <Route path='/:serverId/:coll/:id/window/websearch/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/window/websearch/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Directory type=''/>}>
                    <Route path='/:serverId/:coll/:id/window/directory' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/window/directory/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  
                  </Route>

                  <Route element={<CommunityLayout/>}>
                    <Route path='/:serverId/:coll/:id/community' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/community/publications/:publicationId' element={<PublicationDetailsPage isDrawer/>}/>
                
                  </Route>
                  <Route element={<Website type='community' selectedWindow=''/>}>
                    <Route path='/:serverId/:coll/:id/community/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/community/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Websearch type='community'/>}>
                    <Route path='/:serverId/:coll/:id/community/window/websearch' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/community/window/websearch/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/community/window/websearch/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Website type='community' selectedWindow='websearch'/>}>
                    <Route path='/:serverId/:coll/:id/community/window/websearch/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/community/window/websearch/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Directory type='community'/>}>
                    <Route path='/:serverId/:coll/:id/community/window/directory' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/community/window/directory/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                 

                  <Route element={<CompanyLayout/>}>
                    <Route path='/:serverId/:coll/:id/organization' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/organization/publications/:publicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/organization/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/organization/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/organization/volumes/:volumeId' element={<VolumeDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/organization/owner/:ownerId' element={<OwnerDetailsUrl/>}/>
                    <Route path='/:serverId/:coll/:id/organization/sponsors/:sponsorId' element={<PartnerDetailsUrl/>}/>
                  </Route>
                  <Route element={<Website type='organization' selectedWindow=''/>}>
                    <Route path='/:serverId/:coll/:id/organization/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/organization/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Websearch type='organization'/>}>
                    <Route path='/:serverId/:coll/:id/organization/window/websearch' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/organization/window/websearch/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                    <Route path='/:serverId/:coll/:id/organization/window/websearch/website/:websitePublicationId' element={<PublicationDetailsPage/>}/>
                  </Route>
                  <Route element={<Website type='organization' selectedWindow='websearch'/>}>
                    <Route path='/:serverId/:coll/:id/organization/window/websearch/webresources/:websiteId' element={<></>}/>
                    <Route path='/:serverId/:coll/:id/organization/window/websearch/webresources/:websiteId/webpage/:webpageId' element={<PublicationDetailsPage/>}/>
                  </Route>
                 
                

                  <Route path='/*' element={<></>}/>



              </Route>


        





          </Routes>
        </UIContextProvider>
      </SearchContextProvider>

    </QfsContextProvider>
  )
}
