import { Form,notification,Select, Spin, TreeSelect} from 'antd'
import { useEffect, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import RichText from './RichText'
import { useLocation, useNavigate } from 'react-router-dom'
import { storageUrl } from '../../../../../../services/api/constants'
import { updatePublication } from '../../../../../../services/api/request/publications'
import { fetchInt } from '../../../../../../utils/api/fetchInt'
import { usePublicationCreationContext } from '../../../../context/PublicationCreationContext'
import useGetPubsTypes from '../../../../../../hooks/useGetPubsTypes'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'



export const mapTreeIcon = (tree:any[], group?:any) => {
    return tree.map(item => {
      if(group){
        item.groupe=group
       }
       item.iconT = item.icon.src
        if(item.children) {
            
            mapTreeIcon(item.children, {id:item.id, title:item.title, iconUrl:item.icon.src})
        }
      if(item.title){
        item.icon = <ReactSVG src={`${storageUrl}${item.icon?.src}`} className='icon-sm' />
      
        item.label = item.title.en
        item.title = item.title.en

      }else{
        item.icon = <ReactSVG src={`${storageUrl}${item.icon?.src}`} className='icon-sm' />
        item.label = item.name_singular?.en
        item.title = item.name_singular?.en

      }
        item.value = item.id
        item.key = item.id
        
       
        return item
    })
}
export default function EditForm({url, initDescription, edit, seterrors, errors, mode}: {url:string, initDescription:string, edit:boolean, seterrors:any, errors:any, mode:'new' | 'edit' | 'publish'}) {
  const form = Form.useFormInstance()
  const { active, InitId, setType } = usePublicationCreationContext()
  const editorRef = useRef<any>()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setloading] = useState(false);
  const { publicationsTypes, loading: loadingTypes } = useGetPubsTypes()
  const parentRef = useRef<any>()


  const handleNext = (e:any) => {
    e.preventDefault()
    const requiredValues = [{name: 'title', type:'string'}, {name: 'description', type:'string'}, {name: 'url', type:'string'}, {name: 'keywords', type:'array'}]
    requiredValues.forEach(value => {
      if(!form.getFieldValue(value.name) || (value.type=== 'array' && form.getFieldValue(value.name)?.length === 0)){
        seterrors((prev:any) => ({...prev, [value.name] : `${value.name} is required`}))
      }
    })
    if(Object.keys(errors).length === 0){
      navigate(`/mydesk/publications/create/publish`, {state: location.state})

    }
  }

    return (
      
      <div className='relative space-y-2' ref={parentRef}>
         <Form.Item
                    
            name={`id`} 
            hidden
          >
                

            </Form.Item> 
            <div className="md:grid grid-cols-7 gap-4 items-center">
                  <p className="md:text-end font-bold col-span-2">
                    Language
                  </p>
                  <div className="col-span-5">
                  <Form.Item
                          className="group"
                          style={{marginBottom: 0}}
                          initialValue={'en'}
                          name={'local'}
                          required
                    >
                      <Select
                        getPopupContainer={() => parentRef.current}
                          className=" rounded language transition-none w-[100px] border border-main  "
                            defaultValue={'en'}
                            style={{
                              height:'var(--input-height)'
                            }}
                            options={[
                              {
                                value: 'en',
                                label: 'English',
                              },
                              {
                                value: 'fr',
                                label: 'French',
                              }
                            ]}
                           
                          />
                      
                    </Form.Item>
                  
                         
                             
                  </div>
            
              </div>
              <div className="md:grid grid-cols-7 gap-4 items-center">
                  <p className="md:text-end font-bold col-span-2">
                    Type
                  </p>
                  <div className="col-span-5">
                    <Form.Item
                      className="group"
                      style={{marginBottom: 0}}
                      name={'publicationTypeId'}
                      validateTrigger={'onFocus'}
                      rules={[{required:true, message: 'Please select a type'}]}
                    >
                      <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) => prevValues.publicationTypeId !== curValues.publicationTypeId}

                        >
                          {({ getFieldValue, setFieldsValue }) => (
                                  
                                  
                                  <TreeSelect
                                    value={getFieldValue('publicationTypeId')}
                                    getPopupContainer={() => parentRef.current}
                                    placeholder={'Select a type'}
                                    className='rounded border border-main'
                                    treeData={publicationsTypes ? mapTreeIcon(JSON.parse(JSON.stringify(publicationsTypes.filter((type:any) => {
                                      if(type.id !== 'popular' && type.children?.length > 0){
                                        return type
                                      }
                                    }
                                    )))) : []}
                                    treeNodeFilterProp='title'
                                    labelInValue
                                    allowClear
                                    showSearch
                                    switcherIcon={(props : any) =>
                                      props.expanded ? (
                                          <ChevronDownIcon className='w-3.5 h-3.5' />
                                      ) : (
                                          <ChevronRightIcon className='w-3.5 h-3.5' />
                                      )
                                    }
                                    treeIcon
                                    onSelect={(value,node) => {
                                      setFieldsValue({ publicationTypeId: value})
                                    }}
                                    onClear={() => setFieldsValue({ publicationTypeId: null})}
                                  />
                              )}
                          
                        </Form.Item>

                    </Form.Item>
                  
                         
                             
                  </div>
            
              </div>
          
              <div className="md:grid grid-cols-7 gap-4 items-center">
                <p className="md:text-end font-bold col-span-2">Url</p>
                <div className="col-span-5">
                  <Form.Item
                    validateStatus={errors?.url && "error"}
                    help={errors?.url && errors?.url}
                    className="group"
                    style={{marginBottom: 0}}
                    name="url"
                    required
                    rules={[{required:true, message: 'Please submit a url'}, {type:'url', message: 'Please submit a valid url'}]}
                  >
                  
                      
                        <input
                          type="text"
                          placeholder={'URL de la page web'}
                          autoComplete="off"
                          className="focus:border border border-main border-opacity-20 rounded input-h w-full placeholder:text-muted"
                          

                        />
                    
                  </Form.Item>
                </div>
                
              </div>
              <div className="md:grid grid-cols-7 gap-4 items-center">
                <p className="md:text-end font-bold col-span-2">Title </p>
                <div className="col-span-5"> 
                  <Form.Item
                      validateStatus={errors?.title && "error"}
                      help={errors?.title && errors?.title}
                      className="group"
                      style={{marginBottom: 0}}
                      name={`title`}
                      required
                      rules={[{required:true, message: 'Please submit a title'}]}
                    >
                      <input
                      type="text"
                      placeholder={'Link title'}
                      
                      autoComplete="off"
                      className=" border border-main border-opacity-20 rounded input-h w-full"
                      />

                    </Form.Item>
                            
                </div>
              </div>
              <div className="md:grid grid-cols-7 gap-4">
                <p className="md:text-end font-bold col-span-2  pt-[10px]">
                  Description
                </p>
                <div className="col-span-5">
                  <Form.Item
                      validateStatus={errors?.description && "error"}
                      help={errors?.description  && errors?.description }
                      className="group"
                      style={{marginBottom: 0}}
                      name={'description'}
                      required
                      rules={[{required:true, message: 'Please submit a description'}]}
                  >
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.description !== curValues.description}
                      >
                       {({ getFieldValue, setFieldsValue }) => (  
                            <RichText initialValue={getFieldValue('description')} disabled={false} editorRef={editorRef} onChange={value => setFieldsValue({ description: value})}/>
                        )}
                      </Form.Item>

                  </Form.Item>
                </div>
              </div>
              <div className="md:grid grid-cols-7 gap-4 ">
            
                <p className="md:text-end font-bold col-span-2  pt-[10px]">
                  Keywords
                </p>
                <div className="col-span-5"> 
                  <Form.Item
                      validateStatus={errors?.keywords && "error"}
                      help={errors?.keywords  && errors?.keywords }
                      className="group"
                      style={{marginBottom: 0}}
                      name={`keywords`}
                      required
                      rules={[{required:true, message: 'Please submit a keywords'}, ]}
                    >
                    <Select
                        className=" rounded transition-none w-full rightpage border border-main border-opacity-20 "
                        mode="tags"
                        open={false}
                        style={{width: "100%" ,height: '100px', background:'#fff'}}
                        placeholder={'Insert a maximum of 8 keywords'}
                        maxLength={8}
                        // value={keywords}
                        
                        // onDeselect={(e) => setkeywords((prev:string[] ) => (prev.filter(item => item !== e)))}
                        // onSelect={(e) => setkeywords((prev:string[]) => ([...prev, e]))}
                    />       
 
                    </Form.Item>
                </div>
              </div>
              
      

      </div>
  )
}
