import { CameraIcon } from '@heroicons/react/solid'
import React from 'react'
import Collapse, { Panel } from '../../../../components/shared/Collapse'
import BlockwithImgLayout from '../../components/block/withImgLayout'
import TextBlock2 from '../../components/block/TextBlock2'
import { useTranslation } from 'react-i18next'


const Case = ({gray, noSelected}:{gray?:boolean, noSelected?:boolean}) => {
    return (
        <div className={`text-level3 !mt-0 border-r border-white py-4 ${gray && 'bg-gray-100'} px-5 flex items-center justify-center`}>
         {!noSelected &&<div className="bg-black opacity-80 w-3 h-3"></div>}
         </div>
    )
}
const Line = ({title, content}:{title:string, content:JSX.Element}) => {
    return (
        <div className="flex space-x-5 border-b border-white">
            <div className="w-[400px] grid grid-cols-3 gap-x-2 !mt-0 border-x border-white py-4 ">
                <div></div>
                <p className="col-span-2 text-level3 !mt-0 text-end !font-semibold">{title} </p>
            </div>
            <div className="flex-1 grid grid-cols-2 ">

            {content}

            </div>

        </div>

    )
}
export const Phrase = ({text, gray}:{text:string, gray?:boolean}) => {
    return (
        <p className={`text-level3 !mt-0 border-r border-white py-4 ${gray && 'bg-gray-100 '} px-5 text-center`}>{text}</p>
    )
}
export default function Pricing() {
    const {t} = useTranslation(['pricing'])
  return (
    <div className="w-full h-full relative ">
       
    <div className="space-between-main-blocks3 pb-end-page">
    
        
        
        <div className="space-y-[150px] pb-5">
            <div className="relative flex flex-col justify-center  max-w-page mx-auto mt-[100px]">
                <div className='flex space-x-[55px] justify-centyer items-center  '>
                <div className=" w-full text-center space-y-10">
                       
                        <div>
                            <p className='title-main w-full'>
                                {t('pricing:title')}
                            </p>
                            <p className="text-main max-w-[950px] mx-auto">
                                {t('pricing:text')}


                            </p>

                        </div>
                   
                        

                    </div>
                 
                 

                </div>
            </div>


            <div className='max-w-page mx-auto overflow-x-auto'>
                <div className="border-t border-white min-w-[900px]">
                    <div className="flex space-x-5 border-b border-gray-200">
                        <div className="w-[400px] border-x border-white"></div>
                        <div className="flex-1 grid grid-cols-2 ">
                                
                            <p className="text-[28px] !font-bold border-r border-white px-5 py-4 text-center ">{t('pricing:table.row1.c2')}</p>
                            <p className="text-[28px] !font-bold border-r border-white bg-gray-100 px-5 py-4 text-center ">{t('pricing:table.row1.c3')}</p>
                       


                        </div>
                    </div>
                    <div className="flex space-x-5 border-b border-gray-200">
                        <div className="w-[400px] border-x border-white"></div>
                        <div className="flex-1 grid grid-cols-2 ">
                                
                            <p className="text-[28px] !font-bold border-r border-white px-5 py-4 text-center "><span className='text-[17px] !font-normal'>{t('pricing:table.row2.c2')}</span> <br/> 150€</p>
                            <p className="text-[28px] !font-bold border-r border-white bg-gray-100 px-5 py-4 text-center "> <span className='text-[17px] !font-normal'>{t('pricing:table.row2.c3')}</span> <br/> 500€</p>
                       

                        </div>
                    </div>
                    <div className="flex space-x-5 border-b border-gray-200 p">
                        <div className="w-[400px] border-x border-white pl-6 py-4">
                            <p className="text-level3 !font-bold !mt-0">
                                {t('pricing:table.row3.c1')}
                            </p>
                        </div>
                        <div className="flex-1 grid grid-cols-2 ">
                        
                            <p className="text-level3 !mt-0 border-r border-white py-4 px-5 text-center">
                                {t('pricing:table.row3.c3')}
                            </p>
                            <p className="text-level3 !mt-0 border-r border-white py-4 bg-gray-100 px-5 text-center">
                                {t('pricing:table.row3.c4')}
                            </p>
                            


                        </div>
                    </div>
                  
                    <Collapse defaultActiveKey={['1']}>
                        <>
                            <Panel key={'1'} header={<p className='text-level3 !font-bold !mt-0 !py-4'>{t('pricing:table.modules.title')}  </p>} className='pricing-panel ' >
                            <div className=' -mt-[62px]'>                              
                                <Line
                                    title={t('pricing:table.modules.home')}
                                    content={
                                        <>
                                        <Case/>
                                        <Case gray/>
                                        </>
                                    }
                                />
                              
                              <Line
                                  title={t('pricing:table.modules.websearch')}
                                  content={
                                      <>
                                      <Case/>
                                      <Case gray/>
                                      </>
                                  }
                              />
                                <Line
                                    title={t('pricing:table.modules.directory')}
                                    content={
                                        <>
                                        <Case/>
                                        <Case gray/>
                                        </>
                                    }
                                />
                                <Line
                                    title={t('pricing:table.modules.agenda')}
                                    content={
                                        <>
                                        <Case/>
                                        <Case gray/>
                                        </>
                                    }
                                />
                                <Line
                                    title={t('pricing:table.modules.jobs')}
                                    content={
                                        <>
                                        <Case/>
                                        <Case gray/>
                                        </>
                                    }
                                />
                                <Line
                                    title={t('pricing:table.modules.marketplace')}
                                    content={
                                        <>
                                        <Case />
                                        <Case  gray/>
                                        </>
                                    }
                                />
                             
                             <Line
                                 title={t('pricing:table.modules.training')}
                                 content={
                                     <>
                                     <Case />
                                     <Case gray/>
                                     </>
                                 }
                             />
                                        <Line
                                            title={t('pricing:table.modules.library')}
                                            content={
                                                <>
                                                <Case />
                                                <Case  gray/>
                                                </>
                                            }
                                        />
                                    <Line
                                        title={t('pricing:table.modules.opportunities')}
                                        content={
                                            <>
                                            <Case/>
                                            <Case noSelected gray/>
                                            </>
                                        }
                                    />
                              
                                <Line
                                    title={t('pricing:table.modules.immovablesC')}
                                    content={
                                        <>
                                        <Case/>
                                        <Case gray noSelected/>
                                        </>
                                    }
                                />
                             
                           
                             
                               
                              
                            </div>
                            </Panel>
                            <Panel key={'2'} header={<p className='text-level3 !font-bold !mt-0 !py-4'>{t('pricing:table.services.title')} </p>} className='pricing-panel' >
                            <div className=' -mt-[59px]'>
                                <Line
                                    title={t('pricing:table.services.row1.c1')}
                                    content={
                                        <>
                                     
                                     
                                        <Phrase
                                            text={t('pricing:table.services.row1.c3')}
                                            
                                        />
                                        <Phrase
                                            text={t('pricing:table.services.row1.c4')}
                                            gray
                                        />
                          
                                        </>
                                    }
                                />
                                <Line
                                    title={t('pricing:table.services.row2.c1')}
                                    content={
                                        <>
                                     
                                     
                                        <Phrase
                                            text={t('pricing:table.services.row2.c3')}
                                            
                                        />
                                        <Phrase
                                            text={t('pricing:table.services.row2.c4')}
                                            gray
                                        />
                          
                                        </>
                                    }
                                />
                                   <Line
                                    title={t('pricing:table.services.row3.c1')}
                                    content={
                                        <>
                                     
                                  
                                        <Phrase
                                            text={t('pricing:table.services.row3.c3')}
                                            
                                        />
                                        <Phrase
                                            text={t('pricing:table.services.row3.c4')}
                                            gray
                                        />
                          
                                        </>
                                    }
                                />
                                {/* <Line
                                    title='Prospection  '
                                    content={
                                        <>
                                     
                                        <Phrase
                                            text='En option  '
                                            gray
                                        />
                                        <Phrase
                                            text='100 contacts / mois + 5€/contact supplémentaire'
                                            
                                        />
                                        <Phrase
                                            text='100 contacts / mois + 5€/contact supplémentaire '
                                            gray
                                        />
                          
                                        </>
                                    }
                                /> */}
                                <Line
                                    title={t('pricing:table.services.row4.c1')}
                                    content={
                                        <>
                                     
                                            <div className="col-span-2 text-center">
                                                <Phrase gray text={t('pricing:table.services.row4.c2')}/>
                                            </div>
                                        </>
                                    }
                                />
                           
                            </div>
                            </Panel>
                            <Panel key={'3'} className='pricing-panel' header={<p className='text-level3 !font-bold !mt-0 !py-4'>{t('pricing:table.pricing.title')}
                                </p>}  >
                            <div className=' -mt-[59px]'>
                                <Line
                                    title={t('pricing:table.pricing.row1.c1')}
                                    content={
                                        <>
                                           
                                             <Phrase
                                                text='4.500€ '
                                                
                                            />
                                             <Phrase
                                                text='4.500€ '
                                                gray
                                            />
                                        </>
                                    }
                                />
                                <Line
                                    title={t('pricing:table.pricing.row2.c1')}
                                    content={
                                        <>
                                           
                                             <Phrase
                                                text='850€ '
                                                
                                            />
                                             <Phrase
                                                text='850€ '
                                                gray
                                            />
                                        </>
                                    }
                                />
                                 <Line
                                    title={t('pricing:table.pricing.row3.c1')}
                                    content={
                                        <>
                                        <div className="col-span-2 text-center">
                                            <Phrase
                                                text='75%'
                                                gray
                                            />

                                        </div>
                                             
                                        </>
                                    }
                                />
                              
                            </div>
                            </Panel>
                            <Panel key={'4'} header={<p className='text-level3 !font-bold !mt-0 !py-4'>{t('pricing:table.special-offer.title')} * 
                                </p>} className='pricing-panel' >
                            <div className=' -mt-[59px]'>
                                <Line
                                    title={t('pricing:table.special-offer.row1.c1')}
                                    content={
                                        <>
                                          
                                            <Phrase
                                                text='150€ '
                                                
                                            />
                                            <Phrase
                                                text='500€ '
                                                gray
                                            />
                                        </>
                                    }
                                />
                                 <Line
                                    title={t('pricing:table.special-offer.row2.c1')}
                                    content={
                                        <>
                                           
                                            <Phrase
                                                text='450€ '
                                                
                                            />
                                            <Phrase
                                                text='450€ '
                                                gray
                                            />
                                        </>
                                    }
                                />
                                    <Line
                                    title={t('pricing:table.special-offer.row3.c1')}
                                    content={
                                        <>
                                        <div className="col-span-2 text-center">
                                            <Phrase
                                                text='90%** '
                                                gray
                                            />

                                        </div>
                                           
                                        </>
                                    }
                                />
                              
                            </div>
                            </Panel>
                        </>
                    </Collapse>
                    <p className='italic mt-2'>{t('pricing:table.p')}</p>

                </div>
                
            </div>


            
       
    
        </div> 
    </div>

         



</div>
  )
}
