import { CameraIcon } from '@heroicons/react/solid'
import React from 'react'
import Block from './Block'


type Props = {
    columns:string,
    content: {
        img?: {type:'icon' | 'image', src:string, dimensions?:string, center?:boolean, hidden?:boolean},
        text:string | JSX.Element, 
        center?:boolean,
        title?:string,
        size?:string,
        extraContent?:JSX.Element,
    }[]
}
export default function Blocks({columns, content}:Props) {

  return (
    <div className={`grid  ${columns} gap-x-[70px] gap-y-[100px]`}>
    {content.map((block, index)=>(
      <div className="space-y-2">
        <Block block={block} key={index}/>
        {block.extraContent}
      </div>

    ))}
       

        
    </div>
  )
}
