import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import JSON files directly
import commonEN from "./locales/en/common.json";
import commonFR from "./locales/fr/common.json";
import benefitsFr from "./locales/fr/benefits.json";
import benefitsEn from "./locales/en/benefits.json";
import HomeFr from "./locales/fr/home.json";
import HomeEn from "./locales/en/home.json";
import QordduFr from "./locales/fr/qorddu.json";
import QordduEn from "./locales/en/qorddu.json";
import HtwFr from "./locales/fr/htw.json";
import HtwEn from "./locales/en/htw.json";
import RevenuFr from "./locales/fr/revenu.json";
import RevenuEn from "./locales/en/revenu.json";
import PricingFr from "./locales/fr/pricing.json";
import PricingEn from "./locales/en/pricing.json";
import servergFr from "./locales/fr/server.json";
import servergEn from "./locales/en/server.json";
import modulesgFr from "./locales/fr/modules.json";
import modulesgEn from "./locales/en/modules.json";
import connectFr from "./locales/fr/connect.json";
import connectEn from "./locales/en/connect.json";
import solutionFr from "./locales/fr/solution.json";
import solutionEn from "./locales/en/solution.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        common: commonEN,
        benefits:benefitsEn,
        qorddu:QordduEn,
        home:HomeEn,
        htw:HtwEn,
        revenu:RevenuEn,
        pricing:PricingEn,
        server:servergEn,
        modules:modulesgEn,
        connect: connectEn,
        solution: solutionEn
      },
      fr: {
        common: commonFR,
        benefits:benefitsFr,
        qorddu:QordduFr,
        home:HomeFr,
        htw:HtwFr,
        revenu:RevenuFr,
        pricing:PricingFr,
        server:servergFr,
        modules:modulesgFr,
        connect: connectFr,
        solution: solutionFr
      },
    },
    fallbackLng: "fr",
    returnObjects: true,
    supportedLngs: ["en", "fr"],
    detection: {
      order: ["path", "localStorage", "navigator"],
      lookupFromPathIndex: 1, // Gets language from /en/home

      caches: ["localStorage"],
    },
    interpolation: { escapeValue: false },
    defaultNS: "common",
    ns: ["common"],
    react: {
      useSuspense: false,
    },
    debug: true,
  });

// Enable HMR for translation JSON files
if ((module as any).hot) {
  (module as any).hot.accept(() => {
    console.log("Reloading translations...");
    i18n.reloadResources().then(() => {
      i18n.changeLanguage(i18n.language); // Force UI update
    });
  });
}

// if ((import.meta as any).webpackHot) {
//   (import.meta as any).webpackHot.accept(["./locales/en/common.json", "./locales/fr/common.json"], () => {
//     console.log("Reloading translations...");
//     i18n.reloadResources().then(() => {
//       i18n.changeLanguage(i18n.language); // Force UI update
//     });
//   });
// }

export default i18n;
