import React, { useEffect, useState } from 'react'
import { Link, Outlet, useParams, useSearchParams } from 'react-router-dom'


import useGetOwner from '../../../../../hooks/useGetOwner'
import { Spin, Tooltip } from 'antd'
import LocationgGlobalLayout from '../../../../../components/locationLayout/globalLayout'
import Icon from '../../../../../../../components/icons/Icon'
import { CameraIcon } from 'lucide-react'
import QfsActionsBar from '../../../../../GlobalLayout/QfsActionsBar'
import useGetPublications from '../../../../../hooks/useGetPublications'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import Collapse, { Panel } from '../../../../../../../components/shared/Collapse'
import Publications from '../../../../../../../components/shared/publications'
import PublicationsLink from '../../../../../components/resourcesLayout/publications/PublicationsLink'
import Footer from '../../../../../components/Footer'
import { useQfsContext } from '../../../../../context/qfsContext'
import { useUIContext } from '../../../../../context/UIContext'


export default function CommunityActive() {
    const [loading, setloading] = useState(false)
    const params = useParams()
  
    const { owner } = useGetOwner({coll: params.coll!, id:params.id!, setloading})
    const { hidePanel, setHidePanel} = useUIContext()
    const { publications, isLoaded, totalHits: totalHitsPubs } = useGetPublications({
        id:params.id!,
        limit:7, 
        mode:'volume', 
        groupBy:false, 
        extraFilter:'&& publicationType.id :! company_profile',
        query:'*',
        pagination:{page:1, offset:0, limit:7},
        setGroupBy:() => {},
        selectedPubType: {value:'', icon:'', type:'type'},
        setPaginate:() => {},
        setselectedPubType: () => {}
    })
const { currentLocation } = useQfsContext()
    
    
   


  
  
    
  return (
  
    <>
      
           
            <div className='overflow-auto height-community space-y-[60px]'>
                {
                    owner && (
                        <div>
                            {owner.thumb ? (
                                <div className='border-b border-main'>
                                    <img src={owner.thumb} className='w-full h-[500px] object-cover' alt="" />

                                </div>
                            ) : (
                                 <div className="w-full h-[500px] bg-skin-fill-muted flex items-center justify-center">
                                    {owner.logo ? <img src={owner.logo} className='w-[400px] h-[400px] opacity-20' alt="" style={{filter: 'grayscale(100%)'}}/>:<CameraIcon className='w-[120px] h-[120px] opacity-10 fill-transparent'/>}
                                    
                                </div>

                            )}
                            
                            <div className="pl-[50px]">
                                <div className="flex space-x-[150px]">
                                    <div className='w-[200px]'> 
                                        <div className='-mt-[150px]'>
                                            <div className="flex space-x-10 ">
                                                <div className='w-full h-[200px] border border-main rounded overflow-hidden bg-sub-windows' style={{filter:'drop-shadow(1px 2px 6px var(--shadow-win-color))'}}>
                                                    {owner.logo ? (
                                                        <img src={owner.logo} className='w-full h-full' alt="" />
                                                    ) : (
                                                        <div className="w-full h-full flex items-center justify-center" style={{background:'rgb(242, 242, 242)'}}>
                                                            <CameraIcon className='w-[50px] h-[50px] fill-transparent text-white'/>
                                                        </div>
                                                    )}
                                                    {/* <img src={owner.logo} className='w-full h-full' alt="" /> */}
                                                </div>
                                            

                                            </div>

                                        </div>
                                        <div className="mt-[30px]  w-full">
                                            <p className="font-bold text-[20px] ellipsis-3">{owner.name}</p>
                                            <p className='mt-2 mb-4 ellipsis-3'>{owner.description}</p>
                                            <p>{owner.adresse?.line1}</p>
                                            <p className='mt-0.5'>{owner.adresse?.zipcode} {owner.adresse?.city}</p>
                                            <div className='flex space-x-1 -ml-1 mt-2'>
                                                <Tooltip title={`Visit ${owner.name}'s website`}>
                                                    <Link to={owner.webResources?.website!} target='_blank' rel="noopener noreferrer" className='p-1 bg-hover-transparent rounded !text-inherit'>
                                                        <Icon className='icon-mini' name='OpenWindow'/>
                                                    </Link>

                                                </Tooltip>
                                                <Tooltip title={`Visit ${owner.name}'s linkedin profile`}>
                                                    <Link to={owner.webResources?.linkedin!} target='_blank' className='p-1 bg-hover-transparent rounded !text-inherit' rel="noopener noreferrer">
                                                        <Icon className='icon-mini' name='Linkedin'/>
                                                    </Link>

                                                </Tooltip>
                                                <Tooltip title={`Location of ${owner.name}`}>
                                                    <Link to={'/'} target='_blank' rel="noopener noreferrer" className='p-1 bg-hover-transparent rounded !text-inherit'>
                                                        <Icon className='icon-mini' name='Map'/>
                                                    </Link>

                                                </Tooltip>
                                                
                                                <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' >
                                                    <Icon className='icon-mini' name='Chat'/>
                                                </button>
                                                <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                                    <Icon className='icon-mini' name='Call'/>
                                                </button>
                                                <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                                    <Icon className='icon-mini' name='Pin'/>
                                                </button>
                                                <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                                    <Icon className='icon-mini' name='Share'/>
                                                </button>
                                            
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex-1 mt-[78px]">
                                        {publications && (
                                            <Collapse
                                                activeKey={['publications']}

                                            >
                                                    <Panel
                                                    header={<p className='text-groupe'>Recent publications </p>}
                                                    key={'publications'}
                                                >
                                                    <div className="mb-[40px] max-[580px]:mb-[20px] ml-[18px] mt-[2px]">
                                                    <PublicationsLink
                                                        publications={publications}
                                                        webResources={undefined}
                                                        suffix={`${params.serverId}/${params.coll}/${params.id}/community/`}
                                                        groupBy={{webresources:false, paidPubs:false}}
                                                        totalHits={{pubs:0, webresources:0}}
                                                        searchon={false}
                                                        selectedPubType={{value:'', icon:'', type:'type'}}
                                                        barActivatedFields={{author:true, date:true}}
                                                        setRecheckPin={() => {}}
                                                        displayMode='OneLine'
                                                    />
                                                    
                                                    {/* <p className='italic mt-2'>Seacrh among {totalHits.webresources ?? 0 + (totalHits.pubs??0)} resources in this server</p> */}
                                                    </div>
                                                </Panel>
                                            
                                            </Collapse>

                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }
            </div>
   
          <Footer
                totalHits={{webresources: 0 , pubs: totalHitsPubs, location:0}}
                pagination={{limit:7, offset:0, page:1}}
                setPaginate={(data) => {}}
                onlyWebpages={false}
                currentLocation={currentLocation}
                allowPaginate={
                  {
                  webresources:false,
                  pubs: false
                  }
                }
                windows={{
                    active: '',
                    suffix:`${params.serverId}/${params.coll}/${params.id}/community`,
                    disabled:false
                }}
            />
        <Outlet/>
    
    </>

    
      

    
  )
}
