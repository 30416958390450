import React, { useEffect, useState } from 'react';
import { Highlight, Publication } from '../../../types';
import { searchPublications } from '../../../services/search/search';
import { searchDocuments } from '../../../services/api/request/search';
import { useDebounce } from '../../../hooks/useDebounce';
import { QUERY_DEBOUNCE_DELAY } from '../../../constants';
import { useSearchContext } from '../context/searchContext';

// Debounce Hook


export default function useGetPublications({
  id,
  limit,
  sponsored,
  groupBy,
  mode,
  extraFilter,
  query,
  selectedPubType,
  pagination,
  setGroupBy,
  setPaginate,
  setselectedPubType
}: {
  id: string;
  limit: number;
  sponsored?: boolean;
  groupBy: boolean;
  mode?: 'roots' | 'volume';
  extraFilter?: string;
  query:string;
  pagination:{page:number, offset:number, limit:number},
  setGroupBy: (data:{webresources?:boolean, paidPubs?:boolean}) => void,
  selectedPubType:{value:string, icon:string, type:'groupe' | 'type'},
  setPaginate: (data:{page:number, offset:number}) => void,
  setselectedPubType: (data:{ value: string; icon: string  ; type: 'type' | 'groupe'; title: string}) => void,
}) {
  const [publications, setPublications] = useState<undefined | { document: Publication; highlight: Highlight }[]>(undefined);
  const [newRequest, setNewRequest] = useState(0);
  const [isLoaded, setIsLoaded] = useState(true);
  const [totalHits, setTotalHits] = useState(0);
  const debouncedQuery = useDebounce(query, QUERY_DEBOUNCE_DELAY);
  const debouncedPagination = useDebounce(pagination, QUERY_DEBOUNCE_DELAY);

  useEffect(() => {
    (async () => {
      if (id) {
        const req = await fetch(searchDocuments('publications'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: searchPublications({
              q: debouncedQuery,
              volumeId: id,
              type: { value: selectedPubType.value, type: selectedPubType.type },
              limit,
              offset: debouncedPagination.offset,
              page: debouncedPagination.page,
              mode,
              sponsored,
              groupBy: groupBy ? 'publicationGroupe.id, publicationGroupe.title.en' : undefined,
              extraFilter,
            }),
          }),
        });

        const res: any = await req.json();

        if (res.data.grouped_hits) {
          setGroupBy({ paidPubs: true });
          setPublications(res.data?.grouped_hits);
       
            setTotalHits(res.data.found_docs );
          
        } else {
          setGroupBy({ paidPubs: false });
          setPublications(res.data?.hits);
         
            setTotalHits( res.data.found );
          
        }

      
      } else {
        setPublications([]);
      }
    })();
  }, [
    id,
    debouncedQuery,
    selectedPubType.value,
    selectedPubType.type,
    debouncedPagination.offset,
    debouncedPagination.page,
    newRequest,
    mode,
    extraFilter,
  ]);

  useEffect(() => {
    setIsLoaded(false);
    setTimeout(() => {
      setNewRequest((prev) => prev + 1);
      setIsLoaded(true);
    }, 100);
  }, [limit, groupBy]);

  useEffect(() => {
    setselectedPubType({ type: 'type', value: '', icon: '', title: '' });
    setPaginate({ page: 1, offset: 0 });
  }, [groupBy]);

  return {
    publications,
    isLoaded,
    totalHits
  };
}
