import { CameraIcon } from '@heroicons/react/solid'
import React from 'react'
import Blocks from '../../../../../../../home/components/block/Blocks'

export default function Lancement({prevStep}:{prevStep:()=>void}) {
  return (
    <div className='space-y-14 max-w-[1100px]'>
    <p className="title-level2">Lancement</p>
    <div className="w-full h-[400px]  flex justify-center items-center rounded">
      <CameraIcon className='w-[100px] h-[100px] opacity-10'/>
    </div>
   
    <div className='space-y-7'>
      <div className=' flex justify-center w-full'>
        <div className="">
          <Blocks
            columns='grid-cols-4'
            content={[
              {
                title:'Intégrez le widget Qorddu ',
                text:'Intégrez le widget Qorddu dans votre site web en quelques secondes et assurez la promotion de votre serveur Qorddu. ',
                img:{src:'',type:'icon',hidden:true}
              },
              {
                title:'Un article par mois ',
                text:'Publiez chaque début de mois une actualité sur les succès de votre serveur et le déploiement de la grille Qorddu. ',
                img:{src:'',type:'icon',hidden:true}
              },
              {
                title:'Montée en charge ',
                text:'Assurez la montée en charge du contenu de votre serveur – et donc son succès - avec une offre de lancement auprès des entreprises.   ',
                img:{src:'',type:'icon',hidden:true}
              },
            ]}
          />

        </div>

      </div>
    </div>
    <div className="flex w-full justify-end space-x-3">

    <button onClick={prevStep} className='px-4 py-2 rounded bg-gray-300 text-level2 '>Revenir a l'étape précédente </button>

    </div>
  </div>
  )
}
