import React, { useEffect, useState } from 'react'
import { useSearchContext } from '../context/searchContext';
import { useUIContext } from '../context/UIContext';
import { useQfsContext } from '../context/qfsContext';

import { useParams } from 'react-router-dom';

import UserActionsButton from '../../../components/shared/bars/TopBar/userActionsButton';
import useNavigateTo from '../hooks/useNavigateTo';
import { useAppContext } from '../../../context/AppContext';
import { UI_WIDTH_HIDE_SOME_ACTIONS, UI_WIDTH_IS_MOBILE } from '../../../constants';
import { BreadcrumbComp } from '../../../components/shared/breadcrumb';
import SwitchLanguages from '../../../components/shared/bars/TopBar/SwitchLanguages';

export default function PathBarContainer() {
    const { all} = useUIContext()
    const { currentLocation } = useQfsContext()
    const { domain} = useAppContext()
    const { setSelectedPubType, setQuery, totalHits, setOnlyWebpages } = useSearchContext()
    const { winSize } = useAppContext()
    const [validQuery, setvalidQuery] = useState('');
    const [openSearchDropdown, setopenSearchDropdown] = useState(false);
    const {goTo} = useNavigateTo()
    useEffect(() => {
    
        if(validQuery.length > 2){
          setQuery(validQuery)
        }else{
          setQuery('')
        }
      }, [validQuery]);



    return (
        <div className='bar-h border-b border-main'>

            <div className='flex justify-between items-center w-full pr-7 pl-[20px] h-full '>
                <div className={` flex-1 items-center flex space-x-3`}>
                    <div className="flex items-center space-x-2 flex-1">
                        {/* <button className={`p-1.5 bg-hover-transparent rounded no-dragable disabled:opacity-20 transition-all ${hidePanel ? ' rotate-180' :''}`} onClick={() => setHidePanel(!hidePanel)} disabled={false}>
                            <Icon name='PanelOpen' className='icon-sm'/>
                        </button> */}
                        <BreadcrumbComp
                            className='flex-1'
                            routes={
                                winSize.w > UI_WIDTH_HIDE_SOME_ACTIONS ? 
                            currentLocation?.routes? 
                                        [
                                            {
                                                icon: {type: 'logo', src: 'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logo.svg?alt=media&token=c5969d32-3d07-44f3-8117-1c5fdb7473ec'},
                                                name: 'Qorddu',
                                                id: 'qorddu',
                                            },
                                        
                                        ...domain ? [
                                           
                                        {
                                            name: domain.title,
                                            id: domain.id,
                                            coll: 'Domains'
                                        }
                                        ] : [],
                                        ...currentLocation.routes,
                                        ...all?[
                                        {
                                            name:all
                                        }
                                        ]: []
                                        ] : 
                                        [
                                    
                                        ...domain ? [
                                            {
                                                icon: {type: 'logo', src: 'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logo.svg?alt=media&token=c5969d32-3d07-44f3-8117-1c5fdb7473ec'},
                                                name: 'Qorddu',
                                                id: 'qorddu',
                                            } as any,
                                            
                                        {
                                            name: domain.title,
                                             
                                        }
                                        ] : []
                                        ] : [
                                            {
                                                icon: {type: 'logo', src: 'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logo.svg?alt=media&token=c5969d32-3d07-44f3-8117-1c5fdb7473ec'},
                                                name: 'Qorddu',
                                                id: 'qorddu',
                                            },
                                           ...(currentLocation?.routes && winSize.w >UI_WIDTH_IS_MOBILE) ? [
                                            {
                                                name: currentLocation?.routes[currentLocation.routes.length-1]?.name,
                                                id: currentLocation?.routes[0]?.id,
                                            }
                                           ] :[]
                                        ]
                                    }
                            
                        //  disabled={!!props.hidePanel.disabled}
                        
                        />

                    </div>

                
                        
                                
            
                </div>
                <div className='flex items-center space-x-5'>
                    <SwitchLanguages/>
                <UserActionsButton/>

                </div>
            </div>       
        </div>

   
  )
}
