import { Form, Modal, Spin } from 'antd';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { auth } from '../../../utils/firebase';
import { useAuthContext } from '../../../context/AuthContext';
import { login } from '../../../services/api/request';
import { useAppContext } from '../../../context/AppContext';
import { useTranslation } from 'react-i18next';
// import { signInWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../../utils/firebase';

export default function Login() {
    const [open, setopen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<any>(null);
    const [Status, setStatus] = useState(false);
    const [loading, setloading] = useState(false);
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const { user} = useAuthContext()
    const {domain} = useAppContext()
    const currentQueryParams = location.search;
    const {t} = useTranslation(['connect'])
    console.log('connect', location.state)
    const submitForm = async (event:any) => {
        event.preventDefault();
        setloading(true)
        setErrors(null)
     
 
            fetch(login, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json", 
                    "Access-Control-Allow-Credentials": "true", 
                },
                credentials: "include",
                body: JSON.stringify({ email, password }),
            }).then(res => res.json()).then((data) => {
         
                if(data.success){
                    
                    if(searchParams.get('EmailValidated') && searchParams.get('accountType')){
                        if(searchParams.get('accountType') === 'INDIVIDUAL'){
                            navigate(`/connect/signup/steps/6/individual${currentQueryParams}`)
                        }else{
                            navigate(`/connect/signup/steps/6/organization${currentQueryParams}`)
                        }
                    }else{
                        navigate(domain ? location.state?.from?.pathname || '/' : '/home', {replace:true})
        
                    }
                }else{
                    setErrors(data.error)
                }

            }).catch(err => setErrors('Invalid credentials')).finally(() => setloading(false))
        
      };
    // const submitForm = (event:any) => {
    //     event.preventDefault();
    //     setloading(true)
    //     setErrors(null)
    //     signInWithEmailAndPassword(auth, email,password).then(async userCredentials => {
    //         if(searchParams.get('EmailValidated') && searchParams.get('accountType')){
    //             if(searchParams.get('accountType') === 'INDIVIDUAL'){
    //                 navigate(`/connect/signup/steps/6/individual${currentQueryParams}`)
    //             }else{
    //                 navigate(`/connect/signup/steps/6/organization${currentQueryParams}`)
    //             }
    //         }else{
    //             navigate('/')

    //         }
      
    //       }
    //         ).catch(err => setErrors('Invalid credentials')).finally(() => setloading(false))
        
    //   };
    useEffect(() => {
      setopen(true)
    }, [])
    useEffect(() => {
        
        if(searchParams.get('EmailValidated') && searchParams.get('accountType') && user){
            if(searchParams.get('accountType') === 'INDIVIDUAL'){
                navigate(`/connect/signup/steps/4/individual${currentQueryParams}`)
            }else{
                navigate(`/connect/signup/steps/4/organization${currentQueryParams}`)
            }
        }
    }, [user]);
    
  return (
                      
            <Form className="flex-1 w-full">
                <div className="space-y-3 flex flex-col items-end w-full">
                    <Form.Item
                        validateStatus={errors ? "error" : ''}
                        help={errors}
                        className="group"
                        style={{ marginBottom: "0", width:'100%' }}
                        required
                        
                    >
                        <input
                        type="email"
                        placeholder={t('connect:inputs.email')}
                        className={`rounded-lg py-2 px-3 block  w-full h-[50px] !text-[18px] placeholder:text-[18px] `}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors(null);
                        }}
                        required
                        />
                    </Form.Item>
                    <Form.Item
                        validateStatus={errors ? "error" : ''}
                        help={errors}
                        className="group"
                        style={{ marginBottom: "0", width:'100%' }}
                        required
                    >
                        <input
                        type="password"
                        placeholder={t('connect:inputs.password')}
                        className="rounded-lg py-2 px-3 block  w-full h-[50px] !text-[18px] placeholder:text-[18px] "
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors(null);
                        }}
                        required
                        />
                    </Form.Item>

                </div>
                <div className='flex space-x-4 mt-4 items-center'>
                    <Spin spinning={loading}>
                        <div className=''>
                            <button
                            className="text-level2 block  bg-gray-300 px-7 py-2.5  rounded-lg "
                            type="submit"
                            disabled={loading}
                            onClick={submitForm}
                            // style={{background: '#000'}}
                            >
                            {t('connect:buttons.login')}
                            </button>

                        </div>
                    </Spin>
                    {/* <div className='w-full flex '>
                        <p className='text-[14px] text-level2'>{t('connect:buttons.forget-password')}</p>
                    </div> */}

                </div>
            </Form>

                             
               
                
         
     
        
  )
}
