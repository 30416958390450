import React, { useEffect } from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { useAuthContext } from "../../../context/AuthContext";

export default function Demo(){
  const { user } = useAuthContext()
  
  return (
    <div className="w-full h-full relative ">
   
           
                {user && (
                    <InlineWidget 
                    url={`https://calendly.com/rania-teddra/30min?name=${encodeURIComponent(user.name)}&email=${encodeURIComponent(user.email)}&primary_color=%23000`}  
                  />
                  
                  

                )}
            

    </div>
//       <iframe
//     src="https://tidycal.com/saadirania406"
//     width="100%"
//     height="100%"
//     frameBorder="0"
//     allow="autoplay; encrypted-media"
//     title="Book a Demo"
//   ></iframe>
  );
};

