import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Breadcrumb } from "../../../components/shared/bars/TopBar/types";



export type displayModes = 'OneLine' | 'TwoLines' | 'Thumb' | 'Blocks'
type States = {
 
  mode: 'object' | 'url',
  disabled: {publicationBar: boolean, query: boolean, filter:boolean, display:boolean},
  stats: {publications: number, webresources: number},
  displayMode: displayModes,
  hidePanel:boolean, 
  barActivatedFields: { author:boolean, date: boolean},
  all:string,
  breadcrumb: Breadcrumb[]
 
}
type Functions = {
  

  setObjectDisplayMode: ( mode: 'object' | 'url') => void,
  setdisable : (data:{publicationBar?: boolean, query?: boolean, filter?:boolean, display?:boolean}) => void,
  setStats: (data:{volumes: number, publications: number, webresources:number}) => void,
  setDisplayMode: (data: displayModes) => void,
  setHidePanel: (data: boolean) => void,
  setBarActivatedFields: (data:{ author?:boolean, date?: boolean}) => void,
  setAll :(value:string) => void,
  setbreadcrumb : (data:Breadcrumb[]) => void

}
type Props = States & Functions
const UIContext = createContext<Props>({} as Props);

export const useUIContext = () => useContext(UIContext);

export const UIContextProvider = ({ children } : { children : JSX.Element}) => {
  const [state, setState] = useState<States>({
   
    mode: 'object' ,
    disabled: {publicationBar: false, query: false, filter:false, display:false},
    all:'',
    stats: { publications: 0, webresources: 0},
    displayMode: 'OneLine',
    hidePanel:false,

    barActivatedFields: { author: false, date: true},
    breadcrumb:[]
  })
  

  const value = useMemo(() => {
    

  
    const setObjectDisplayMode = (mode: 'url' | 'object') => {
      setState((prev) => ({
        ...prev,
        mode
      }))
    }

  

    const  setdisable = (data:{publicationBar?: boolean, query?: boolean, filter?:boolean, display?:boolean}) => {
      setState((prev) => ({
        ...prev,
        disabled: {...prev.disabled, ...data},
      }))
    }



    const setStats= (data:{volumes: number, publications: number, webresources: number}) => {
      setState((prev) => ({
        ...prev,
        stats: data
      }))
    }

    const setDisplayMode= (data:displayModes) => {
      setState((prev) => ({
        ...prev,
        displayMode: data
      }))
    }

    const setHidePanel = (data:boolean) => {
      setState((prev) => ({
        ...prev,
        hidePanel:data
      }))
    }

    const setAll = (data:string) => {
      setState((prev) => ({
        ...prev,
        all:data
      }))
    }


   
    const setBarActivatedFields = (data:{ author?:boolean, date?: boolean}) => {
      setState((prev) => ({
        ...prev,
        barActivatedFields: {
          author : data.author ? !prev.barActivatedFields.author : prev.barActivatedFields.author,
          date : data.date ? !prev.barActivatedFields.date : prev.barActivatedFields.date
        }
      }))
    }

    const setbreadcrumb = (data:Breadcrumb[]) => {
      setState((prev) => ({
        ...prev,
        breadcrumb: data
      }))
    }
  
    return {
      ...state,

      setObjectDisplayMode,
     
      setdisable,
      setStats,
      setDisplayMode,
      setHidePanel, 

      setBarActivatedFields,
      setAll,
      setbreadcrumb
    }
  }, [state])

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
};