import { Outlet, useParams } from "react-router-dom";
import { useQfsContext } from "../../../context/qfsContext";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../../../context/AppContext";
import { PartnerType } from "../../../../../types";
import { useUIContext } from "../../../context/UIContext";
import Icon from "../../../../../components/icons/Icon";
import Footer from "../../../components/Footer";
import Divider from "../../../../../components/shared/Divider";
import { useTranslation } from "react-i18next";
import { CameraIcon } from "@heroicons/react/solid";
import { UI_MD, UI_WIDTH_IS_MOBILE } from "../../../../../constants";
import ReactSvg from "../../../../../components/shared/ReactSvg";
import useNavigateTo from "../../../hooks/useNavigateTo";
import { MessageCircleWarningIcon } from "lucide-react";
import Infobulle from "../../../../../components/icons/files/Infobulle";
import { notification } from "antd";


type Props = {
    stats:{
        organizations:number,
        publications:number,
        people:number,
        pins:number
    },
    children:JSX.Element,
    partner:PartnerType | null,
    isPreReserved:boolean,
    isOwner:boolean
}
export default function HomeLayout({stats, children, partner,isPreReserved, isOwner}: Props) {
    const {currentLocation} = useQfsContext()
    const params = useParams()
    const {winSize, domain} = useAppContext()
    const { hidePanel, setHidePanel} = useUIContext()
    const {t} = useTranslation(['server'])
    const {goTo} = useNavigateTo()
   
    useEffect(() => {
      notification.close("warning");
      if(isOwner){

        setTimeout(() => {
          notification.open({
            key: "warning",
            message:"",
            description: <div className="flex space-x-3">
              <Icon name='Warning' className='w-[70px] h-[70px] text-yellow-400'/>
              <p className="flex-1">
                <span className="font-bold">Important :</span> en phase de réréservation, les données nominatives (logo, nom, description,...) des éditeurs sont uniquement visibles par leur propriétaire respectif.
              </p>
            </div>,
            placement: "bottomLeft",
            duration: 0,
          
            style: {
              width: "100%",
              maxWidth: "340px",
              bottom:'30px',
              right:'20px',
              paddingBottom:'38px',
              paddingRight:'40px'
            },
          });
        }, 2000);
      }

   
    }, [isOwner]);

  return (
    <>
    

            <div className='overflow-auto height-community space-y-[60px] relative pb-10'>
                    
              <div>
                 
                
                    <div className="bar-h flex items-center px-5 space-x-3 border-l border-b border-main">
                        <button className={`p-1.5 bg-hover-transparent rounded no-dragable disabled:opacity-20 transition-all ${hidePanel ? ' rotate-180' :''}`} onClick={() => setHidePanel(!hidePanel)} disabled={false}>
                            <Icon name='PanelOpen' className='icon-sm'/>
                        </button>
                        <Divider/>
                        <div className="flex space-x-2 items-center flex-1">
                          <ReactSvg src={currentLocation?.iconUrl??''} className="icon-sm"/>
                          <p className="ellipsis-1 flex-1 min-w-0">{currentLocation?.title}</p>
                        </div>

                    </div>

                  
                  {/* <div className="py-3.5 gap-x-[150px] border-b border-main flex pl-[50px] max-[480px]:pl-7 max-[980px]:gap-x-10">
                    <div className="w-[180px] max-[750px]:hidden"></div>
                    <div className="flex-1 h-max flex gap-4 flex-wrap items-center">
                      <div className={`${!currentLocation?.isActive && 'opacity-30'} flex space-x-2 items-center`}>
                        <Icon className='icon-sm' name='Entreprise'/>
                        <p>{stats.organizations} {t('server:stats.companies')}</p>
                      </div>
                      <Divider/>
                      <div className={`${!currentLocation?.isActive && 'opacity-30'} flex space-x-2 items-center`}>
                        <Icon className='icon-sm' name='UserIcon'/>
                        <p>{stats.people} {t('server:stats.profiles')}</p>
                      </div>
                      <Divider/>
                      <div className={`${!currentLocation?.isActive && 'opacity-30'} flex space-x-2 items-center`}>
                        <Icon className='icon-sm' name='Document'/>
                        <p>{stats.publications} {t('server:stats.publications')}</p>
                      </div>
                      <Divider/>
                      <div className={`${!currentLocation?.isActive && 'opacity-30'} flex space-x-2 items-center`}>
                        <Icon className='icon-sm' name='Pin'/>
                        <p>{stats.pins} {t('server:stats.pins')}</p>
                      </div>
                    </div>
                  </div> */}
                  <div className="pl-7 pr-7 mt-[27px]">
                      <div className="flex gap-x-[70px] max-[980px]:gap-x-10 max-[750px]:block">
                          <div className="w-[180px] max-[750px]:w-full">
                            <div className=' max-[750px]:flex max-[750px]:space-x-3'> 
                                <div className=' '>
                                    <div className="flex space-x-10 ">
                                        <div className='w-full h-[180px] max-[750px]:h-[150px] max-[750px]:w-[150px] max-[480px]:w-[100px] max-[480px]:h-[100px] border border-main rounded overflow-hidden bg-sub-windows relative z-20' >
                                          
                                                <div className="w-full h-full flex items-center justify-center bg-white  ">
                                                  { partner?.logo ? (
                                                    <img src={partner?.logo} className='w-full h-full' alt="" />
                                                  ) : (
                                                    isPreReserved ? (
                                                      <ReactSvg src='https://firebasestorage.googleapis.com/v0/b/qorddu-production.firebasestorage.app/o/icons%2Fvolumes%2FclusterReserved.svg?alt=media&token=bce4b379-67bd-4e69-abb0-8cc42ed2f6a1' className='w-[50px] h-[50px] '/>

                                                    ):(

                                                      <ReactSvg src='https://firebasestorage.googleapis.com/v0/b/qorddu-production.firebasestorage.app/o/icons%2Fvolumes%2Fserver.svg?alt=media&token=00fa8f53-cbd6-47a5-b077-22021a3d5368' className='w-[50px] h-[50px] opacity-[0.05]'/>
                                                    )
                                                    

                                                  )}
                                                </div>
                                            
                                        </div>
                                    

                                    </div>

                                </div>
                              
                                  <div className="mt-[15px] max-[750px]:mt-0  w-full flex-1 ">
                                      <p className="font-bold text-title ellipsis-3">{partner? partner.name:currentLocation?.title}</p>
                                      <p className='mt-2 mb-4 ellipsis-4'>
                                        
                                        {partner ? 
                                        partner?.description : 
                                        isPreReserved? 'Ce serveur est préréservé pour un editeur' : 
                                        'Ce serveur n\'est pas préréservé'
                                        }
                                      </p>
                                      <div className='flex space-x-1 -ml-1 mt-2'>
                                        <button className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' onClick={() => window.open(partner?.webResources?.website,'_blank')} disabled={ !partner?.webResources?.website}>
                                          <Icon className='icon-mini' name='OpenWindow'/>
                                        </button >
                                        <button className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' onClick={() => window.open(partner?.webResources?.linkedin,'_blank')} disabled={ !partner?.webResources?.linkedin} >
                                          <Icon className='icon-mini' name='Linkedin'/>
                                        </button >
                                      
                                        <button className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' disabled>
                                          <Icon className='icon-mini' name='Map'/>
                                        </button >
                                        
                                          
                                          <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' >
                                              <Icon className='icon-mini' name='Chat'/>
                                          </button>
                                          <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                              <Icon className='icon-mini' name='Call'/>
                                          </button>
                                          <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                              <Icon className='icon-mini' name='Pin'/>
                                          </button>
                                          <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                              <Icon className='icon-mini' name='Share'/>
                                          </button>
                                      
                                      </div>
                                     


                                  </div>

                              

                            </div>
                            {isOwner && (
                              <div className="mt-[40px]">

                                <div className="space-y-3">
                                  {/* <p className="font-bold text-title">{t('server:stats.title')}</p> */}
                                  {/* <div className="space-y-3 max-[750px]:flex max-[750px]:gap-4 flex-wrap max-[750px]:space-y-0">
                                    <div className={`${!currentLocation?.isActive && 'opacity-30'} flex space-x-2 items-center`}>
                                      <Icon className='icon-sm' name='Entreprise'/>
                                      <p>{stats.organizations} {t('server:stats.companies')}</p>
                                    </div>
                                    <div className={`${!currentLocation?.isActive && 'opacity-30'} flex space-x-2 items-center`}>
                                      <Icon className='icon-sm' name='UserIcon'/>
                                      <p>{stats.people} {t('server:stats.profiles')}</p>
                                    </div>
                                    <div className={`${!currentLocation?.isActive && 'opacity-30'} flex space-x-2 items-center`}>
                                      <Icon className='icon-sm' name='Document'/>
                                      <p>{stats.publications} {t('server:stats.publications')}</p>
                                    </div>
                                    <div className={`${!currentLocation?.isActive && 'opacity-30'} flex space-x-2 items-center`}>
                                      <Icon className='icon-sm' name='Pin'/>
                                      <p>{stats.pins} {t('server:stats.pins')}</p>
                                    </div>
                                  </div> */}
                                  {/* <button className="bg-skin-fill-inverted w-full text-skin-inverted px-2 rounded py-2" onClick={() => goTo(`/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}/activation/pages/reserve`, {})}>{t('server:reserve')}</button> */}
                                  <button className="bg-skin-fill-inverted w-full text-skin-inverted px-2 rounded py-2" onClick={() => goTo(`/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}/activation/pages`, {})}>Réservez ce serveur, demandez une démo...</button>
    
                                </div>
                              
                              </div>
                              
                            )}
  
                            </div>
                          {winSize.w < 751 && (
                            <div className="w-full h-[1px] bg-gray-200 my-[30px]"></div>

                          )}
                          <div className="flex-1 -mt-[8px]">
                            <div className="flex-1 ">
                              {children}

                                
                            </div>

                             
                          </div>
                      </div>

                  </div>
              </div>
                    
                
            </div>
            <Footer
                totalHits={{webresources:0, pubs:0, location:0}}
                pagination={{limit:7, offset:0, page:1}}
                setPaginate={(data) => {}}
                onlyWebpages={false}
                currentLocation={currentLocation}
                allowPaginate={
                    {
                    webresources: false,
                    pubs: false
                    }
                }
                windows={{
                    active: '',
                    suffix:`/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}`,
                    disabled:!currentLocation?.isActive
                }}
       />

        <Outlet/>

   
        
    
    </>
  )
}
