import { apiEndPoint } from "../constants"

export const signup = `${apiEndPoint}/auth/register`


export const updateUser = `${apiEndPoint}/auth/user/update`
export const getUser = `${apiEndPoint}/auth/user`
export const updateVolum = ({volumId, volumColl}:{volumId:string, volumColl:string}) => `${apiEndPoint}/volums/specializeds/${volumId}/${volumColl}/user`
export const getOrganization = `${apiEndPoint}/organizations/user`
export const updateOrganization = (id:string) => `${apiEndPoint}/organizations/user/${id}`
export const getUserCard = `${apiEndPoint}/auth/user/get-payment-card`
export const setUserCard = `${apiEndPoint}/auth/user/set-payment-card`
export const userCredits = `${apiEndPoint}/subscriptions/my-credits`


