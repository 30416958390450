import React from 'react'
import { useAppContext } from '../../../../context/AppContext'
import usegetQueries from '../../hooks/useGetQueries'

export default function SearchDropdown({query, onSelectQuery}: {query:string, onSelectQuery:(query:string)=>void}) {
    const { domain } = useAppContext()
    const { queries } = usegetQueries({domainId:domain?.id!, query})
  return (
    <div className='w-[265px] bg-white min-h-[200px]  -ml-[29px]  teddra-shadow bg-menu teddra-rounded p-2'>
        {queries?.map(query => (
           <button key={query.document.id} className="px-4 py-2 bg-hover-transparent w-full block text-start truncate rounded" onClick={() => onSelectQuery(query.document.query)}>
             {query.highlight?.query ? (
               <p dangerouslySetInnerHTML={{__html: query.highlight.query.snippet}}></p>
             ) : (
            
               <p>{query.document.query}</p>
             )}

           </button>
     
           
            
        ))}
        {!query && (
          <p className='px-4  text-skin-muted italic py-2'>Please tab more than 2 letters to search</p>
        )}
         
    </div>
 
  )
}
