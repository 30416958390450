import i18n from "../../i18n";

const { fetch: originalFetch } = window;

export const fetchInt = async (...args:any) => {
    let [resource, config ] = args;
    const response = await originalFetch(resource, {
        ...config,
        headers: {
            ...config?.headers,
            "Access-Control-Allow-Credentials": "true", 
           "Accept-Language": i18n .language
            
        },
        credentials: "include",
    });
    return response;
};