import React, { useRef } from 'react'
import CardWithWebsiteLayout from '../WithWebsite/Layout'
import Description from '../WithWebsite/Description'
import { useAppContext } from '../../../../context/AppContext'
import { UI_WIDTH_IS_MOBILE } from '../../../../constants'
import Sm from '../WithWebsite/Sm'

type PartnerType = {
  owner: any,
  imgHeight?:string,
  active?:boolean,
}
export default function OwnerWithWebsite({ owner, imgHeight,active } : PartnerType) {
  const {winSize}= useAppContext()

  return (
    <>
    {winSize.w > UI_WIDTH_IS_MOBILE ? (
      <div className={` ${active && 'bg-skin-fill-inverted text-skin-inverted'} rounded flex space-x-3`}>
        <CardWithWebsiteLayout
          item={{
            title: <p className={`${!active ? '!text-main-base' : 'text-white' }`}>{owner.name}</p>,
            image: owner.logo
          }}
        
          imgClassName={`h-[190px] w-full ${imgHeight && imgHeight}`}
          border
          active={active}
        />
        <Description
            title={owner.name}
            description={owner.description?.en}
            website={owner.webResources?.website ?? owner?.website}
            type=''
            active={!!active}
            actions={{website:true, chat:true, card:true}}
          />
      
      </div>

    ) : (
      <Sm title={owner.name} type={''} image={owner.logo} active={active}/>
    )}
    
    </>
  )
}
