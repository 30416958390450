import React, { useEffect, useState } from 'react'
import { BreadcrumbComp } from '../../components/shared/breadcrumb'
import { ACTIVATED_DOMAINS, MAIN_DOMAINS, UI_WIDTH_HIDE_SOME_ACTIONS, UI_WIDTH_IS_MOBILE, UI_WIDTH_PUBLICATIONS_SM } from '../../constants'
import UserActionsButton from '../../components/shared/bars/TopBar/userActionsButton'
import { useAppContext } from '../../context/AppContext'
import Icon from '../../components/icons/Icon'
import { ButtonLg } from '../../components/shared/Buttons/ButtonLg'
import { Dropdown } from 'antd'
import { CameraIcon, SearchIcon } from '@heroicons/react/solid'
import LocationgUiLayout from '../Qfs/components/locationLayout/Uilayout'
import Collapse, { Panel } from '../../components/shared/Collapse'
import { Highlight, iconType, VolumeSearch } from '../../types'
import Description from '../../components/shared/cards/WithWebsite/Description'
import useSearchDomains from './hooks/useSearchDomains'
import Footer from '../Network/components/Footer'
import DetailsTopBar from '../../components/shared/bars/ResourcesBar/DetailsPanel'
import ResourcesTopBar from '../../components/shared/bars/ResourcesBar/ResourcesPanel'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import SwitchLanguages from '../../components/shared/bars/TopBar/SwitchLanguages'

type Props = {
    active :boolean,
    icon:iconType,
    name:string,
    description:string,
    onClick:() => void,
    link?:JSX.Element,
    width?:{
        description?:string,
        picture?:string
    },
    maxLinesName?:string,
    horizontale?:boolean,
    maxLinesDescription?:string
}
export const btnClassName = (active:boolean) => `p-1 rounded bg-hover-transparent !text-inherit h-max  -ml-1 w-max flex items-center space-x-2 opacity-10 group-hover:opacity-100 ${active && 'opacity-100' } px-1`

export const CardComponent = ({active, icon, name, description, onClick, link, width, maxLinesName, horizontale, maxLinesDescription}:Props) => {
    return(
        <div className={` ${active ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'} pb-1 rounded ${!horizontale ? 'flex space-x-1' : ''}  w-max  cursor-pointer pr-2 group `} onClick={onClick}>
            <div className={`${active && 'bg-skin-fill-inverted text-skin-inverted'} h-max relative show-icon w-max rounded-[3px] overflow-hidden`}>
      
                <div>
                    <div className={`relative w-full p-[13px] h-max `}>
              
                        {icon && (
                            <div className={`relative   ${width?.picture ?? '!h-[100px] !w-[150px]'} rounded overflow-hidden max-[580px]:!w-[80px] max-[580px]:!h-[80px]`}>
                                {icon.type === 'image' && icon.src ? (
                                    <div className='border border-main p-1 bg-white rounded h-full'>
                                        <div className='border border-main rounded overflow-hidden h-full'>
                                            <img src={icon.src} className='w-full h-full object-cover ' alt="" />

                                        </div>

                                    </div>

                                ):(
                                    <div className={`w-full h-full border border-main rounded flex justify-center items-center ${active && 'bg-white'} group-hover:bg-white`}>
                                        <CameraIcon className={`w-[35px] h-[35px] ${active ? 'opacity-60' : 'opacity-[0.05]'}  `} />
                                    </div>
                                )}
                            </div>

                        )}
          
                    </div>
                </div>
            </div>
                
             <div className={`mt-1.5 ${horizontale ? 'mx-3 mt-0' : ''}`}>
                    <Description
                    title={name}
                    maxLinesName={maxLinesName}
                    maxLines={maxLinesDescription??'ellipsis-3'}
                    description={description}
                    width={width?.description}
                    type=''
                    active={!!active}
                    actions={{website:false, chat:false, card:false}}
                    />
                    {link ? (
                        link

                    ): (
                        <></>
                    )}
                        

             </div>
        
                
              
              </div> 
    )
}
export default function HomeLayout
() {
    const { winSize } = useAppContext()
    const {domains} = useSearchDomains({filterBy: `id:=[${ACTIVATED_DOMAINS}]`, query: '*'})
    // const {domains} = useSearchDomains({filterBy: `id:=[${ACTIVATED_DOMAINS}]`, query: '*'})
    // const {domains} = useSearchDomains({filterBy: `popular:true`, query: '*'})
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const { t } = useTranslation(['common', 'home']);
    const [mainDomains, setmainDomains] = useState<{document:VolumeSearch, highlight: Highlight}[]>([]);
    const [subdomains, setsubdomains] = useState<{document:VolumeSearch, highlight: Highlight}[]>([]);


    useEffect(() => {
        
        if(domains){
            setmainDomains(domains.filter(doc => MAIN_DOMAINS.includes(doc.document.id)))
             setsubdomains(domains.filter(doc => !MAIN_DOMAINS.includes(doc.document.id)))

        }
    }, [domains]);

//   if (!ready) return <p>Loading...</p>;
//     console.log(t('search'), i18n.language)

    const handleNavigation = (path:string) => {
        navigate(`/home/${i18n.language}/${path}`)
    }
  return (
    <div>
        <div className='bar-h border-b border-main'>
            <div className='flex justify-between items-center w-full pr-7 pl-[20px] h-full '>
                <div className={` flex-1 items-center flex space-x-3`}>
                    <div className="flex items-center space-x-2 flex-1 ">
                   
                        <BreadcrumbComp
                            className='flex-1'
                            routes={
                        
                                        [
                                            {
                                                icon: {type: 'logo', src: 'https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logo.svg?alt=media&token=c5969d32-3d07-44f3-8117-1c5fdb7473ec'},
                                                name: 'Qorddu',
                                                id: 'qorddu',
                                            },
                                        
                                        ]
                                    }
                            
                        
                        />

                    </div>

                
                        
                                
            
                </div>
            <div className='flex items-center space-x-5'>
                <SwitchLanguages/>
                {winSize.w > UI_WIDTH_HIDE_SOME_ACTIONS && <UserActionsButton/>}

            </div>
            </div>       
        </div>
       
        <div>
            <div className='flex justify-between items-center w-full px-5 bar-h border-b border-main border-r'>
                <div className={` flex-1 items-center flex space-x-3`}>
                    
                
                    <div className="flex items-center rounded space-x-2">
                        <button className='p-1 rounded bg-hover-transparent'>
                            <Icon className='icon-sm' name='HomeIcon' />
                        </button>
                        {winSize.w > UI_WIDTH_IS_MOBILE && (
                            <div className='h-[15px] w-[1px] bg-gray-300'></div>

                        )}
                        <div className=' px-1 flex items-center ' id='tutorial-search'>
                            <SearchIcon className='icon-mini'/>
                            
                                <input 
                                type="text" 
                                className='bg-input border-none roundedw-full placeholder:font-bold w-[220px] max-[1150px]:w-[170px]' 
                                placeholder={`${t('search')}`} 
                                
                                />

                        </div>
                        <div className='h-[15px] w-[1px] bg-gray-300'></div>
                        <Dropdown
                            overlay={<></>}
                            >
                                <button>
                                    <ButtonLg
                                    
                                        suffix={{
                                            icon:{type:'icon', src: 'ChevronDownIcon'},
                                            size: 'mini',
                                        
                                        }}
                                        prefix={{
                                            icon: {type:'icon', src: 'FilterIcon'},
                                            size: 'sm'
                                        }}
                                        buttonProps={{
                                            disabled:true
                                        }}
                                        width='w-[80px]'
                                    >
                                        <>
                                    </>
                                    </ButtonLg>

                                </button>
                            

                            </Dropdown>
                            {winSize.w > UI_WIDTH_HIDE_SOME_ACTIONS && (
                                <>
                                    <div className='h-[15px] w-[1px] bg-gray-300 '></div>
                                    <Dropdown
                                        overlay={<></>  }
                                        trigger={['click']}
                                        disabled={true}
                                        >
                                                <button>
                                                    <ButtonLg
                                                        suffix={{
                                                            icon:{type:'icon', src: 'ChevronDownIcon' },
                                                            size: 'mini'
                                                        }}
                                                        buttonProps={{
                                                            disabled: true
                                                        }}
                                                        prefix={{
                                                            icon:{type:'icon', src:'Degroup' },
                                                            size: 'sm'
                                                        }}
                                                
                                                        width='w-[80px]'
                                                    >
                                                        <>
                                                        </>
                                                        </ButtonLg>
                    
                                                </button>
                                                                                            
                                            
                                        </Dropdown>
                                
                                </>
                            )}

                    </div>
                        
                                
            
                </div>
        
            </div>      
        </div>
        <div className='flex  items-center bg-bars bar-h border-b border-main pl-[11px]'>
            <div className='flex-1 flex flex-col'>
                <ResourcesTopBar isPublication={false} author={false} date={false}/>
            
            </div>
            {winSize.w > UI_WIDTH_PUBLICATIONS_SM && (
            <div className='w-[33%] items-center flex justify-between'>
                <DetailsTopBar/>
                    

                
            
            </div>

            )}

     
        </div>
        <LocationgUiLayout loading={false}>
            <div className='border-r border-main'>
    
                <div className="pl-[20px] pt-[14px] overflow-auto h-[calc(100vh-180px)] space-y-[60px]" >
                    <Collapse defaultActiveKey={['about','network']} className='' >
                    <>
                    
                        <Panel
                            header={<p className='text-groupe'>{t('home:panels.title1')}</p>}
                            key={'about'}
                        >
                            <div className='mb-[40px] max-[580px]:mb-[20px] ml-[20px] mt-[10px]'>
                            <div className="flex flex-wrap ml-[-7px] gap-x-10 gap-y-5">
                                    <CardComponent
                                        name={t('home:qorddu.title')}
                                        description={t('home:qorddu.description')}
                                        icon={{type:'image', src:''}}
                                        active={location.pathname.includes('/qorddu')}
                                        onClick={() => handleNavigation(`qorddu`)}
                                        link={
                                            <button className={btnClassName(location.pathname.includes('/vision'))}  onClick={(e)=>{
                                                e.stopPropagation();
                                                navigate(`/home/${i18n.language}/pages/qorddu/vision`)
                                            }} >
                                            <Icon name='OpenWindow' className='icon-mini'/>
                                            <p>{t('open')}</p>
                                        </button>
                                        }
                                        // link={<button onClick={(e) => {e.stopPropagation(); handleNavigation(`pages/qorddu`)}} className={btnClassName(location.pathname.includes('/qorddu'))}>{t('open')}</button>}
                                    />
                                      {/* <CardComponent
                                        name={t('home:video.title')}
                                        description={t('home:video.description')}
                                        icon={{type:'image', src:''}}
                                        active={location.pathname.includes('/video')}
                                        onClick={() => handleNavigation('video')}
                                    /> */}

                                    <CardComponent
                                        name={t('home:forbusiness.title')}
                                        description={t('home:forbusiness.description')}
                                        icon={{type:'image', src:''}}
                                        active={location.pathname.includes('/business')}
                                        onClick={() => handleNavigation('business')}
                                    />
                                    <CardComponent
                                        name={t('home:forcommunities.title')}
                                        description={t('home:forcommunities.description')}
                                        icon={{type:'image', src:''}}
                                        active={location.pathname.includes('/communities')}
                                        onClick={() => handleNavigation('communities')}
                                    />
                                </div>
                               
                             

                            </div>

                        </Panel>
                        <Panel
                            header={<p className='text-groupe'>{t('home:panels.title2')}</p>}
                            key={'new'}
                        >
                            <div className='mb-[40px] max-[580px]:mb-[20px] ml-[22px] mt-[10px]  '>
                                <div className=' '>
                                   <p className='italic'>{t('home:no-new')}</p>
                                </div>

                            </div>
                        </Panel>
                        <Panel
                            header={<p className='text-groupe'>{t('home:panels.title3')}</p>}
                            key={'network'}
                        >
                            <div className='mb-[40px] max-[580px]:mb-[20px] ml-[20px] mt-[10px]  '>
                                <div className='ml-[-7px] space-y-5'>
                                    <div className="flex flex-wrap gap-x-10 gap-y-5">

                                     {mainDomains && mainDomains.map(d => (
                                            <CardComponent
                                                maxLinesName='ellipsis-1'
                                                name={(d.document.title as any)[i18n.language] ?? d.document.title.en}
                                                description={d.document.description ?(d.document.description as any)[i18n.language] ?? d.document.description.en : ''}
                                                icon={{type:'image', src:`${d.document.image}`}}
                                                active={params.domainId === d.document.id}
                                                onClick={() => handleNavigation(`domains/${d.document.id}`)}
                                                key={d.document.id}
                                                link={
                                                // <a className={btnClassName(params.domainId === d.document.id)} href={`https://${d.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">{t('open')}</a>
                                                <a className={btnClassName(params.domainId === d.document.id)} href={`https://${d.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">
                                                <Icon name='OpenWindow' className='icon-mini'/>
                                                <p>{t('open')}</p>
                                            </a>
                                            }
                                            />

                                        ))}
                                    </div>
                                  
                                    <div className="flex flex-wrap gap-x-10 gap-y-5">
                                        {subdomains && subdomains.map(d => (
                                            <CardComponent
                                                maxLinesName='ellipsis-1'
                                                name={(d.document.title as any)[i18n.language] ?? d.document.title.en}
                                                description={d.document.description ?(d.document.description as any)[i18n.language] ?? d.document.description.en : ''}
                                                icon={{type:'image', src:`${d.document.image}`}}
                                                active={params.domainId === d.document.id}
                                                onClick={() => handleNavigation(`domains/${d.document.id}`)}
                                                key={d.document.id}
                                                link={
                                                // <a className={btnClassName(params.domainId === d.document.id)} href={`https://${d.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">{t('open')}</a>
                                                <a className={btnClassName(params.domainId === d.document.id)} href={`https://${d.document.title.en.toLocaleLowerCase().split(' ').join('-')}.qorddu.com`} target='_blank' rel="noopener noreferrer">
                                                <Icon name='OpenWindow' className='icon-mini'/>
                                                <p>{t('open')}</p>
                                            </a>
                                            }
                                            />

                                        ))}
                                    
                                    
                                    </div>

                                </div>
                                

                            </div>

                        </Panel>


                
                        
                        
                    
                    </>
                    
                    
                    </Collapse>

            

                
        
                </div>
            </div>
        </LocationgUiLayout>
        <Footer withoutDomains/>
    </div>
  )
}
