import { useNavigate } from "react-router-dom";
import Icon from "../../../../components/icons/Icon";
import Collapse, { Panel } from "../../../../components/shared/Collapse";
import ObjectMd from "../../../../components/shared/object/objectMd";
import { Dropdown } from "antd";
import VolumesDropdown from "../../components/dropdowns/VolumesDropdown";
import OneLine from "../../../../components/shared/object/oneLine";
import UserVolumesDropdown from "../../components/dropdowns/UserVolumesDropdown";
import { useMydeskContext } from "../../context/MydeskContext";
import { useEffect, useState } from "react";
import { volumeCodes } from "../../constants";
import { storageUrl } from "../../../../services/api/constants";
import { Server } from "../../../../types";
import { fetchInt } from "../../../../utils/api/fetchInt";
import { getUserVolumes } from "../../../../services/api/request";
import ReactSvg from "../../../../components/shared/ReactSvg";
import MenuLayout from "../../../../components/shared/menu/menuLayout";
import { userCredits } from "../../../../services/api/request/user";



export default function MydeskHome(){
    const navigate = useNavigate()
    const { userVolumes } = useMydeskContext()
    const [credits, setcredits] = useState({directories: 0, drives:0, folders:0, publications:0});
    
    

    useEffect(() => {
        
        fetchInt(userCredits).then(res => res.json()).then(data => {
            setcredits(data.data)
        }).catch(err => {
            console.log(err)
        })
    }, []);

    return (                                                 
      
        <div className="overflow-auto pt-5 ">
            <div className="w-[65%]">
                <Collapse defaultActiveKey={['1', '2']}>
                    <>
                        <Panel
                            key={'1'}
                            header={
                                <div className="w-full flex space-x-3">
                                    <p className="text-group">My volumes</p>
                                    {(credits.drives > 0 || credits.directories >0 || credits.folders>0) && (
                                        <div className="flex ">
                                            <span>(</span>
                                            {credits.drives > 0 && <p>{credits.drives} Drive</p>}
                                            {credits.directories > 0 && <p>{credits.directories} Directory</p>}
                                            {credits.folders > 0 && <p>{credits.folders} Folder</p>}
                                            <span>)</span>

                                        </div>

                                    )}

                                </div>
                            }
                        >
                            <div className="pl-2 flex flex-wrap gap-3">
                                {/* <Dropdown
                                    overlay={
                                        <div className="relative -top-[40px] left-[40px]">
                                                <VolumesDropdown
                                                    volumes={volumes}
                                                    handleSelect={(value:string) => navigate(`/mydesk/volumes/create/${value}/edit`)}
                                                />
                                            
                                        </div>
                                    }
                                >
                                    <button>
                                        <ObjectMd
                                        iconSize="icon-lg"
                                            title="Create a new volume"
                                            icon={<Icon className="icon-lg" name="AddLocation" />}
                                            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, ab ea. Deserunt corporis sunt incidunt aperiam iure sint consectetur"
                                            onSelect={() => {}}

                                        />
                                        
                                    </button>

                                </Dropdown> */}
                                {userVolumes && userVolumes.length > 0 ? userVolumes.map(volum => (
                                    <Dropdown
                                    overlay={
                                        <div className="relative -top-[40px] left-[40px]">
                                            <MenuLayout>
                                                <>
                                                
                                                    <button className="bg-hover-transparent px-4 py-2 w-full block text-start">
                                                        Open volume
                                                    </button>
                                                    <button className="bg-hover-transparent px-4 py-2 w-full block text-start"
                                                    onClick={() => navigate(`/mydesk/volumes/${volum.coll}/${volum.id}/edit`)}>
                                                        Edit volume
                                                    </button>
                                                </>
                                            </MenuLayout>
                                        </div>
                                    }
                                >
                                    <button>
                                        <ObjectMd
                                        iconSize="icon-lg"
                                        title={volum.title}
                                        icon={<ReactSvg className="icon-lg" src={volum.iconUrl} />}
                                        description={volum.description??''}
                                        onSelect={() => {}}

                                        />

                                    </button>
                                </Dropdown>
                                    
                                )) : (
                                    <div>
                                        <p className="italic font-semibold pl-4">You didn't add any volumes yet</p>
                                    </div>
                                )
                                }
                               
                                    

                            </div>
                        </Panel>
                        <Panel
                            key={'2'}
                            header={
                                <div className="w-full flex space-x-3">
                                    <p className="text-group">My publications</p>
                                    <div>

                                    {credits.publications > 0 && <p>({credits.publications} Publications)</p>}
                                    </div>
                                </div>
                            }
                        >
                            {/* <div className="pl-2">
                                <Dropdown
                                    overlay={
                                        <div className="relative -top-[10px] left-[30px]">
                                                <UserVolumesDropdown
                                                    userVolumes={userVolumes}
                                                    handleSelect={(coll:string, id:string) => navigate(`/mydesk/${coll}/${id}/publications/create/edit`)}
                                                />
                                            
                                        </div>
                                    }
                                >
                                    <button>
                                        <ObjectMd
                                        iconSize="icon-lg"
                                            title="Create a new publication"
                                            icon={<Icon className="icon-lg" name="AddDocument" />}
                                            description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, ab ea. Deserunt corporis sunt incidunt aperiam iure sint consectetur"
                                            onSelect={() => {}}

                                        />
                                        
                                    </button>

                                </Dropdown>

                            </div> */}
                            <div className="pl-2">
                                <p className="italic font-semibold pl-4">You didn't add any publication yet</p>
                            </div>
                        </Panel>
                    </>
                </Collapse>

            </div>
        </div>

    )
} 