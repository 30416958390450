import React from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Login from '../login/pages/Login'
import SwitchLanguages from '../../components/shared/bars/TopBar/SwitchLanguages'

export default function ConnectLayout() {
    const { t } = useTranslation(['connect'])
  return (
    
     <div className={`space-between-main-blocks3 overflow-auto`} >
        <div className='space-y-7 h-full'>
            <div className="flex space-between-2cols items-center h-full justify-center pl-[220px] max-[1400px]:pl-[120px] max-[1200px]:pl-[70px] max-[1150px]:px-7 overflow-x-hidden">
                <div className='flex-1'>
                    <div className=' ' >
                        <div className=' '>
                            <div className='flex space-x-[50px] items-end absolute top-[20px] max-[1150px]:w-full max-[1150px]:justify-center left-[220px] max-[1400px]:left-[120px] max-[1200px]:left-[70px] max-[1150px]:left-0'>
                                <img className='w-auto h-[79px] max-[450px]:h-[60px]' src='https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logoq_black.svg?alt=media&token=14900b17-78f4-4977-9105-c8397ab84b02'/>
                                <SwitchLanguages/>
                            </div>
                            <div className='gap-8 flex max-[1150px]:flex-col max-[1150px]:items-center'>
                                <div className='w-[250px] max-[1150px]:w-[300px] max-[400px]:w-[90%]'>
                                    <p className="title-level2">{t('connect:title')}</p>
                                    <p className='text-level2'>
                                    {t('connect:description')}
 
                                    </p>

                                </div>
                                <div className="flex-1 w-full max-w-[350px] max-[1150px]:w-[300px] max-[400px]:w-[90%]">
                                    <Login/>

                                </div>
                            </div>
                            
                        


                        </div>
     

                    </div>
                    
                </div>
              
                <div className="w-[700px] max-[1450px]:w-[600px] max-[1300px]:w-[550px] h-screen max-[1150px]:w-0">
                 
                    <div className='absolute max-[1150px]:hidden top-[-99px] max-[1600px]:right-[-150px] right-[-214px] h-[calc(100%+140px)] w-[50%] max-[1200px]:right-[-44px]'>
                            <div className="bg-skin-fill-muted h-full w-full -rotate-[8deg]" style={{background: 'url(../cyber-security.png)', backgroundRepeat: 'no-repeat',backgroundPosition: '-3px -133px' }}></div>                   
                        </div>
                </div>
            </div>
         
            

        </div>
            <Outlet/>            
                          
    </div>
  )
}
