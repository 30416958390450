import { CameraIcon, CheckIcon } from '@heroicons/react/solid'
import React from 'react'
import DetailsPageLayout from '../../components/DetailsPageLayout'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

export default function Communities() {
    const {t} = useTranslation(['common', 'home'])
  return (
    <DetailsPageLayout>
        <div className='h-[calc(100vh-180px)] overflow-y-auto flex justify-center'>
        <div className='max-w-[330px] px-4 pt-14 space-y-5'>
            <div>
                <div className="w-[180px] h-[130px] border border-main rounded flex justify-center items-center">
                    <CameraIcon className='w-[50px] h-[50px] opacity-10 ' />
                </div>
            </div>
            <div className='space-y-4'>
                <p className="font-bold">
                    {t('home:forcommunities.title')}
                </p>
                <p>
                {t('home:forcommunities.block.p1')}  
                </p>
                <p>
                {t('home:forcommunities.block.p2')}  
                </p>
        
                <button className='bg-gray-300 px-7 py-2 rounded opacity-20' >{t('coming-soon')}  </button>

            </div>
        </div>
        <Outlet/>
        </div>

    </DetailsPageLayout>
  )
}
