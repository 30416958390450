import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import i18n from '../../../../../../../i18n'
import DetailsLayout from '../../../../../../home/components/DetailsLayout'
import Check from './Check'

export default function OfferLayout() {
    const params = useParams()
    const {t} = useTranslation()
    const location = useLocation()
     const [currentPage, setcurrentPage] = useState<{title:string, isHome:boolean}>({title:'', isHome:true});
      
    
    useEffect(() => {
        
        if(location.pathname.includes('newspapers')){
            setcurrentPage({title: t('server:cards.why.title'), isHome:false})
        }else if(location.pathname.includes('qorddu')){
            setcurrentPage({title: t('server:cards.qorddu.title'), isHome:false})
        }else if(location.pathname.includes('demo')){
            setcurrentPage({title: t('server:cards.demo.title'), isHome:false})
        }else if(location.pathname.includes('reserve')){
            setcurrentPage({title: t('server:cards.reserve.title'), isHome:false})
        }else{
            setcurrentPage({title: '', isHome:true})
        }
    }, [location.pathname]);
    const backLink = `/${i18n.language}/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}/activation${!currentPage.isHome ? '/pages' : ''}` 
  return (
    <Check>

    <DetailsLayout title={currentPage.title} backLink={backLink}>

       <Outlet />

    </DetailsLayout>

    </Check>
  )
}
