import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import useFetchVolum from '../../../../hooks/useFetchVolum'
import { useSearchContext } from '../../context/searchContext'
import { useUIContext } from '../../context/UIContext'
import { useQfsContext } from '../../context/qfsContext'
import { useAppContext } from '../../../../context/AppContext'
import { getExtraPath } from '../../../../utils/utils'
import i18n from '../../../../i18n'
import { storageUrl } from '../../../../services/api/constants'

type Props = {
    children:JSX.Element,
    selectedKey:string,
    disabled:{filter:boolean, display:boolean, new:boolean,query:boolean},
    publicationBar:boolean,
    collection:string,
    id:string
}
export default function LocationgGlobalLayout(props : Props) {
    const { clearSearch } = useSearchContext()
    const { setdisable } = useUIContext()
    const { setCurrentLocation, currentLocation } = useQfsContext()
    const {domain} = useAppContext()
    
    const { volume} = useFetchVolum(props.id, props.collection, domain?.id)
    useEffect(() => {
      clearSearch()
      // setselectedKey(props.selectedKey)
      
      setdisable({query:props.disabled.query, filter:props.disabled.filter, display:props.disabled.display, publicationBar:!props.publicationBar})
    }, [props.selectedKey])

    useEffect(() => {
      if(volume){
        const clusterId = volume.routes?.filter((v:any) => v.depth ===2)?.[0]?.id
        let routes

        if(volume.routes){
          routes = volume.routes.map((route:any) => {
            if(route.depth>2){
              route.path = `/${clusterId}${route.path}`
              
            }
            route.path = `/${i18n.language}${route.path}`
            return route
          })
         
        }
        const extraPath = getExtraPath(props.collection)
        setCurrentLocation({...volume, clusterId: clusterId??null, isDomain: volume.id === domain?.id, routes: routes??volume.routes, title: clusterId === volume.id ? `${domain?.title} - ${volume.title}` : volume.title , coll: props.collection, path:volume.path+extraPath, iconUrl: `${storageUrl}${volume?.icon?.src}` })

      }
    }, [volume?.id]);
    if(currentLocation?.id !== props.id){
      return (
        <>
        <Spin spinning={true}></Spin>
        <div className='absolute bottom-[2px] w-full left-0 bg-black bar-h-mini text-white'>

          </div>
        
        </>

      )
    }
  return (
      props.children

  )
}
