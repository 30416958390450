import { apiEndPoint } from "../constants"

export const getAuthUser = `${apiEndPoint}/auth/user`
export const getUserSubscriptions = (isCurrent?:boolean) =>`${apiEndPoint}/subscriptions/my-subscriptions${isCurrent ? '?isCurrent=true' : ''}`
export const getUserVolumes = `${apiEndPoint}/auth/user/volumes`
export const updateUser = `${apiEndPoint}/auth/user/update`
export const createDedicatedVolume = ({ parentColl, parentId, collection }:{parentColl:string, parentId:string, collection:string}) =>`${apiEndPoint}/volums/specializeds/${parentColl}/${parentId}/${collection}/user`

export const getDatacenterLocalServers = ({ id}: {id:string}) => `${apiEndPoint}/volum/specializeds/${id}/servers`


export const getDatacenterPartners = ({ id}: {id:string}) => `${apiEndPoint}/volum/specializeds/datacenters/${id}/partners/get?grouped=true`

export const getVolumWithPartners = ({ id, coll}: {id:string, coll:string}) => `${apiEndPoint}/volum/specializeds/${coll}/${id}`



export const getDirectoryDetails = ({ id, orgId }: {id:string, orgId: string|undefined}) => `${apiEndPoint}/volum/specializeds/${id}/details?orgId=${orgId}`

export const isPinned = ({id} : {id:string}) => `${apiEndPoint}/pinned/${id}/check`

export const getPinneResources = ({type}:{type:string}) => `${apiEndPoint}/pinned/${type}`
export const pinResource = ({type}:{type:string}) => `${apiEndPoint}/pinned/${type}`
export const unpinResource = ({type, id}:{type:string, id:string}) => `${apiEndPoint}/pinned/${type}/${id}`

export const getGeographicTree = `${apiEndPoint}/volums/geographics`
export const getGeographicServers = ({countryId} : {countryId:string}) => `${apiEndPoint}/volums/geographics/${countryId}`


export const getAllDomains = `${apiEndPoint}/volums/specializeds/univers/nodes/childrens`
export const getDomains = `${apiEndPoint}/volums/specializeds/univers/childrens`
export const getNodeDomains = ({univerId, nodeId, featuredDomain}:{univerId:string, nodeId:string, featuredDomain:string}) => `${apiEndPoint}/volums/specializeds/${univerId}/${nodeId}/${featuredDomain}/domains`
export const getTree = ({id, geo} : {id:string, geo?:boolean}) => `${apiEndPoint}/volums/specializeds/domains/${id}/childrens${geo ? '?geograohic=true' : ''}`
export const getClusterTree = ({id, domainId} : {id:string, domainId:string}) => `${apiEndPoint}/volums/specializeds/clusters/${id}/childrens${domainId ? '?domainId=' + domainId : ''}`
export const getDomainCollaborators = ({domainId}:{domainId:string}) => `${apiEndPoint}/collaborators/${domainId}`
export const getDatacenterCollaborators = ({datacenterId}:{datacenterId:string}) => `${apiEndPoint}/collaborators/${datacenterId}/datacenters`

export const getVolumCollaborators = ({volumId, volumColl}:{volumId:string, volumColl:string}) => `${apiEndPoint}/collaborators/${volumId}/${volumColl}/collaborators/${volumColl === 'Datacenters' ? '?datacenter=true' : ''}`
export const getVolumOwner = ({volumId, volumColl}:{volumId:string, volumColl:string}) => `${apiEndPoint}/collaborators/${volumId}/${volumColl}/owner`
export const getGuestPartner = ({volumId}:{volumId:string}) => `${apiEndPoint}/collaborators/${volumId}/partners`

export const getVolum = ({volumId, volumColl, domainId}:{volumId:string, volumColl:string, domainId?:string}) => `${apiEndPoint}/volums/specializeds/${volumColl}/${volumId}${domainId ? `?domainId=${domainId}` : ''}`
export const getDomain = ({subdomain}:{subdomain:string}) => `${apiEndPoint}/volums/specializeds/${subdomain}/domain`
export const getVolumChilds = ({volumId, volumColl, clusterId}:{volumId:string, volumColl:string, clusterId?:string}) => `${apiEndPoint}/volums/specializeds/${volumColl}/${volumId}/volumes${clusterId ? `?clusterId=${clusterId}` : ''}`
export const getServerTree = ({id, clusterId, limit}:{id:string, clusterId:string, limit?:number}) => `${apiEndPoint}/volums/specializeds/clusters/${clusterId}/${id}/childrens${limit ? `?limit=${limit}` : ''}`


export const login = `${apiEndPoint}/auth/create-session`
export const logout = `${apiEndPoint}/auth/logout`
export const checkSession = `${apiEndPoint}/auth/check-session`