import { useTranslation } from 'react-i18next'
import { ButtonSm } from '../../../../components/shared/Buttons/Small'
import Input from '../../../../components/shared/input'
import { useAppContext } from '../../../../context/AppContext'
import { UI_WIDTH_SIDEBAR_SM } from '../../../../constants'
import Icon from '../../../../components/icons/Icon'
import { useUIContext } from '../../context/UIContext'


type Headerprops = {
    mode: 'search' | 'globalTree' | 'localTree',
    setmode: any,
    search: {
      value:string,
      setValue:(value:string) => void
    }

}
export default function Header({mode, setmode, search}: Headerprops) {
  const {t} = useTranslation()
  const {winSize} = useAppContext()
  const { hidePanel, setHidePanel} = useUIContext()
  return (
    <div className='border-b border-main  bar-h pl-[32px] '>
        <div className="flex items-end h-full justify-between">
          <div className='flex items-center -ml-1.5 space-x-1'>
            
            <ButtonSm icon='LocalTree' iconSize='sm' onClick={() => setmode('localTree')} active={mode === 'localTree'} />
              <div className={ `relative ${mode === 'search' && 'search-mode'}`}>
              <Input
                  className={`w-full relative input-h rounded !border-none ${mode === 'search' && 'active-mode-mini'}`}
                  value={search.value}
                  onChange={e => search.setValue(e.target.value)}
                  placeholder={t('search-a-server')}
                  onFocus={() => setmode('search')}
              />

              </div>


          </div>
            {winSize.w < UI_WIDTH_SIDEBAR_SM && (
              <button className={`p-1.5 bg-hover-transparent rounded no-dragable disabled:opacity-20 transition-all ${hidePanel ? ' rotate-180' :''}`} onClick={() => setHidePanel(!hidePanel)} disabled={false}>
                  <Icon name='PanelOpen' className='icon-sm'/>
                </button>
            )}

        </div>

    </div>
  )
}
