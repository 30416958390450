import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import SingleServerLine from '../../SingleServerLine';
import { Server } from '../../../../../types';
import { getTree } from '../../../../../services/api/request';
import { useAppContext } from '../../../../../context/AppContext';
import { fetchInt } from '../../../../../utils/api/fetchInt';

type Props = {
   selectedNetworkId:string,
   selectedClusterId:string,
   onSelectCluster:(cluster:Server) => void
}
export default function Clusters({selectedNetworkId, onSelectCluster, selectedClusterId}:Props) {
    const [servers, setservers] = useState<Server[]>([]);
    const [loading, setloading] = useState(false);
   
    useEffect(() => {
            setloading(true)
          
            fetchInt(getTree({id:selectedNetworkId!})).then(res =>res.json()).then(data =>  setservers(data.data)).finally(() => setloading(false))
           
        
      }, [selectedNetworkId])
   
  return (
    <div className=''>
        <Spin spinning={loading}>
            <div className='overflow-auto height-publishin pl-3 space-y-7'>
                    {servers?.map(serevr => (
                        <div key={serevr.id} className='space-y-2'>
                            <p className='text-group'>{serevr.title}</p>
                            <div className="-ml-2">
                                {serevr.children?.map(server => (
                                    <SingleServerLine  item={server} key={server.id} active={server.id === selectedClusterId} clickFn={() => onSelectCluster(server)}/>
                                ))}
                            </div>
                        </div>
                    ))}

            </div>
        </Spin>
        <div className="col-span-2">
            <Outlet/>
        </div>
    </div>
  )
}
