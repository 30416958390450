
import CollaboratorDeviceLayout from '../../../../components/shared/details/Collaborator/deviceLayout'
import DetailsActionsComp from '../../../../components/shared/bars/TopBar/actionsBar/actions/details'
import { useUIContext } from '../../context/UIContext'


export default function OwnerDetailsUrl() {

 const { mode, setObjectDisplayMode } = useUIContext()
  
  return (
    <>
      <CollaboratorDeviceLayout
        collaboratorLayout={{mode}}
        header={
          <div className='flex justify-end'>
            <DetailsActionsComp
              setObjectDisplayMode={setObjectDisplayMode}
              mode={mode}
              recheckPin={0}
  
            
            />

          </div>
        }
      />
    </>
  )
}
