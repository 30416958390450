import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MydeskContextProvider } from './context/MydeskContext'
import MydeskLayout from './Layout'
import MydeskHome from './pages/home'

import Settings from './pages/account/Settings'
import CreateVolumeLayout from './pages/create/volume/layout'
import EditVolume from './pages/create/volume'
import { VolumeCreationContextProvider } from './context/volumeCreationContext'
import PublishVolume from './pages/create/volume/publish'

import { PublicationCreationContextProvider } from './context/PublicationCreationContext'
import CreatePublicationLayout from './pages/create/publications/layout'
import EditPublication from './pages/create/publications'
import PublishPublication from './pages/create/publications/publish'
import ChooseVolume from './pages/create/publications/chooseVolume'


export default function MydeskNavigation() {
 
  return (
    <MydeskContextProvider>
      <Routes>
        {/* <Route element={<PrivateRoute />}> */}
          <Route element={<MydeskLayout/>}>
              <Route path='/account/settings' element={<Settings/>}/>



              
              

              <Route element={<MydeskHome/>} path='/'/>
              <Route path='/publications' element={<ChooseVolume/>}/>

              <Route path='/*' element={<></>}></Route>

          </Route>
          <Route element={<VolumeCreationContextProvider><CreateVolumeLayout/></VolumeCreationContextProvider>}>
            <Route element={<EditVolume/>} path='/volumes/create/:type/edit'/>
            <Route element={<EditVolume/>} path='/volumes/:coll/:id/edit'/>

            <Route element={<PublishVolume/>}>
              <Route element={<></>} path='/volumes/create/:type/publish'/>
              
            </Route>
          </Route>

          <Route element={<PublicationCreationContextProvider><CreatePublicationLayout/></PublicationCreationContextProvider>}>
            <Route element={<EditPublication/>} path='/:coll/:id/publications/create/edit'/>
              <Route element={<PublishPublication/>}>
                <Route element={<></>} path='/:coll/:id/publications/create/publish'/>
             
              </Route>
          </Route>
        {/* </Route> */}

      </Routes>

    </MydeskContextProvider>
  )
}
