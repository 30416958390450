import { Dropdown } from 'antd'
import React, { useEffect, useState } from 'react'
import DomainNavigation from './domainNavigation'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

export default function Footer({withoutDomains} : {withoutDomains?: boolean}) {
    const [navigationOpened, setnavigationOpened] = useState(false);
    const [time, setTime] = useState(new Date());
    const {t} = useTranslation(['common'])
    const [date, setDate] = useState(new Date());
        
    useEffect(() => {
    const interval = setInterval(() => {
        setTime(new Date());
    }, 1000); 

    return () => clearInterval(interval);
    }, []);

    useEffect(() => {
    const updateDate = () => {
        setDate(new Date());
    };

    const now = new Date();
    const msUntilMidnight = 
        new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() - now.getTime();

    const timeout = setTimeout(() => {
        updateDate();

        setInterval(updateDate, 86400000);
    }, msUntilMidnight);

    return () => clearTimeout(timeout);
    
    }, []);
    const formattedTime = time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedDate = date.toLocaleDateString();
  return (
    <div>
         <div className="bar-h absolute bottom-0 left-0 w-full bg-sub-windows z-[2000] border-t border-main px-5 flex items-center">
            {withoutDomains ? <div></div> : (
                <Dropdown
                    trigger={['click']}
                    overlay={
                    <DomainNavigation/>
                    }
                    onVisibleChange={(visible) => setnavigationOpened(visible)}
                >
                    <button className={`flex items-center space-x-2 p-1 justify-center rounded  `}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/qorddu-staging.firebasestorage.app/o/logo.svg?alt=media&token=c5969d32-3d07-44f3-8117-1c5fdb7473ec" className='h-[28px] w-auto' alt="" />
                        {navigationOpened ? (
                            <ChevronDownIcon className='icon-mini'/>

                        ) : <ChevronUpIcon className='icon-mini'/>}
                        
                    </button>

                </Dropdown>

            )}
          <div className=" flex justify-end flex-1 px-3">
            <div className="flex text-xs space-x-4 justify-end items-center">
                <p className="cursor-not-allowed text-teddra-gray-200 only-pc max-[500px]:text-[10px]">{t('footer.copyright')}</p>
                <p className="cursor-not-allowed text-teddra-gray-200 only-pc max-[500px]:text-[10px]">{t('footer.confidentiality')}</p>
                <p className="cursor-not-allowed text-teddra-gray-200 only-pc max-[500px]:text-[10px]">{t('footer.cookies')}</p>
                <p className="cursor-not-allowed text-teddra-gray-200 only-pc max-[500px]:text-[10px]">{t('footer.privacyPolicy')}</p>
                <p className=' max-[500px]:hidden'>{formattedTime}</p><p className=' max-[500px]:hidden'>{formattedDate}</p>
                <div className="cursor-not-allowed text-teddra-gray-200 max-[500px]:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"></path></svg></div><div className="cursor-pointer max-[500px]:hidden"><svg xmlns="http://www.w3.org/2000/svg" fill="transparent" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"></path></svg></div></div></div>
        </div>
    </div>
  )
}
