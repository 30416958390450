import { Dropdown } from 'antd'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../../context/AuthContext';
import Icon from '../../../icons/Icon';
import { logout } from '../../../../services/api/request';
import { useTranslation } from 'react-i18next';


export default function UserActionsButton() {
    const [dropdownVisible, setdropdownVisible] = useState(false);
    const {user} = useAuthContext()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const logoutHandler = async () => {
        await fetch(logout, {
            method: "POST",
            headers: { 
                "Content-Type": "application/json", 
                "Access-Control-Allow-Credentials": "true", 
            },
            credentials: "include",
        });  
        navigate('/connect')   
    }
  return (
    <div>
          {user?.id ? (
            <Dropdown
                onVisibleChange={visible => setdropdownVisible(visible)}
                destroyPopupOnHide
                trigger={['click']}
                overlay={<div className='bg-sub-windows rounded shadow w-[150px] '>
                    <button disabled className='text-start opacity-50 !border-none w-full px-4 py-2 bg-hover-transparent block !text-inherit'>
                        {t('open-my-qorddu')}
                    </button>
                    <button disabled  className='text-start opacity-50 border-y !border-x-0 border-main w-full px-4 py-2 bg-hover-transparent block !text-inherit'>
                        {t('settings')}
                    </button>
                    <button className='block w-full text-start py-2 bg-hover-transparent px-4' onClick={logoutHandler}>
                        {t('logout')}
                    </button>
                </div>}
            >
                <button id='profile' className={`flex space-x-2 items-center cursor-pointer px-3 py-0.5 rounded no-dragable profile ${dropdownVisible ? 'bg-skin-fill-inverted-main text-skin-inverted' : 'bg-hover-transparent'}`} >
                    
                        
                    {user?.picture ? <div className='bg-white  w-[25px] h-[30px] rounded overflow-hidden border p-[1px] max-[400px]:hidden'>
                    <img className='w-full h-full object-cover rounded-[4px]' src={user?.picture}/> 
                        </div> : (
                            <div className='w-[25px] h-[30px] flex items-center justify-center max-[400px]:hidden' ><Icon name='UserIcon' className='w-full h-full '/></div>

                    )}
                        <p className='max-w-[100px] truncate text-[11px] font-light'>{user?.name}</p>

                </button>
            </Dropdown>
        ) : (
            <Link to="/connect" target="_blank" className='bg-skin-fill-inverted text-skin-inverted px-4 py-1.5 rounded'>
                {t('login')}
            </Link>

        )}
    </div>
  )
}
