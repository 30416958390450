
import React, { act, useEffect, useRef, useState } from 'react'



import { Form, Input, message, notification, Select } from 'antd';

import { useNavigate, useParams } from 'react-router-dom';

import { usePublicationCreationContext } from '../../../context/PublicationCreationContext';
import Header from './form/pageViewer/Header';
import PageViewer from './form/pageViewer';
import EditForm from './form/EditForm';
import UrlLists from './form/urlList';
import { Publication } from '../../../../../types';
import Visibility from './form/visibility';
import { create } from 'domain';

export default function EditPublication() {

    const [errors, seterrors] = useState<any>({})
    const [form] = Form.useForm()
    const { setEditData, setActive, setInitId, InitId } = usePublicationCreationContext()
    const navigate = useNavigate()
    const elRef = useRef<any>(null)
    const [url, seturl] = useState('')
    const [inputValue, setinputValue] = useState('')
    const [navigationActive, setnavigationActive] = useState({prev: false, next:false})
    const [mode, setmode] = useState<'new' | 'edit' | 'publish'>('new');
    const params = useParams()
    useEffect(() => {
            setActive({edit:true})
        
        }, []);

        useEffect(() => {
            
            if(params.publicationId){
                setmode('edit')
                setInitId(params.publicationId)
                form.setFieldValue('id', params.publicationId)
            }
        }, [params.publicationId]);
      
   
      
      const handleInputChange = (e:any) => {
       setinputValue(e.target.value)
      }
      const handleInputClick = (e:any) => {
       if(e.key === 'Enter'){
           seturl(inputValue)
           form.setFieldValue('url', inputValue)
       }
      }
   const handleNext = () => {
    navigate(`/mydesk/publications/create/publish`)
   }

//    const handleValuesChange = (changedValues:any,allValues:any) => {
//     const { title, keywords, description, url, publicationType} = allValues
//     setEditData(allValues)
//     seterrors((prev:any) => {
//         const state = {...prev}
//         state[Object.keys(changedValues)[0]] = undefined
//         return state
//     })
//     if(title && keywords && description && url && publicationType){
//         seterrors({})
//         setActive({publish:true})
//     }else{
//         setActive({publish:false})
//     }
//    }

   const handlePageSelection = (page:Publication) => {
    setInitId(page.id)
    setmode('publish')
    if(page.title){
        const data = {
            title: page.title[page.local] ??'',
            description: page.description?.[page.local]??'',
            keywords: page.keywords?.[page.local]??[],
            url: page.url,
            local: page.local,
            publicationTypeId:null,
            id:page.id
        }
        form.setFieldsValue(data)
        setinputValue(page.url)
        seturl(page.url)
        
    }else{
        seturl(page.url)
        setinputValue(page.url)
        form.setFieldsValue({url:page.url, id:page.id})
        
    }
   }
   const handleFormSubmit = async (event:any) => {
  
    if(event.nativeEvent.submitter.name === 'submit'){
        if(mode === 'new'){
            
        }else{
            Update(mode)
        }
    }
    if(event.nativeEvent.submitter.name === 'draft'){
        handleDraft()
    }
   }
   const Update = async (status:string) => {
    if(!InitId){
      message.error('Something went very wrong!')
      return
    }
    try{
        await form.validateFields()
    }catch(err){
        console.log(err)
    }
  
    console.log(form.getFieldsValue())
    const {local, title, keywords, description, url, publicationTypeId, id} = form.getFieldsValue()
    const insertedData = {
      title: {
        [local]: title
      },
      keywords: {
        [local]: keywords
      },
      publicationTypeId,
      description : {
        [local]: description
      },
      url: url,
      local:local,
      status
    }
    console.log('submit')
      // setloading(true)
      // const req = await fetchInt(updatePublication(InitId), {
      //   method:'PATCH',
      //   body: JSON.stringify(insertedData),
      //   headers: {
      //       'Accept': 'application/json',
      //       'Content-Type': 'application/json'
      //   },
      // }).finally(() => setloading(false))
      // const res = await req.json()
      // if(res.success){
      //   notification.success({message:'Publication updated succussfully'})
      //   navigate('/publish/published')
      // }else{
      //   notification.error({message:res.message})
      // }
    }

  const handleDraft = async () => {
    if(mode === 'new'){
      // create draft
    }
    if(mode==='publish'){
      // update draft
    }

  }
  
  return ( 
    <>
  
        <Form 
            className='h-full flex '
            form={form}
            onFinish={handleFormSubmit}
            validateTrigger={"none"}
            
                >
                    {/* <div className="w-[310px] border-r border-main">
                        <UrlLists handlePageSelection={handlePageSelection}/>
                    </div> */}
                    <div className="flex-1">
                        {/* <Header elRef={elRef} inputValue={inputValue} navigationActive={navigationActive} handleInputChange={handleInputChange} handleInputClick={handleInputClick} /> */}
                        <div className='flex h-full flex-1 bg-sub-windows' >
                            
                            {/* <div className='flex-1 h-full'>
                            
                                <PageViewer url={url} elRef={elRef} setinputValue={setinputValue} setnavigationActive={setnavigationActive}   />
                            </div> */}
                            <div className="w-[50%] bg-sub-windows height-with-2bars overflow-auto pb-4">
                                <div className="pt-5 space-y-2 pr-5" >
                                    <EditForm mode={mode} errors={errors} seterrors={seterrors} edit={false} url={url} initDescription={''} />
                                    <div className="md:grid grid-cols-7 gap-4 ">
            
                                        <p className="md:text-end font-bold col-span-2  pt-[10px]">
                                            Visibility
                                        </p>
                                        <div className="col-span-5">
                                            <Visibility/>
                                        </div>
                                        
                                    </div>
                                    <div className='md:grid grid-cols-7'>
                                        <div className='col-span-7 flex space-x-3'>
                                        {mode !== 'edit' && (
                                            <button name='draft' type='submit' className='block ml-auto px-3 py-1.5 bg-skin-fill-muted rounded  disabled:text-skin-muted'>Save as draft</button>

                                        )}
                                        {InitId ? (
                                            <button name='submit' type='submit' className='block ml-auto px-3 py-1.5 bg-skin-fill-muted rounded  disabled:text-skin-muted'>{mode==='edit' ? 'Update' : 'Publish'}</button>
                                        ) : (
                                            <button type='submit' className='block ml-auto px-3 py-1.5 bg-skin-fill-muted rounded  disabled:text-skin-muted'  >Next</button>

                                        )}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
            </Form>

 
    
    </>
    
                    
    

  )
}

