export const BASE_PATH='mydesk'
export const volumeCodes = [
    {
         name: 'Server',
         icon:{
            id: 'icons/tree/all_icons/cluster',
            src: '/o/icons%2Ftree%2Fcluster.svg?alt=media&token=c4e9e01f-3a49-4b23-aa07-6db0b6e5c1bc'
         },
         code: '015',
         collection: 'LocalClusters',
         
     },
     {
         name: 'Drive',
         icon:{
            id: 'icons/tree/all_icons/dedicated_drive',
            src: '/o/icons%2Ftree%2Fdedicated_drive.svg?alt=media&token=247103b3-f400-4fe3-84b4-645ccc0331d6'
         },
         code: '004',
         collection:'DedicatedDrives'
     },
     {
         name: 'Directory',
         icon:{
            id: 'icons/tree/all_icons/dedicated_directory',
            src: '/o/icons%2Ftree%2Fdedicated_directory.svg?alt=media&token=988e7a2f-fef3-4163-83ee-3f602be2d85a'
         },
         code: '003',
         collection:'DedicatedDirectories'
        },
        {
              name: 'Folder',
              icon:{
                id: 'icons/tree/all_icons/dedicated_folder',
                src: '/o/icons%2Ftree%2Fdedicated_folder.svg?alt=media&token=2aec337f-0d10-4d7b-9bcf-f4b2b7e3c608'
             },
              code: '002',
              collection: 'DedicatedFolders'
          },
      
]