import { ReactSVG } from 'react-svg'
import Slide from './Slide'
import Icon from '../../../../icons/Icon'
import ReactSvg from '../../../ReactSvg'
import { iconType } from '../../../../../types'

type Props = {
    fields: {
        name: string | JSX.Element,
        value: string | JSX.Element,
        center?:boolean,
        keywords?:boolean,
        className?:string
    }[],
    thumb?: {
        type: 'icon',
        icon: iconType
    } | {
        type:'slide',
        images:{src: string, type:string, dim?:string}[] 
    },
    image?:JSX.Element
}
export default function ObjectMode({ fields, thumb, image }:Props) {
    
  return (
    <div className="w-full flex justify-center">
       
       
        <div className="space-y-4 pt-5 pb-4 w-full flex flex-col items-center">
            {image && image}
            <div className='pr-5 space-y-4  max-w-[400px]'>
                {thumb && (
                    thumb.type === 'slide' ? (
                        <div className='w-full pl-3'>
                            <Slide media={thumb.images} />
                        </div>
                    ): (
                        <div className="flex space-x-4">
                            <div className="w-[85px] text-title !font-bold text-end"></div>
                            <div className="flex-1">
                                <div className="w-[135px]  flex items-end ">
                                    <div className="relative w-[100px] h-[100px]">
                                        {thumb.icon.type === 'icon' ? (
                                            <Icon name={thumb.icon.src} className='w-full h-full' />
                                        ) : (
                                            <ReactSvg src={thumb.icon.src} className='w-full h-full' />

                                        )}
                                    </div>   
                                </div>
        
                            </div>
                        </div>
                    )

                )}
            
                {fields.map(field => (
                    <div className={`relative flex space-x-4 ${field.center && 'items-center'}`}>
                        <p className={`w-[85px] text-title !font-bold text-end ${!field.center && ''} ${field.className && field.className}`}>{field.name}</p>
                        <div className="flex-1" style={{lineHeight:'1.6'}}>
                            {field.value}
                        </div>
                    </div>

                ))}

            </div>
      
        </div>
      

    </div>
  )
}
