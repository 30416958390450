import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { UI_WIDTH_PUBLICATIONS_SM } from '../../../constants';
import DrawerSmLayout from '../../../components/shared/Drawers/drawerSmLayout';
import i18n from '../../../i18n';

type Props = {
    children: JSX.Element,
    backLink?:string
}
export default function DetailsPageLayout({children, backLink}:Props) {
    const { winSize } = useAppContext()
    const navigate = useNavigate()
    const [visible, setvisible] = useState(false);
    
    useEffect(() => {
        
        setvisible(true)
    }, []);
  return (
    <>
               {winSize.w >  UI_WIDTH_PUBLICATIONS_SM ? (
                
                    children
                ) : (
                        <DrawerSmLayout visible={visible} onClose={() => navigate( backLink??`/home/${i18n.language}`)} placement='right' title='Details'>
        
                        
                               
                                {children}
                            
        
                        </DrawerSmLayout>
                    
                )}
    </>
  )
}
