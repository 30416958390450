import React, { useEffect, useState } from 'react'
import { fetchInt } from '../../../utils/api/fetchInt';
import { getUserSubscriptions } from '../../../services/api/request';
import { subscriptionDetails } from '../../../types';
import { Table } from 'antd';
import { render } from 'react-dom';
import { title } from 'process';

export default function Subscriptions() {
    const [subscriptions, setsubscriptions] = useState<subscriptionDetails[] | null>(null);
    const [tableRows, settableRows] = useState<{key:string, name:string, status:string, paymentdate:string, expirationdate:string, cardnumber:string, payedprice:string, isAutoRenewal:boolean}[]>([]);
    useEffect(() => {
        
        fetchInt(getUserSubscriptions()).then(res => res.json()).then(data => setsubscriptions(data.data.map((s:any) => ({...s.subscriptionData, id:s.id})))).catch(err => console.log(err))
    }, []);
    useEffect(() => {
        
        if(subscriptions){
            const data:any = subscriptions.map(s => ({
                key: s.id,
                name: s.tier.title.en,
                status: s.status,
                paymentdate: convertToDate(s.paymentDate).toLocaleDateString(),
                expirationdate: convertToDate(s.expirationDate).toLocaleDateString(),
                cardnumber: s.payment?.cardNumber,
                payedprice: s.payment?.payedTierPrice,
                isAutoRenewal: s.isAutoRenewal
            }))
            settableRows(data)
        }
    }, [subscriptions]);
    function convertToDate(date:any) {
        const { _seconds, _nanoseconds } = date;
        const milliseconds = _seconds * 1000 + Math.floor(_nanoseconds / 1e6);
        return new Date(milliseconds);
    }
    const columns = [
        {
          title: 'Plan name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Payment date',
          dataIndex: 'paymentdate',
          key: 'paymentdate',
        },
        {
            title: 'Expiration date',
            dataIndex: 'expirationdate',
            key: 'expirationdate',
        },
        {
            title: 'Card number',
            dataIndex: 'cardnumber',
            key: 'cardnumber',
        },
        {
            title: 'Payed price',
            dataIndex: 'payedprice',
            key: 'payedprice',
        },
        {
            title: 'Auto renewale',
            dataIndex: 'isAutoRenewal',
            key: 'isAutoRenewal',
            render: (isAutoRenewal:boolean) => isAutoRenewal ? 'Yes' : 'No'
        },
        {
            title: 'Actions',
            dataIndex: 'ations',
            key: 'ations',
            render: (_:any, record:any) => (
                record.status === 'ACTIVE' && (
                    <div className='flex space-x-2'>
                    <button onClick={() => console.log(record)} className='px-4 py-1.5 rounded border border-main'>Cancel</button>
                    <button onClick={() => console.log(record)} className='px-4 py-1.5 rounded border border-main'>Upgard</button>
                    </div>
                )
            )
        }
      ];
  return (
    <div className='pt-10 space-y-7'>
        <div className='space-y-5'>

            <p className='font-bold'>My subscriptions</p>
            <Table dataSource={tableRows} columns={columns} />
           
        </div>
        <div className='space-y-5'>

            <p className='font-bold'>My Invoices</p>
            <Table
                columns={[
                    {
                        title: 'Invoice ID',
                        dataIndex: 'invoiceId',
                        key: 'invoiceId',
                    },
                    {
                        title: 'total',
                        dataIndex: 'total',
                        key: 'total',
                    },
                    {
                        title: '',
                        dataIndex: 'ations',
                        key: 'ations',
                        render: (_:any, record:any) => (
                            <button onClick={() => console.log(record)} className='px-4 py-1.5 rounded border border-main'>Download</button>
                        )
                    }
                ]}
                dataSource={[]}
            />
           
        </div>
    </div>
  )
}
