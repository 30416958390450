import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1107 1495"
    fill="transparent"
    {...props}
  >
    <title>{"download (1d7)-svg"}</title>
    <style>
      {"\r\n\t\t.s0 { fill: currentColor } \r\n\t\t.s1 { fill: currentColor } \r\n\t"}
    </style>
    <path
      id="path28"
      className="s0"
      d="m64.8 239.7l1019.7 1058.2-106.6 102.4-162.2-166.4-157.8 153.6c-72.6 72.5-170.7 110.9-277.4 106.6-102.4 0-200.5-42.6-273-119.4-72.6-72.6-111-170.7-106.7-277.4 0-102.4 42.7-200.5 119.5-273l106.6-102.4 102.4 106.6-106.6 102.4c-47 42.7-72.6 102.4-72.6 166.4 0 64 21.4 123.8 68.3 170.7 42.7 46.9 102.4 72.5 166.4 72.5 64 0 123.7-21.3 170.7-68.2l157.8-153.6-648.5-661.4z"
    />
    <path
      id="path30"
      fillRule="evenodd"
      className="s1"
      d="m999.2 521.3c72.5 72.6 110.9 170.7 106.7 277.4 0 102.4-42.7 200.5-119.5 273l-12.8 12.8-102.4-106.6 12.8-12.8c46.9-42.7 72.5-102.4 72.5-166.4 0-64-21.3-123.8-68.2-170.7-25.6-25.6-51.2-46.9-85.4-59.7v-157.9c76.8 17.1 145.1 55.5 196.3 110.9zm-836.3-430.9l-72.5 72.5 102.4 106.7 72.5-68.3c72.6-68.2 183.5-68.2 251.8 4.3 68.2 72.5 68.2 183.5-4.3 251.7l-72.5 68.3 102.4 106.7 72.5-68.3c132.3-123.7 132.3-332.8 8.5-465.1-123.7-128-328.5-132.2-460.8-8.5z"
    />
  </svg>
)
export default SvgComponent
