import React, { useEffect, useState } from 'react'


import { Drawer, Dropdown } from 'antd'
import Icon from '../../../components/icons/Icon'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppContext } from '../../../context/AppContext'
import { CameraIcon, ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'


type Props = {
    children: JSX.Element,
    title: string,
    backLink: string,

}
export default function DetailsLayout({children, title, backLink}:Props) {
    const [open, setopen] = useState(false);
    const navigate = useNavigate()
    const {winSize} = useAppContext()
    const [menuOpen, setmenuOpen] = useState(false);
    const {t} = useTranslation(['common', 'home'])
    
    useEffect(() => {
        
        setopen(true)
    }, []);

    const handleClose = () => {
        navigate(backLink)
    }
  return (
    <Drawer
        visible={open}
        onClose={handleClose}
        placement="right"
        footer={null}
        headerStyle={{display: 'none'}}
        destroyOnClose
        width={winSize.w > 1300 ? '90%' : '100%'}
        contentWrapperStyle={{overflow: 'hidden'}}

    >
        <div className='overflow-hidden h-full'>
            <div className='flex justify-between items-center px-5 h-[60px] border-b border-main'>
                <button className="flex space-x-2 item-center bg-hover-transparent rounded px-2 py-1" onClick={handleClose}>
                    <ChevronLeftIcon className='icon-sm'/>
                    <p className=' max-[520px]:hidden'>{t('back-to-home')}</p>
                </button>
                <div className="flex space-x-5 items-center">
                    {/* <div className="flex space-x-2 items-center">
                        <p className="font-bold text-[16px]">{title}</p>
                    </div> */}
                    {/* <Dropdown 
                    onVisibleChange={(visible) => setmenuOpen(visible)}
                    overlay={
                        <div className='w-max bg-white teddra-rounded min-h-[100px] px-4 py-8' style={{filter: 'drop-shadow(2px 2px 5px var(--shadow-win-color))'}}>
                            <div className="grid grid-cols-2 gap-y-3 gap-x-8">
                                <div className={`flex space-x-5 p-1.5 bg-hover-transparent cursor-pointer rounded max-w-[320px] `} onClick={() => navigate(`/home/${i18n.language}/pages/qorddu`)}>
                                    <div className="flex justify-center items-center rounded border border-main w-[80px] h-[50px]">
                                     <CameraIcon className='icon-md opacity-10'/>

                                    </div>
                                    <div className="flex-1">
                                        <p className=' font-bold text-[16px]'>{t('home:qorddu.title')}</p>
                                        <p className="font-light text-[15px]">{t('home:qorddu.description')}</p>
                                    </div>
                                </div>
                                <div className="flex space-x-5 p-1.5 bg-hover-transparent cursor-not-allowed rounded max-w-[320px]">
                                <div className="flex justify-center items-center rounded border border-main w-[80px] h-[50px]">
                                     <CameraIcon className='icon-md opacity-10'/>

                                    </div>
                                    <div className="flex-1">
                                        <p className=' font-bold text-[16px]'>{t('home:video.title')}</p>
                                        <p className="font-light text-[15px]">{t('home:video.description')}</p>
                                        <p>{t('coming-soon')}</p>

                                    </div>
                                </div>
                                <div className="flex space-x-5 p-1.5 bg-hover-transparent cursor-not-allowed rounded max-w-[320px]">
                                <div className="flex justify-center items-center rounded border border-main w-[80px] h-[50px]">
                                     <CameraIcon className='icon-md opacity-10'/>

                                    </div>
                                    <div className="flex-1">
                                        <p className=' font-bold text-[16px]'>{t('home:forbusiness.title')}</p>
                                        <p className="font-light text-[15px]">{t('home:forbusiness.description')}</p>
                                        <p>{t('coming-soon')}</p>

                                    </div>
                                </div>
                                <div className="flex space-x-5 p-1.5 bg-hover-transparent cursor-not-allowed rounded max-w-[320px]">
                                <div className="flex justify-center items-center rounded border border-main w-[80px] h-[50px]">
                                     <CameraIcon className='icon-md opacity-10'/>

                                    </div>
                                    <div className="flex-1">
                                        <p className=' font-bold text-[16px]'>{t('home:forcommunities.title')}</p>
                                        <p className="font-light text-[15px]">{t('home:forcommunities.description')} </p>
                                        <p>{t('coming-soon')}</p>
                                    </div>
                                </div>
                                <div className="flex space-x-5 p-1.5 bg-hover-transparent cursor-pointer rounded max-w-[320px]" onClick={() => navigate(`/home/${i18n.language}/pages/newspapers`)}>
                                <div className="flex justify-center items-center rounded border border-main w-[80px] h-[50px]">
                                     <CameraIcon className='icon-md opacity-10'/>

                                    </div>
                                    <div className="flex-1">
                                        <p className=' font-bold text-[16px]'>{t('home:fornewspapers.title')}</p>
                                        <p className="font-light text-[15px]">{t('home:fornewspapers.description')}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    > */}
                        <button className={`flex text-start space-x-5 items-center py-2 rounded-lg ${menuOpen ? 'bg-skin-fill-inverted-main text-skin-inverted' : ''} `}>
                            <div className="flex space-x-4 items-center">
                                {/* <CameraIcon className='icon-md opacity-10'/> */}
                                <p className="absolute left-1/2 transform -translate-x-1/2 font-bold text-[16px]">{title}</p>

                            </div>
                            {/* <ChevronDownIcon className='icon-mini'/> */}
                        </button>
                    {/* </Dropdown> */}

                </div>
                <button className='rounded bg-red-600 text-white p-1.5' onClick={handleClose}>
                    <Icon name='Close' className='icon-mini' />
                </button>

            </div>
            {children}
            
        </div>
           

    </Drawer>
  )
}
