import React, { useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import useGetPublications from '../../hooks/useGetPublications'
import LocationLayout from '../../components/locationLayout'
import { useSearchContext } from '../../context/searchContext'
import useGetWebResources from '../../hooks/useGetWebResources'
import QfsActionsBar from '../../GlobalLayout/QfsActionsBar'
import SearchBar from '../../GlobalLayout/SearchBar'
import useGetWebPages from '../../hooks/useGetWebPages'
import { useQfsContext } from '../../context/qfsContext'
import Footer from '../../components/Footer'
import useGetPubsTypes from '../../../../hooks/useGetPubsTypes'
import { displayModes, useUIContext } from '../../context/UIContext'

export default function Websearch({type}: {type:string}) {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const [query, setquery] = useState('');
    const [selectedPubType, setselectedPubType] = useState<{value:string, icon:string, type:'groupe' | 'type'}>({value:'', icon:'', type:'type'});
    const [pagination, setPaginate] = useState<{page:number, offset:number, limit:number}>({page:1, offset:0, limit:15});
    const [groupBy, setgroupBy] = useState({webresources:true, paidPubs:false});
    const { currentLocation } = useQfsContext()
    const { publicationsTypes } = useGetPubsTypes()
    const [displayMode, setDisplayMode] = useState<displayModes>('OneLine');
    
    const { barActivatedFields, setBarActivatedFields } = useUIContext()
    const searchOn = !!query || !!selectedPubType.value
    const { setAll } = useUIContext()
    const { webresources, isLoaded, totalHits } = useGetWebPages({
        id:params.id!, 
        groupBy: true,
        query,
        pagination,
        setGroupBy:(data) => setgroupBy(prev => ({...prev, data})),
        selectedPubType,
        setPaginate:(data) => setPaginate(prev => ({...prev, ...data})),
        onlyWebpages:true,
        limit: !searchOn ? 5 : 13
    })

   
 
   const OnsearchClear = () => {
    setquery('')
    setselectedPubType({value:'', icon:'', type:'type'})
    setPaginate({page:1, offset:0, limit:7})

  }

  return (
    <>
         <SearchBar
                query={query}
                setQuery={setquery}
                selectedPubType={selectedPubType}
                setSelectedPubType={setselectedPubType}
                groupBy={groupBy}
                totalHits={{webresources:totalHits, pubs:0}}
                setOnlyWebpages={() => {}}
                onlyWebpages={true}
                setDisplayMode={setDisplayMode}
                disabled={{filter:false, display:false, new:false,query:false}}
                displayMode={displayMode}
                barActivatedFields={barActivatedFields}
                setBarActivatedFields={setBarActivatedFields}
                currentLocation={currentLocation}
                publicationsTypes={{
                    types:publicationsTypes.filter((g:any) => g.id==='web_resources')?.[0]?.children,
                    loading:false
                }}
                onClear={OnsearchClear}
            />
        <QfsActionsBar details={true}/>
      
        <LocationLayout
            layoutProps={
                {
                loading,
                selectedKey: params.id!,
                disabled:{filter:false, new:false, display:false,query:false},
                publicationBar:true,
                id: params.id!,
                collection: params.coll!
                }
                
            }
            
            suffix={`${params.serverId ? `${params.serverId}/` : ''}${params.coll }/${params.id}/${type ? type + '/' : ''}window/websearch/`}
            isLoaded={isLoaded}
            all='websearch'
            data={{
                    partners:null,
                    sponsors:null,
                    publications:[],
                    webResources:webresources,
                    availableLocations:null
            }}
            searchStates={
                {
                    query: query,
                    selectedPubType: selectedPubType,
                    onlyWebpages: true,
                    totalHits: {pubs: totalHits, webresources:totalHits, locations: 0},
                    isSearchActive: searchOn, 
                 
                    allowSearch:true
                }
            }
            ui={{
                displayMode,
                barActivatedFields,
                setAll,
                groupBy
            }}
        />
        <Footer
            totalHits={{webresources:totalHits, pubs:0, location:0}}
            pagination={pagination}
            setPaginate={(data) => setPaginate(prev => ({...prev, ...data}))}
            onlyWebpages={true}
            currentLocation={currentLocation}
            allowPaginate={
                {
                webresources: false ,
                pubs: false
                }
              }
            windows={{
                active: 'websearch',
                suffix:`/${params.serverId ? `${params.serverId}/` : ''}${params.coll}/${params.id}${type ?'/' +type : ''}`,
                disabled:false
            }}
        />
    
    </>
  )
}
