import { InformationCircleIcon } from "@heroicons/react/solid";
import { Button, Divider, Input, Select, Space, Tooltip } from "antd";
import BlockwithoutImgLayout from "../../components/block/withoutImg";
import TextBlock2 from "../../components/block/TextBlock2";
import Blocks from "../../components/block/Blocks";
import { useEffect, useRef, useState } from "react";
import BarChart from "../../components/ChartComponent";
import BlockwithImgLayout from "../../components/block/withImgLayout";
import { useTranslation } from "react-i18next";



let index = 0;
const formatNumber = (num:number) => {
    if (num >= 1000) {
      return (num / 1000)
        .toLocaleString("de-DE", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })
        .replace(/[.,]/g, " "); // Replace both . and , with spaces
    }
    return num.toLocaleString("de-DE").replace(/[.,]/g, " ");
  };
  
  
  
export default function Revenu() {
    const [items, setItems] = useState([
     
        {
            value: '1000',
            label: formatNumber(1000),
        },
        {
            value: '2000',
            label: formatNumber(2000),
        },
        {
            value: '5000',
            label: formatNumber(5000),
        },
        {
            value: '10000',
            label: formatNumber(10000),
        },
        {
            value: '20000',
            label: formatNumber(20000),
        }
    ]);
    const [name, setName] = useState<any>('');
    const inputRef = useRef<any>(null);

    const [companies, setcompanies] = useState(0);
    const [subs, setsubs] = useState(0);
    const [rev, setrev] = useState(0);
    const [market, setmarket] = useState(0);
    const [H1, setH1] = useState({monthly:0, yearly:0});
    const [H2, setH2] = useState({monthly:0, yearly:0});
    const [H3, setH3] = useState({monthly:0, yearly:0});
    const [selectedH, setselectedH] = useState('H1');
    const [chartValue, setchartValue] = useState(0);
    const {t} = useTranslation(['common','revenu'])
    const onNameChange = (event:any) => {
      setName(event.target.value);
    };
    const addItem = (e:any) => {
      e.preventDefault();
      setItems([...items, {value: name, label:formatNumber(name*1)} ]);
      setName('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    };

    useEffect(() => {
        
        if(companies && subs && rev){
            setmarket((companies * subs * rev*12)/100)
        }else {
            setmarket(0)
        }
    }, [companies, subs, rev]);

    useEffect(() => {
        if(market){
            setH1({monthly: (market*0.05/12), yearly: (market*0.05)})
            setH2({monthly: (market*0.15/12), yearly: (market*0.15)})
            setH3({monthly: (market*0.3/12), yearly: (market*0.3)})

        }else{
            setH1({monthly: 0, yearly: 0})
            setH2({monthly: 0, yearly: 0})
            setH3({monthly: 0, yearly: 0})
        }
    }, [market]);

    useEffect(() => {
        if(selectedH === 'H1'){
            setchartValue(H1.yearly)
        }else if(selectedH === 'H2'){
            setchartValue(H2.yearly)
        }else if(selectedH === 'H3'){
            setchartValue(H3.yearly)
        }
       
    }, [H1.yearly, H2.yearly, H3.yearly, selectedH]);


 
  return (
    <div className="w-full h-full relative">
    
        <div className="pb-end-page">
        
           
            <div className="space-y-[270px]">
                <div className="relative flex flex-col justify-center  max-w-page mx-auto min-h-[calc(100vh-176px)]">
                    <div className='flex space-x-[55px] justify-centyer items-center '>
                        <div className=" w-full flex space-x-10">
                          
                            <div className="">
                                <p className='title-main text-center'>
                                {t('revenu:title')}
                                </p>
                                <p className="text-main text-center">
                                {t('revenu:text')}
                                </p>
                             

                            </div>
                       
                       
                            

                        </div>
                     

                    </div>
                </div>

                <BlockwithImgLayout orientation="right">
                    <>
                        <TextBlock2
                        label=""
                            title={t('revenu:section1.title')}
                            text={t('revenu:section1.text')}
                        />

                        <Blocks
                            columns="grid-cols-2 max-[450px]:grid-cols-1"
                            content={[
                                {
                                    title: t('revenu:section1.block1.title'),
                                    text: t('revenu:section1.block1.text')
                                },
                                {
                                    title: t('revenu:section1.block2.title'),
                                    text: t('revenu:section1.block2.text')
                                }
                            ]}
                        />
                    </>
                </BlockwithImgLayout>

                <div className="space-y-16">
                    <BlockwithoutImgLayout orientation='right'>
                        <>
                        <TextBlock2
                            label=""
                            title={t('revenu:section2.title')}
                            text={t('revenu:section2.text')}
                        />
                    
                    
                        </>

                    </BlockwithoutImgLayout>
                    <div className="max-w-page mx-auto ">
                        <div className="border border-black ">
                            <div className="">
                                <div className="grid grid-cols-4 gap-x-7 gap-y-2 items-center px-3 py-2 border-b border-black max-[750px]:grid-cols-1">
                                <p className="text-level2 !font-semibold">{t('revenu:section2.table.row1.c1')}</p>
                                <p className="text-level2 col-span-2 max-[750px]:col-span-1">
                                    {t('revenu:section2.table.row1.c2')}
                                </p>
                                <div className="flex-1">
                                    {/* <input type="text" className="w-full rounded-lg text-level2 !mt-0 placeholder:text-[22px] bar-h !bg-gray-700 border-none !text-white" placeholder="EX: 10000 entreprises"/> */}
                                    <Select
                                        value={formatNumber(companies)}
                                        placeholder="Select"
                                        onChange={(v:any) => setcompanies(v*1)}
                                        className="rounded w-full bar-h text-level2 page-type !bg-gray-700 border-none !text-white !mt-0"
                                        dropdownClassName="page-type"
                                        dropdownRender={(menu) => (
                                            <>
                                            {menu}
                                            <Divider
                                                style={{
                                                margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                padding: '0 8px 4px',
                                                }}
                                            >
                                                <Input
                                                placeholder={t('revenu:section2.input')}
                                                ref={inputRef}
                                                value={name}
                                                onChange={onNameChange}
                                                />
                                                <Button type="text" onClick={addItem}>
                                                {t('revenu:section2.btn')}
                                                </Button>
                                            </Space>
                                            </>
                                        )}
                                        options={items}
                                        />
                                
                                </div>
                                </div>
                                <div className="grid grid-cols-4 gap-x-7 gap-y-2 items-center px-3 py-2 border-b border-black max-[750px]:grid-cols-1">
                                <p className="text-level2 !font-semibold">{t('revenu:section2.table.row2.c1')}</p>
                                <p className="text-level2 col-span-2">
                                    {t('revenu:section2.table.row2.c2')}
                                </p>
                                    <div className="flex-1">
                                        {/* <input type="text" className="w-full rounded-lg text-level2 !mt-0 placeholder:text-[22px] bar-h !bg-gray-700 border-none !text-white" placeholder="EX: 10000 entreprises"/> */}
                                        <Select
                                            className="rounded w-full bar-h text-level2 page-type !bg-gray-700 border-none !text-white !mt-0"
                                            placeholder="Select"
                                            dropdownClassName="page-type"
                                            value={`${subs}€/mois`}
                                            onChange={(v:any) => setsubs(v*1)}
                                            options={[
                                                {
                                                    value: '20',
                                                    label: `20€${t('per-month')}`,
                                                },
                                                {
                                                    value: '40',
                                                    label: `40€${t('per-month')}`,
                                                },
                                                {
                                                    value: '60',
                                                    label: `60€${t('per-month')}`,
                                                },
                                                {
                                                    value: '80',
                                                    label: `80€${t('per-month')}`,
                                                },
                                                {
                                                    value: '100',
                                                    label: `100€${t('per-month')}`,
                                                },
                                                {
                                                    value: '120',
                                                    label: `120€${t('per-month')}`,
                                                },
                                            ]}
                                        
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-4 gap-x-7 gap-y-2 items-center px-3 py-2 border-b border-black max-[750px]:grid-cols-1">
                                <p className="text-level2 !font-semibold">{t('revenu:section2.table.row3.c1')}</p>
                                <p className="text-level2 col-span-2">
                                    {t('revenu:section2.table.row3.c2')}
                                </p>
                                    <div className="flex-1">
                                        {/* <input type="text" className="w-full rounded-lg text-level2 !mt-0 placeholder:text-[22px] bar-h !bg-gray-700 border-none !text-white" placeholder="EX: 10000 entreprises"/> */}
                                        <Select
                                            className="rounded w-full bar-h text-level2 page-type !bg-gray-700 border-none !text-white !mt-0"
                                            placeholder="Select"
                                            dropdownClassName="page-type"
                                            value={`${rev}%`}
                                            onSelect={(v:any) => setrev(v*1)}
                                            
                                            options={[
                                                {
                                                    value: '25',
                                                    label: '25%',
                                                },
                                                {
                                                    value: '50',
                                                    label: '50%',
                                                },
                                                {
                                                    value: '75',
                                                    label: '75%',
                                                },
                                            ]}
                                        
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="grid grid-cols-4 gap-x-7 items-center  px-3  py-2 max-[750px]:grid-cols-1 gap-y-2">
                                <p className="text-level2 !font-semibold">{t('revenu:section2.table.row4.c1')}</p>
                                <p className="text-level2 col-span-2">
                                     {t('revenu:section2.table.row4.c2')}
                                </p>
                                <div className="flex-1">
                                    <input type="text" className="!bg-skin-fill-inverted text-skin-inverted bar-h  w-full rounded border-none !text-[22px] !font-bold" value={`${formatNumber(market)}€`} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="max-w-page mx-auto    ">
                    <div className="text-center  border-b  border-black pb-10">
                        <div className="max-w-[80%] mx-auto">
                            <p className="title-level2">
                                {t('revenu:section3.title')}
                            </p>
                            <p className="title-level2-light">
                            {t('revenu:section3.text')}
                            </p>

                        </div>

                    </div>
                    <div className="grid grid-cols-3 max-[550px]:grid-cols-1 ">
                        <div className="border-r border-l border-b border-black h-full px-4 py-7">
                            <p className="title-level2 text-center">
                               {t('revenu:section3.block1.title')}
                            </p>
                            <p className="text-level2 text-center">
                            {t('revenu:section3.block1.text')}
                            </p>
                            <div className="mt-5 ">
                                <div className="">
                                    <p className="text-[30px] text-center">{formatNumber(H1.monthly)}
                                        <span>€{t('per-month')}</span>
                                    </p>
                                    <p className="text-[30px] text-center">{formatNumber(H1.yearly)}
                                        <span>€{t('per-year')}</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className=" border-b border-r border-black h-full px-4 py-7 max-[550px]:border-l">
                            <p className="title-level2 text-center">
                                {t('revenu:section3.block2.title')}
                            </p>
                            <p className="text-level2 text-center">
                                {t('revenu:section3.block2.text')}
                            </p>
                            <div className="mt-5 ">
                                <div className="">
                                    <p className="text-[30px] text-center">{formatNumber(H2.monthly)}
                                        <span>€{t('per-month')}</span>
                                    </p>
                                    <p className="text-[30px] text-center">{formatNumber(H2.yearly)}
                                        <span>€{t('per-year')}</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className=" h-full px-4 py-7  border-b border-black border-r max-[550px]:border-l">
                            <p className="title-level2 text-center">
                                {t('revenu:section3.block3.title')}
                            </p>
                            <p className="text-level2 text-center">
                                {t('revenu:section3.block3.text')}
                            </p>
                            <div className="mt-5 ">
                                <div className="">
                                    <p className="text-[30px] text-center">{formatNumber(H3.monthly)}
                                        <span>€{t('per-month')}</span>
                                    </p>
                                    <p className="text-[30px] text-center">{formatNumber(H3.yearly)}
                                        <span>€{t('per-year')}</span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-y-16">
                    <BlockwithoutImgLayout orientation='right'>
                            <>
                            <TextBlock2
                                // label={t('platform:p1.label')}
                                label=""
                                title={t('revenu:section4.title')}
                                // text={t('platform:p1.text')}
                                text={t('revenu:section4.text')}
                            />
                            </>

                        </BlockwithoutImgLayout>
                        <div className="max-w-page mx-auto">
                            <div className="space-y-7">
                                <div className="flex space-x-3 items-center">\
                                    <Select
                                        value={selectedH}
                                        placeholder="Select Hypothese"
                                        onChange={(v:any) => setselectedH(v)}
                                        className="rounded w-[220px] bar-h text-level2 page-type !bg-gray-700 border-none !text-white !mt-0"
                                        dropdownClassName="page-type"
                                        options={[
                                            {
                                                label: t('revenu:section3.block1.title'),
                                                value: 'H1',
                                            },
                                            {
                                                label: t('revenu:section3.block2.title'),
                                                value: 'H2',
                                            },
                                            {
                                                label: t('revenu:section3.block3.title'),
                                                value: 'H3',
                                            }
                                        ]}
                                    />
                                    <p className="!italic text-level2">{t('revenu:section4.p1')} {selectedH === 'H1' && '5%'} {selectedH === 'H2' && '15%'} {selectedH === 'H3' && '30%'} {t('revenu:section4.p2')}</p>
                                </div>
                                <div style={{ width: "100%", height: "700px", overflow: "hidden" }}>
                                    {selectedH && (
                                        <BarChart 
                                        baseValue={
                                            chartValue
                                            
                                        } 
                                        precentage={
                                        selectedH === 'H1' ? 0.05 : selectedH === 'H2' ? 0.15 : 0.30
                                        }
                                        
                                        />

                                    )}
                                </div>

                            </div>

                        </div>

                </div>
                   


              

           

   
            </div>
        </div>
            

         

    </div>

             
                      
                    
                    
      
  )
}