import React, { useEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import DetailsLayout from '../components/DetailsLayout';
import i18n from '../../../i18n';


function AboutLayout({server}:{server?:boolean}) {
  const location = useLocation()
  const scrollRef = useRef<any>(null);
  const [scrollY, setScrollY] = useState(0);
  const {t} = useTranslation(['common', 'home', 'nHome'])
  const params = useParams()
  const backLink = server ? `/${i18n.language}/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}/activation` : undefined

useEffect(() => {
  
   
  scrollRef.current?.scrollTo({ top: 0, behavior: "instant" });
  
}, [location.pathname, scrollRef.current]);
useEffect(() => {
  const handleScroll = () => {
    if (scrollRef.current) {
      setScrollY(scrollRef.current.scrollTop);
    }
  };

  const divElement = scrollRef.current;
  if (divElement) {
    divElement.addEventListener("scroll", handleScroll);
  }

  return () => {
    if (divElement) {
      divElement.removeEventListener("scroll", handleScroll);
    }
  };
}, []);


return (

      <div className=''>
      
          <div>

              <div className={` h-[60px] sticky top-0 border-b border-main bg-white z-[10000] `}>
                  <div className='flex items-center h-full  px-page   relative max-[950px]:!px-4 max-[750px]:!px-2'>
                      <div className="max-w-page w-full mx-auto justify-between flex max-[670px]:flex-wrap gap-4">
                          
                        
                          <Link to={server ? `${backLink}/pages/vision` : `/home/${i18n.language}/pages/vision`}  className='text-inherit  '>
                              <button className={location.pathname.includes('vision') ? 'active' : ''}>
                                  <p className='text-level2'>Vision</p>
                                  
                              </button>
                          
                          </Link>
                   
                  
                              <button className={location.pathname.includes('/valeurs') ? 'active' : 'disabled:opacity-30'} disabled>
                                  <p className='text-level2'>Valeurs</p>

                              </button>
                          
                  
                              <button className={location.pathname.includes('/revenu') ? 'active' : 'disabled:opacity-30'} disabled>
                                  <p className='text-level2'>Societé</p>

                              </button>
                          
                              <button className={location.pathname.includes('/jobs') ? 'active' : 'disabled:opacity-30'} disabled>
                                  <p className='text-level2'>Jobs</p>

                              </button>
                          
                              <button className={location.pathname.includes('/contact') ? 'active' : 'disabled:opacity-30'} disabled>
                                  <p className='text-level2'>Contact</p>

                              </button>
                          

                      </div>

                  </div>
              </div>
              <div className={`overflow-y-auto ${server ? 'h-[calc(100vh-89px)]' : 'h-[calc(100vh-135px)]'}  overflow-x-hidden `} ref={scrollRef}      style={{ scrollBehavior: "smooth" }} // Ensures smooth scrolling
              >
                      <Outlet/>
              </div>
          </div>
      </div>

)
}

export default function About({server}:{server?:boolean}) {
    const {t} = useTranslation(['common', 'home', 'nHome'])
  



  return (
    server ? (
      <AboutLayout server/>
    ): (
    <DetailsLayout title={t('home:qorddu.title')} backLink={`/home/${i18n.language}`}>

        <AboutLayout/>

    </DetailsLayout>

    )
  )
}
