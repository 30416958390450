import React, { useEffect, useState } from 'react'
import { useMydeskContext } from '../../../context/MydeskContext'
import { Modal } from 'antd'
import { useNavigate } from 'react-router-dom';
import ReactSvg from '../../../../../components/shared/ReactSvg';
import { Server } from '../../../../../types';

export default function ChooseVolume() {
    const { userVolumes } = useMydeskContext()
    const [visible, setvisible] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        
        setvisible(true)
    }, []);

    const handleSelectVolume = (volume:Server)=> {
         navigate(`/mydesk/${volume.coll}/${volume.id}/publications/create/edit`)
    }
  return (
    <div>
        <Modal
            visible={visible}
            onCancel={() => navigate('/mydesk')}
            footer={null}
            width={350}
        >
            <div className='px-5 py-12 '>
                {userVolumes && userVolumes.length > 0 ? (
                    <div className='space-y-4'>
                        <p className='font-bold'>Where you want to create your publication?</p>
                        {userVolumes.map(v => (
                            <button className='flex space-x-2 w-full items-center bg-hover-transparent py-2 rounded !border border-main px-4' key={v.id} onClick={() => handleSelectVolume(v)}>
                                <ReactSvg src={v.iconUrl} className='icon-sm'/>
                                <p>{v.title}</p>
                            </button>
                        ))}
                    </div>
                ): (
                    <div className='space-y-4'>
                        <p className='text-center'>Please create a volume first tobe able to add documents to it.</p>
                        <button className='px-4 py-2 border border-main rounded block mx-auto' onClick={() => navigate('/mydesk')}>Close</button>
                    </div>
                )}
            </div>
        </Modal>
    </div>
  )
}
