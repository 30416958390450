import { CameraIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import Blocks from '../../../../../../../home/components/block/Blocks'
import confirm from 'antd/lib/modal/confirm';
import { message, Modal, notification } from 'antd';
import { fetchInt } from '../../../../../../../../utils/api/fetchInt';
import { validateReservation } from '../../../../../../../../services/api/request/partners';
import { useParams } from 'react-router-dom';
import i18n from '../../../../../../../../i18n';

export default function Deployment({nextStep, partnerId}:{nextStep:()=>void, partnerId:string}) {
  const [loading, setloading] = useState(false);
  const params = useParams()
  const [openModal, setopenModal] = useState(false);
  const formDta = new FormData()
  formDta.append('validated', "false")
  formDta.append('lang', i18n.language)
  const handleCancel = async () => {
      setloading(true)
      const req = await fetchInt(validateReservation({id: partnerId, serverId:params.id!}), {
          method: 'PATCH',
              
          body:  formDta

      })
      const res = await req.json()
      setloading(false)
      console.log(res)
      if(res.success){
          notification.success({
              message: "La reservation a été annulé avec succès", 

          });
          setopenModal(true)
      }
  }

  const onCancel = () => {
    confirm({
      title: 'Confirm',
      content: `Are you sure you want to cancel?`,
      onOk() {
        return handleCancel().then(() => {}).catch(() => message.error('something went very wrong!'));
      },
      onCancel() {},
     
    
      
    });
  }
  return (
    <div className='space-y-14 max-w-[1100px]'>
      <p className="title-level2">Déploiement </p>
      <div className="w-full h-[400px]  flex justify-center items-center rounded">
        <CameraIcon className='w-[100px] h-[100px] opacity-10'/>
      </div>
      <div className='space-y-7'>
        <div className=' flex justify-center w-full'>
          <div className="">
            <Blocks
              columns='grid-cols-4'
              content={[
                {
                  title:'Exclusivité',
                  text:'Chaque serveur Qorddu est déployé et exploité avec un partenaire unique. Créez un avantage déterminant face à vos concurrents.  ',
                  img:{src:'',type:'icon',hidden:true}
                },
                {
                  title:'3 mois offerts ',
                  text:"Démarrez et testez votre serveur sans risque : frais d’installation et 3 mois d’exploitation offerts aux 5 premiers partenaires.   ",
                  img:{src:'',type:'icon',hidden:true}

                },
                {
                  title:"90% des revenus reversés ",
                  text:"Faites partie des 5 premiers partenaires et recevez 90% des revenus générés par votre serveur. Pour toujours. ",
                  img:{src:'',type:'icon',hidden:true}

                }
              ]}
            />

          </div>

        </div>
      </div>
      <div className="flex w-full justify-end space-x-3">
        <button className='px-4 py-2 rounded bg-gray-300 text-level2 ' onClick={onCancel}>Je ne suis pas intéressé  </button>

        <button className='px-4 py-2 rounded bg-gray-300 text-level2 ' onClick={nextStep}>Je réserve ce serveur </button>
      </div>

      <Modal
        visible={openModal}
        onCancel={() => setopenModal(false)} 
        footer={null}
        title="Confirmation"
        width={400}
      >
        <div className="">
          <p>
            La reservation a été annulée avec succès
            les donnes vont etre supprime immediatement 
          </p>
        </div>

      </Modal>
    </div>
  )
}
