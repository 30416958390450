import { Form, Input, message, notification, Spin, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import TextArea from 'antd/lib/input/TextArea'
import UploadFormImage from '../../../components/shared/upload/UploadImage';
import SelectCity from '../../login/components/SelectCity';
import SelectCountry from '../../login/components/SelectCountry';
import { useAuthContext } from '../../../context/AuthContext';
import { updateUser } from '../../../services/api/request';
import { fetchInt } from '../../../utils/api/fetchInt';


export default function OrgForm() {
    const [selectedCountry, setselectedCountry] = useState<undefined | string>(undefined);
    const [form] = Form.useForm()
    const {user} = useAuthContext()
    const [loading, setloading] = useState(false);

    useEffect(() => {
      
      if(user){
        form.setFieldsValue({
          name: user.name,
          phone: user.phone,
          email: user.email,
          picture: user.picture,
          adressLine1: user.adress?.line1,
          adressLine2: user.adress?.line2,
          country: user.adress?.country,
          city: user.adress?.city,
          zipcode: user.adress?.zipcode,
          website: user.website,
          linkedin: user.linkedin,
          position: user.position,
          company: user.company,
          bio: user.bio
        })
      }
    }, [user?.id]);
    const edit = async () => {
      const { name, picture, website, linkedin, adressLine1, adressLine2, zipcode, position, company, bio, country, city} = form.getFieldsValue()
      const adress:any = {}
      if(adressLine1) adress.line1 = adressLine1
      if(adressLine2) adress.line2 = adressLine2
      if(zipcode) adress.zipcode = zipcode
      if(country) adress.country = country
      if(city) adress.city = city

      const data = {
        displayName:name,
        picture,
        website,
        linkedin,
        adress,
        company,
        bio,
        position
      }
      setloading(true)
      const req = await fetchInt(updateUser, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      const res = await req.json()
      if(res.success){
        notification.success({message: 'Profile updated successfully'})
        window.location.reload()
      }
      setloading(false)
    }
    
  return (
    <Spin spinning={loading}>
      <Form form={form} className='max-w-[500px] px-8 pt-10' onSubmitCapture={edit}>


        <div className="space-y-8">
            <div className="space-y-2">
            <p className="font-bold">Identity</p>
            <div className="flex space-x-4">
                <div className='flex-1 space-y-2'>
                <Form.Item
                    name={'name'}
                    rules={[{required:true}]}
                >
                    
                    <Input placeholder='Name' className='rounded border border-gray-500'/>

                </Form.Item>
                <Form.Item
                    name={'position'}
                >
                    
                    <Input placeholder='Position' className='rounded border border-gray-500'/>

                </Form.Item>
                <Form.Item
                    name={'company'}
                >
                    
                    <Input placeholder='Company' className='rounded border border-gray-500'/>

                </Form.Item>
                <Form.Item
                    name={'bio'}
                >
                    
                    <TextArea placeholder='About' className='rounded border border-gray-500' rows={3}/>

                </Form.Item>
              

                </div>
                <div className="w-[220px] h-[220px] border border-gray-500 rounded">
                    <UploadFormImage
                    
                        name='picture'
                        uploadText='Upload your logo'
                        maxSize={3}
                        storageUrl={`user/${user?.id}`}
                        reset={false}
                        // rules={[{required:true, message: 'Please upload a logo'}]}
                        listType="picture-card"
                        uploadUrl="uploads"
                    />

                </div> 
          
            

            </div>
          
            </div>
            <div className="flex space-x-4">
                <div className='space-y-2 w-1/2'>
                    <p className="font-bold">Adress</p>
                    <Form.Item
                        name={'adressLine1'}
                    >
                        <Input placeholder='line1' className='rounded border border-gray-500'/>
                    </Form.Item>
                    <Form.Item
                        name={'adressLine2'}
                    >
                        <Input placeholder='line2' className='rounded border border-gray-500'/>
                    </Form.Item>
                    <div className="flex space-x-1">
                        <Form.Item
                            name={'country'}
                            className='w-1/2'
                        >
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) => prevValues.country !== curValues.country}

                            >
                                {({ getFieldValue, setFieldsValue }) => (
                    
                                    <SelectCountry
                                        selectedCountry={{id: selectedCountry, value: getFieldValue('country')}}
                                        setselectedCountry={(id:string, value:string | null) => {
                                        setselectedCountry(id)
                                    
                                        setFieldsValue({ country: value, city: undefined})

                                        }}
                                    />
                                )}

                            </Form.Item>

                        </Form.Item>
                        <Form.Item
                            name={'city'}
                            className='w-1/2 '
                        >

                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) => prevValues.city !== curValues.city}
                            >
                                {({ getFieldValue, setFieldsValue }) => (
                                    
                                
                                    <SelectCity
                                        selectedCountry={selectedCountry}
                                        selectedRegion={getFieldValue('city')}
                                        setselectedRegion={(value:string | null) => {
                                            setFieldsValue({ city: value})
                                        }}
                                    />
                                    )}

                            </Form.Item>

                        </Form.Item>
                    

                    </div>
                    <Form.Item
                        name={'zipcode'}
                    >
                        <Input placeholder='postalCode' className='rounded border border-gray-500'/>
                    </Form.Item>
                
                </div>

                <div className="space-y-2 w-1/2 ">
                    <p className="font-bold">Contact info</p>
                
                    <Form.Item
                        name={'phone'}
                    >
                        <Input placeholder='phone' className='rounded border border-gray-500'/>
                    </Form.Item>
                    <Form.Item
                    name={'website'}
                    rules={[ { type: 'url'}, { type: 'string', min: 6 }]}
                >
                    <Input placeholder='website' className='rounded border border-gray-500' />
                </Form.Item>
                <Form.Item 
                    name={'linkedin'}
                    rules={[{ type: 'url'}, { type: 'string', min: 6 }]}
                >
                    <Input placeholder='linkedin' className='rounded border border-gray-500'/>
                </Form.Item>
                </div>

            </div>
            <button className='w-[100px] justify-center input-h flex items-center rounded bg-sub-windows disabled:opacity-40 border border-black' type={'submit'} disabled={loading}>Submit</button>
        </div>
      </Form>

    </Spin>

  )
}
