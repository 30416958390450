import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BlockwithImgLayout from '../../components/block/withImgLayout'
import TextBlock2 from '../../components/block/TextBlock2'
import { CameraIcon, ChevronRightIcon } from '@heroicons/react/solid'
import Blocks from '../../components/block/Blocks'
import BlockwithoutImgLayout from '../../components/block/withoutImg'
import CentredBlock from '../../components/block/CentredBlock'
import { Link, useNavigate } from 'react-router-dom'
import HorizontalBlock from '../../components/block/HorizontalBlock'
import i18n from '../../../../i18n'



export default function Home() {
    const navigate = useNavigate()
    const {t} = useTranslation(['benefits'])

 
  return (
    <div className="w-full h-full relative">
    
        <div className="pb-end-page">
        
            
            
            <div className=" space-y-[270px]">
                <div className="relative flex flex-col justify-center  max-w-page mx-auto min-h-[calc(100vh-176px)]">
                    <div className='flex space-x-[55px] justify-centyer items-center '>
                        <div className=" w-full text-center space-y-10">
                            <div className="h-[400px] flex justify-center items-center w-full">
                                <CameraIcon className='w-[100px] h-[100px] opacity-10 ' />
                            </div>
                            <div>
                                <p className='title-main w-full'>
                                    {t('benefits:title')}
                                </p>
                                <p className="text-main max-w-[950px] mx-auto">
                                {t('benefits:description.p1')}<br/> {t('benefits:description.p2')}<br/> {t('benefits:description.p3')}
  

                                </p>

                            </div>
                       
                            

                        </div>
                     

                    </div>
                </div>

                <div className="space-y-16">

                    <BlockwithoutImgLayout orientation='right'>
                        <>
                        <TextBlock2
                            // label={t('platform:p1.label')}
                            label=""
                            title={t('benefits:section1.title')}
                            // text={t('platform:p1.text')}
                            text={t('benefits:section1.text')}
                        />
                        
                       
                        </>

                    </BlockwithoutImgLayout>
                    <div className="max-w-page mx-auto">
                    <Blocks
                            columns={`grid-cols-4 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1`}
                            content={[
                                {
                                    title: t('benefits:section1.block1.title'),
                                    text: t('benefits:section1.block1.text')
                                },
                                {
                                    title: t('benefits:section1.block2.title'),
                                    text: t('benefits:section1.block2.text')
                                },
                                {
                                    title: t('benefits:section1.block3.title'),
                                    text: t('benefits:section1.block3.text')
                                },
                            ]}
                        />
                    </div>
                </div>
            
            <div className="space-y-[100px]">
                <BlockwithImgLayout orientation='left'>
                        <>
                        <TextBlock2
                            // label={t('platform:p1.label')}
                            label=''
                            title={t('benefits:section2.title')}
                            // text={t('platform:p1.text')}
                            text={t('benefits:section2.text')}
                        />
                        <Blocks
                            columns={'grid-cols-2 max-[450px]:grid-cols-1'}
                            content={[
                                {
                                    title: t('benefits:section2.block1.title'),
                                    text: t('benefits:section2.block1.text')
                                },
                                {
                                    title: t('benefits:section2.block2.title'),
                                    text: t('benefits:section2.block2.text')
                                },
                               
                            ]}
                        />
                        </>

                    </BlockwithImgLayout>
               

            </div>
                
                <CentredBlock
                    topElement={
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="var(--bg-inverted)" fill-opacity="1" d="M0,192L1440,96L1440,320L0,320Z"></path></svg>
                    }
                    text={
                        <TextBlock2
                        // label={t('platform:p3.label')}
                        label=''
                        // title={t('platform:p3.title')}
                        title={t('benefits:section3.title') }
                        // text={t('platform:p3.text')}
                        text={<>
                            <span dangerouslySetInnerHTML={{ __html: t('benefits:section3.text') }} />
 
                        </>}
                    />
                    }
                    blocks={
                        {
                            columns: 'grid-cols-3 max-[750px]:grid-cols-2 max-[450px]:grid-cols-1',
                            content:[
                                {
                                    title: t('benefits:section3.block1.title'),
                                    text: t('benefits:section3.block1.text')
                                 
                                },
                                {
                                    title: t('benefits:section3.block2.title'),
                                    text: t('benefits:section3.block2.text')
                                 
                                },
                                {
                                    title: t('benefits:section3.block3.title'),
                                    text: t('benefits:section3.block3.text')
                                 
                                },
                            ]
                        }
                    }
                />

                <BlockwithImgLayout orientation='left'>
                    <>
                    <TextBlock2
                        label=''
                        title={t('benefits:section4.title')}
                        // text={t('platform:p1.text')}
                        text={t('benefits:section4.text')}
                    />
                    <HorizontalBlock title={t('benefits:section4.block1.title')} text={t('benefits:section4.block1.text')}/>
                    <HorizontalBlock title={t('benefits:section4.block2.title')} text={t('benefits:section4.block2.text')}/>
                    <HorizontalBlock title={t('benefits:section4.block3.title')} text={t('benefits:section4.block3.text')}/>
             

                    </>

                </BlockwithImgLayout>
                <div className='space-y-10'>
                    <BlockwithoutImgLayout orientation='right'>
                        <>
                        <TextBlock2
                            // label={t('platform:p2.label')}
                            label=''
                            title={t('benefits:section5.title')}
                            // title={t('platform:p2.title')}
                            // text={t('platform:p2.text')}
                            text={t('benefits:section5.text')}
                        />

                
                        </>

                    </BlockwithoutImgLayout>
                    <div className='max-w-page mx-auto'>
                        <button className='bg-gray-300 px-7 py-2 rounded text-level2 mb-16' onClick={()=>navigate(`/home/${i18n.language}/pages/newspapers/revenu`)}>{t('benefits:section5.btn')}</button>
                        <Blocks 
                            columns={'grid-cols-3 max-[750px]:grid-cols-2 max-[450px]:grid-cols-1'} 
                            content={[
                                {
                                    title: t('benefits:section5.block1.title'),
                                    text: t('benefits:section5.block1.text')
                                },
                                {
                                    title: t('benefits:section5.block2.title'),
                                    text: t('benefits:section5.block2.text')
                                },
                                {
                                    title: t('benefits:section5.block3.title'),
                                    text: t('benefits:section5.block3.text')
                                }
                            ]} 
                        />

                    </div>
                    
                    
                </div>

                {/* <BlockwithImgLayout orientation='left'>
                    <>
                    <TextBlock2
                        label='Obtenez des insights'
                        title="Obtenez des stats précieuses sur votre secteur, territoire,...."
                     
                        text="Votre emplacement Qfs génère du trafic et de l'activité (recherche, ajouts de publications...). Accédez aux rapports statistiques et tirez des enseignements précieux sur le fonctionnement de votre secteur d'activité, de votre territoire...."
                    />
              
                    </>

                </BlockwithImgLayout> */}

           

              
            </div>
        </div>


       
                      
            

         

    </div>

             
                      
                    
                    
      
  )
}