import { Dropdown, Spin, Steps } from 'antd'
import React, { useState } from 'react'
import Preservation from './Reservation';
import { useQfsContext } from '../../../../../../context/qfsContext';
import Subscription from './Subscription';
import Deployment from './Deployment';
import Prelancement from './Prelancement';
import Lancement from './Lancement';
import Preparation from './Preparation';
import { useAppContext } from '../../../../../../../../context/AppContext';
import { ChevronDownIcon } from '@heroicons/react/solid';
import useGeGuestPartner from '../../../../../../hooks/useGetGuestPartner';
import { useParams } from 'react-router-dom';
const { Step } = Steps

function StepsComponent({winSize, currentStep, setcurrentStep}:{winSize:any, currentStep:number, setcurrentStep:any}) {
    return (
        <Steps direction={winSize > 900 ? 'vertical' : 'horizontal'} current={currentStep -1} className='offer' > 
                    
            <Step title={<p className={`${currentStep===1 && '!font-bold'} text-level2 !mt-0`}>Déploiement</p>} description='Mar-Avr 2025' status='finish' onClick={() => setcurrentStep(1)} />
            <Step title={<p className={`${currentStep===2 && '!font-bold'} text-level2 !mt-0`}>Réservation</p>} description='Avr-Mai 2025' onStepClick={() => console.log('clicked')} onClick={() => setcurrentStep(2)} status={currentStep ===2 ? 'wait' : 'process'} />
            <Step title={<p className={`${currentStep===3 && '!font-bold'} text-level2 !mt-0`}>Souscription</p>} description='Mai 2025' onStepClick={() => console.log('clicked')} onClick={() => setcurrentStep(3)} status='process' />

            <Step title={<p className={`${currentStep===4 && '!font-bold'} text-level2 !mt-0`}>Installation</p>} description='Jun 2025' onStepClick={() => console.log('clicked')} onClick={() => setcurrentStep(4)} status='process' />
            <Step title={<p className={`${currentStep===5 && '!font-bold'} text-level2 !mt-0`}>Lancement</p>} description='Sep 2025' onStepClick={() => console.log('clicked')} onClick={() => setcurrentStep(5)} status='process' />

        </Steps>
    )
}
export default function Reserve() {
    const [currentStep, setcurrentStep] = useState(1);
    const {currentLocation} = useQfsContext()
    const {winSize} = useAppContext()
    const [loading, setloading] = useState(false);
    const params = useParams()
    const { partner } = useGeGuestPartner({id: params.id!, setloading});
    
  return (
    <div className='space-y-[100px]'>
        <Spin spinning={loading}>
            <div className='flex space-4 items-center  h-full max-[900px]:block max-[900px]:space-x-0'>
                <div className="space-y-7 pl-[100px] max-[1400px]:pl-[50px] max-[900px]:pt-[20px] max-[574px]:space-y-1 w-[350px]">
                    <p className="!font-bold text-level2 !mt-0 ">{currentLocation?.title}</p>
                    {winSize.w > 574 ? (
                        <div className='max-h-[calc(100vh-150px)] h-max max-[900px]:max-h-[calc(100vh-274px)]  overflow-auto'>
                            <StepsComponent winSize={winSize.w} currentStep={currentStep} setcurrentStep={setcurrentStep}/>

                        </div>

                    ) : (
                        <Dropdown trigger={['click']} overlay={
                            <div className='bg-white p-2 rounded teddra-shadow w-full pt-3'>
                                <StepsComponent winSize={winSize.w} currentStep={currentStep} setcurrentStep={setcurrentStep}/>

                            </div>
                    }>
                            <button className='flex space-x-2 items-center'>
                                <p className="text-level2 !mt-0">Les étapes</p>
                                <ChevronDownIcon className='icon-mini'/>
                            </button>
                        </Dropdown>
                    )}

                </div>
                <div className="flex-1 ">
                    <div className='pl-[180px] pr-[70px] max-[1700px]:pl-[70px] max-[1570px]:px-[20px] flex-1 space-y-[50px] h-[calc(100vh-60px)] max-[900px]:h-[calc(100vh-184px)]  overflow-auto flex flex-col pb-[40px]'>
                        {/* <div className=" w-full flex space-x-10 justify-center mt-[80px]">          
                            <div className="">
                                <p className='title-level2 text-center'>
                                    {currentLocation?.title}
                                </p>
                                <p className="text-level2 text-center !mt-0">
                                        Réservez ce serveur et suivez son déploiement 
                                </p>
                            

                            </div>
                        </div> */}
                        <div className="flex-1 flex flex-col mt-[100px] pb-[40px]">
                            {currentStep === 1 &&
                                <Deployment partnerId={partner?.id!} nextStep={() => setcurrentStep(2)}/>
                            }
                            {currentStep === 2 &&
                                <Preservation partner={partner} nextStep={() => setcurrentStep(3)}  prevStep={() => setcurrentStep(1)}/>
                            }
                            {currentStep === 3 &&
                                <Subscription nextStep={() => setcurrentStep(4)} prevStep={() => setcurrentStep(2)}/>
                                }
                                {currentStep === 4 &&
                                <Preparation nextStep={() => setcurrentStep(5)} prevStep={() => setcurrentStep(3)}/>
                                }
                        
                            {currentStep === 5 &&
                                <Lancement prevStep={() => setcurrentStep(4)}/>
                                }

                        </div>

                    </div>

                </div>
                
            </div>

        </Spin>
    </div>
  )
}
