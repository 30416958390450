import React, { useMemo } from 'react';
import { useQfsContext } from '../../context/qfsContext';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import LocationGlobalLayout from '../../components/locationLayout/globalLayout';
import i18n from '../../../../i18n';
import { useAppContext } from '../../../../context/AppContext';

const ServerLayout = () => {
  // Only extract what we need from context to prevent unnecessary re-renders
  const { currentLocation } = useQfsContext();
  const params = useParams();
  const location = useLocation();
  const { domain } = useAppContext();
  // Memoize path building to avoid recalculations on re-renders
  const navigationPaths = useMemo(() => {
    const basePath = `/${params.serverId ? `${params.serverId}/` : ''}${params.coll}/${params.id}`;
    return {
      standard: basePath,
      activation: `${basePath}/activation`
    };
  }, [params.serverId, params.coll, params.id]);
  
  // Memoize the redirect decision to avoid unnecessary work during re-renders
  const redirectPath = useMemo(() => {
    // Check if this is the current location's page
    if(!currentLocation){
      return null
    }
    if (currentLocation?.id !== params.id) {
      return null;
    }

    const isActivationPath = location.pathname.includes('activation');
    
    // Redirect inactive locations to activation page
    if (!currentLocation.isActive && !isActivationPath) {
      return navigationPaths.activation;
    }
    
    // Redirect active locations away from activation page
    if (currentLocation.isActive && isActivationPath) {
      return navigationPaths.standard;
    }
    
    return null;
  }, [currentLocation?.id, currentLocation?.isActive, params.id, location.pathname, navigationPaths]);

  // Memoize the layout props to prevent unnecessary re-creation
  const layoutProps = useMemo(() => ({
    selectedKey: params.id || '',
    disabled: {
      filter: false,
      new: false,
      display: false,
      query: false
    },
    publicationBar: true,
    collection: params.coll ?? 'Domains',
    id: params.id ?? domain?.id!
  }), [params.id, params.coll]);

  // If we need to redirect, do so after all hooks have been called
  if (redirectPath) {
    return <Navigate to={`/${i18n.language}${redirectPath}`} replace />;
  }

  return (
    <div className="server-layout-container h-full flex flex-col">
      <LocationGlobalLayout {...layoutProps}>
        <Outlet />
      </LocationGlobalLayout>
    </div>
  );
};

// Use React.memo to prevent re-renders when props haven't changed
export default React.memo(ServerLayout);