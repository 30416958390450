import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import TreeComponent from './tree/TreeComponent'
import { iconType, Server, VolumeSearch } from '../../../../types'
import Search from './search'
import Icon from '../../../../components/icons/Icon'
import { getClusterTree, getServerTree, getTree } from '../../../../services/api/request'
import { Spin } from 'antd'

import { Link } from 'react-router-dom'
import { storageUrl } from '../../../../services/api/constants'
import { useQfsContext } from '../../context/qfsContext'
import { fetchInt } from '../../../../utils/api/fetchInt'


type Props = {
    handleLocationSelection: (node:any) => void, 
    selectedKey:string,
    handleSearchSelection : (doc:VolumeSearch) => void,
    openAction: ({title, icon,key, id}:{title:string, icon:iconType, key:string, id:string}) => void,
    datacenter: Server | null, 
    serverId:string|undefined, 
    server: Server | null,
    resultsLength:number,
    setresultsLength:any,
    isSm:boolean,
    setHidePanel:any
}
const LIMIT = 15
const loopThrough = (tree:any, id:string, data:any) => {
  return tree.map((node:Server) => {
      if (node.id === id) {
          return {
              ...node,
              children:data,
          };
      }
      if (node.children) {
          return {
              ...node,
              children: loopThrough(node.children, id, data)
          };
      }
      return node;
  });
}
export default function Sidebar({handleLocationSelection, selectedKey,handleSearchSelection, openAction, datacenter, serverId, setresultsLength}: Props) {
    const [serverTree, setserverTree] = useState<Server[] | null>(null);
    const [tree, settree] = useState<any[] | null>([]);

    const [mode, setmode] = useState<'search'  | 'localTree'>('localTree');
    const [searchValue, setsearchValue] = useState('');
    const {currentLocation} = useQfsContext()
    const [loading, setloading] = useState(false);
    const [clusterExist, setclusterExist] = useState(true);
    const [serverExist, setserverExist] = useState(true);
    const [regionId, setregionId] = useState<null | string>(null);
    const [loadingAllServerTree, setloadingAllServerTree] = useState<string|null>(null);

    useEffect(() => {
      (async () => {
     
        if(!datacenter) return
        setloading(true)

           fetchInt(getTree({id: datacenter.id})).then(res => res.json()).then(data =>{
                     settree([
                      {
                        title:datacenter?.title, 
                        iconUrl:`${storageUrl}${(datacenter as any)?.icon?.src}`,
                        id:'/',
                        children: data.data,
                        key:'/'
                      }
                     ])
                    //  setexpandedKeys(['0', '1', '2', '3', '4', '5'])
                    //  setautoExpandParent(false)
                }).catch(e => console.log(e)).finally(() => setloading(false))
      })() 
    }, [datacenter?.id])


    const updateTree:any = async( data:Server, all?:boolean) =>{  
        if(data.children) return
        if(data.depth === 1){
          const  children = await fetchInt(getClusterTree({id:data.id, domainId: datacenter?.id!})).then(res => res.json())
          const newTree =  loopThrough(tree, data.id, children.data)
          settree(newTree)

        }
        if(data.coll === 'RegionalServers' ){
          setloadingAllServerTree(data.id)
          
          let  children = await fetchInt(getServerTree({id:data.id, clusterId:data.clusterId, limit: all ? undefined : LIMIT})).then(res => res.json())
          if(children.count > LIMIT && !all){
            children ={data:[... children.data, {title: `see all ${children.count}`, id:'seeall', isLeaf:true, iconUrl:'Open', iconType:'icon', serverId:data.id, clusterId:data.clusterId, coll:'RegionalServers'}]}
          }
          const newTree =  loopThrough(tree, data.id, children.data)
          settree(newTree)
          setloadingAllServerTree(null)
        }
    
      
    }

    useEffect(() => {
      console.log('verifycluster')
      
      if(tree && tree.length >0 && serverId){
        let itExist = false
       
        tree[0]?.children?.map((node:any) => {
          node?.children?.map((child:any) => {
            if(child.id === serverId){
              if(child.children){
                itExist = true
              }
            }
          })
        })
        setclusterExist(itExist)

      }
    }, [tree?.length, serverId]);
    useEffect(() => {
      console.log('verifyserver')
     
      if(tree && serverId && currentLocation && serverTree){
        const serverVolume = currentLocation?.routes.filter((v:any) => v.depth ===3)?.[0]?.id
        console.log(currentLocation?.routes)
        if(serverVolume){
          let itExist = false
          tree[0]?.children?.map((node:any) => {
            node?.children?.map((child:any) => {
              child?.children?.map((c:any) => {
                if(c.id === serverVolume){
                  if(c.children){ 
                    itExist = true
                  }
                }
              })
            })
          })
          setserverExist(itExist)

        }

      }
    }, [serverId, currentLocation?.id, serverTree?.length]);
    // console.log(serverExist, tree?.length, currentLocation?.routes, clusterExist, serverTree?.length)
    useEffect(() => {
            
      if(serverId && tree && tree.length >0 && datacenter?.local){
        (async () => {
            setloading(true)
          
            if(!clusterExist ){
             
              const  children = await fetchInt(getClusterTree({id:serverId, domainId: datacenter?.id!})).then(res => res.json())
      
        
              const newTree =  loopThrough(tree, serverId, children.data)
              settree(newTree)
              setserverTree(children.data)

            }

              setloading(false)
          })()
      }
  }, [clusterExist]);

  useEffect(() => {
      (async () => {
        console.log(currentLocation?.routes, tree?.length, serverId, serverExist)
        if(currentLocation?.routes && tree && tree.length > 0 && datacenter && serverId && !serverExist && serverTree && datacenter?.local ){
          console.log('fetch drives')
          const serverVolume = currentLocation?.routes.filter((v:any) => v.depth ===3)?.[0]?.id
          if(serverVolume){
            setloading(true)
            const  children = await fetchInt(getServerTree({id:serverVolume, clusterId:serverId})).then(res => res.json())
           
            
            const newTree =  loopThrough(tree, serverVolume, children.data)
            settree(newTree)
            setloading(false)
            setregionId(serverVolume)
          }else{
            setregionId(null)
          }
        }

      })()
  }, [serverExist]);
  
  return (
    <div className='modules h-full  '>
        <div className=" h-full ">
      
        
          <div className="">
              <Header 
                mode={mode} 
                setmode={setmode} 
                search={{value:searchValue, setValue:setsearchValue}}
              />
            <Spin spinning={loading}>
              <div className='overflow-auto height-sidebar border-b border-main border-r border-main' id='tutorial-sidebar'>

                {mode === 'search' && (
                  <div className='space-y-2'>
                
                    {/* <SearchResults value={searchValue} selectedKey={selectedKey} handleSearchSelection={handleSearchSelection} results={searchResults}/> */}
                    <Search
                      selectedKey={selectedKey}
                      domainId={datacenter?.id!}
                      setResultsLength={setresultsLength}
                      handleSearchSelection={(doc) => {
                        setclusterExist(true)
                        setserverExist(true)
                        handleSearchSelection(doc)
                      }}
                      searchValue={searchValue}
                      domainType={datacenter?.local ? 'local' : datacenter?.regional ? 'regional' : 'thematic'}
                    />
                  </div>

                )
                }
                {mode === 'localTree' && (
                  <div className='space-y-5 pt-4'>
                    {/* <ResourcesTree
                      tree={resourcesTree}
                      handleLocationSelection={handleLocationSelection}
                      selectedKey={selectedKey}
                      openAction={openAction}
                    
                    /> */}
                    <TreeComponent
                    handleLocationSelection={handleLocationSelection}
                    selectedKey={selectedKey}
                    openAction={openAction}
                    datacenter={datacenter}
                    tree={tree}
                    setResultsLength={setresultsLength}
                    updateTree={updateTree}
                    defaultExpandedKeys={serverId ? [serverId, ...regionId ? [regionId] : []] : []}
                    loadingAllServerTree={loadingAllServerTree}
                  />
                  </div>
                )}
                
              </div>

            </Spin>
            
        

          </div>
        
        
        </div>
    
        
      
    </div>
  )
}
