import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../../context/AppContext'
import { Drawer } from 'antd'
import { useNavigate } from 'react-router-dom'
import Icon from '../../../icons/Icon'
import { UI_WIDTH_PUBLICATIONS_SM } from '../../../../constants'
import VolumeDetails from './index'
import DrawerSmLayout from '../../Drawers/drawerSmLayout'

export default function VolumeDeviceLayout({header}:{header:JSX.Element}) {
    const { winSize } = useAppContext()
    const navigate = useNavigate()
    const [visible, setvisible] = useState(false);
    useEffect(() => {
        
        setvisible(true)
    }, []);
  return (
    <>
        {winSize.w >  UI_WIDTH_PUBLICATIONS_SM ? (
        
            <VolumeDetails/>
        ) : (
                <DrawerSmLayout visible={visible} onClose={() => navigate(-1)} placement='right' title='Details'>

                    <>
                       
                        {header}
                        <VolumeDetails/>
                    </>

                </DrawerSmLayout>
            
        )}
    </>
  )
}
