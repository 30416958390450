import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";
import { UI_WIDTH_IS_MOBILE } from "../../../../constants";
import { Dropdown } from "antd";
import { ChevronDownIcon } from "@heroicons/react/solid";



const Languages = ({language, changeLanguage}:{language:string,changeLanguage:(data:string) => void}) => {
  return (
    <>
      <button className={`${language === "en" ? "active-lng max-[400px]:after:hidden max-[400px]:font-bold" : ""} block`} onClick={() => changeLanguage("en")}>En</button>
      <button className={`${language === "fr" ? "active-lng max-[400px]:after:hidden max-[400px]:font-bold" : ""} block`} onClick={() => changeLanguage("fr")}>Fr</button>
      <button className={`${language === "es" ? "active-lng max-[400px]:after:hidden max-[400px]:font-bold" : ""} disabled:opacity-30 block`} disabled onClick={() => changeLanguage("es")}>Es</button>
    </>
  )
}
const SwitchLanguages = () => {
  const { i18n } = useTranslation();
  const { lng } = useParams();
  const location = useLocation()
  const {winSize}= useAppContext()
  // Function to change language
  const changeLanguage = (newLang:string) => {
    if (lng !== newLang) {
      // Update i18n language
      i18n.changeLanguage(newLang);

      // Replace the language part in the URL
      const newPath = location.pathname.replace(`/${lng}`, `/${newLang}`);

      // Force a full page reload with the new language
      window.location.replace(newPath);
    }
  };

  return (
    <div className="flex space-x-3">
      {winSize.w > UI_WIDTH_IS_MOBILE ? (
        <Languages language={i18n.language} changeLanguage={changeLanguage} />

      ) : (
        <Dropdown
        trigger={["click"]}
          overlay={
            <div className="bg-white teddra-shadow p-2 rounded w-[80px]">
              <Languages language={i18n.language} changeLanguage={changeLanguage} />

            </div>
          }
        >
          <div className="flex items-center space-x-2">
            <button className={'active-lng'}>{i18n.language === 'en' ? 'En' : 'Fr'}</button>
            <ChevronDownIcon className="icon-mini"/>
          </div>
      </Dropdown>
      )}
      
    </div>
  );
};

export default SwitchLanguages;
