import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9413 15.5965V19.6532C13.9413 20.3991 14.546 21.0037 15.2919 21.0037H19.7937C20.1519 21.0037 20.4954 20.8615 20.7487 20.6082C21.002 20.3549 21.1443 20.0114 21.1443 19.6532V15.6614"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0042 16.3493L18.0648 13.1977C17.7359 12.9346 17.2687 12.9346 16.9398 13.1977L13.0004 16.3493"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99832 13.5006H10.9996"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99832 17.0021H8.99874"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0017 6.99792L13.0004 9.99917L10.2853 7.99833L7.99832 9.99917"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0017 8.99875V6.99792H14.0008"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9996 21.0037H5.99749C4.89246 21.0037 3.99666 20.1079 3.99666 19.0029V4.99708C3.99666 3.89205 4.89246 2.99625 5.99749 2.99625H18.0025C19.1075 2.99625 20.0033 3.89205 20.0033 4.99708V10.9996"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
