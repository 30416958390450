import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { fetchInt } from '../../../utils/api/fetchInt';
import { setUserCard } from '../../../services/api/request/user';

const CardForm = ({ onSuccess }:{onSuccess:()=>void}) => {
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event:any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet
            return;
        }

        setLoading(true);

        const card:any = elements.getElement(CardElement);

        // Create a token using the card information
        const { token, error } = await stripe.createToken(card);

        if (error) {
            console.error('Error creating token:', error.message);
            setLoading(false);
            return;
        }
        console.log(token)
        // Send the token to your backend for validation
        try {
            const req = await fetchInt(setUserCard, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({cardToken:token.id})
            })
            const res = await req.json()
      

            if (res.success) {
                onSuccess()
            } else {
                alert('Card validation failed.');
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Error processing card.');
        }

        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit} className='p-8 space-y-4 max-w-[500px]'>
            <p className='font-bold'>Enter Card Details</p>
            <CardElement />
            <button type="submit" className='px-4 py-2 border border-main rounded' disabled={loading || !stripe}>
                {loading ? 'Processing...' : 'Save'}
            </button>
        </form>
    );
};

export default CardForm;
