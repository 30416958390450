import React, { useEffect, useState } from 'react'
import { btnClassName, CardComponent } from '../../../../../../home/Layout';
import Icon from '../../../../../../../components/icons/Icon';
import { useTranslation } from 'react-i18next';
import useNavigateTo from '../../../../../hooks/useNavigateTo';
import { useLocation, useParams } from 'react-router-dom';
import Block from '../../../../../../home/components/block/Block';
import { useQfsContext } from '../../../../../context/qfsContext';

export default function Offer() {
    const {t} = useTranslation()
    const {goTo} = useNavigateTo()
    const params = useParams()
    const { currentLocation} = useQfsContext()
    const handleClick = (page:string) => {
        goTo(`/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}/activation/pages/${page}`, {})
    }

  

  return (
    <div className={`overflow-y-auto h-[calc(100vh-89px)]  `}    style={{ scrollBehavior: "smooth" }} // Ensures smooth scrolling
                >
            <div className="w-full h-full relative ">

                       
                        <div className='max-w-page mx-auto  flex flex-col   justify-center h-full max-[800px]:justify-normal max-[800px]:pt-5'>
                            <div className=" max-w-[1100px] mx-auto space-y-[70px]">
                                <p className="title-level2 ">Serveur : {currentLocation?.title}</p>
                                <div className="grid grid-cols-4 gap-14 max-[800px]:grid-cols-2 max-[560px]:grid-cols-1 mx-auto">
                                    <div className='space-y-4'>
                                    
                                        <Block
                                            block={{
                                                title: 'Réservez maintenant votre serveur',
                                                text: 'Ne manquez pas cette opportunité : chaque serveur Qorddu est proposé en exclusivité à un partenaire unique.',
                                                img:{src:'                                                      https://firebasestorage.googleapis.com/v0/b/qorddu-production.firebasestorage.app/o/icons%2Fvolumes%2FclusterReserved.svg?alt=media&token=bce4b379-67bd-4e69-abb0-8cc42ed2f6a1' 
                                                , type:'image'}
                                            }}
                                        />
                                        <button className='bg-skin-fill-inverted text-skin-inverted rounded px-8 py-2 text-level2' onClick={() => handleClick(`reserve`)}>Réservez ce serveur</button>
                                    </div>
                                    <div className='space-y-4'>
                                        <Block
                                            block={{
                                                title: t('server:cards.demo.title'),
                                                text: t('server:cards.demo.description'),
                                                img:{src:'Demo', type:'icon'}
                                            }}
                                        />
                                        <button className='bg-gray-300 rounded px-8 py-2 text-level2' onClick={() => handleClick(`demo`)}>{t('open')}</button>
                                    </div>
                                    <div className="space-y-4">
                                        <Block
                                            block={{
                                                title: t('server:cards.why.title'),
                                                text: t('server:cards.why.description'),
                                                img:{src:'WhyReserve', type:'icon'}
                                            }}
                                        />
                                        <button className='bg-gray-300 rounded px-8 py-2 text-level2' onClick={() => handleClick(`newspapers/benefits`)}>{t('open')}</button>

                                    </div>
                                    <div className="space-y-4">
                                        <Block
                                            block={{
                                                title: t('server:cards.qorddu.title'),
                                                text: t('server:cards.qorddu.description'),
                                                img:{src:'Grid', type:'icon'}
                                            }}
                                        />
                                        <button className='bg-gray-300 rounded px-8 py-2 text-level2' onClick={() => handleClick(`qorddu/vision`)}>{t('open')}</button>
                                    </div>
                                
                            
                                
                                
                                </div>

                            </div>

                        </div>

            </div>

    </div>
  )
}
