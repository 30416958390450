import { useEffect, useState } from "react";
import { getDomain } from "../services/api/request";
import { Server } from "../types";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchInt } from "../utils/api/fetchInt";

const useFetchDomain = (
  
  
  ) => {
    const [hostname, sethostname] = useState('');
    const [domain, setdomain] = useState<null |Server | undefined>(undefined);
    const [loading, setloading] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        
        sethostname(window.location.hostname)
    }, []);
    const handleNavigateToHome = () => {
      if(location.pathname === '/'){
        navigate('/home')
      }
    }
    
    useEffect(() => {
      const fetchVolum = async () => {
        if (!hostname) {
          // setdomain(null);
          return;
        }
  
        setloading(true);
        try {
          const response = await fetchInt(getDomain({ subdomain:hostname }));
          const data = await response.json();
          if(data.data){
            setdomain({ ...data.data, full_title: data.data.full_title, title: data.data.title, description: data.data.description });

          }else{
            setdomain(null);
          }
        } catch (error) {
        } finally {
          setloading(false);
        }
      };
  
      fetchVolum();
    }, [hostname]);
    useEffect(() => {
      
      if(domain === null){
        handleNavigateToHome()
      }
    }, [domain]);

    return {
      domain, loading
    }
  };

  export default useFetchDomain