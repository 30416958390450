import React from 'react'
import Icon from '../../../icons/Icon'
import IconAction from './IconAction'
import CardActions from './CardActions'

type Props = {
    type:string, title:string, description:string, website?:string, country?:string, active:boolean, width?:string, maxLines?:string, maxLinesName?:string, actions:{website:boolean, chat:boolean, card:boolean}
}
export default function Description({type, title, description, website, country, active, width, maxLines, maxLinesName, actions}:Props) {
  return (
    <div className={`${width ?? 'w-[165px]'} py-1 pr-1.5 leading-[19px] flex flex-col`}>
      <div className='flex-1'>
        <div className='flex space-x-2 items-center'>
            <p className={`text-muted text-[12px]`}>{type} {country}</p>

        </div>
          <p className={`font-bold ${maxLinesName && maxLinesName}`}>{title}</p>
          <p className={`${maxLines ?? 'ellipsis-4'}   ${active? 'text-inherit text-small' : 'text-muted '}`}>{description}</p>

      </div>
        <CardActions>
          <>
          {actions.website && (
            <IconAction link={website} icon='OpenWindow'/>

          )}
          {actions.chat && (
            <IconAction  icon='Chat'/>

          )}
          {actions.card && (
            <IconAction  icon='Chat'/>

          )}
          
          </>

        </CardActions>
             

     
        </div>
  )
}
