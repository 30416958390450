import React, { useEffect } from 'react'
import useFetchDomain from './hooks/useFetchDomain'
import { Spin } from 'antd'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAppContext } from './context/AppContext'
import useWindowSize from './hooks/useWindowSize'

export default function GlobalLayout() {

    const { domain, loading } = useFetchDomain()
    const { setDomain } = useAppContext()
    useWindowSize()
    useEffect(() => {
      
      document.title = 'Qorddu'
    }, []);

    useEffect(() => {
        if(domain === null && !loading){
          // navigate('/home')
        }else{
          setDomain(domain)

        }
    }, [domain, loading]);

   
    if(loading){
      return <Spin/>
    }
    
  return (
    <Spin spinning={loading}>
        <Outlet/>
    </Spin>
  )
}
