import React, { useEffect, useState } from 'react';
import { searchLocations } from '../../../services/search/search';
import { VolumeSearch } from '../../../types';
import { searchDocuments } from '../../../services/api/request/search';
import { useDebounce } from '../../../hooks/useDebounce';
import { QUERY_DEBOUNCE_DELAY } from '../../../constants';



type Props = {
  query: string;
  filter: string;
  sort?: string;
  limit?: number;
  status?: string;
};

export default function useSearchLocations({ query, filter, sort, limit, status }: Props) {
  const [searchResults, setSearchResults] = useState<{ document: VolumeSearch }[]>([]);
  const [resultsLength, setResultsLength] = useState(0);

  // Debounced Query
  const debouncedQuery = useDebounce(query, QUERY_DEBOUNCE_DELAY);

  useEffect(() => {
    (async () => {
    
        const req = await fetch(searchDocuments('volumes'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: searchLocations({ q: debouncedQuery, limit, sort, filter, status }),
          }),
        });
        const res: any = await req.json();
        setSearchResults(res.data.hits ?? []);
        setResultsLength(res.data.found);
      
    })();
  }, [debouncedQuery, filter, sort, limit, status]);

  return {
    searchResults,
    resultsLength,
  };
}
