import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LocationLayout from '../../components/locationLayout'
import useGetWebResources from '../../hooks/useGetWebResources'
import { useSearchContext } from '../../context/searchContext'
import { useUIContext } from '../../context/UIContext'
import { retrieveDocumentsById } from '../../../../services/api/request/search'
import { useQfsContext } from '../../context/qfsContext'
import useGetWebPages from '../../hooks/useGetWebPages'
import { useHandleLimits } from '../../hooks/useHandleLimits'
import Footer from '../../components/Footer'
import SearchBar from '../../GlobalLayout/SearchBar'
import QfsActionsBar from '../../GlobalLayout/QfsActionsBar'

export default function Website({type, selectedWindow}: {type:string, selectedWindow:string}) {
    const {disabled} = useUIContext()
    const params = useParams()
    const [mainWebsite, setmainWebsite] = useState<string | undefined>(undefined);
    const [query, setquery] = useState('');
    const [selectedPubType, setselectedPubType] = useState<{value:string, icon:string, type:'groupe' | 'type'}>({value:'', icon:'', type:'type'});
    const [pagination, setPaginate] = useState<{page:number, offset:number, limit:number}>({page:1, offset:0, limit:15});
    const [groupBy, setgroupBy] = useState({webresources:false, paidPubs:false});
    const { currentLocation } = useQfsContext()
    const { displayMode, setDisplayMode, barActivatedFields, setBarActivatedFields, setAll } = useUIContext()
    useHandleLimits({displayMode, disabled: false, setPaginate: (data) => setPaginate(prev => ({...prev, ...data}))})
    const { webresources, isLoaded, totalHits } = useGetWebPages({id:params.id!, groupBy: false, query, pagination, setGroupBy:(data) => setgroupBy(prev => ({...prev, data})), selectedPubType, setPaginate:(data) => setPaginate(prev => ({...prev, ...data})), onlyWebpages:true, websiteId:params.websiteId, limit:pagination.limit})
    const searchOn = !!query || !!selectedPubType.value
    
  const OnsearchClear = () => {
    setquery('')
    setselectedPubType({value:'', icon:'', type:'type'})
    setPaginate({page:1, offset:0, limit:7})

  }
    useEffect(() => {
      
      (async() => {
        if(!params.websiteId) return
        const req:any = await fetch(retrieveDocumentsById('publications', params.websiteId))
        const res:any = await req.json()
        const url = res?.data?.url 
        if(url){
          const hostname = new URL(url)
          if(hostname?.hostname){
            setmainWebsite(hostname?.hostname)
          }else{
            setmainWebsite(res.data?.title?.[res.data?.local])
          }
        }else{
          setmainWebsite(res.data?.title?.[res.data?.local])

        }
      })()
    }, [params.websiteId]);
    
  return (
    <>
    <SearchBar
          query={query}
          setQuery={setquery}
          selectedPubType={selectedPubType}
          setSelectedPubType={setselectedPubType}
          groupBy={groupBy}
          totalHits={{webresources:0, pubs:totalHits}}
          setOnlyWebpages={() => {}}
          onlyWebpages={true}
          setDisplayMode={setDisplayMode}
          disabled={{filter:false, display:false, new:false,query:false}}
          displayMode={displayMode}
          barActivatedFields={barActivatedFields}
          setBarActivatedFields={setBarActivatedFields}
          currentLocation={currentLocation}
          publicationsTypes={{
              types:[],
              loading:false
          }}
          onClear={OnsearchClear}
      />
  <QfsActionsBar details={true}/>
    <LocationLayout
    data={
      {
        partners:null,
        sponsors:null,
        publications:undefined,
        webResources:webresources,
        availableLocations:null
      }
      }
     
      suffix={`${params.serverId ? `${params.serverId}/` : ''}${params.coll}/${params.id}${type ? '/' + type : ''}/${selectedWindow ?  'window/' + selectedWindow + '/' : ''}webresources/${params.websiteId}/`}
      layoutProps={{
        selectedKey: params.id!,
        loading:!isLoaded,
        publicationBar:true,
        disabled:{...disabled, new:false},
        collection:params.coll!,
        id: params.id ? params.id : params.serverId!
      }}
      all={mainWebsite}
      isLoaded={isLoaded}
      searchStates={
        {
          query: query,
          selectedPubType: selectedPubType,
          onlyWebpages: true,
          totalHits: {pubs: 0, webresources:totalHits, locations: 0},
          isSearchActive: searchOn,
          allowSearch:true
        }
      }
      ui={{
        displayMode,
        barActivatedFields,
        setAll,
        groupBy
      }}

    
    />
      <Footer
          totalHits={{webresources:totalHits, pubs:0, location:0}}
          pagination={pagination}
          setPaginate={(data) => setPaginate(prev => ({...prev, ...data}))}
          onlyWebpages={true}
          currentLocation={currentLocation}
          allowPaginate={
              {
              webresources: true ,
              pubs: false
              }
            }
            windows={{
              active: selectedWindow,
              suffix:`/${params.serverId ? `${params.serverId}/` : ''}${params.coll}/${params.id}${type ? '/'+ type  : ''}`,
              disabled:false
            }}
      />
    
    </>
  )
}
