import React, { useEffect, useState } from 'react'
import { fetchInt } from '../../../utils/api/fetchInt';
import { getUserCard } from '../../../services/api/request/user';
import CardForm from '../components/CardForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { Card } from '../../../types';
import CardDetails from '../components/CardDetails';
import { message, Modal, notification } from 'antd';
const stripePromise = loadStripe('pk_test_51JsCxTD9lATj4rM20ZO2NndMIJqsO8uak28uEvHMjmQ060n7v1GmJjMdnEHts5qc766orYsXlKLcgOUoYpwTiVoR00HNybMpyD');  // Replace with your actual Stripe public key

export default function Billing() {
    const [card, setcard] = useState<Card | null>(null);
    const [Edit, setEdit] = useState(false);
    const [revalidate, setrevalidate] = useState(0);

    useEffect(() => {
        
        fetchInt(getUserCard).then(res => res.json()).then(data => setcard(data.paymentCard)).catch(err => console.log(err))
    }, [revalidate]);

    const onEdit = ()=> {
        setrevalidate(prev => prev+1)
        setEdit(false)
        notification.success({message: 'Payment card saved'})
    }
  return (
    <div className='py-10 '>
        {card ? (
            <div className="space-y-4 max-w-[400px]">
                <p className="font-bold">Payment Card</p>
                <CardDetails last4={card.cardNumber} brand={card.brand} expDate={card.expDate} />
                <button className='px-4 py-2 border border-main rounded' onClick={() => setEdit(true)}>Edit</button>
            </div>

        ): (
            <div className="space-y-4">
                <p>You don't have a credit card</p>
                <button className='px-4 py-2 border border-main rounded'>Add</button>
            </div>
        )}
        <Modal
            visible={Edit}
            onCancel={() => setEdit(false)}
            footer={null}
        >
            <Elements stripe={stripePromise}>
                <CardForm onSuccess={onEdit}/>
            </Elements>

        </Modal>
    </div>
  )
}
