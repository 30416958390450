import React from 'react'


type Props = {
    image:string, 
    title:string,
    type:string,
    active?:boolean
}
export default function Sm({image, title, type, active}:Props) {
  return (
    <div className={`hover-lg ${active ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'} rounded `}>
        <div className='w-[80px] h-[80px] border border-main mx-auto rounded' >
            <img src={image} className='w-full h-full rounded mx-auto ' alt="" />

        </div>
        <div>
            <p className="font-bold text-center">{title}</p>
            <p className='text-muted text-center'>{type}</p>

        </div>
    </div>
  )
}
