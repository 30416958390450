import React from 'react'
import PublicationDetails from '../../../../components/shared/details/Publications'
import { useUIContext } from '../../context/UIContext'
import { useAppContext } from '../../../../context/AppContext'
import { Drawer } from 'antd'
import { useNavigate } from 'react-router-dom'
import PublicationDeviceLayout from '../../../../components/shared/details/Publications/deviceLayout'
import DetailsActionsComp from '../../../../components/shared/bars/TopBar/actionsBar/actions/details'


export default function PublicationDetailsPage({isDrawer}:{isDrawer?:boolean}) {
    const { mode, setObjectDisplayMode } = useUIContext()
  
  return (
    <>
      <PublicationDeviceLayout
        publicationProps={{mode}}
        isDrawer={isDrawer}
        header={
          <div className='flex justify-end'>
            <DetailsActionsComp
              setObjectDisplayMode={setObjectDisplayMode}
              mode={mode}
              recheckPin={0}
  
            
            />

          </div>
        }
      />
    </>
  )
}
