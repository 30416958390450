import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../../../context/AppContext'
import { Drawer } from 'antd'
import PublicationDetails from './index'
import { useNavigate } from 'react-router-dom'
import { UI_WIDTH_PUBLICATIONS_SM } from '../../../../constants'
import DrawerSmLayout from '../../Drawers/drawerSmLayout'
type Props = {
    publicationProps:{
        mode : 'url' | 'object',
        extra?:{
          name:string | JSX.Element,
          value:JSX.Element | string
        }[],
        height?:string

    },
    header: JSX.Element,
    isDrawer?:boolean
  }
export default function PublicationDeviceLayout(props:Props) {
    const { winSize } = useAppContext()
    const navigate = useNavigate()
    const [visible, setvisible] = useState(false);
    useEffect(() => {
        
        setvisible(true)
    }, []);
  return (
    <>
        {(winSize.w >  UI_WIDTH_PUBLICATIONS_SM && !props.isDrawer) ? (
        
            <PublicationDetails {...props.publicationProps}/>
        ) : (
                <DrawerSmLayout visible={visible} onClose={() => navigate(-1)} placement='right' title='Details'>

                        <>
                        {props.header}
                        <PublicationDetails {...props.publicationProps}/>
                        
                        </>
                </DrawerSmLayout>
        )}
    </>
  )
}
