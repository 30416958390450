import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import UrlMode from '../modes/Url'
import ObjectMode from '../modes/Object'
import { Spin } from 'antd'
import { Highlight, OwnerType, PartnerType, Publication } from '../../../../types'

import { getVolumOwner } from '../../../../services/api/request'
import { getCollaborator } from '../../../../services/api/request/partners'

type Props = {
  mode : 'url' | 'object',
  extra?:{
    name:string | JSX.Element,
    value:JSX.Element | string
  }[],
  height?:string
}
export default function CollaboratorDetails({ mode, extra, height }: Props) {
    const location = useLocation()
    const [item, setitem ] = useState<null | PartnerType >(null)
    const [url, seturl] = useState<null | string>(null)
    const params = useParams()
    const [loading, setloading] = useState(false)
    useEffect(() => {
      if(location.state){
        setitem(location.state.item)
        seturl(location.state.url)

      }else{
       console.log(params)
        if(params.partnerId || params.sponsorId){
            setloading(true)
            fetch(getCollaborator({id:params.partnerId??params.sponsorId!, type : params.partnerId ? 'Partners' : 'Sponsors'})).then(res => res.json()).then(data =>{
                console.log(data)
                setitem(data.data)
                seturl(data.data.webResources?.website??data.data.website)

            }
            ).finally(() => setloading(false)).catch(err => console.log(err))
        }
        if(params.ownerId && params.coll && params.id){
            setloading(true)
            fetch(getVolumOwner({volumId:params.id, volumColl:params.coll!})).then(res => res.json()).then(data => {
                setitem(data.data)
                seturl(data.data.webResources?.website??data.data.website)
            }).finally(() => setloading(false)).catch(err => console.log(err))
        }
        
       
       
      }
    },[location.state, params.pparterId, params.ownerId, params.coll, params.id, params.sponsors])

  return (
    <div className='h-full w-full'>
      <Spin spinning={loading}>
        {mode === 'url' ? (

            url ? <UrlMode  url={url} /> : <></>
        ) : (
          <div className={` overflow-auto ${height ? height : 'height-explorer'} `}>
            {item && (
              <ObjectMode 
               

                fields={
                  [
                    {
                        name:'',
                        value: <div className='w-[120px] h-[120px]'>
                            <img src={item.logo} className='w-full h-full rounded' alt="" />
                        </div>
                    },
                    {
                      name:'Title',
                      value: item.name
                    },
                 
                    ...item.description ? [
                      {
                        name: 'Description',
                        // value:<p dangerouslySetInnerHTML={{__html: item.description[item.local]!}} style={{lineHeight:'1.6'}}></p>
                        value: <p>{item.description}</p>

                      }
                    ]:[],
                   
                  ]
                }
              />

            )}

          </div>
        )}

      </Spin>
    
    
    </div>
  )
}
