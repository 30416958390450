import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../../../../../../context/AuthContext'
import { useQfsContext } from '../../../../../context/qfsContext'

export default function Check({children}:{children:JSX.Element}) {
    const {id} = useParams()
    const {user} = useAuthContext()

   if(id && !user?.serversIds?.includes(id) && !['admin','super_admin'].includes(user?.role??'')){
    return <div></div>   
   }
  return (
    <>
        {children}
    </>
  )
}
