import React, { useState } from 'react'
import useFetchVolum from '../../../../../../../hooks/useFetchVolum'
import { useParams } from 'react-router-dom'

export default function Visibility() {
    const params = useParams()
    // const { volume, loading } = useFetchVolum(params.id!, params.coll!)
    const [volumes, setvolumes] = useState();

    return (
    <div className='pt-2'>
        <p className="">
            your publication will be visible in:
        </p>
    </div>
  )
}
