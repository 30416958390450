import React, { useState } from 'react'
import PublishInLayout from '../../../components/publishLayout/publishIn'
import { useVolumeCreationContext } from '../../../context/volumeCreationContext'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchInt } from '../../../../../utils/api/fetchInt';
import { createDedicatedVolume } from '../../../../../services/api/request';
import { message, notification } from 'antd';

export default function PublishVolume() {
    const [loading, setloading] = useState(false);
    const [selectedLocations, setselectedLocations] = useState<any>([]);
    const { editData, volumeType } = useVolumeCreationContext()
    const navigate = useNavigate()
    const removeSelectedLocation = (loc:any) => {
       setselectedLocations([])
    }
    const addSelectedLocation = (loc:any) => {
      setselectedLocations([loc])  
    }
    const submit = async () => {
      if(selectedLocations.length === 0){
        message.error('Please select at least one location')
        return
      }
      const submittedData:any = {
        title: {
            en: editData.title,
        },
        description: {
            en: editData.description
        }, 
        keywords:{
            en:editData.keywords
        },
        status: 'enabled',
        icon: volumeType.icon
      }
      try{

          const req = await fetchInt(createDedicatedVolume({collection:volumeType.collection, parentColl: selectedLocations[0].coll, parentId: selectedLocations[0].id}),{
              method: 'POST', 
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
              body: JSON.stringify(submittedData)
          })
          const res = await req.json()
          if(res.success){
              notification.success({message:res.message})
              navigate('/mydesk')
          }
      }catch(err){
          console.log(err)
      }finally{
          setloading(false)
      }
    }
  return (
    <div>
        <PublishInLayout
            submit={submit}
            editData={editData}
            submitLoading={loading}
            selectedLocations={selectedLocations}
            removeSelectedLocation={removeSelectedLocation}
            addSelectedLocation={addSelectedLocation}
            footerTitle='Display my volume in:'
        />
    </div>
  )
}
