

import CardWithWebsiteLayout from '../WithWebsite/Layout'
import { useRef } from 'react'
import Description from '../WithWebsite/Description'
import Sm from '../WithWebsite/Sm'
import { useAppContext } from '../../../../context/AppContext'
import { UI_WIDTH_IS_MOBILE } from '../../../../constants'

type Props = {
  sponsor: any,
  action?:() => void,
  active?:boolean,
  placeholder?:boolean
}
export default function SponsorWithMinuature({ sponsor , placeholder, active} : Props) {
  const {winSize} = useAppContext()
  return (
    <>
    {(winSize.w > UI_WIDTH_IS_MOBILE) ? (

      <div className={` ${active && 'bg-skin-fill-inverted text-skin-inverted'} rounded flex space-x-3`}>
        <CardWithWebsiteLayout
            item={{
              title: <p className={`${!active ? '!text-main-base' : 'text-white' }`}>{sponsor.title}</p>,
              image: sponsor.logo
            }}
            placeholder={placeholder}
            imgClassName={`h-[190px] w-full `}
            border
            active={active}
          />
          <Description
            title={sponsor.name}
            description={sponsor.description}
            website={sponsor.website}
            type=''
            active={!!active}
            actions={{website:true, chat:true, card:true}}
          />
  
      </div>

    ): (
      <Sm title={sponsor.name} type={''} image={sponsor.logo} active={active}/>
    )}
    </>
  )
}
