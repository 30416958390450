import { createRoot } from 'react-dom/client';
import { BrowserRouter, HashRouter, Navigate, Outlet, Route, Router, Routes, useLocation, useParams } from 'react-router-dom';

import 'antd/dist/antd.css';
import './styles/theme.css';
import './styles/global.css';
import './i18n'; 

import { AppContextProvider } from './context/AppContext';
import GlobalLayout from './GlobalLayout';
import { AuthContextProvider } from './context/AuthContext';
import CheckAuthRoute from './components/CheckAuth';
import PrivateRoute from './components/PrivateRoute';
import Network from './modules/Network';
import FilesNavigation from './modules/Qfs/Routings';
import StatsNavigation from './modules/Stats/Routings';
import MydeskNavigation from './modules/Mydesk/Routings';
import LoginRoutings from './modules/login/Routings';
import SettingsNavigation from './modules/settings/Routing';
import HomeRoutings from './modules/home/Routings';
import ConnectLayout from './modules/connect/Layout';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const getUserLanguage = localStorage.getItem('i18nextLng') || 'fr';
const LanguageWrapper = ({children}:{children:JSX.Element}) => {
  const { i18n } = useTranslation();
  const { lng } = useParams(); // Get lang from URL
  // Change language if it doesn't match
  useEffect(() => {
    if (lng && i18n.language !== lng) {
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n]);

  return children;
};

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <BrowserRouter>
      <Routes>
          <Route element={<AppContextProvider>
            <AuthContextProvider>
            <LanguageWrapper>
              <GlobalLayout/>

            </LanguageWrapper>
            </AuthContextProvider>
            </AppContextProvider>}>
            <Route path="/connect" element={<Navigate to={`/${getUserLanguage}/connect`} replace />} />

            <Route path="/:lng/connect" element={<ConnectLayout/>} />
            <Route
              element={<CheckAuthRoute/>}
            >
             <Route element={<PrivateRoute/>}>
              <Route path='/mydesk/*' element={<MydeskNavigation/>}/>
             <Route path='/stats/*' element={<StatsNavigation/>}/>
              <Route path="/home" element={<Navigate to={`/home/${getUserLanguage}`} replace />} />
             
              <Route path='/home/*' element={<HomeRoutings/>}/>
              <Route path='/settings/*' element={<SettingsNavigation/>}/>
              <Route path="/" element={<Navigate to={`/${getUserLanguage}`} replace />} />
              <Route path='/:lng/*' element={<FilesNavigation/>}/>
             </Route>


            </Route>
          
          </Route>




      







          {/* </Route> */}





      </Routes>

    </BrowserRouter>


);
