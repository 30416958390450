import { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useAppContext } from "../../../../context/AppContext";
import { useUIContext } from "../../context/UIContext";
import { useTranslation } from "react-i18next";
import Icon from "../../../../components/icons/Icon";
import Divider from "../../../../components/shared/Divider";
import ReactSvg from "../../../../components/shared/ReactSvg";
import Footer from "../../components/Footer";




export default function Domain() {
    const params = useParams()
    const [loading, setloading] = useState(false);
    const {winSize, domain} = useAppContext()
    const { hidePanel, setHidePanel} = useUIContext()
    const {t} = useTranslation(['server'])

  
   

      

  return (
    <>
    

            <div className='overflow-auto height-community space-y-[60px] relative pb-10'>
                    
              <div>
                 
                  <div className="w-full h-[370px] max-[480px]:h-[300px] bg-skin-fill-muted flex items-center justify-center border-b border-main relative">
                    <div className="bar-h flex items-center px-5 absolute top-0 left-0 z-30">
                        <button className={`p-1.5 bg-hover-transparent rounded no-dragable disabled:opacity-20 transition-all ${hidePanel ? ' rotate-180' :''}`} onClick={() => setHidePanel(!hidePanel)} disabled={false}>
                            <Icon name='PanelOpen' className='icon-sm'/>
                        </button>

                    </div>
                    <img src={domain?.image} className={`w-full h-full object-cover `} />
                     <div className="absolute bg-white w-[70%] h-full left-0 top-0 pl-[50px] flex items-center max-[850px]:w-full img-clip-path max-[520px]:pl-0 max-[520px]:justify-center" >
                      <div className="w-max flex flex-col items-center">
                        <div className="flex ">
                            <span className='bg-skin-fill-inverted text-skin-inverted font-bold  p-5 w-max text-[35px] max-[520px]:text-[26px] h-max truncate '>{domain?.full_title.en}</span>
                            <div className="flex-1 mt-4">
                                <div className=''>
                                    <p className='text-[35px] max-[520px]:text-[26px] '>
                                        <span className='font-bold relative top-[-5px] mx-0.5'>.</span>
                                        <span className='font-light'>qorddu</span>
                                        <span className='font-bold relative top-[-5px] mx-0.5'>.</span>
                                        <span className='font-light'>com</span>
                                      
                                    </p>
                                  
                                

                                </div>

                            </div>
                        </div>
                       
                      
                      </div>
                     </div>
                  </div>

                  
                  <div className="py-3.5 gap-x-[150px] border-b border-main flex pl-[50px] max-[480px]:pl-7 max-[980px]:gap-x-10">
                    <div className="w-[180px] max-[750px]:hidden"></div>
                    <div className="flex-1 h-max flex gap-4 flex-wrap items-center">
                      <div className={` flex space-x-2 items-center opacity-30`}>
                        <Icon className='icon-sm' name='Entreprise'/>
                        <p>0 {t('server:stats.companies')}</p>
                      </div>
                      <Divider/>
                      <div className={` flex space-x-2 items-center opacity-30`}>
                        <Icon className='icon-sm' name='UserIcon'/>
                        <p>0 {t('server:stats.profiles')}</p>
                      </div>
                      <Divider/>
                      <div className={` flex space-x-2 items-center opacity-30`}>
                        <Icon className='icon-sm' name='Document'/>
                        <p>0 {t('server:stats.publications')}</p>
                      </div>
                      {/* <Divider/>
                      <div className={` flex space-x-2 items-center`}>
                        <Icon className='icon-sm' name='Pin'/>
                        <p>0 {t('server:stats.pins')}</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="pl-[50px] max-[480px]:pl-7 pr-7">
                      <div className="flex gap-x-[150px] max-[980px]:gap-x-10 max-[750px]:block">
                          <div className='w-[180px] max-[750px]:w-[300px] max-[750px]:flex max-[750px]:space-x-3'> 
                              <div className='-mt-[130px] max-[750px]:mt-5'>
                                  <div className="flex space-x-10 ">
                                      <div className='w-full h-[180px] max-[750px]:h-[150px] max-[750px]:w-[150px] max-[480px]:w-[100px] max-[480px]:h-[100px] border border-main rounded overflow-hidden bg-sub-windows relative z-20' >
                                         
                                              <div className="w-full h-full flex items-center justify-center bg-white  ">
                                                    <div className="w-full h-max p-2 flex items-center justify-center">
                                                        <ReactSvg src='https://firebasestorage.googleapis.com/v0/b/qorddu-production.firebasestorage.app/o/logoq.svg?alt=media&token=ed433b48-8939-4016-a727-4bcc2c34c8fa' className='w-full h-full '/>

                                                    </div>
                                                  

                                            
                                              </div>
                                          
                                      </div>
                                  

                                  </div>

                              </div>
                             
                                <div className="mt-[30px] max-[750px]:mt-[18px]  w-full flex-1 ">
                                    <p className="font-bold text-[16px] ellipsis-3">{`Qorddu pour ${domain?.title}`}</p>
                                    <p className='mt-2 mb-4 ellipsis-4'>
                                       
                                    {`Réseaux mondial de serveurs spécialisés pour ${domain?.title}`}
 
                                    </p>
                                    <div className='flex space-x-1 -ml-1 mt-2'>
                                      <button className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' onClick={() => window.open('','_blank')} >
                                        <Icon className='icon-mini' name='OpenWindow'/>
                                      </button >
                                      <button className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' onClick={() => window.open('','_blank')} >
                                        <Icon className='icon-mini' name='Linkedin'/>
                                      </button >
                                     
                                      <button className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' disabled>
                                        <Icon className='icon-mini' name='Map'/>
                                      </button >
                                      
                                        
                                        <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit' >
                                            <Icon className='icon-mini' name='Chat'/>
                                        </button>
                                        <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                            <Icon className='icon-mini' name='Call'/>
                                        </button>
                                        <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                            <Icon className='icon-mini' name='Pin'/>
                                        </button>
                                        <button disabled className='disabled:opacity-30 p-1 bg-hover-transparent rounded !text-inherit'>
                                            <Icon className='icon-mini' name='Share'/>
                                        </button>
                                    
                                    </div>
                                    {/* {!currentLocation?.isActive && (
                                        <button className='bg-gray-300 px-7 py-2 rounded mt-5' onClick={setdemoWindow}>Reserver ce serveur </button>

                                    )} */}


                                </div>

                             

                          </div>
                          {winSize.w < 751 && (
                            <div className="w-full h-[1px] bg-gray-200 mt-[30px]"></div>

                          )}
                          <div className="flex-1 mt-[78px] max-[750px]:mt-[30px]">
                            <div className=" text-[18px] flex-1 max-[650px]:-ml-2 max-w-[450px] ">
                                <p className="font-bold">Description</p>
                                <p>
                                    {domain?.description}
                                </p>
                             
                            </div>

                             
                          </div>
                      </div>

                  </div>
              </div>
                    
                
            </div>
            <Footer
                totalHits={{webresources:0, pubs:0, location:0}}
                pagination={{limit:7, offset:0, page:1}}
                setPaginate={(data) => {}}
                onlyWebpages={false}
                currentLocation={null}
                allowPaginate={
                    {
                    webresources: false,
                    pubs: false
                    }
                }
                windows={{
                    active: '',
                    suffix:`/${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}`,
                    disabled:true,
                    hidden:true
                }}
       />

        <Outlet/>

   
        
    
    </>
  )
}
