import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import ServerWithCheck from '../../ServerWithCheck';
import { getClusterTree } from '../../../../../services/api/request';
import { Server } from '../../../../../types';
import { useAppContext } from '../../../../../context/AppContext';



type Props = {
    selectedServerId:string,
    selectedClusterId:string,
    onSelectServer:(cluster:Server) => void,
    path:string,
    selectedLocations:any,
    removeSelectedLocation:(loc:any) => void,
    addSelectedLocation:(data:Server) => void,
 }
export default function Servers({selectedServerId, selectedClusterId, onSelectServer, path, selectedLocations, removeSelectedLocation, addSelectedLocation}:Props) {
    const [regionalDrives, setregionalDrives] = useState<Server[]>([]);
    const [specDrives, setspecDrives] = useState<Server[]>([]);

    const [loading, setloading] = useState(false);
    const { domain} = useAppContext()
    
    useEffect(() => {
            setloading(true)
          
            fetch(getClusterTree({id:selectedClusterId, domainId: domain?.id!})).then(res =>res.json()).then(data =>  {
                setregionalDrives(data.data?.[0]?.children)
                setspecDrives(data.data?.[1]?.children)
            }).finally(() => setloading(false))
           
        
      }, [selectedClusterId])

  return (

    <div className=''>
        <div className='overflow-auto height-publishin pl-3'>
            <Spin spinning={loading}>
                <div className='space-y-7'>
                <div className="space-y-2">
                        <p className="text-group">Regional servers</p>
                        <div className='-ml-2'>
                            {regionalDrives && regionalDrives.filter(drive => !drive.media).map(server => (
                                <ServerWithCheck item={server} path={`/${path}/${server.title}`} key={server.id} active={server.id === selectedServerId} clickFn={() => onSelectServer(server)} selectedLocations={selectedLocations} removeSelectedLocation={removeSelectedLocation} addSelectedLocation={addSelectedLocation}/>
                            ))}
                        </div>

                    </div>
                    {specDrives?.length > 0 && (
                        <div className="space-y-2">
                            <p className="text-group">Thematics servers</p>
                            <div className='-ml-2'>
                                {specDrives?.map(server => (
                                    <ServerWithCheck path={`/${path}/${server.title}`} item={server} key={server.id} active={server.id === selectedServerId} clickFn={() => onSelectServer(server)} selectedLocations={selectedLocations} removeSelectedLocation={removeSelectedLocation} addSelectedLocation={addSelectedLocation}/>
                                ))}
                            </div>

                        </div>

                    )}
                    
                </div>
       

            </Spin>
        </div>
        <div className="">
            <Outlet/>
        </div>
    </div>
  )
}
