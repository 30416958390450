import React from 'react'
import { CameraIcon } from '@heroicons/react/solid'
import Icon from '../../../../components/icons/Icon'


type Props = {
    title: string,
    text: string,
    icon?:string,
    className?:string,
    iconSize?:string
}
export default function HorizontalBlock({title, text, icon, className, iconSize}:Props) {
  return (
    <div className={`flex space-x-3 ${className}`}>
        {icon ? (
            <Icon className={`${iconSize ?? 'w-[50px] h-[50px] '}`} name={icon} />
        ) : (
            <CameraIcon className={`${iconSize ?? 'w-[50px] h-[50px] '} opacity-10`} />
        )}
        <div className="flex-1">
            {title && (
                <p className="text-level2 !font-semibold">{title}</p>

            )}
            <p className="text-level2 !mt-0">
                {text}
            </p>
        </div>
    </div>
  )
}
