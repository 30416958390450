import { Navigate, Outlet, OutletProps, useLocation } from 'react-router-dom'
import ValidateEmail from './ValidateEmail'
import { useAuthContext } from '../context/AuthContext'
import i18n from '../i18n'


export default function PrivateRoute(props:OutletProps) {
  const { user } = useAuthContext()
  const location = useLocation()
    
    
   
  console.log('user', location)
    if(!user){
      return <Navigate to={`/${i18n.language}/connect`}replace state={{ from: location }} />;
    }
    // if(!user.emailVerified){
    //     return <ValidateEmail />
    // }
    
    return <Outlet {...props}/>
   
}
