import { Dropdown, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import TopBarContainer from './components/TopBar'




export default function SettingsLayout() {
    const navigate = useNavigate()
    const location = useLocation()
    
    return (

        <div className="flex flex-col h-full teddra-rounded rounded-t-none overflow-hidden ">
            <TopBarContainer/>
            <div className='bar-h border-b border-main px-4 flex space-x-4 items-center'>
                <Link to={'/settings/profile'} className={`${location.pathname.includes('/profile') && 'active-mode font-bold'}`}>
                    <p className={`!text-inherit `}>Profile</p>
                </Link>
                <Link to={'/settings/account'} className={`${location.pathname.includes('/account') && 'active-mode font-bold'}`}>
                    <p className={`!text-inherit `}>Account</p>
                </Link>
                <Link to={'/settings/organization'} className={`${location.pathname.includes('/organization') && 'active-mode font-bold'}`}>
                    <p className={`!text-inherit `}>Organization</p>
                </Link>
                <Link to={'/settings/subscription'} className={`${location.pathname.includes('/subscription') && 'active-mode font-bold'}`}>
                    <p className={`!text-inherit `}>Subscription</p>
                </Link>
                <Link to={'/settings/billing'} className={`${location.pathname.includes('/billing') && 'active-mode font-bold'}`}>
                    <p className={`!text-inherit `}>Billing</p>
                </Link>
            </div>
         
            <div className="flex-1  flex flex-col pl-4" id='test'>
                <Outlet/> 
            </div>

            
          
        </div>

  )
}
