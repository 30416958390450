
import React, { act, useEffect, useRef, useState } from 'react'



import { Form, Input, notification, Select, Spin } from 'antd';

import { useVolumeCreationContext } from '../../../context/volumeCreationContext';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import { volumeCodes } from '../../../constants';
import ReactSvg from '../../../../../components/shared/ReactSvg';
import { storageUrl } from '../../../../../services/api/constants';
import { getVolum } from '../../../../../services/api/request';
import { fetchInt } from '../../../../../utils/api/fetchInt';
import { updateVolum } from '../../../../../services/api/request/user';


export default function EditLink() {

    const [errors, seterrors] = useState<any>({})
    const [form] = Form.useForm()
    const { setEditData, setActive, editData, active, setVolumeType, volumeType } = useVolumeCreationContext()
    const navigate = useNavigate()
    const params = useParams()
    const [action, setaction] = useState<'create' | 'edit'>('create');
    const [loading, setloading] = useState(false);
   
   const handleNext = async () => {
    if(action === 'create'){
        navigate(`/mydesk/volumes/create/${params.type}/publish`)
    }else{
        const { title, keywords, description} = form.getFieldsValue()
        setloading(true)
        const req = await fetchInt(updateVolum({volumId:params.id!, volumColl:params.coll!}), {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({title:{en:title}, description:{en:description}, keywords:{en:keywords}})
        })
        const res = await req.json()
        setloading(false)
        if(res.success){
            notification.success({message: 'Volume updated successfully'})
            navigate(`/mydesk`)
        }
    }
   }
   
   const handleValuesChange = (changedValues:any,allValues:any) => {
    const { title, keywords, description} = allValues
    setEditData(allValues)
    seterrors((prev:any) => {
        const state = {...prev}
        state[Object.keys(changedValues)[0]] = undefined
        return state
    })
    if(title && keywords && description){
        seterrors({})
        setActive({publish:true})
    }else{
        setActive({publish:false})
    }
   }

   useEffect(() => {
    if(params.type){

        const volume =volumeCodes.filter(code => code.code === params.type)[0]
        if(volume){
            setVolumeType(volume)
        }
    }
   }, [params.type]);

   useEffect(() => {
    if(params.id && params.coll){
        setloading(true)
        fetch(getVolum({volumColl:params.coll, volumId:params.id})).then(res => res.json()).then(data => {
            setEditData({
                title: data.data.title.en,
                description:data.data.description?.en ??'',
                keywords:data.data.keywords?.en??[],
            })
            form.setFieldsValue({
                title: data.data.title.en,
                description:data.data.description?.en ??'',
                keywords:data.data.keywords?.en??[],
            })
            setaction('edit')
            setVolumeType({
                name:data.data.title.en,
                icon:data.data.icon,
                collection:params.coll!,
            })
        }).finally(() => setloading(false))
    }else{
        setaction('create')
    }
   
   }, [params.id, params.coll]);
 
  
  return ( 
    <>
  
        <Form 
        form={form}
        className='h-full flex flex-col'
        onValuesChange={handleValuesChange}
        initialValues={editData}
        
         >
            <div className=' px-7 flex justify-center relative top-[20%]'>
                <div>
                    <div>
                        <ReactSvg src={`${storageUrl}${volumeType.icon.src}`} className='w-[200px] h-[200px] -rotate-12'/>
                    </div>
                    <div className="flex">
                        <div className="flex-1 max-w-[300px] ">
                            <p className='font-bold title-level2 '>Tell us about your {volumeType.name}</p>
                            <p className='text-level2  '>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam cumque, dolorum nam a exercitationem
                            </p>
                        </div>
                        <div className="max-w-max w-[70%] bg-sub-windows overflow-auto pb-4 ">
                        <div className='relative pr-5 space-y-2 w-max' >     
                                <div className="flex gap-4 w-max items-center">
                                <p className="md:text-end font-bold w-[150px]">Title </p>
                                <div className="flex-1 max-w-full w-[450px] "> 
                                    <Form.Item
                                        validateStatus={errors?.title && "error"}
                                        help={errors?.title && errors?.title}
                                        className="group"
                                        style={{marginBottom: 0}}
                                        name={`title`}
                                        required
                                    >
                                        <input
                                        type="text"
                                        placeholder={'Volum title (20 characters max)'}
                                        
                                        autoComplete="off"
                                        className=" border border-main border-opacity-20 rounded input-h w-full"
                                        />
                
                                    </Form.Item>
                                            
                                </div>
                                </div>
                                <div className="flex gap-4 w-max">
                                <p className="md:text-end font-bold w-[150px]  pt-[10px]">
                                    Description
                                </p>
                                <div className="flex-1 max-w-full w-[450px] ">
                                    <Form.Item
                                        validateStatus={errors?.description && "error"}
                                        help={errors?.description  && errors?.description }
                                        className="group"
                                        style={{marginBottom: 0}}
                                        name={'description'}
                                        required
                                        
                                    >
                                        
                                        <TextArea rows={7} className='border border-main' placeholder='Describe your volum (100 characters max)'/>
                                    </Form.Item>
                                </div>
                                </div>
                                <div className="flex gap-4 w-max ">
                            
                                <p className="md:text-end font-bold w-[150px]  pt-[10px]">
                                    Keywords
                                </p>
                                <div className="flex-1 max-w-full w-[450px] "> 
                                    <Form.Item
                                        validateStatus={errors?.keywords && "error"}
                                        help={errors?.keywords  && errors?.keywords }
                                        className="group"
                                        style={{marginBottom: 0}}
                                        name={`keywords`}
                                        required
                                    >
                                    <Select
                                        className="input-style rounded transition-none w-full rightpage  border border-main "
                                        mode="tags"
                                        open={false}
                                        style={{width: "100%", background:'#fff'}}
                                        placeholder={'Insert a maximum of 8 keywords'}
                                        maxLength={8}
                                        // value={keywords}
                                        
                                        // onDeselect={(e) => setkeywords((prev:string[] ) => (prev.filter(item => item !== e)))}
                                        // onSelect={(e) => setkeywords((prev:string[]) => ([...prev, e]))}
                                    />       
                
                                    </Form.Item>
                                </div>
                                </div>
                                <div className='flex'>
                                    <div className="w-[150px]"></div>
                                    <Spin spinning={loading}>
                                        <div className="flex-1 max-w-full w-[450px] ">
                                            <button type='submit' className='block ml-auto px-3 py-1.5 bg-skin-fill-muted rounded  disabled:text-skin-muted' disabled={!active.publish || loading} onClick={handleNext}>
                                                {action === 'create' ? 'Next' : 'Save'}
                                            </button>

                                        </div>

                                    </Spin>
                
                                    
                
                
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Form>

 
    
    </>
    
                    
    

  )
}

