import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import '../../i18n'; 

import HomeLayout from './Layout';
import DomainDetails from './pages/details/domainDetails';
import Qorddu from './pages/details/Qorrdu';
import QordduPage from './pages/QordduPage';
import Newspapers from './pages/details/Newspapers';
import Business from './pages/details/Business';
import Communities from './pages/details/Communities';
import Video from './pages/details/Video';
import NewspapersLayout from './pages/newspapers/Layout';
import NewspapersPage from './pages/newspapers';
import HowItWorks from './pages/newspapers/HowItWorks';
import Home from './pages/newspapers/Benefits';
import Revenu from './pages/newspapers/Revenu';
import Pricing from './pages/newspapers/Pricing';
import Demo from './pages/Demo';
import Platform from './pages/newspapers/Platforme';
import Solution from './pages/newspapers/Solution';
import About from './pages/About';

export default function HomeRoutings() {
  return (
    <Routes>
      {/* Redirect /home/ to /home/fr */}

      <Route element={<HomeLayout />}>
        {/* Language-based routing */}
        <Route path="/:lng" element={<></>} />

        {/* Qorddu Routes */}
        <Route element={<Qorddu />}>
          <Route path="/:lng/qorddu" element={<></>} />
          <Route element={<About/>} >
            <Route path="/:lng/pages/qorddu/vision" element={<QordduPage/>}/>
          </Route>
        </Route>

        {/* Newspapers Routes */}
        <Route element={<Newspapers />}>
          <Route path="/:lng/newspapers" element={<></>} />
          <Route element={<NewspapersLayout />}>
            {/* <Route path="/:lng/pages/newspapers" element={<NewspapersPage />} /> */}
            <Route path="/:lng/pages/newspapers/how-it-works" element={<HowItWorks />} />
            <Route path="/:lng/pages/newspapers/benefits" element={<Home />} />
            <Route path="/:lng/pages/newspapers/solution" element={<Solution />} />

            <Route path="/:lng/pages/newspapers/revenu" element={<Revenu />} />
            <Route path="/:lng/pages/newspapers/pricing" element={<Pricing />} />
            <Route path="/:lng/pages/newspapers/demo" element={<Demo />} />

          </Route>
        </Route>

        {/* Other Sections */}
        <Route path="/:lng/communities" element={<Communities />} />
        <Route path="/:lng/business" element={<Business />} />
        <Route element={<Video />}>
          <Route path="/:lng/video" element={<></>} />
        </Route>

        {/* Domain Details */}
        <Route path="/:lng/domains/:domainId" element={<DomainDetails />} />
      </Route>
    </Routes>
  );
}
