import React, { useEffect, useMemo, useState } from 'react'
import { Highlight, OwnerType, PartnerType, Publication, VolumeSearch } from '../../../../types'

import PartnersAndSponsors from './Partners'
import Owner from './Owner'
import AvailableLocations from './AavilableLocations'
import PublicationsLink from './publications/PublicationsLink'
import WebResources from './publications/WebResources'
import { displayModes, useUIContext } from '../../context/UIContext'
import Collapse, { Panel } from '../../../../components/shared/Collapse'


type Props = {
  data: {
    availableLocations: {document :VolumeSearch}[] | null,
    publications: undefined | {document:Publication, highlight:Highlight}[],
    webResources: undefined | {document:Publication, highlight:Highlight}[],
    sponsors: PartnerType[] | null
    partners : PartnerType[] | null,
    owner?:OwnerType | undefined,
  }
  suffix: string,
  isLoaded:boolean,
  all?:string,
  searchStates : {
      query: string,
      selectedPubType: {value:string, icon:string, type:'groupe' | 'type'},
      allowSearch: boolean,
      onlyWebpages: boolean,
      totalHits: { pubs: number | null, webresources:number | null, locations:number } ,
      isSearchActive: boolean
      
  },
  title?:{
    webresources?:string,
    pubs?:string
  },
  ui:{
    displayMode: displayModes,
    barActivatedFields: { author:boolean, date: boolean},
    setAll :(value:string) => void,
    groupBy?:{
      webresources:boolean,
      paidPubs: boolean
    },
  }
}


export default function ResourcesLayout({data:{ partners, sponsors, availableLocations, webResources, publications, owner}, suffix, all, isLoaded, searchStates, title, ui:{displayMode, barActivatedFields, setAll, groupBy}} : Props) {
    const [activePanels, setactivePanels] = useState<string |string[]>(['partners', 'locations', 'owner','webresources','publications', 'stats']);
    
    useEffect(() => {
      if(all){
        
        setAll(all)
      }else{
       
        setAll('')
      }
    }, [all]);
   
    const showPartnersAndSponsors = (!searchStates.isSearchActive) && ((partners && partners?.length>0) || (sponsors && sponsors?.length > 0))
    const showOwner = (!searchStates.isSearchActive) && (owner?.website || owner?.webResources)

    const showAvailableLocations = (availableLocations && availableLocations.length > 0) && !searchStates.onlyWebpages && !searchStates.selectedPubType.value
    const showPublications = isLoaded && publications && publications.length>0 && !searchStates.onlyWebpages
    const showWebResources = isLoaded && webResources && webResources.length>0 && (!searchStates.selectedPubType.value && (!groupBy?.webresources || searchStates.isSearchActive) && (!groupBy?.paidPubs || all) || searchStates.onlyWebpages)
    

  
    useEffect(() => {
     if(searchStates.isSearchActive){
      setactivePanels(prev =>  Array.isArray(prev) ? [...prev, 'publications', 'webresources', 'publications-partner','webresources'] : ['publications', 'webresources', 'publications-partner','webresources'])
     }
    }, [searchStates.isSearchActive]);
    useEffect(() => {
      
      setactivePanels(['partners', 'locations', 'owner','webresources', 'publications', 'stats'])
    }, [suffix]);
    return (
      <div className='border-r border-main'>
        
        <div className="pl-[20px] pt-[14px] overflow-auto height-explorer space-y-[60px]" >
             <Collapse activeKey={activePanels} className='' onChange={(key) => setactivePanels(key)}>
             <>
              
                {showPartnersAndSponsors && (
                  <Panel
                    header={<p className='text-groupe'>{partners && partners.length > 0 ? 'Partners & Sponsors' : 'Sponsors'}</p>}
                    key={'partners'}
                  >
                    <div className='mb-[40px] max-[580px]:mb-[20px] ml-[20px] mt-[10px]'>
                      <PartnersAndSponsors
                        partners={partners}
                        sponsors={sponsors}
                        suffix={suffix}
                      />

                    </div>

                  </Panel>


                )}
        
                
                {showOwner && (
                  <Panel
                    header={<p className='text-groupe'>Owner</p>}
                    key={'owner'}
                  >
                    <div className="mb-[40px] max-[580px]:mb-[20px] ml-[20px] mt-[10px]">
                      <Owner
                        owner={owner}
                        suffix={suffix}
                      />

                    </div>

                </Panel>


                )}
                
                {showAvailableLocations && (
                    <Panel
                    header={<p className='text-groupe'>{searchStates.isSearchActive ? availableLocations.length < searchStates.totalHits.locations ? `Top ${availableLocations.length} Communities from ${searchStates.totalHits.locations} communities` : `${searchStates.totalHits.locations} communities found` : 'Communities'}</p>}
                    key={'locations'}
                  >
                    <div className="mb-[40px] max-[580px]:mb-[20px] ml-[14px] mt-[2px]">
                      <AvailableLocations
                        availableLocations={availableLocations}
                        setRecheckPin={() => {}}
                        suffix={suffix}
                      />
                      {searchStates.totalHits.locations > availableLocations.length && (
                        <button className="bg-skin-fill-muted px-3 py-1.5 rounded mt-2" >See all...</button>

                      )}


                    </div>

                  </Panel>


                  )
                }
              
                {showPublications && (
                    <Panel
                    header={<p className='text-groupe'> {title?.pubs ??(groupBy?.paidPubs ? 'Groupes' : (
                      searchStates.isSearchActive ? `${searchStates.totalHits.pubs} publications found` : `Recently added publications`
                  ))}</p>}
                    key={'publications'}
                  >
                    <div className="mb-[40px] max-[580px]:mb-[20px] ml-[18px] mt-[2px]">
                      <PublicationsLink
                        publications={publications}
                        webResources={webResources}
                        groupBy={groupBy}
                        totalHits={searchStates.totalHits}
                        searchon={searchStates.isSearchActive}
                        selectedPubType={searchStates.selectedPubType}
                        barActivatedFields={barActivatedFields}
                        setRecheckPin={() => {}}
                        displayMode={displayMode}
                        suffix={suffix}
                      />
                      {(groupBy?.paidPubs || !groupBy?.webresources || !searchStates.isSearchActive) && webResources && (
                        <WebResources
                        webResources={webResources}
                        searchon={searchStates.isSearchActive}
                        barActivatedFields={barActivatedFields}
                        setRecheckPin={() => {}}
                        displayMode={displayMode}
                        suffix={suffix}
                        groupBy={groupBy}
                        totalHits={searchStates.totalHits}
                        onlyWebpages={searchStates.onlyWebpages}
                      />
                      )}
                    {/* <p className='italic mt-2'>Seacrh among {totalHits.webresources ?? 0 + (totalHits.pubs??0)} resources in this server</p> */}
                    </div>
                </Panel>
      
                )}
                {showWebResources && (
                 
                  <Panel
                    header={<p className='text-groupe'>{title?.webresources?? (searchStates.isSearchActive ? ` ${searchStates.totalHits.webresources} web pages found` : 'Latest web resources indexed')}</p>}
                    key={'webresources'}
                  >
                    <div className="ml-[18px] mt-[2px]">
                      <WebResources
                        webResources={webResources}
                        searchon={searchStates.isSearchActive}
                        barActivatedFields={barActivatedFields}
                        setRecheckPin={() => {}}
                        displayMode={displayMode}
                        suffix={suffix}
                        groupBy={groupBy}
                        onlyWebpages={searchStates.onlyWebpages}
                        totalHits={searchStates.totalHits}
                      />

                    </div>

                  </Panel>

                  )}
                
             
             </>
              
             
             </Collapse>

      

          
  
        </div>
      </div>


    )
}
