import React, { useEffect, useRef, useState } from 'react'
import BlockwithImgLayout from '../../components/block/withImgLayout'
import TextBlock2 from '../../components/block/TextBlock2'
import Blocks from '../../components/block/Blocks'
import BlockwithoutImgLayout from '../../components/block/withoutImg'
import HorizontalBlock from '../../components/block/HorizontalBlock'
import Block from '../../components/block/Block'
import { CameraIcon, PlusSmIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import PlusIcon from '../../../../components/icons/files/PlusIcon'
import { Modal, Tooltip } from 'antd'
import CloseBtn from '../../../../components/shared/Buttons/CloseBtn'
import Icon from '../../../../components/icons/Icon'




const Plateform = () => {
    const {t} = useTranslation(['htw'])
    return (
        <div className='space-y-10'>
                        
            <BlockwithoutImgLayout orientation='right'>
                <>
                <TextBlock2
                    label=''
                    title={t('htw:section2.title')}
                    text={t('htw:section2.text')}
                />
                </>


            </BlockwithoutImgLayout>
            <div className="max-w-page mx-auto">
                <Blocks
                    columns={'grid-cols-4 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1'}
                    
                    content={[
                        {
                            title: t('htw:section2.block4.title'),
                            text: t('htw:section2.block4.text'),
                        },
                        {
                            title: t('htw:section2.block1.title'),
                            text: t('htw:section2.block1.text'),
                        },
                        {
                            title: t('htw:section2.block2.title'),
                            text: t('htw:section2.block2.text'),
                        },
                        {
                            title: t('htw:section2.block3.title'),
                            text: t('htw:section2.block3.text'),
                        },
                    ]}
                />

            </div>
        </div>
    )
}
const Content = () => {
    const {t} = useTranslation(['htw'])
    return (
   <div className="space-y-16">
                <div className="max-w-page mx-auto">
               
                            <TextBlock2
                                label=''
                                title={t('htw:section3.title')}
                                text={t('htw:section3.text')}
                            />

                       
                </div>
                    
                <div className="max-w-page mx-auto">
                    <Blocks
                        columns='grid-cols-4 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1'
                        
                        content={[
                            {
                                title: t('htw:section3.block1.title'),
                                text: t('htw:section3.block1.text')
                            },
                            {
                                title: t('htw:section3.block2.title'),
                                text: t('htw:section3.block2.text')
                            },
                            {
                                title: t('htw:section3.block3.title'),
                                text: t('htw:section3.block3.text')
                            },
                            {
                                title: t('htw:section3.block4.title'),
                                text: t('htw:section3.block4.text')
                            },
                           
                        ]}
                    />
                  

                </div>

              </div>

    )
}
const Visibility = () => {
    const {t} = useTranslation(['htw'])
    return (
        <div className='space-y-10'>
        
            <BlockwithoutImgLayout orientation='right'>
                <>
                <TextBlock2
                    label=''
                    title={t('htw:section4.title')}
                    text={t('htw:section4.text')}
                
                />
                </>


            </BlockwithoutImgLayout>
            <div className="max-w-page mx-auto">
                <Blocks
                    columns={'grid-cols-4 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1'}
                    
                    content={[
                        {
                            title: t('htw:section4.block1.title'),
                            text: t('htw:section4.block1.text'),    
                        },
                        {
                            title: t('htw:section4.block2.title'),
                            text: t('htw:section4.block2.text'),    
                        },
                        {
                            title: t('htw:section4.block3.title'),
                            text: t('htw:section4.block3.text'),    
                        },
                        
                    ]}
                />

            </div>
        </div>
                        
    )
}
const Revenu = () => {
    const {t} = useTranslation(['htw'])
    return (
        <div className='space-y-16'>

            <BlockwithoutImgLayout orientation='right'>
                <>
                <TextBlock2
                    label=''
                    title={t('htw:section5.title')}
                    text={t('htw:section5.text')}
                    
                />
                
                </>


            </BlockwithoutImgLayout>
                <Blocks 
                    columns='grid-cols-4 max-[750px]:grid-cols-2 max-[450px]:grid-cols-1'
                    content={[
                        {
                            title: t('htw:section5.block1.title'),
                            text: t('htw:section5.block1.text')
                        },
                        {
                            title: t('htw:section5.block2.title'),
                            text: t('htw:section5.block2.text')
                        },
                        {
                            title: t('htw:section5.block3.title'),
                            text: t('htw:section5.block3.text')
                        }
                    ]}
                />
        </div>
        
    )
}
export default function HowItWorks() {
    const {t} = useTranslation(['htw'])
    const [selectedBlock, setselectedBlock] = useState<'platform'| 'content' | 'visibility' | 'revenu' | null>(null);
 
  return (
    <div className="w-full h-full relative ">
       
        <div className="space-between-main-blocks3 pb-end-page">
        
            
            
            <div className="space-y-[270px]">
                <div className="relative flex flex-col justify-center  max-w-page mx-auto min-h-[calc(100vh-176px)]">
                    <div className='flex space-x-[55px] justify-centyer items-center  '>
                    <div className=" w-full text-center space-y-10">
                            <div className="h-[400px] flex justify-center items-center w-full">
                                <CameraIcon className='w-[100px] h-[100px] opacity-10 ' />
                            </div>
                            <div>
                                <p className='title-main w-full'>
                                Rejoinez le reseau de partenaires Qorddu 
                                </p>
                                <p className="text-main max-w-[950px] mx-auto">
                                Sans risques. Sans peine.
  

                                </p>

                            </div>
                       
                            

                        </div>
                     
                     

                    </div>
                </div>
                 <div>
                                    
                    
            
                </div>

                
                <div className='space-y-10'>
                    <BlockwithoutImgLayout orientation='right'>
                        <>
                        <TextBlock2
                            label=''
                            title={'Sans risques'}
                            text={'Lorem ipsum dolor sit amet consectetur adipisicing elit. A accusamus perspiciatis amet magni numquam quis eaque recusandae voluptate quae laudantium reprehenderit quisquam quo deserunt culpa nisi, cum tempora sapiente porro.'}
                        />
                        </>


                    </BlockwithoutImgLayout>
                    <div className="max-w-page mx-auto">
                        <Blocks
                            columns={'grid-cols-4 max-[950px]:grid-cols-2 max-[450px]:grid-cols-1'}
                            
                            content={[
                                {
                                    title: 'Intégrez la widget Qorddu',
                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                                    extraContent: 
                                    <Tooltip title='Prochainement disponible'>
                                        <button className='bg-gray-300 rounded w-[40px] h-[40px] flex opacity-30 items-center justify-center text-[30px]'>
                                            <PlusSmIcon className='w-[30px] h-[30px]'/>

                                        </button>

                                    </Tooltip>
                                },
                                {
                                    title: "Un article par mois sur l'activité de Qorddu",
                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                                    extraContent: <Tooltip title='Prochainement disponible'>
                                    <button className='bg-gray-300 rounded w-[40px] h-[40px] flex opacity-30 items-center justify-center text-[30px]'>
                                        <PlusSmIcon className='w-[30px] h-[30px]'/>

                                    </button>

                                </Tooltip>
                                },
                                {
                                    title: 'Votre serveur Qorddu a partir de 250€/mois seulement',
                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                                    extraContent:<Tooltip title='Prochainement disponible'>
                                    <button className='bg-gray-300 rounded w-[40px] h-[40px] flex opacity-30 items-center justify-center text-[30px]'>
                                        <PlusSmIcon className='w-[30px] h-[30px]'/>

                                    </button>

                                </Tooltip>

                                },
                                
                            ]}
                        />

                    </div>
                </div>

                <div className='space-y-10'>
                    <BlockwithoutImgLayout orientation='left'>
                        <>
                        <TextBlock2
                            label=''
                            title={'Sans peine'}
                            text={'Lorem ipsum dolor sit amet consectetur adipisicing elit. A accusamus perspiciatis amet magni numquam quis eaque recusandae voluptate quae laudantium reprehenderit quisquam quo deserunt culpa nisi, cum tempora sapiente porro.'}
                        />
                        </>


                    </BlockwithoutImgLayout>
                    <div className="max-w-page mx-auto">
                       <Blocks 
                            columns={'grid-cols-4 max-[950px]:grid-cols-3 max-[550px]:grid-cols-2 max-[450px]:grid-cols-1'}
                            content={[
                                {
                                    title: t('htw:section1.block1.title'),
                                    text: t('htw:section1.block1.text'),
                                    extraContent: <button onClick={() => setselectedBlock('platform')} className='bg-gray-300 rounded w-[40px] h-[40px] flex items-center justify-center text-[30px]'>
                                        <PlusSmIcon className='w-[30px] h-[30px]'/>
                                    </button>

                                    // size: 'text-[16px] font-light',
                                },
                                {
                                    title: t('htw:section1.block2.title'),
                                    text: t('htw:section1.block2.text'),
                                    extraContent: <button onClick={() => setselectedBlock('content')} className='bg-gray-300 rounded w-[40px] h-[40px] flex items-center justify-center text-[30px]'>
                                        <PlusSmIcon className='w-[30px] h-[30px]'/>
                                    </button>

                                    // size: 'text-[16px] font-light',
                                },
                                {
                                    title: t('htw:section1.block3.title'),
                                    text: t('htw:section1.block3.text'),
                                    extraContent: <button onClick={() => setselectedBlock('visibility')} className='bg-gray-300 rounded w-[40px] h-[40px] flex items-center justify-center text-[30px]'>
                                        <PlusSmIcon className='w-[30px] h-[30px]'/>
                                    </button>

                                    // size: 'text-[16px] font-light',
                                },
                                {
                                    title: t('htw:section1.block4.title'),
                                    text: t('htw:section1.block4.text'),
                                    extraContent: <button onClick={() => setselectedBlock('revenu')} className='bg-gray-300 rounded w-[40px] h-[40px] flex items-center justify-center text-[30px]'>
                                        <PlusSmIcon className='w-[30px] h-[30px]'/>
                                    </button>

                                    // size: 'text-[16px] font-light',
                                },
                                                              
                                                           ]}
                                                           />

                    </div>
                </div>
              
             

             
                

                <Modal
                    visible={!!selectedBlock}
                    onCancel={() => setselectedBlock(null)}
                    closeIcon={ <button onClick={() => setselectedBlock(null)} className='flex space-x-3 items-center p-1.5 absolute top-2 rounded bg-red-500 text-white' >
                            <Icon className='icon-mini' name='Close'/>
                        </button>}
                    footer={null}
                    width={'80%'}
                    style={{
                        height:'80vh'
                    }}
                >
                    <div className="bg-white rounded py-[60px] px-[30px] max-w-[1200px] mx-auto flex items-center h-full">
                            {selectedBlock === 'platform' && <Plateform/>}
                            {selectedBlock === 'content' && <Content/>}
                            {selectedBlock === 'visibility' && <Visibility/>}
                            {selectedBlock === 'revenu' && <Revenu/>}
                    </div>
                </Modal>
                 

             
               

              

                
             

                

                
              


                
                
                
                
            </div>
        </div>
            

             

 

    </div>

             
                      
                    
                    
      
  )
}
