import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 5.317C17.925 5.15 18.894 5.053 19.956 5.017C20.524 4.998 21 5.448 21 6.016V17.853C21 18.395 20.567 18.843 20.026 18.851C16.934 18.898 14.606 19.468 12 21C9.394 19.468 7.066 18.898 3.974 18.852C3.433 18.844 3 18.395 3 17.853V6.016C3 5.457 3.459 4.998 4.017 5.016C7.088 5.115 9.406 5.693 12 7.146"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 15.238V3.012C17 2.283 16.234 1.774 15.583 2.101C13.827 2.981 12.347 4.77 12 6.612V21C12.396 18.901 14.261 16.871 16.331 16.176C16.733 16.041 17 15.662 17 15.238Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
