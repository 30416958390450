import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../../context/AuthContext'
import { Input } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";

export default function Account() {
    const { user } = useAuthContext()
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [passwordConfirm, setpasswordConfirm] = useState('');


    useEffect(() => {
        if (user) setemail(user?.email)
    }, [user?.id]);
  return (
    <div className='pt-10 space-y-8 max-w-[400px]'>
        <div className="space-y-4">
            <p className="font-bold">Edit Email</p>
            <div className="flex space-x-3">
                <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    type="email"
                    placeholder="Email"
                    className=" border !border-gray-500"
                    value={email}
                    onChange={e => setemail(e.target.value)}
                />
                <button className='px-4 py-2 border border-main rounded'>Confirm</button>

            </div>
        </div>
        <div className='space-y-4'>
            <p className="font-bold">Edit password</p>
            <div className="flex space-x-3">
                 <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                    className=" border !border-gray-500"
                    value={password}
                    onChange={e => setpassword(e.target.value)}
                />
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirm password"
                    className=" border !border-gray-500"
                    value={passwordConfirm}
                    onChange={e => setpasswordConfirm(e.target.value)}
                />
                <button className='px-4 py-2 border border-main rounded'>Confirm</button>
            </div>
        </div>
    </div>
  )
}
