import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'


import useGetPublications from '../../../../hooks/useGetPublications'
import useGetPartners from '../../../../hooks/useGetPartners'
import LocationLayout from '../../../../components/locationLayout'
import useSearchLocations from '../../../../hooks/useSearchLocations'
import SearchBar from '../../../../GlobalLayout/SearchBar'
import { useQfsContext } from '../../../../context/qfsContext'
import useGetWebPages from '../../../../hooks/useGetWebPages'
import QfsActionsBar from '../../../../GlobalLayout/QfsActionsBar'
import Footer from '../../../../components/Footer'
import { useHandleLimits } from '../../../../hooks/useHandleLimits'
import { displayModes, useUIContext } from '../../../../context/UIContext'
import useGetOwner from '../../../../hooks/useGetOwner'


export default function CompanyLayout() {
    const [loading, setloading] = useState(false)
    const params = useParams()
    const { owner} = useGetOwner({id:params.id!, coll:params.coll!, setloading:setloading})
    const [query, setquery] = useState('');
    const [selectedPubType, setselectedPubType] = useState<{value:string, icon:string, type:'groupe' | 'type'}>({value:'', icon:'', type:'type'});
    const [groupBy, setgroupBy] = useState({webresources:true, paidPubs:false});
    const { currentLocation } = useQfsContext()
    const searchOn = !!query || !!selectedPubType.value
    const { barActivatedFields, setBarActivatedFields, setAll } = useUIContext()
    const [displayMode, setDisplayMode] = useState<displayModes>('OneLine');
    const [pagination, setPaginate] = useState({page:1, offset:0, limit:7})
    useHandleLimits({displayMode, disabled:!selectedPubType.value, setPaginate: (data) => setPaginate(prev => ({...prev, ...data}))})
    const [onlyWebpages, setonlyWebpages] = useState(false);
    const { searchResults, resultsLength } = useSearchLocations({query: query??'*', filter: `&& roots:=${params.id} && id:!=${params.id}`, sort: 'created_at:desc', limit: 6})
    const { webresources, isLoaded:isLoaded2, totalHits:totalHitsWeb } = useGetWebPages({
        id:params.id!, 
        groupBy: true,
        query,
        pagination,
        setGroupBy:(data) => setgroupBy(prev => ({...prev, data})),
        selectedPubType,
        setPaginate:(data) => setPaginate(prev => ({...prev, ...data})),
        onlyWebpages:false,
        limit:searchOn ? 7 : 2
      
    })
    const { publications, isLoaded, totalHits:totalHitsPubs } = useGetPublications({
        id:params.id!,
        limit:searchOn ? (selectedPubType.value ? pagination.limit : 15) : 7, 
        mode:'roots',
        groupBy:groupBy.paidPubs,
        // extraFilter:'&& publicationType.id :! company_profile',
        query,
        pagination:pagination,
        setGroupBy:(data) => setgroupBy(prev => ({...prev, data})),
        selectedPubType,
        setPaginate:(data) => setPaginate(prev => ({...prev, ...data})),
        setselectedPubType: (data) => setselectedPubType(data)
    })
    
    useEffect(() => {
      (async () => {
        if( params.id ){
          setloading(true)
         
          // const res:any = await searchChildLocations({parentId:params.id})
          // setavailableLocations(res.hits)
          setloading(false)

        }

      })()
     
  }, [params.id])


  const OnsearchClear = () => {
    setquery('')
    setselectedPubType({value:'', icon:'', type:'type'})
    setPaginate({page:1, offset:0, limit:7})

  }
  
  return (
    <>
    <SearchBar
            query={query}
            setQuery={setquery}
            selectedPubType={selectedPubType}
            setSelectedPubType={setselectedPubType}
            groupBy={groupBy}
            totalHits={{webresources:!selectedPubType.value ? totalHitsWeb : 0, pubs:onlyWebpages ? 0 : totalHitsPubs}}
            setOnlyWebpages={setonlyWebpages}
            onlyWebpages={onlyWebpages}
            setDisplayMode={setDisplayMode}
            disabled={{filter:false, display:false, new:false,query:false}}
            displayMode={displayMode}
            barActivatedFields={barActivatedFields}
            setBarActivatedFields={setBarActivatedFields}
            currentLocation={currentLocation}
            publicationsTypes={{
              types:[],
              loading:false
            }}
            onClear={OnsearchClear}
        />
    <QfsActionsBar details={true}/>
    <LocationLayout
        layoutProps={{
            loading,
            selectedKey:params.id!,
            disabled:{filter:false, query:false, display:false, new:false},
            publicationBar:true,
            collection:params.coll!,
            id:params.id!
        }}
       
        suffix={`${params.serverId ? params.coll + '/' : ''}${params.coll}/${params.id}/organization/`}
        isLoaded={isLoaded && isLoaded2}
        data={{
          partners:null,
          sponsors:null,
          owner:owner,
          publications:publications,
          webResources:webresources,
          availableLocations:searchResults
        }}
        searchStates={{
          query: query,
          selectedPubType: selectedPubType,
          onlyWebpages: false,
          totalHits: {pubs: totalHitsPubs, webresources:totalHitsWeb, locations: resultsLength},
          isSearchActive: !!searchOn,
          allowSearch:true
        }}
        ui={{
          displayMode,
          barActivatedFields,
          setAll,
          groupBy
        }}
        

    />
    <Footer
        totalHits={{webresources:!selectedPubType.value ? totalHitsWeb : 0, pubs:onlyWebpages ? 0 : totalHitsPubs, location:resultsLength}}
        pagination={pagination}
        setPaginate={(data) => setPaginate(prev => ({...prev, ...data}))}
        onlyWebpages={onlyWebpages}
        currentLocation={currentLocation}
        allowPaginate={
          {
          webresources: !groupBy.webresources || onlyWebpages,
          pubs: !groupBy.paidPubs && !!selectedPubType.value
          }
        }
        windows={{
          active: '',
          suffix:`${params.serverId ? params.serverId + '/' : ''}${params.coll}/${params.id}/organization`,
          disabled:false
      }}
    />
  
    </>
      

    
  )
}
