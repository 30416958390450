import React from "react";
import { CreditCard } from "lucide-react";

type CardDetailsProps = {
  brand: string | null;
  last4: string | null;
  expDate: string | null;
};

const CardDetails: React.FC<CardDetailsProps> = ({ brand, last4, expDate }) => {
    const cardIcons: Record<string, JSX.Element> = {
        visa: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291 191" className="w-full h-full">
            <path
              fill="#1A1F71"
              d="M0 0h291v191H0z"
            />
            <path
              fill="#F7B600"
              d="M147.5 74.7L176.8 115h-18.5L147.5 74.7zm-.1 64.5h16.3L175.6 76l1.6-10.3-7.7 10.2-16.5 63.3zm-74.3-36.6L98.5 83l-4.8 19.4-5.6 22.5h-7.5l-12.4-64.8-14.3 64.8h-7.5l-16-64.8H6.1L0 115h5.7l8.3-30.3h18.3l9.8 36.3L62 115h5.7zm138.8-13.1h12.7v8.7h-.1l-.8 6.1H162c-1.6 0-2.8 1.3-2.8 2.8v11.8H133l4-11.7c0-1.5.8-2.7 2.2-3.3l28.2-11.2c1.1-.4 1.5-1.8.8-2.7z"
            />
          </svg>
        ),
        mastercard: (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 30" className="w-full h-full">
            <circle cx="15" cy="15" r="15" fill="#FF5F00" />
            <circle cx="33" cy="15" r="15" fill="#EB001B" />
            <circle cx="24" cy="15" r="15" fill="#F79E1B" />
          </svg>
        ),
        default: <CreditCard className="w-full h-full" />,
      };
    
      const cardIcon = cardIcons[brand?.toLowerCase() || "default"];

  return (
    <div className="flex items-center space-x-4 p-4 bg-white shadow-md rounded-2xl border border-gray-200">
      <div className="w-12 h-12">
        {cardIcon}
      </div>
      <div className="flex flex-col">
        <span className="text-lg font-semibold text-gray-800">
          {brand || "Unknown Card"} {last4 || "0000"}
        </span>
        <span className="text-sm text-gray-600">
          Expires {expDate}
        </span>
      </div>
      <div className="ml-auto">
        <CreditCard className="w-6 h-6 text-gray-500" />
      </div>
    </div>
  );
};

export default CardDetails;
