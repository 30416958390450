import React, { useEffect } from "react";
import { Bar, Chart } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import { UI_WIDTH_IS_MOBILE } from "../../../constants";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,ChartDataLabels);

const BarChart = ({ baseValue, precentage }:{baseValue:number, precentage:number}) => {
  const {t} = useTranslation(['common'])
  const {winSize} = useAppContext()
 
  // Calculate values
  const values = [
    baseValue * 0.2, // 20%
    baseValue * 0.5, // 50%
    baseValue * 1, // 10%
  ];

  // Define years
  const years = [`${t('year')} 1`, `${t('year')} 2`, `${t('year')} 3`];

  // Chart data
  const data = {
    labels: years,
    datasets: [
      {
        data: values,
        backgroundColor: ["#ccc", "#ccc", "#ccc", "#ccc"],
        borderColor: ["#ccc", "#ccc", "#ccc", "#ccc"],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options:any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false }, // Hide legend
      tooltip: {
        callbacks: {
          label: function (tooltipItem:any) {
            return `€${tooltipItem.raw.toLocaleString()}`; // Add € in tooltip
          },
        },
      },
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: (value:any) => `€${value.toLocaleString()}  ${value ? `(${value/baseValue*100*precentage}%)` : ''} `, // Display value above bars
        color: "#000",
        font: { weight: "bold", size: winSize.w > UI_WIDTH_IS_MOBILE ? 22 : 14 },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: winSize.w > UI_WIDTH_IS_MOBILE?16:20, 
          },
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: Math.max(...values) * 1.1, // Add 20% padding to the highest bar

        ticks: {
          callback: function (value:any) {
            return `€${value.toLocaleString()}`; // Add € in Y-axis
          },
          font: {
            size: winSize.w > UI_WIDTH_IS_MOBILE?16:20, 
          },
        },
      },
    },
  };
  

  return <Bar data={data} options={options} />;
};

export default BarChart;
