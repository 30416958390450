import {Link, Outlet, useLocation, useParams, useSearchParams} from 'react-router-dom'

import Footer from './components/Footer'

import PathBarContainer from './GlobalLayout/PathBarContainer'
import SidebarContainer from './GlobalLayout/SidebarContainer'
import QfsActionsBar from './GlobalLayout/QfsActionsBar'
import WelcomSplashScreen from './GlobalLayout/WelcomSplashScreen'
import useFetchVolum from '../../hooks/useFetchVolum'
import { useEffect, useState } from 'react'
import { useAppContext } from '../../context/AppContext'
import { useQfsContext } from './context/qfsContext'

import SubscribtionPopup from './GlobalLayout/SubscribtionPopup'
import useNavigateTo from './hooks/useNavigateTo'
import { useSearchContext } from './context/searchContext'
import { useUIContext } from './context/UIContext'




export default function FoldersLayout() {

    const { currentLocation } = useQfsContext()
    const { volume:server } = useFetchVolum(currentLocation?.clusterId!, 'LocalClusters')
    const { setServer } = useAppContext()
    const [openSplashScreen, setopenSplashScreen] = useState(false);
    const [startTutorial, setstartTutorial] = useState(false);
    const { goTo } = useNavigateTo()
    const params = useParams()
    const location = useLocation()
    const { setOnlyWebpages} = useSearchContext()
        const { hidePanel, setHidePanel} = useUIContext()

        const [searchParams, setSearchParams] = useSearchParams();
const [isLoaded, setisLoaded] = useState(false);
    useEffect(() => {
      
      if(searchParams.get('fullWidth')){
        setHidePanel(true)
      }
      setisLoaded(true)
    }, []);
    
    useEffect(() => {
      
      if(!localStorage.getItem('tutorialDone') && !openSplashScreen){
        setTimeout(() => {
          setstartTutorial(true)
        }, 1000);
      }
    }, [openSplashScreen]);
    useEffect(() => {
      if(server){
        
        setServer(server)
      }else{ 
        setServer(null)
      }
    }, [server])
   
  return (
   
          
            <div className='flex-1  flex flex-col h-full overflow-hidden bg-sub-window'>
              
              
              {isLoaded && (
                <div className="flex-1  flex flex-col">
                  <PathBarContainer/>
                        <SidebarContainer
                        >
                            <>
                           

                                <div className={`flex-1 h-full`}>
                                    <Outlet/>
                                </div>
                                
                                
                            
                            </>
                                
                        </SidebarContainer>
                    
                </div>

              )}
              

            
                {/* <div className='absolute bottom-0 right-0'>
                    <div className="bg-skin-fill-inverted text-skin-inverted w-[100px] h-[40px]"></div>
                </div> */}
                
                <WelcomSplashScreen/>
                {/* <SplashScreen
                  open={openSplashScreen}
                  setOpen={setopenSplashScreen}
                />  */}
                {/* {startTutorial && <Tutorial/>} */}
              </div>
            

     
  )
}
