import { CameraIcon, CheckIcon } from '@heroicons/react/solid'
import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import i18n from '../../../../i18n'
import DetailsPageLayout from '../../components/DetailsPageLayout'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../components/icons/Icon'

export default function Qorddu() {
    const navigate = useNavigate()
    const {t} = useTranslation(['common', 'home'])

    
  return (
    <DetailsPageLayout>
    <div className='h-[calc(100vh-180px)] overflow-y-auto flex justify-center'>
        <div className='max-w-[330px] px-4 pt-14 space-y-5'>
            <div>
                <div className="w-[180px] h-[130px] border border-main rounded flex justify-center items-center">
                    <CameraIcon className='w-[50px] h-[50px] opacity-10 ' />
                </div>
            </div>
            <div className='space-y-4'>
                <p className="font-bold">
                    {t('home:qorddu.title')}
                </p>
                <p>
                {t('home:qorddu.block.p1')}  
                </p>
                <p>
                {t('home:qorddu.block.p2')}  
                </p>
        
                <button className={`p-1 rounded bg-hover-transparent !text-inherit h-max  -ml-1 w-max flex items-center space-x-2  `}  onClick={()=>navigate(`/home/${i18n.language}/pages/qorddu/vision`)} >
                    <Icon name='OpenWindow' className='icon-mini'/>
                    <p>{t('open')}</p>
                </button>
            </div>
        </div>
        <Outlet/>
    </div>

    </DetailsPageLayout>
  )
}
